import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./TotalProj.css";

const TotalUserProjectChart = ({ chartData }) => {
  const projectsData = [
    { x: new Date("2023-01-01").getTime(), y: 3000000 },
    { x: new Date("2023-02-01").getTime(), y: 4000000 },
    { x: new Date("2023-03-01").getTime(), y: 3500000 },
    { x: new Date("2023-04-01").getTime(), y: 5000000 },
    { x: new Date("2023-05-01").getTime(), y: 4500000 },
    { x: new Date("2023-06-01").getTime(), y: 5500000 },
    { x: new Date("2023-07-01").getTime(), y: 6000000 },
  ];

  const usersData = [
    { x: new Date("2023-01-01").getTime(), y: 1000 },
    { x: new Date("2023-02-01").getTime(), y: 1500 },
    { x: new Date("2023-03-01").getTime(), y: 1200 },
    { x: new Date("2023-04-01").getTime(), y: 1800 },
    { x: new Date("2023-05-01").getTime(), y: 1600 },
    { x: new Date("2023-06-01").getTime(), y: 2000 },
    { x: new Date("2023-07-01").getTime(), y: 2200 },
  ];

  const [activeTab, setActiveTab] = useState("projects");

  const getString = (milisecond) => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dt = new Date(milisecond);

    return `${month[dt.getMonth()]}, ${dt.getFullYear()}`;
  };

  const series = useMemo(() => {
    return [
      {
        name: activeTab === "projects" ? "Total Projects" : "Total Users",
        data:
          activeTab === "projects"
            ? chartData?.project?.map((ele) => ({
                ...ele,
                x: getString(ele.x),
              })) || []
            : chartData?.user?.map((ele) => ({
                ...ele,
                x: getString(ele.x),
              })) || [],
      },
    ];
  }, [chartData, activeTab]);

  const options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        tools: {
          download: false, // Show only the download button
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          selection: false,
          polygon: false,
          pan: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    xaxis: {
      type: "string",
      // labels: {
      //   format: "MMM",
      // },
    },
    tooltip: {
      shared: false,
      // x: {
      //   format: "MMM",
      // },
    },
  };

  const handleDownloadSVG = () => {
    const svg = document.querySelector("#chart svg");
    const svgBlob = new Blob([svg.outerHTML], { type: "image/svg+xml" });
    const url = URL.createObjectURL(svgBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "chart.svg";
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div>
        <button
          className={`chartBtn ${
            activeTab === "projects" ? "active" : "inactive"
          }`}
          onClick={() => setActiveTab("projects")}
        >
          Total Projects
        </button>
        <button
          className={`chartBtn ${
            activeTab === "users" ? "active" : "inactive"
          }`}
          onClick={() => setActiveTab("users")}
        >
          Total Users
        </button>
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default TotalUserProjectChart;
