export const ROLES = [
  {
    _id: "66ab2550ac4900c8ca8e7ac9",
    name: "Admin",
    __v: 0,
  },
  {
    _id: "66ab58972665ae9aa84c588b",
    name: "employee",
    createdAt: "2024-08-01T09:42:47.150Z",
    updatedAt: "2024-08-01T09:42:47.150Z",
    __v: 0,
  },
  {
    _id: "66ab589f2665ae9aa84c588f",
    name: "employer",
    createdAt: "2024-08-01T09:42:55.935Z",
    updatedAt: "2024-08-01T09:42:55.935Z",
    __v: 0,
  },
  {
    _id: "66ac7b81520df2f94754efbc",
    name: "chat support",
    createdAt: "2024-08-02T06:24:01.304Z",
    updatedAt: "2024-08-02T06:24:01.304Z",
    __v: 0,
  },
  {
    _id: "66acb419520df2f94754efc1",
    name: "Accountant",
    createdAt: "2024-08-02T10:25:29.686Z",
    updatedAt: "2024-08-02T10:25:29.686Z",
    __v: 0,
  },
];

export const EMPLOYEE_ROLE = "66ab58972665ae9aa84c588b";
export const EMPLOYER_ROLE = "66ab589f2665ae9aa84c588f";
export const CHAT_ROLE = "66ac7b81520df2f94754efbc";
export const ADMIN_ROLE = "66ab2550ac4900c8ca8e7ac9";
export const ACCOUNTANT_ROLE = "66acb419520df2f94754efc1";
