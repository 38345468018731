// src/Localization/faTranslations.js
const faTranslations = {
  // home: 'خانه',
  // browseJobs: 'مشاغل را مرور کنید',
  // help: 'کمک',
  // categories:'دسته بندی ها',
  // contactUs: 'تماس با ما',
  // websiteItSoftware: 'وب سایت، IT و نرم افزار',
  // designMediaArchitecture: 'طراحی، رسانه و معماری',
  // dataEntryAdmin: 'ورود اطلاعات و مدیریت',
  // education: 'تحصیلات',
  // artificialIntelligence: 'هوش مصنوعی',
  // signIn: 'ورود',
  // join: 'پیوستن',
  profile: "پروفایل",
  VerifyEmail: "تأیید ایمیل",
  BillingandPayments: "صورت‌حساب و پرداخت‌ها",
  // account: 'حساب',
  // dashboard: 'داشبورد',
  // logout: 'خروج',
  Categories: "دسته بندی ها",
  Home: "صفحه اصلی",
  BrowseJobs: "مشاهده کارها",
  Help: "کمک",
  Contact: "با ما تماس بگیرید",
  Contact2: "مخاطب",
  SignIn: "ورود",
  Join: "پیوستن",
  OneStop: "بستر کاریابی یک مرحله ای در ایران",
  Match: "مهارت ها و علایق خود را با مشاغل عالی مطابقت دهید.",
  Simple: "فرآیند درخواست ساده - یک کلیک برای درخواست",
  Build: "ساخت یک پروفایل قوی برای جلب توجه کارفرمایان",
  Connect: "ارتباط با شرکت ها و آژانس های برتر در ایران.",
  TwentyM: "پروژه تحویل داده شده",
  Active: "فریلنسر فعال",
  Safe: "ایمن و مطمعن",
  Trustworthy: "قابل اعتماد و حرفه ",
  TotalFreelance: "کل فریلنسرها",
  Positive: "نظر مثبت",
  Order: "سفارشهای دریافت شده",
  Projects: "پروژه های تکمیل شده",
  Files: "فایل ها",
  File: "فایل",
  addAmt: "لطفا مقدار اضافه کنید",
  selectDel: "لطفا زمان تحویل را انتخاب کنید",
  greaterThen0: "لطفا مقدار بیشتر از 0 را اضافه کنید.",
  editProporal: "ویرایش پیشنهاد",
  coverLet: "نامه همراه باید حداقل 10 کاراکتر باشد.",
  Trusted: "مورد اعتماد بهترین های جهان",
  BrowseTalent: "استعدادها را بر اساس دسته مرور کنید",
  Find: ".آن را ساده میکند WorkWaveHub .تنها با چند کلیک بهترین مهارت ها و تجربه ها را برای پروژه بعدی خود بیابید",
  All: "همه دسته بندی ها",
  Website: "وب سایت، فناوری اطلاعات و نرم افزار",
  Design: "طراحی، رسانه و معماری",
  Data: "ورود اطلاعات و مدیریت",
  Education: "تحصیلات",
  Artificial: "هوش مصنوعی",
  Talented:
    "از استخدام استعدادهای برجسته برای کسب و کارتان استفاده کنید - بازار کار ایران",
  Search:
    "جستجو و ارتباط با مجموعه وسیعی از متخصصان ماهر در صنایع مختلف. پلت فرم نوآورانه ما فرآیند استخدام را ساده می کند و در زمان و منابع شما صرفه جویی می کند. پیدا کردن بهترینها برای موقعیت های باز شغلی شما-از توسعه دهندگان باتجربه گرفته تا جادوگران بازاریابی و هرچیز این ما بین. کسب و کار خود را با استعدادهای برتر ایرانی تقویت کنید.شروع کنید WorkWaveHub امروز با  ",
  The: "بهترین استعداد",
  OurPlatform:
    "پلتفرم ما شما را با مجموعه وسیعی از متخصصان ایرانی از پیش بررسی شده در صنایع مختلف مرتبط می کند.",
  Fast: "پیشنهادهای سریع",
  WorkWaveHubs:
    " WorkWaveHubفرایند کارامد  به شما امکان می‌دهد به سرعت شغل ارسال کنید و پیشنهادات را از نامزدهای واجد شرایط دریافت کنید. ",
  Quality: "کار با کیفیت",
  Our: "تمرکز ما بر متخصصان ماهر تضمین می کند که کار با کیفیت بالا را از متخصصان واجد شرایط و ماهر دریافت  کنید.",
  Be: "در کنترل باشد",
  WorkWaveHub2:
    "پلت فرم WorkWaveHub ابزارهای ارتباطی واضح و گزینه های پرداخت امن را فراهم می کند و به شما کنترل کامل بر کل فرآیند استخدام می دهد.",
  How: "WorkWaveHub چگونه کار می کند؟",
  Create: "پروفایل خود را ایجاد کنید",
  Showcase:
    "مهارت ها و تجربه خود را به نمایش بگذارید تا در بین شرکت های برتر ایرانی متمایز شوید.",
  FindPerfect: "مشاغل ایده آل را پیدا کنید",
  SearchBrowse: "هزاران فرصت را در صنایع مختلف در ایران جستجو و مرور کنید.",
  SimpleApp: "برنامه ساده",
  Apply:
    "با یک کلیک برای مشاغل درخواست دهید و با کارفرمایان بالقوه ارتباط برقرار کنید.",
  Manage: "حرفه خود را مدیریت کنید",
  Track:
    "مدیریت کنید برنامه ها را ردیابی کنید، هشدارهای شغلی را دریافت کنید وWorkWaveHubسفر شغلی خود را در ",
  Love: "عاشق کسب درآمد با Workwavehub",
  Clients: "مشتریان حرفه ای را در Workwavehub رتبه بندی می کنند",
  Customers: "95 درصد از مشتریان از دیدن مترجمان آزاد خود راضی هستند",
  AwardWinner: "برنده ی جایزه",
  HomeOw: "مالکیت خانه",
  Followus: "ما را دنبال کنید",
  Terms: "سیاست حفظ حریم خصوصی",
  Privacy: "سیاست حفظ حریم خصوصی",
  Site: "نقشه سایت ",
  AboutOne: "در باره",
  CategoriesOne: "دسته بندی ها",
  WebsiteSoft: "وب سایت، فناوری اطلاعات و نرم افزار",
  DesignMedia: "طراحی، رسانه و معماری",
  DataEntry: "ورود اطلاعات و مدیریت",
  Sales: "فروش و بازاریابی",
  Product: "منبع یابی و تولید محصول",
  Translation: "ترجمه و زبان ها",
  Freight: "باربری، حمل و نقل و حمل و نقل",
  EducationOne: "تحصیلات",
  ArtificialIntel: "هوش مصنوعی",
  Health: "خدمات بهداشت و درمان",
  Jobs: "مشاغل برای هر کسی",
  Support: "حمایت کردن",
  HelpSupport: "راهنما و پشتیبانی",
  Trust: "اعتماد و ایمنی",
  Subscribe: "اعتماد و ایمنی",
  Your: "آدرس ایمیل شما",
  Send: "ارسال",
  Downloads: "دانلودها از",
  AllRights: "تمامی حقوق محفوظ است @workwavehub",
  Explore: "با WorkWaveHub پتانسیل های شغلی خود را در ایران کشف کنید.",
  FindJob: "فرصت های شغلی هیجان انگیز را در صنایع مختلف پیدا کنید.",
  ShowcaseSkill: "مهارت ها و تجربیات خود را به شرکت های برتر نشان دهید.",
  Get: "بر اساس علایق خود با مشاغل عالی مطابقت داشته باشید.",
  ManageJob: "با ابزار WorkWaveHub جستجوی شغل خود را به طور موثر مدیریت کنید.",
  Already: "از قبل حساب کاربری دارید؟ ورود",
  JoinUs: "به ما بپیوند!",
  JoinGrow: "به جامعه رو به رشد متخصصان ما در ایران بپیوندید.",
  Employee: "کارمند",
  Personal: "حساب شخصی برای مدیریت تمام فعالیت های شما.",
  Employer: "کارفرما",
  Own: "مالک یا متعلق به یک شرکت باشید، این برای شماست.",
  Register: "ثبت حساب کارفرما!",
  RegisterEmployee: "تسجيل حساب الموظف!",
  WorkWaveHub:
    "WorkWaveHub برنامه یک مرحله ای شما برای یافتن استعدادهای مناسب در ایران است. ما از فناوری هوشمندانه برای تطبیق مهارت ها و علایق با شغل شما استفاده می کنیم.",
  First: "نام کوچک *",
  Last: "نام خانوادگی",
  Email: "آدرس ایمیل*",
  password: "كلمة المرور",
  CreatePass: "ایجاد رمز عبور*",
  I: "من با شرایط و ضوابط موافقم",
  SignUp: "ثبت نام",
  RegisterGoogle: "لطفاً شماره تلفن خود را تأیید کنید",
  Please: "لطفاً شماره تلفن خود را تأیید کنید",
  joinWWh:
    "با اطمینان به انجمن WorkWaveHub بپیوندید. شماره خود را امروز تأیید کنید!",
  Phone: "اطلاعات شما ایمن است",
  SendCode: "اطلاعات شما ایمن است",
  YourInfo: "اطلاعات شما ایمن است",
  selectCategoryFirst: "لطفاً ابتدا دسته را انتخاب کنید",
  MaxBudgetGreater: "لطفا حداکثر بودجه را بیشتر از بودجه تخمینی وارد کنید",
  Almost:
    "تقریباً وجود دارد! شماره تلفن خود را تأیید کنید تا حساب خود را ایمن کنید. تأیید شماره تلفن خود یک گام سریع برای تکمیل ثبت نام است.",
  We: " ما یک کد تأیید به شماره تلفن شما [5 رقم آخر پوشانده شده] ارسال کرده ایم. برای تکمیل ثبت نام WorkWaveHub خود کد زیر را وارد کنید.",
  Didnts:
    "کد را دریافت نکردید؟ معمولاً ظرف 30 ثانیه می رسد. اگر بیشتر طول کشید می توانید دوباره آن را بفرستید.",
  OTP: "تأیید OTP",
  WeOTP: "ما یک OTP به شماره تلفن شما ارسال کرده ایم!",
  resendOTP: "ارسال مجدد OTP؟",
  DidntOne: "OTP دریافت نکردید؟",
  Verify: "تأیید کنید",
  Skills: "مهارت هایی که به شما کمک می کند شغلی پیدا کنید",
  WorkWaveHubHelp:
    "WorkWaveHub می تواند به شما کمک کند مشاغلی را پیدا کنید که با مهارت های شگفت انگیز شما مطابقت دارد! امروز یک پروفایل عالی بسازید!",
  Suggested: "مهارت های پیشنهادی",
  Save: "ذخیره و ادامه",
  Complete: "مشخصات خود را کامل کنید!",
  YourChance:
    "نمایه شما شانس شما برای متمایز شدن از جمعیت است. به خوبی گرد و جالب خود را نشان دهید.",
  Next: "بعد",
  Tell: "برای ما کمی در مورد خودتان بگویید",
  Show: "به کارفرمایان نشان دهید که چرا منحصربه‌فرد هستید و چه چیزی را می‌توانید روی میز بیاورید.",
  What: "یک توضیح یک خطی در مورد خودتان بنویسید.",
  Describe: "خودت را توصیف کن",
  IStrong:
    "با فرزانه رفتم که پورتفولیوی قوی و سبک ارتباطی روشنی داشت. در طول پروژه، او من را در جریان پیشرفت خود قرار داد و بازخوردهای من را به طور یکپارچه در نظر گرفت. محصول نهایی فوق العاده است – وب سایتی که نه تنها زیباست، بلکه سرنخ های بیشتری نیز ایجاد می کند.  من خیلی خوشحالم که WorkWaveHub را پیدا کردم. این یک پلت فرم فوق العاده برای تجارت در ایران است تا با متخصصان با استعداد ارتباط برقرار کنند.",
  Darius: "داریوش فرهادی، مدیرعامل",
  Welcome: "خوش برگشتی!",
  To: "برای شروع این سفر، به ما بگویید چه نوع حسابی را می خواهید باز کنید.",
  EmailAddd: "آدرس ایمیل*",
  EnterEmailAdd: "آدرس ایمیل را وارد کن",
  Password: "کلمه عبور*",
  Enter: "رمز عبور خود را وارد کنید",
  Remember: "مرا به خاطر بسپار",
  Forgot: "رمز عبور را فراموش کرده اید؟",
  RegisterACCC: "ثبت حساب",
  Login: "با گوگل وارد شوید",
  ToReset:
    "برای بازنشانی رمز عبور، به ایمیل یا شماره موبایل خود نیاز دارید که قابل احراز هویت باشد",
  Reset: "بازنشانی رمز عبور",
  Back: "بازگشت به صفحه ورود",
  Check: "ایمیلتان را چک کنید",
  Open: "ایمیل خود را باز کنید",
  You: "ایمیل را دریافت نکرده اید؟  ارسال مجدد ",
  Successfully: "با موفقیت",
  YourPass1:
    "رمز عبور شما به روز شده است، لطفاً رمز عبور خود را مرتباً تغییر دهید تا این اتفاق نیفتد",
  Continue: "ادامه هید",
  DesignCreative: " طراحی و خلاقیت",
  Give: "با طراحی UX جامد، یک تجربه آنلاین صاف را به بازدیدکننده خود بدهید",
  WhatLook: " دنبال چی میگردی؟",
  ITandSoftware: "فناوری اطلاعات و نرم افزار",
  Medical: "پزشکی",
  Architecture: "معماری",
  Choose: "دسته را انتخاب کنید",
  SearchText: "جستجو کردن",
  noChatFound: "چتی پیدا نشد!",
  Filters: "فیلترها",
  ProjectTypeOne: "نوع پروژه",
  Hourly: "نرخ ساعتی",
  Fixed: "قیمت ثابت",
  Type: "یک پیام تایپ کنید",
  BudgetOne: "بودجه",
  Location: "محل",
  Clear: "پاک کردن",
  Top: "نتایج برتر",
  Sort: "مرتب سازی بر اساس",
  Latest: "آخرین",
  Bids: "مناقصه می دهد",
  Budget: "بودجه",
  USD: "دلار آمریکا",
  minutes: "دقیقه قبل",
  ProjectDetails: "جزئیات پروژه",
  Skill: "مهارت مورد نیاز",
  Place: "طرح پیشنهادی در این پروژه",
  Proposal: "مبلغ پیشنهادی",
  HowProj: "این پروژه چقدر طول می کشد؟",
  Select: "انتخاب کنید",
  Cover: "جلد نامه",
  Attachments: "پیوست ها",
  perHour: "در ساعت",
  Notifications: "اطلاعیه",
  phoneVerified: "شماره تلفن تایید شد",
  phoneNotVerified: "شماره تلفن تأیید نشده است",
  emailVerified: "ایمیل تأییده شده است",
  emailNotVerified: "ایمیل تایید نشد",
  AwardData:
    "هرچه کار شما در پلتفرم WorkWaveHub بیشتر باشد و وظایف را به موقع انجام دهید. بیشتر نمایه قوی خواهید داشت. نمایه قوی به شما کمک می کند تا در سطوح مختلف حرکت کنید. برای هر شغل، باید نظرات خود را از استخدام کننده شغل دریافت کنید. این بررسی‌ها می‌توانند هنگام مناقصه برای پروژه‌ها و تکمیل موفقیت‌آمیز آن‌ها مزیتی به شما بدهند.",
  Drag: "سایر پیشنهادات",
  Other: "سایر پیشنهادات",
  in: " در 7 روز",
  Replies: "در عرض یک ساعت پاسخ می دهد",
  Similar: "پروژه های مشابه ",
  SendProp: "ارسال پیشنهاد",
  Share: "این پروژه را با دیگران به اشتراک بگذارید",
  Copy: "لینک را کپی کنید",
  Report: "گزارش پروژه",
  Client: "تایید مشتری",
  Dashboard: "داشبورد",
  Logout: "خروج",
  My: "پیشنهادات من",
  Submitted: "پیشنهادات ارسال شده",
  Archive: "آرشیو پیشنهادات",
  ProjectName: "نام پروژه",
  Proposals: "پیشنهادات",
  Average: "پیشنهاد متوسط",
  MyProposal: "پیشنهاد من",
  Action: "عمل",
  Status: " وضعیت",
  MyProj: "پروژه های من",
  ProjectDetailsOne: " جزئیات پروژه",
  AllPop: "همه پیشنهادات",
  SkillReq: "مهارت مورد نیاز",
  Ongoing: "پروژه های در حال انجام",
  Cost: "هزینه",
  Duration: "مدت زمان",
  inDays: "(در روز)",
  MyBook: " نشانک من",
  Bookmarked: "پروژه های نشانه گذاری شده",
  Message: "پیام. ",
  SendMessage: "پیام فرستادن",
  Type: "یک پیام تایپ کنید",
  Delete: "چت را حذف کنید",
  ActiveOne: "فعال",
  MyProf: "پروفایل من",
  Profile: "جزئیات نمایه",
  Max: '"حداکثر اندازه فایل 1 مگابایت، حداقل ابعاد: 330x300 و فایل های مناسب می باشدjpg و .png"',
  Introduce: "خودتان را معرفی کنید",
  Languages: "سطح زبان ها",
  Language: "زبان",
  City: "شهر",
  Country: "کشور",
  TypeOne: "تایپ کنید",
  Specialization: "تخصص",
  Gender: "جنسیت",
  HourlyRate: "نرخ ساعتی",
  PhoneNo: "شماره تلفن",
  EmailAdd: "آدرس ایمیل",
  LastName: "نام خانوادگی",
  FirstName: "نام کوچک",
  SkillsOne: "مهارت ها",
  SkillsTwo: "مهارت 1",
  Point: "نقطه",
  Are: "آیا برای خارج شدن مطمئن هستید؟",
  Cancel: "لغو کنید",
  Yes: "بله",
  RegisterEmployee: "ثبت حساب کارمندی!",
  WorkWaveHubIran:
    "WorkWaveHub برنامه یک مرحله ای شما برای یافتن استعدادهای مناسب در ایران است. ما از فناوری هوشمندانه برای تطبیق مهارت ها و علایق با شغل شما استفاده می کنیم.",
  Full: "نام و نام خانوادگی",
  UserNameOne: "نام کاربری",
  PhoneOne: "شماره تلفن",
  TermsAnd: "من با شرایط و ضوابط موافقم",
  SignupOne: "ثبت نام",
  OTPVerify: " تأیید OTP",
  WeVerify:
    "ما یک کد تأیید را از طریق پیامک برای شما ارسال می کنیم. فقط آن را در برنامه وارد کنید، و شما آماده هستید.",
  SkillsInterst: "مهارت ها و علاقه ای که به دنبال آن هستید",
  Post: "شغل خود را پست کنید و بهترین استعدادها را برای شغل خود استخدام کنید - به همین سادگی!",
  SuggestedSkill: "مهارت های پیشنهادی",
  SaveContinue: "ذخیره و ادامه",
  SavePost: "ذخیره و ارسال کنید",
  CreateProj: "ایجاد پروژه ها",
  BrowseProf: "نمایه را مرور کنید",
  HireSkill:
    "متخصصان مستقل ماهر را برای هر پروژه ای از طریق اینترنت استخدام کنید.",
  LargestHub: "بزرگترین مرکز متخصصین مستقل ایران.",
  JobOpputunities: "انواع فرصت های شغلی.",
  EnjoySavings: "از صرفه جویی تا 90٪ لذت ببرید و قیمت های رایگان دریافت کنید.",
  PayOnly: "پرداخت فقط در صورت رضایت شما",
  FoPurpose: "به منظور مقررات صنعت، جزئیات شما مورد نیاز است.",
  EnterUserName: "نام کاربری را وارد کنید",
  MaxRow: "حداکثر 4 ردیف",
  Submite: "ارسال",
  EnterFullName: "نام کامل را وارد کنید",
  EnterPass: "رمز عبور را وارد کنید",
  Tell2: "مهارت های خود را به ما بگویید",
  ThiHelp: "این به ما کمک می کند مشاغلی را برای شما پیشنهاد کنیم.",
  ShownEmployee:
    "به کارفرمایان نشان دهید که چرا منحصربه‌فرد هستید و چه چیزی را می‌توانید روی میز بیاورید.",
  WriteLine: "یک توضیح یک خطی در مورد خودتان بنویسید.",
  BeginJourney:
    "برای شروع این سفر، به ما بگویید چه نوع حسابی را می خواهید باز کنید",
  LoginEmployee: "وارد شدن",
  SignUpEmployee: "ثبت نام",
  DontHavAccount: "حساب کاربری ندارید؟",
  BackToHome: "بازگشت به خانه",
  FullNameIsRequired: "نام کامل الزامی است",
  EmailIsRequired: "ایمیل مورد نیاز است",
  PasswordIsRequired: "رمز عبور مورد نیاز است",
  FullNameAtleastThree: "نام کامل باید بیش از 3 کاراکتر باشد",
  InvalidEmailFormat: "قالب ایمیل نامعتبر است",
  PassWordMustBe8Characters:
    "رمز عبور باید حداقل 8 کاراکتر و شامل یک حرف بزرگ باشد. یک حرف کوچک و یک کاراکتر خاص",
  YouMustAgree: "شما باید با شرایط و ضوابط موافقت کنید",
  UserNameIsRequired: "نام کاربری لازم است",
  UserNameIs3Characters: "نام کاربری باید بیش از 3 کاراکتر باشد",
  PleaseEnterPhoneNumber: "لطفا شماره تلفن را وارد کنید",
  PleaseEnterValidNumber: "لطفا یک شماره تلفن معتبر وارد کنید",
  Browse: "مرور کردن",
  CreateProjectAndStartActivities:
    "پروژه ها را ایجاد کنید و فعالیت های خود را شروع کنید.",
  BrowseProfileAndStartActivities:
    "پروفایل ها را مرور کنید و فعالیت های خود را شروع کنید.",
  UploadAttachments: "آپلود فایل های پیوست",
  NoDocumentsFound: "هیچ سندی یافت نشد",
  BackButton: "بازگشت",
  FileName: "نام فایل",
  CompletedProjects: "پروژه های انجام شده",
  Milestones: "نقاط عطف",
  MileStoneName: "نام نقطه عطف",
  Document: "سند",
  Filter: "فیلتر کنید",
  MyBookmarks: "نشانک های من",
  BookmarkProjects: "پروژه های نشانک ",
  MyProfile: "پروفایل من",
  Skills: "مهارت ها",
  ChangePassword: "رمز عبور را تغییر دهید",
  DeleteAccount: "حذف حساب کاربری",
  VerifyEmail: "تأیید ایمیل",
  State: "حالت",
  Save: "صرفه جویی",
  WhatAreTheMainSkills: "مهارت اصلی مورد نیاز برای کار شما چیست؟",
  UpdateSkill: "به روز رسانی مهارت",
  OldPassword: "رمز عبور قدیمی",
  EnterOldPassword: "رمز عبور قدیمی را وارد کنید",
  NewPassword: "رمز عبور جدید",
  EnterNewPassword: "رمز عبور جدید را وارد کنید",
  ConfirmPassword: "رمز عبور جدید را تأیید کنید",
  EnterConfirmPassword: "تایید رمز عبور جدید را وارد کنید",
  CloseAccount: "بستن حساب",
  WarningCloseAccount:
    "هشدار: اگر حساب خود را ببندید، اشتراک تمام اشتراک خود لغو می‌شوید و برای همیشه دسترسی را از دست خواهید داد.",
  DeleteAccountEmployee: "حذف",
  EmailAlreadyVerified: "ایمیل قبلاً تأیید شده است",
  SendOTP: "ارسال OTP",
  PleaseEnterOpt: "لطفا OTP را وارد کنید",
  OTP6Digit: "OTP باید 6 رقمی باشد",
  NewPasswodRequired: "رمز عبور جدید مورد نیاز است",
  ConfirmPasswordRequired: "تایید رمز عبور لازم است",
  PassWordNotMatch: "رمزهای ورود مطابقت ندارند",
  SentOPT: "ما یک OTP به شماره تلفن شما ارسال کرده ایم!",
  PleaseAddSkills: "لطفا مهارت ها را اضافه کنید",
  BrowseProject: "پروژه را مرور کنید",
  DashboardBookMark: "نشانک",
  DashboardProfile: "مشخصات",
  Bids: "مناقصه دو ماه دیگر به پایان می رسد",
  SkillsRequired: "مهارت های مورد نیاز",
  TimeDuration: "مدت زمان",
  AwardProject: "پروژه جایزه به",
  sendAnOffer: "یک پیشنهاد ارسال کنید",
  projectMilestone: "نقاط عطف پروژه",
  Milestone: "نقطه عطف",
  hireFor: "استخدام برای",
  subtotal: "جمع فرعی",
  marketPlaceFee: "هزینه بازار",
  pay: "پرداخت",
  estimatedTotal: "تخمین کلی",
  addNewMilestone: "نقطه عطف جدید اضافه کنید",
  areYouSure: "آیا مطمئنید که میخواهید حذف کنید؟",
  onceDeleted: `پس از حذف، بازیابی اطلاعات ممکن است امکان پذیر نباشد. برای تأیید روی «بله» یا برای بازگشت روی «لغو» کلیک کنید.`,
  Balance: "تعادل",
  paypalPaymentDes: "پرداخت آنلاین امن از طریق درگاه پی پال",
  stripePaymentDes: "پرداخت آنلاین امن از طریق درگاه Stripe",
  cryptoPaymentDes: "پرداخت آنلاین امن از طریق درگاه Crypto",
  Payment: "پرداخت",
  deleteProject: "آیا مطمئن هستید که می خواهید پروژه را حذف کنید؟",
  addBillingMethod: "افزودن روش صورتحساب",
  CoverLetter: "جلد نامه",
  EnterProjectDetails: "جزئیات پروژه خود را وارد کنید",
  NoProposalFound: "هیچ پیشنهادی یافت نشد",
  UpdateProposal: "به روز رسانی پیشنهاد",
  PostedProjects: "پروژه های ارسال شده",
  Chat: "چت کنید",
  Award: "جایزه",
  NoDataFound: "داده ای یافت نشد",
  EditMileStone: "Milestone را ویرایش کنید",
  MileStoneTitle: "عنوان نقطه عطف",
  MileStoneDescription: " شرح نقطه عطف",
  DepositeAmount: "مبلغ سپرده",
  amt: "میزان",
  Address: "نشانی",
  timeLeft: "زمان باقی مانده",
  Note: "توجه داشته باشید",
  doNotReload:
    "لطفاً تا زمانی که تراکنش در حال انجام است، به عقب یا این صفحه را بارگذاری مجدد نکنید",
  PaymentId: "شناسه پرداخت",
  suffAmt: "لطفا مبلغ کافی برای کارمزد تراکنش ارسال کنید",
  UpdateMileStone: "به روز رسانی",
  DeleteMilestone: " آیا مطمئن هستید که می خواهید نقطه عطف را حذف کنید؟",
  Finish: "پایان",
  AddMileStone: "Milestone را اضافه کنید",
  getOTP: "OTP را دریافت کنید",
  cryptoAdd: "آدرس کیف پول کریپتو",
  LeaveAReview: "نظر بدهید",
  HowToYouRating: "به کار کردن چه امتیازی می دهید؟",
  WriteAReview: "نظر بنویسید...",
  SubmitReview: "ارسال بررسی",
  BasicInformation: "اطلاعات اولیه",
  selectOpt: "لطفاً یک گزینه را انتخاب کنید",
  enterTitle: "لطفا عنوان را وارد کنید",
  enterMsg: "لطفا پیام را وارد کنید",
  detailsPRojectErr: "جزئیات پروژه باید حداقل 10 کاراکتر باشد.",
  titleCharErr: "عنوان باید حداقل 3 کاراکتر باشد.",
  validDepoistAmt: "لطفا یک مبلغ واریز معتبر اضافه کنید.",
  greaterDepoistAmt: "لطفا مبلغ سپرده بزرگتر از 0 را اضافه کنید.",
  coinPayment: "پرداخت های سکه ای",
  closeIn: "نزدیک",
  close: "بستن",
  firstNameReq: "نام مورد نیاز است",
  lastNameReq: "نام خانوادگی الزامی است",
  crytpoReq: "لطفا آدرس Crypto را وارد کنید",
  hourRateReq: "نرخ هر ساعت نمی تواند کمتر از 0 باشد",
  validCrypto: "لطفا آدرس معتبر USDT Crypto را وارد کنید",
  stateNameReq: "نام ایالت الزامی است",
  firstNameGreater3: "نام باید بیش از 3 کاراکتر باشد",
  lastNameGreater3: "نام خانوادگی باید بیش از 3 کاراکتر باشد",
  Category: "دسته بندی",
  ProjectTitle: "عنوان پروژه",
  ProjectDescription: "شرح پروژه",
  ProjectDuration: "مدت زمان پروژه",
  EstimateBudget: "برآورد بودجه",
  MinBudegte: "حداقل بودجه",
  MaxBudget: "حداکثر بودجه",
  ProjectTitle3: "عنوان پروژه باید حداقل 3 کاراکتر باشد.",
  ProjectDetail25: "جزئیات پروژه باید حداقل 25 کاراکتر باشد.",
  PleaseSelectCategory: "لطفاً یک دسته را انتخاب کنید.",
  PleaseSelectPaymentType: "لطفا نوع پرداخت را انتخاب کنید",
  PleaseSelectProjectType: "لطفا نوع پروژه را انتخاب کنید",
  PleaseSelectEstimateBudget: "لطفاً برآورد بودجه را انتخاب کنید",
  PleaseSelectSkills: "لطفا مهارت ها را انتخاب کنید",
  PleaseSelectLocation: "لطفا مکان را انتخاب کنید",
  PleaseSelectDuration: "لطفا مدت زمان پروژه را انتخاب کنید",
  PleaseSelectPrice: "لطفا قیمت را انتخاب کنید",
  PleaseSelectMinBudget: "لطفا حداقل بودجه را انتخاب کنید",
  PleaseSelectMaxBudget: "لطفا حداکثر بودجه را انتخاب کنید",
  DragandDrop: "یک تصویر را در اینجا بکشید و رها کنید یا کلیک کنید",
  MaxBudgetShould: "حداکثر بودجه نباید بیشتر از تخمین بودجه انتخاب شده باشد",
  EnterValidNumber: "لطفا یک شماره معتبر وارد کنید",
  MaxMinGreater: "حداکثر بودجه باید بیشتر از حداقل بودجه باشد",
  MinBudgetShouldBe: "حداقل بودجه نباید بیشتر از تخمین بودجه انتخابی باشد",
  EditProject: "ویرایش پروژه",
  ProjectType: "لطفا نوع پروژه را انتخاب کنید",
  DepositeAmount: "مبلغ واریز",
  PnoneAlreadyExists: "شماره تلفن از قبل وجود دارد",
  SessionExpires:
    "جلسه شما به پایان می رسد. برای بازگشت، باید دوباره وارد شوید. آیا مطمئن هستید که می‌خواهید ادامه دهید؟",
  Settings: "تنظیمات",
  BillingandPayments: "صورت‌حساب و پرداخت‌ها",
  EmployeeAbout: "درباره کارمند",
  Reviews: "بررسی ها",
  NoReviews: "در حال حاضر هیچ بررسی وجود ندارد.",
  AwardEmployee: "کارمند جایزه",
  AwardEmployeeSeek:
    "من به دنبال یک توسعه دهنده برنامه با تجربه و کارآمد برای ایجاد یک برنامه شبکه اجتماعی نوآورانه برای پلتفرم iOS هستم",
  AwardRequest: "درخواست جایزه",
  Verification: "تایید",
  TopSkills: "مهارت برتر",
  TitleShouldBe5Characters: "عنوان باید حداقل 5 کاراکتر باشد.",
  DescriptionShouldBe5Characters: "توضیحات باید حداقل 5 کاراکتر باشد.",
  PleaseAddTime: "لطفا زمان اضافه کنید",
  PleaseAddDeposite: "لطفا مبلغ واریز را اضافه کنید",
  PleaseGiveStar: "لطفا حداقل 1 ستاره بدهید.",
  ReviewShouldbe5: "مرور باید حداقل 5 کاراکتر باشد.",
  LoadMore: "بارگذاری بیشتر",
  DonateOneTake: "به روشنایی زندگی جوانان در ایران کمک کنید",
  DonateTwoTake:
    "بیایید با هم تأثیر مثبتی بر زندگی فرزندان شایسته بگذاریم. امروز کمک مالی کنید و به روشن کردن راه برای روشن تر کمک کنید فردا.",
  Dontae: "اهدا کنید",
  FindSol: "برای شروع سریع در WorkWaveHub راه حل ها را پیدا کنید",
  LetsGet: "بیایید به شما کمک کنیم.",
  HaveTrouble:
    "اگر با حساب، پروژه یا پرداخت های خود مشکل دارید، نگران نباشید! تیم پشتیبانی ما همیشه خوشحال است که به شما کمک کند.",
  HowWeCan: "ما چگونه می توانیم به شما کمک کنیم؟",
  TitleContact: "عنوان",
  describePlease: "لطفا نیازهای خود را شرح دهید",
  contactone:
    " آیا مشکلی وجود دارد یا فقط یک سوال است؟ شما می توانید به ما اطلاع دهید یا هر چیزی را بپرسید.",
  contacttwo: "در تماس باشید",
  PricingFees: "قیمت و کارمزد",
  privacyPolicy: "سیاست حفظ حریم خصوصی",
  privacyPolicy1:
    "این خط‌مشی رازداری خط‌مشی‌ها و رویه‌های ما را در مورد جمع‌آوری، استفاده و افشای اطلاعات شما هنگام استفاده از سرویس توضیح می‌دهد و به شما در مورد حقوق حریم خصوصی شما و نحوه محافظت قانون از شما می‌گوید.",
  privacyPolicy2: `ما از داده های شخصی شما برای ارائه و بهبود خدمات استفاده می کنیم. توسط
            با استفاده از این سرویس، شما با جمع آوری و استفاده از آن موافقت می کنید
            اطلاعات مطابق با این سیاست حفظ حریم خصوصی.`,
  privacyPolicy3: `تفسیر و تعاریف`,
  privacyPolicy4: `تفسیر`,
  privacyPolicy5: `کلماتی که حرف ابتدایی آن ها با حروف بزرگ نوشته می شود معانی دارند
              تحت شرایط زیر تعریف شده است. تعاریف زیر
              صرف نظر از اینکه در آنها ظاهر می شوند یا نه، معنی یکسانی خواهند داشت
              مفرد یا جمع`,
  privacyPolicy6: `تعاریف`,
  privacyPolicy7: `برای اهداف این سیاست حفظ حریم خصوصی:`,
  privacyPolicy8: `حساب`,
  privacyPolicy9: `به معنای یک حساب منحصر به فرد است
                برای دسترسی شما به سرویس یا بخش هایی از سرویس ما ایجاد شده است.`,
  privacyPolicy10: `وابسته`,
  privacyPolicy11: `به معنای موجودی است که
                کنترل می کند، توسط a کنترل می شود یا تحت کنترل مشترک با a است
                حزب، که در آن "کنترل" به معنای مالکیت 50٪ یا بیشتر از آن است
                سهام، بهره سهام یا سایر اوراق بهادار که حق رای دارند
                برای انتخاب مدیران یا سایر مقامات مدیریتی.`,
  privacyPolicy12: `کاربرد`,
  privacyPolicy13: `به WorkWaveHub اشاره دارد،
                نرم افزار ارائه شده توسط شرکت`,
  privacyPolicy14: `شرکت`,
  privacyPolicy15: `(به عنوان "شرکت" نامیده می شود،
                «ما»، «ما» یا «ما» در این قرارداد) به WorkWaveHub اشاره دارد،
                xxxxxx.`,
  privacyPolicy16: `بیسکویت ها`,
  privacyPolicy17: `فایل های کوچکی هستند که روی شما قرار می گیرند
                رایانه، دستگاه تلفن همراه یا هر دستگاه دیگری توسط یک وب سایت،
                حاوی جزئیات تاریخچه مرور شما در آن وب سایت است
                در میان کاربردهای فراوان آن`,
  privacyPolicy18: `کشور`,
  privacyPolicy19: `اشاره به ایران دارد.`,
  privacyPolicy20: `دستگاه`,
  privacyPolicy21: `به معنای هر دستگاهی است که می تواند به آن دسترسی داشته باشد
                خدماتی مانند کامپیوتر، تلفن همراه یا تبلت دیجیتال.`,
  privacyPolicy22: `اطلاعات شخصی`,
  privacyPolicy23: `هر اطلاعاتی است که مربوط می شود
                به یک فرد شناسایی شده یا قابل شناسایی`,
  privacyPolicy24: `سرویس`,
  privacyPolicy25: `اشاره به برنامه یا
                وب سایت یا هر دو.`,
  privacyPolicy26: `ارائه دهنده خدمات`,
  privacyPolicy27: `به معنای هر طبیعی یا قانونی است
                شخصی که داده ها را از طرف شرکت پردازش می کند. آی تی
                اشاره به شرکت های شخص ثالث یا افراد استخدام شده توسط
                شرکت برای تسهیل خدمات، برای ارائه خدمات در
                از طرف شرکت برای انجام خدمات مربوط به
                خدمات یا کمک به شرکت در تجزیه و تحلیل نحوه سرویس
                استفاده شده.`,
  privacyPolicy28: `خدمات رسانه های اجتماعی شخص ثالث`,
  privacyPolicy29: `اشاره به هر
                وب سایت یا هر وب سایت شبکه اجتماعی که کاربر از طریق آن می تواند
                برای استفاده از سرویس وارد شوید یا یک حساب کاربری ایجاد کنید. `,
  privacyPolicy30: `داده های استفاده`,
  privacyPolicy31: `به داده های جمع آوری شده اشاره دارد
                به صورت خودکار، یا با استفاده از سرویس ایجاد می شود یا
                از زیرساخت سرویس (به عنوان مثال،
                مدت بازدید از صفحه).`,
  privacyPolicy32: `سایت اینترنتی`,
  privacyPolicy33: `اشاره به WorkWaveHub، قابل دسترسی از`,
  privacyPolicy34: `شما`,
  privacyPolicy35: `به معنای دسترسی یا استفاده از فردی است
                خدمات، یا شرکت، یا اشخاص حقوقی دیگر از طرف
                که چنین فردی به خدمات دسترسی دارد یا از آن استفاده می کند
                مناسب.`,
  privacyPolicy36: `جمع آوری و استفاده از داده های شخصی شما`,
  privacyPolicy37: `انواع داده های جمع آوری شده`,
  privacyPolicy38: `اطلاعات شخصی`,
  privacyPolicy39: `در حین استفاده از خدمات ما، ممکن است از شما بخواهیم موارد خاصی را در اختیار ما قرار دهید
              اطلاعات قابل شناسایی شخصی که می توان از آنها برای تماس یا
              شناسایی شما اطلاعات قابل شناسایی شخصی ممکن است شامل اما
              محدود به:`,
  privacyPolicy40: `آدرس ایمیل`,
  privacyPolicy41: `نام و نام خانوادگی`,
  privacyPolicy42: `شماره تلفن`,
  privacyPolicy43: `آدرس حقیقی`,
  privacyPolicy44: `هنگام استفاده از سرویس، داده های استفاده به طور خودکار جمع آوری می شود.`,
  privacyPolicy45: `داده‌های استفاده ممکن است شامل اطلاعاتی مانند اینترنت دستگاه شما باشد
              آدرس پروتکل (به عنوان مثال آدرس IP)، نوع مرورگر، نسخه مرورگر،
              صفحات سرویس ما که بازدید می کنید، زمان و تاریخ شما
              بازدید، زمان صرف شده در آن صفحات، شناسه های منحصر به فرد دستگاه
              و سایر داده های تشخیصی`,
  privacyPolicy46: `هنگامی که شما از طریق یک دستگاه تلفن همراه یا از طریق آن به سرویس دسترسی پیدا می کنید، ممکن است
              جمع آوری اطلاعات خاص به صورت خودکار، از جمله، اما نه
              محدود به نوع دستگاه تلفن همراهی که استفاده می کنید، دستگاه تلفن همراه شما
              شناسه منحصر به فرد، آدرس IP دستگاه تلفن همراه شما، تلفن همراه شما
              سیستم عامل، نوع مرورگر اینترنت موبایلی که استفاده می کنید،
              شناسه های منحصر به فرد دستگاه و سایر داده های تشخیصی.`,
  privacyPolicy47: `ما همچنین ممکن است اطلاعاتی را که مرورگر شما هر زمان ارسال می کند جمع آوری کنیم
              شما از خدمات ما بازدید می کنید یا زمانی که از طریق یا از طریق به سرویس دسترسی دارید
              یک دستگاه تلفن همراه`,
  privacyPolicy48: `اطلاعات از خدمات رسانه های اجتماعی شخص ثالث`,
  privacyPolicy49: `شرکت به شما اجازه می دهد یک حساب کاربری ایجاد کنید و برای استفاده از آن وارد شوید
              خدمات از طریق خدمات رسانه های اجتماعی شخص ثالث زیر:`,
  privacyPolicy50: `گوگل`,
  privacyPolicy51: `فیس بوک`,
  privacyPolicy52: `اینستاگرام`,
  privacyPolicy53: `توییتر`,
  privacyPolicy54: `لینکدین`,
  privacyPolicy55: `اگر تصمیم دارید از طریق ثبت نام کنید یا به ما اجازه دسترسی به آن را بدهید
              یک سرویس رسانه اجتماعی شخص ثالث، ما ممکن است داده های شخصی را جمع آوری کنیم
              که قبلاً با رسانه اجتماعی شخص ثالث شما مرتبط است
              حساب سرویس، مانند نام شما، آدرس ایمیل شما، شما
              فعالیت ها یا لیست مخاطبین شما مرتبط با آن حساب.`,
  privacyPolicy56: `همچنین ممکن است این امکان را داشته باشید که اطلاعات بیشتری را به اشتراک بگذارید
              با شرکت از طریق خدمات رسانه های اجتماعی شخص ثالث شما
              حساب. اگر شما تصمیم به ارائه چنین اطلاعاتی و شخصی دارید
              داده ها، در حین ثبت نام یا موارد دیگر، شما به شرکت می دهید
              اجازه استفاده، اشتراک گذاری و ذخیره آن را به شیوه ای سازگار با
              این سیاست حفظ حریم خصوصی`,
  privacyPolicy57: `اطلاعات جمع آوری شده در هنگام استفاده از برنامه`,
  privacyPolicy58: `هنگام استفاده از برنامه ما، به منظور ارائه ویژگی های ما
              برنامه، ما ممکن است با اجازه قبلی شما جمع آوری کنیم:`,
  privacyPolicy59: `اطلاعات مربوط به موقعیت مکانی شما`,
  privacyPolicy60: `ما از این اطلاعات برای ارائه ویژگی های سرویس خود استفاده می کنیم
              بهبود و سفارشی کردن خدمات ما اطلاعات ممکن است بارگذاری شود
              به سرورهای شرکت و/یا سرور ارائه دهنده خدمات یا آن
              ممکن است به سادگی در دستگاه شما ذخیره شود.`,
  privacyPolicy61: `شما می توانید در هر زمان دسترسی به این اطلاعات را فعال یا غیرفعال کنید،
              از طریق تنظیمات دستگاه شما`,
  privacyPolicy62: `فناوری‌های ردیابی و کوکی‌ها`,
  privacyPolicy63: `ما از کوکی ها و فناوری های ردیابی مشابه برای ردیابی استفاده می کنیم
              فعالیت در سرویس ما و ذخیره اطلاعات خاص. ردیابی
              فن آوری های مورد استفاده عبارتند از بیکن ها، برچسب ها و اسکریپت ها برای جمع آوری و
              ردیابی اطلاعات و بهبود و تجزیه و تحلیل خدمات ما. این
              فناوری هایی که ما استفاده می کنیم ممکن است شامل موارد زیر باشد:`,
  privacyPolicy64: `کوکی ها یا کوکی های مرورگر:`,
  privacyPolicy65: `کوکی کوچک است
                فایل قرار داده شده در دستگاه شما می توانید به مرورگر خود دستور دهید
                تمام کوکی ها را رد کنید یا نشان دهید که یک کوکی در حال ارسال است.
                با این حال، اگر کوکی ها را قبول نکنید، ممکن است نتوانید
                از برخی از بخش های سرویس ما استفاده کنید. مگر اینکه خود را تنظیم کرده باشید
                تنظیمات مرورگر به گونه ای که از کوکی ها امتناع کند، سرویس ما ممکن است
                از کوکی ها استفاده کنید`,
  privacyPolicy66: `چراغ های وب:`,
  privacyPolicy67: `بخش های خاصی از خدمات ما
                و ایمیل های ما ممکن است حاوی فایل های الکترونیکی کوچکی باشد که به عنوان وب شناخته می شوند
                beacon ها (همچنین به عنوان گیف های شفاف، تگ های پیکسل و
                گیف‌های تک پیکسلی) که به شرکت اجازه می‌دهد، برای مثال
                شمارش کاربرانی که از آن صفحات بازدید کرده اند یا ایمیلی را باز کرده اند و
                برای سایر آمارهای وب سایت مرتبط (به عنوان مثال، ثبت
                محبوبیت یک بخش خاص و تأیید سیستم و سرور
                تمامیت).`,
  privacyPolicy68: `کوکی ها می توانند کوکی های "دائمی" یا "جلسه ای" باشند. مداوم
              کوکی ها در رایانه شخصی یا دستگاه تلفن همراه شما باقی می مانند
              آفلاین شوید، در حالی که کوکی‌های جلسه به محض بسته شدن حذف می‌شوند
              مرورگر وب شما در TermsFeed می‌توانید درباره کوکی‌ها بیشتر بدانید
              مقاله وب سایت`,
  privacyPolicy69: `ما از کوکی های Session و Persistent برای اهداف تعیین شده استفاده می کنیم
              در زیر:`,
  privacyPolicy70: `کوکی های ضروری / ضروری:`,
  privacyPolicy71: `نوع: جلسه
                بیسکویت ها؛ اداره شده توسط: ما; هدف: این کوکی ها هستند
                ضروری برای ارائه خدمات در دسترس شما از طریق
                وب سایت و به شما امکان می دهد از برخی از ویژگی های آن استفاده کنید. آنها کمک میکنند
                برای احراز هویت کاربران و جلوگیری از استفاده تقلبی از کاربر
                حساب ها. بدون این کوکی ها، خدماتی که شما دارید
                درخواست ارائه نمی شود، و ما فقط از این کوکی ها استفاده می کنیم
                آن خدمات را به شما ارائه دهد.`,
  privacyPolicy72: `خط مشی کوکی ها / اطلاعیه پذیرش کوکی ها:`,
  privacyPolicy73: `نوع: کوکی های ماندگار؛ اداره شده توسط: ما; هدف: اینها
                کوکی ها تشخیص می دهند که آیا کاربران استفاده از کوکی ها را پذیرفته اند یا خیر
                تارنما.`,
  privacyPolicy74: `کوکی های عملکردی:`,
  privacyPolicy75: `نوع: ماندگار
                بیسکویت ها؛ اداره شده توسط: ما; هدف: این کوکی ها به ما اجازه می دهند
                انتخاب هایی که هنگام استفاده از وب سایت انجام می دهید را به خاطر بسپارید، مانند
                به خاطر سپردن جزئیات ورود به سیستم یا ترجیح زبان. این
                هدف از این کوکی ها ارائه اطلاعات شخصی تر به شما است
                تجربه کنید و از وارد کردن مجدد ترجیحات خود جلوگیری کنید
                هر بار که از وب سایت استفاده می کنید.`,
  privacyPolicy76: `برای اطلاعات بیشتر در مورد کوکی هایی که استفاده می کنیم و انتخاب های شما
              در مورد کوکی‌ها، لطفاً از خط‌مشی کوکی‌ها یا کوکی‌ها دیدن کنید
              بخش سیاست حفظ حریم خصوصی ما`,
  privacyPolicy77: `استفاده از داده های شخصی شما`,
  privacyPolicy78: `شرکت ممکن است از داده های شخصی برای اهداف زیر استفاده کند:`,
  privacyPolicy79: `برای ارائه و حفظ خدمات ما، از جمله برای نظارت بر
                استفاده از خدمات ما`,
  privacyPolicy80: `برای مدیریت حساب شما: برای مدیریت ثبت نام خود به عنوان کاربر
                خدمات.`,
  privacyPolicy81: `برای اجرای یک قرارداد: توسعه، انطباق
                و تعهد قرارداد خرید محصولات، اقلام
                یا خدماتی که خریداری کرده اید یا از هر قرارداد دیگری با ما
                از طریق سرویس`,
  privacyPolicy82: `برای تماس با شما: برای تماس با شما از طریق ایمیل، تماس تلفنی، پیامک،
                یا سایر اشکال معادل از ارتباطات الکترونیکی، مانند الف
                اعلان های فشاری برنامه تلفن همراه در مورد به روز رسانی یا
                ارتباطات اطلاعاتی مرتبط با عملکردها،
                محصولات یا خدمات قراردادی، از جمله به‌روزرسانی‌های امنیتی،
                در صورت لزوم یا منطقی برای اجرای آنها.`,
  privacyPolicy83: `برای ارائه اخبار، پیشنهادات ویژه و اطلاعات عمومی به شما
                در مورد سایر کالاها، خدمات و رویدادهایی که ما ارائه می دهیم
                مشابه مواردی که قبلا خریداری کرده اید یا پرس و جو کرده اید
                در مورد مگر اینکه شما تصمیم گرفته باشید چنین اطلاعاتی را دریافت نکنید.`,
  privacyPolicy84: `برای مدیریت درخواست های شما: برای شرکت و مدیریت درخواست های شما به
                ما`,
  privacyPolicy85: `برای ارائه تبلیغات هدفمند به شما.`,
  privacyPolicy86: `برای نقل و انتقالات تجاری: ممکن است از اطلاعات شما برای ارزیابی استفاده کنیم
                یا انجام ادغام، واگذاری، بازسازی، سازماندهی مجدد،
                انحلال، یا سایر فروش یا انتقال برخی یا همه ما
                دارایی ها، چه به عنوان یک شرکت تداومی یا به عنوان بخشی از ورشکستگی،
                انحلال، یا روند مشابه، که در آن داده های شخصی نگهداری می شود
                توسط ما درباره کاربران سرویس ما از جمله دارایی های منتقل شده است.`,
  privacyPolicy87: `برای مقاصد دیگر: ممکن است از اطلاعات شما برای موارد دیگر استفاده کنیم
                اهداف، مانند تجزیه و تحلیل داده ها، شناسایی روند استفاده،
                تعیین اثربخشی کمپین های تبلیغاتی ما و
                برای ارزیابی و بهبود خدمات، محصولات، خدمات،
                بازاریابی و تجربه شما`,
  privacyPolicy88: `ممکن است اطلاعات شخصی شما را در ادامه مطلب به اشتراک بگذاریم
              موقعیت ها:`,
  privacyPolicy89: `با ارائه دهندگان خدمات: ممکن است اطلاعات شخصی شما را به اشتراک بگذاریم
                با ارائه دهندگان خدمات برای نظارت و تجزیه و تحلیل استفاده از ما
                خدمات، برای نشان دادن تبلیغات به شما برای کمک به پشتیبانی و
                خدمات ما را حفظ کنید، برای تماس با شما، برای تبلیغ در سوم
                وب‌سایت‌های مهمانی پس از بازدید از سرویس ما یا برای شما
                فرایند پرداخت.`,
  privacyPolicy90: `برای نقل و انتقالات تجاری: ممکن است شخصی شما را به اشتراک بگذاریم یا انتقال دهیم
                اطلاعات در ارتباط با یا در طول مذاکرات با هر کدام
                ادغام، فروش دارایی های شرکت، تامین مالی، یا تملک همه
                یا بخشی از کسب و کار ما به شرکت دیگری.`,
  privacyPolicy91: `با شرکت های وابسته: ممکن است اطلاعات شما را با ما به اشتراک بگذاریم
                شرکت های وابسته، در این صورت ما از آن شرکت های وابسته می خواهیم
                به این سیاست حفظ حریم خصوصی احترام بگذارید. شرکت های وابسته شامل شرکت مادر ما هستند
                و سایر شرکت های تابعه، شرکای سرمایه گذاری مشترک یا سایر شرکت ها
                شرکت هایی که ما کنترل می کنیم یا تحت کنترل مشترک با آنها هستند
                ما`,
  privacyPolicy92: `با شرکای تجاری: ممکن است اطلاعات شما را با ما به اشتراک بگذاریم
                شرکای تجاری برای ارائه محصولات، خدمات یا خدمات خاصی به شما
                تبلیغات`,
  privacyPolicy93: `با سایر کاربران: وقتی اطلاعات شخصی را به اشتراک می گذارید یا
                در غیر این صورت در مناطق عمومی با سایر کاربران تعامل داشته باشید
                اطلاعات ممکن است توسط همه کاربران مشاهده شود و ممکن است به صورت عمومی باشد
                در خارج توزیع شده است. اگر با سایر کاربران تعامل دارید یا
                ثبت نام از طریق یک سرویس رسانه اجتماعی شخص ثالث، شما
                مخاطبین در سرویس رسانه های اجتماعی شخص ثالث ممکن است شما را ببینند
                نام، نمایه، تصاویر و شرح فعالیت شما.`,
  privacyPolicy94: `با رضایت شما: ممکن است اطلاعات شخصی شما را فاش کنیم
                هر هدف دیگری با رضایت شما`,
  privacyPolicy95: `حفظ اطلاعات شخصی شما`,
  privacyPolicy96: `شرکت اطلاعات شخصی شما را فقط تا زمانی که هست حفظ خواهد کرد
              برای اهداف تعیین شده در این سیاست حفظ حریم خصوصی ضروری است. ما خواهیم کرد
              داده های شخصی خود را تا حد لازم حفظ و استفاده کنید
              از تعهدات قانونی خود پیروی کنیم (مثلاً اگر از ما خواسته شود
              برای حفظ داده های خود برای مطابقت با قوانین قابل اجرا)، حل کنید
              اختلافات، و اجرای توافق نامه های قانونی و سیاست های ما.`,
  privacyPolicy97: `شرکت همچنین داده های استفاده را برای تجزیه و تحلیل داخلی حفظ خواهد کرد
              اهداف داده‌های استفاده عموماً برای مدت کوتاه‌تری نگهداری می‌شوند
              زمان، به جز زمانی که این داده ها برای تقویت امنیت یا
              برای بهبود عملکرد سرویس ما، یا ما از نظر قانونی هستیم
              موظف به حفظ این داده ها برای دوره های زمانی طولانی تر است.`,
  privacyPolicy98: `انتقال داده های شخصی شما`,
  privacyPolicy99: `اطلاعات شما، از جمله داده های شخصی، در اینجا پردازش می شود
              دفاتر عملیاتی شرکت و در هر مکان دیگری که
              طرف های درگیر در پردازش واقع شده اند. یعنی این
              اطلاعات ممکن است به رایانه‌ها منتقل شده و نگهداری شوند
              در خارج از ایالت، استان، کشور یا کشور شما واقع شده است
              حوزه قضایی دولتی که در آن قوانین حفاظت از داده ها ممکن است
              با کسانی که در حوزه قضایی شما هستند متفاوت است.`,
  privacyPolicy100: `رضایت شما با این سیاست حفظ حریم خصوصی و به دنبال آن ارسال شما از
              چنین اطلاعاتی نشان دهنده موافقت شما با آن انتقال است.`,
  privacyPolicy101: `شرکت تمام اقدامات منطقی لازم را برای اطمینان انجام خواهد داد
              که با داده های شما به صورت ایمن و مطابق با این رفتار می شود
              سیاست حفظ حریم خصوصی و هیچ انتقالی از اطلاعات شخصی شما انجام نخواهد شد
              مکان به یک سازمان یا یک کشور مگر اینکه به اندازه کافی وجود داشته باشد
              کنترل های موجود از جمله امنیت داده های شما و موارد دیگر
              اطلاعات شخصی.`,
  privacyPolicy102: `افشای اطلاعات شخصی شما`,
  privacyPolicy103: `معاملات کسب و کار`,
  privacyPolicy104: `اگر شرکت درگیر ادغام، اکتساب یا فروش دارایی باشد،
              ممکن است داده های شخصی شما منتقل شود. اطلاعیه ارائه خواهیم کرد
              قبل از اینکه داده های شخصی شما منتقل شود و مشمول الف شود
              سیاست حفظ حریم خصوصی متفاوت`,
  privacyPolicy105: `اجرای قانون`,
  privacyPolicy106: `تحت شرایط خاصی، ممکن است از شرکت خواسته شود
              در صورتی که طبق قانون یا در داخل کشور به انجام این کار نیاز باشد، داده های شخصی خود را افشا کنید
              پاسخ به درخواست های معتبر مقامات دولتی (به عنوان مثال دادگاه یا
              یک سازمان دولتی).`,
  privacyPolicy107: `سایر الزامات قانونی`,
  privacyPolicy108: `شرکت ممکن است اطلاعات شخصی شما را با حسن نیت فاش کند
              اعتقاد به اینکه چنین اقدامی برای موارد زیر ضروری است:`,
  privacyPolicy109: `یک تعهد قانونی را رعایت کنید`,
  privacyPolicy110: `حفاظت و دفاع از حقوق یا اموال شرکت`,
  privacyPolicy111: `جلوگیری یا تحقیق از اشتباهات احتمالی در ارتباط با
                خدمات`,
  privacyPolicy112: `حفاظت از ایمنی شخصی کاربران سرویس یا
                عمومی`,
  privacyPolicy113: `در برابر مسئولیت قانونی محافظت کنید`,
  privacyPolicy114: `امنیت داده های شخصی شما`,
  privacyPolicy115: `امنیت داده های شخصی شما برای ما مهم است، اما
              به یاد داشته باشید که هیچ روش انتقال از طریق اینترنت، یا
              روش ذخیره سازی الکترونیکی 100٪ ایمن است. در حالی که ما تلاش می کنیم
              از ابزارهای قابل قبول تجاری برای محافظت از داده های شخصی خود استفاده کنید،
              ما نمی توانیم امنیت مطلق آن را تضمین کنیم.`,
  privacyPolicy116: `حریم خصوصی کودکان`,
  privacyPolicy117: `خدمات ما افراد زیر 13 سال را مورد خطاب قرار نمی دهد
              آگاهانه اطلاعات قابل شناسایی شخصی را از هر کسی جمع آوری می کند
              زیر 13 سال. اگر پدر یا مادر یا قیم هستید و هستید
              آگاه باشید که فرزند شما اطلاعات شخصی را در اختیار ما قرار داده است، لطفا
              با ما تماس بگیرید. اگر متوجه شویم که شخصی را جمع آوری کرده ایم
              داده‌های افراد زیر 13 سال بدون تأیید
              رضایت والدین، ما اقداماتی را برای حذف آن اطلاعات از
              سرورهای ما`,
  privacyPolicy118: `اگر لازم باشد به رضایت به عنوان مبنای قانونی برای پردازش شما تکیه کنیم
              اطلاعات و کشور شما نیاز به رضایت یکی از والدین دارد، ما
              ممکن است قبل از جمع آوری و استفاده از آن به رضایت والدین شما نیاز داشته باشد
              اطلاعات`,
  privacyPolicy119: `پیوند به وب سایت های دیگر`,
  privacyPolicy120: `خدمات ما ممکن است حاوی پیوندهایی به وب سایت های دیگر باشد که چنین نیستند
              توسط ما اداره می شود. اگر روی پیوند شخص ثالث کلیک کنید، خواهید بود
              به سایت آن شخص ثالث هدایت می شود. اکیداً به شما توصیه می کنیم
              سیاست حفظ حریم خصوصی هر سایتی را که بازدید می کنید مرور کنید.`,
  privacyPolicy121: `ما هیچ کنترلی نداریم و مسئولیتی در قبال آن نداریم
              محتوا، سیاست های حفظ حریم خصوصی یا شیوه های هر سایت شخص ثالث یا
              خدمات.`,
  privacyPolicy122: `تغییرات در این سیاست حفظ حریم خصوصی`,
  privacyPolicy123: `ممکن است سیاست حفظ حریم خصوصی خود را هر از چند گاهی به روز کنیم. اطلاع رسانی خواهیم کرد
              با پست کردن سیاست حفظ حریم خصوصی جدید در این صفحه، شما از هرگونه تغییری مطلع شوید.`,
  privacyPolicy124: `به شما توصیه می شود این خط مشی رازداری را به صورت دوره ای برای هر موردی مرور کنید
              تغییر می کند. تغییرات در این سیاست حفظ حریم خصوصی زمانی مؤثر است که انجام شود
              در این صفحه ارسال می شوند.`,
  privacyPolicy125: `با ما تماس بگیرید`,
  privacyPolicy126: `اگر در مورد این سیاست حفظ حریم خصوصی سوالی دارید، می توانید
              با ما تماس بگیرید:`,
  privacyPolicy127: `با ایمیل:`,
  privacyPolicy128: `با مراجعه به این صفحه در وب سایت ما:`,
  privacyPolicy129: `با شماره تلفن:`,
  privacyPolicy130: `ما به شما از طریق ایمیل و/یا یک اطلاعیه برجسته در سایت ما اطلاع خواهیم داد
              سرویس، قبل از موثر شدن تغییر و به روز رسانی
              تاریخ "آخرین به روز رسانی" در بالای این خط مشی رازداری.`,
  privacyPolicy131: `اطلاعات شخصی خود را حذف کنید`,
  privacyPolicy132: `شما حق دارید حذف کنید یا درخواست کنید که ما در حذف کمک کنیم
              داده های شخصی که ما در مورد شما جمع آوری کرده ایم.`,
  privacyPolicy133: `سرویس ما ممکن است به شما این امکان را بدهد که اطلاعات خاصی را حذف کنید
              درباره شما از داخل سرویس`,
  privacyPolicy134: `شما می توانید اطلاعات خود را در هر زمانی به روز رسانی، اصلاح یا حذف کنید
              در صورت داشتن حساب کاربری خود وارد شوید و از آن بازدید کنید
              بخش تنظیمات حساب که به شما امکان می دهد شخصی خود را مدیریت کنید
              اطلاعات همچنین می توانید برای درخواست دسترسی به ما با ما تماس بگیرید
              تصحیح یا حذف هر گونه اطلاعات شخصی که ارائه کرده اید
              به ما.`,
  privacyPolicy135: `با این حال، لطفاً توجه داشته باشید که ممکن است لازم باشد برخی موارد را حفظ کنیم
              اطلاعات زمانی که ما یک تعهد قانونی یا مبنای قانونی برای انجام آن داریم
              بنابراین.`,
  pricingPlans: `قیمت و کارمزد`,
  deletionPolicy: `سیاست حذف`,
  deletionPolicy1: `حذف حساب: می توانید انتخاب کنید که حساب خود را حذف کنید
                پلتفرم (یعنی وب سایت`,
  deletionPolicy2: `و/یا محل کار برنامه تلفن همراه) و هر گونه اطلاعات
                در هر مقطع زمانی با آن مرتبط است. چنین درخواستی برای
                حذف باید کتبا ارسال شود`,
  deletionPolicy3: `یا می توانید وارد حساب کاربری خود شده و حذف اکانت را بررسی کنید
                گزینه ای که در آن می توانید حساب موجود خود را حذف کنید. در رویداد
                که درخواست حذف حساب یا داده های خود را داشته باشید،
                دیگر نمی توانید به پلتفرم دسترسی داشته باشید و/یا از آن استفاده کنید
                خدمات، و ما این حق را برای خود محفوظ می داریم که هیچ کدام را به شما ارائه نکنیم
                خدمات از طریق پلتفرم در صورت درخواست شما، ما
                ممکن است اطلاعات شما (شخصی یا غیره) را حذف کند یا
                آن را از هویت خارج کنید تا ناشناس باشد و دیگر نباشد
                منتسب به شما با وجود هر چیزی که در
                سیاست حفظ حریم خصوصی، ممکن است برخی یا تمام اطلاعات شما را در آن نگه داریم
                مطابق با خط‌مشی‌ها و/یا الزامات ما در زیر
                قانون قابل اجرا. اصطلاحاتی که در اینجا تعریف نشده اند دارای معنی هستند
                در سیاست حفظ حریم خصوصی به آنها اختصاص داده شده است.`,
  termsAndCondition: "شرایط و ضوابط",
  trustAndSafety: "اعتماد و ایمنی",
  trustAndSafety1: `یافتن ارائه‌دهنده خدمات کامل آسان‌تر شد`,
  trustAndSafety2: `وقتی نوبت به یافتن بهترین ابزار و خدمات برای شما می رسد
            کسب و کار، بررسی ها و شهرت اهمیت دارد. به همین دلیل است که
            بازار WorkWaveHub مقایسه، انتخاب و اعتماد را آسان می کند
            فروشندگانی که با آنها کار می کنید`,
  trustAndSafety3: `در اینجا به این صورت است:`,
  trustAndSafety4: `رتبه بندی و سطوح فروشنده:`,
  trustAndSafety5: `ببین دیگه چی
              کسب و کارها فکر می کنند! WorkWaveHub رتبه بندی شفاف و واضح فروشنده را نمایش می دهد
              سطوح، به شما یک عکس فوری سریع از عملکرد هر فروشنده می دهد.`,
  trustAndSafety6: `بازخورد خریدار:`,
  trustAndSafety7: `با خواندن واقعی از رتبه بندی فراتر بروید
              نظرات مشتریان این به شما امکان می دهد از نزدیک ببینید چه چیز دیگری
              کسب و کارها کار با یک فروشنده خاص را تجربه کرده اند.`,
  trustAndSafety8: `با داشتن تمام این اطلاعات در دستان شما، می توانید آگاه شوید
            تصمیم بگیرید و ارائه دهندگان خدمات عالی را برای کمک به خود بیابید
            رونق کسب و کار`,
  trustAndSafety9: `گرفتن تصمیمات آگاهانه کلیدی برای به دست آوردن بیشترین بهره است
            WorkWaveHub. به همین دلیل است که ما آن را آسان می کنیم تا ببینیم چه چیز دیگری
            کسب و کارها به فروشندگانی که مد نظر شما هستند فکر می کنند.`,
  trustAndSafety10: `رتبه بندی فروشنده:`,
  trustAndSafety11: `بعد از اینکه فروشنده یک پروژه را تمام کرد
              (به نام Gig on WorkWaveHub)، خریداران می توانند تجربه خود را ارزیابی کنند.
              این رتبه بندی ها بر اساس معیارهای واضح است، بنابراین شما می توانید دقیقا ببینید
              نحوه عملکرد فروشنده در مناطق مختلف`,
  trustAndSafety12: `نظرات خریداران:`,
  trustAndSafety13: `چیزی بیش از یک امتیاز ستاره می خواهید؟
              مشکلی نیست! خریداران همچنین می توانند نظرات کتبی خود را در مورد آنها بنویسند
              تجربه کار با فروشنده این به شما اجازه می دهد تا ببینید چه چیز دیگری
              کسب و کارها دوست داشتند (یا دوست نداشتند) با آنها کار کنند.`,
  trustAndSafety14: `با اطمینان خرید و فروش کنید`,
  trustAndSafety15: `روزانه میلیون ها تراکنش در WorkWaveHub انجام می شود. ما میدانیم
            امنیت مهم است، بنابراین ما از جدیدترین و بهترین فناوری استفاده می کنیم
            تا پول و اطلاعات خود را ایمن نگه دارید. یعنی می توانید روی آن تمرکز کنید
            یافتن سرویس یا کنسرت عالی، بدون نگرانی در مورد کلاهبرداری یا
            نقض داده ها`,
  trustAndSafety16: `اطلاعات خصوصی می ماند:`,
  trustAndSafety17: `ما برای حریم خصوصی شما ارزش قائل هستیم
              و هرگز اطلاعات شخصی شما را با کسی خارج از آن به اشتراک نخواهد گذاشت
              WorkWaveHub. اطلاعات شما همیشه نزد ما امن است.`,
  trustAndSafety18: `پرداخت های ایمن و مطمئن:`,
  trustAndSafety19: `نیازی به نگرانی نیست
              اطلاعات پول یا کارت اعتباری شما همه معاملات اتفاق می افتد
              به طور ایمن در پلت فرم ما، چه با کارت اعتباری پرداخت کنید،
              پی پال یا روش دیگری. ما بالاترین امنیت را دنبال می کنیم
              استانداردهایی برای ایمن نگه داشتن اطلاعات شما`,
  trustAndSafety20: `با اطمینان ارتباط برقرار کنید:`,
  trustAndSafety21: `پیام های امن ما
              این سیستم به شما امکان می دهد با هر فروشنده ای که در آن حضور دارید چت کنید و فایل ها را به اشتراک بگذارید
              WorkWaveHub بدون نگرانی.`,
  pricingFees: `برای کارمندان`,
  pricingFees1: `به عنوان یک کارمند آزاد در WorkWaveHub، خدماتی را متحمل خواهید شد
                کارمزد بر درآمد شما ساختار هزینه ها به شرح زیر است:`,
  pricingFees2: `پروژه هایی معادل یا بیش از 200 دلار`,
  pricingFees3: `هزینه خدمات 7 درصد اعمال می شود.`,
  pricingFees4: `مثال`,
  pricingFees5: `برای یک پروژه 200 دلاری، هزینه خدمات است
                      7 درصد که به 14 دلار می رسد. پرداخت نهایی شما 186 دلار خواهد بود.`,
  pricingFees6: `برای یک پروژه 300 دلاری،
                      هزینه خدمات 7 درصد است که به 21 دلار می رسد. فینال شما
                      پرداخت 279 دلار خواهد بود.`,
  pricingFees7: `پروژه های زیر 200 دلار`,
  pricingFees8: `هزینه خدمات 9 درصد اعمال می شود.`,
  pricingFees9: `برای یک پروژه 150 دلاری،
                      هزینه خدمات 9٪ است که بالغ بر 13.50 دلار است. فینال شما
                      پرداخت 136.50 دلار خواهد بود.`,
  pricingFees10: `هنگام ایجاد کار خود باید یک آدرس رمزنگاری معتبر ارائه دهید
                حساب. پس از تکمیل پروژه و دریافت تاییدیه معتبر
                از طرف کارفرما، پرداخت شما ظرف 24 پردازش خواهد شد
                ساعت به حساب رمزنگاری شما در USDT.`,
  pricingFees11: `برای کارفرمایان`,
  pricingFees12: `به عنوان یک کارفرما در WorkWaveHub، هزینه خدماتی را متحمل خواهید شد
                هزینه پروژه ساختار هزینه ها به شرح زیر است:`,
  pricingFees13: `پروژه هایی معادل یا بیش از 200 دلار`,
  pricingFees14: `هزینه خدمات 7 درصد اعمال می شود.`,
  pricingFees15: `برای یک پروژه 200 دلاری،
                      هزینه خدمات 7 درصد است که به 14 دلار می رسد. مجموع شما
                      مقدار اضافه کردن 214 دلار خواهد بود.`,
  pricingFees16: `برای یک پروژه 300 دلاری،
                      هزینه خدمات 7 درصد است که به 21 دلار می رسد. مجموع شما
                      مقدار اضافه کردن 321 دلار خواهد بود.`,
  pricingFees17: `هزینه خدمات 9 درصد اعمال می شود.`,
  pricingFees18: `برای یک پروژه 150 دلاری،
                      هزینه خدمات 9٪ است که بالغ بر 13.50 دلار است. مجموع شما
                      مقدار اضافه کردن 163.50 دلار خواهد بود.`,
  pricingFees19: `هنگام ایجاد یک پروژه، باید بودجه مورد نیاز را اضافه کنید
                از طریق PayPal، Stripe یا Crypto. پس از تایید تکمیل شده
                پروژه از کارمند، مبلغ از شما کسر می شود
                صندوق WorkWaveHub و به کارمند آزاد منتقل شد
                حساب.`,
  wallet: "کیف پول",
  withdraw: "برداشت",
  withdrawalHistory: "تاریخچه برداشت",
  fees: "هزینه ها",
  createdAt: "ایجاد شده در",
  paymentMethod: "روش پرداخت",
  inovice: "فاکتور",
  amtPaid: "مبلغ پرداخت شده",
  disputeCase: "پرونده های اختلاف",
  disputes: "اختلافات",
  addDispute: "اختلاف را اضافه کنید",
  dispute: "اختلاف",
  selectMilestone: "لطفا نقطه عطف را انتخاب کنید",
  amtgreaterThan0: "لطفا مقدار بیشتر از 0 را وارد کنید",
  enterReason: "لطفا دلیل را وارد کنید",
  name: "نام",
  project: "پروژه",
  disputeAmt: "مبلغ اختلاف",
  reason: "دلیل",
  setStatus: "وضعیت را تنظیم کنید",
  resByU: "پاسخ توسط شما",
  resByDef: "پاسخ توسط متهم",
  resByCom: "پاسخ توسط شاکی",
  fileDispute: "پرونده اختلاف",
  areUsureCon: "آیا مطمئن هستید که می خواهید ادامه دهید؟",
  sctionSus: `این اقدام ممکن است بر رتبه شما تأثیر بگذارد و منجر به تعلیق حساب شما شود.`,
  proceed: "ادامه دهید",
  dis1: "پیشنهادی برای حل اختلاف به مبلغ $ ارسال کرده است",
  mutRes: "حل و فصل متقابل",
  inPrg: "در حال انجام است",
  resMut: "متقابل حل شود",
  rejdis: "رد اختلاف",
  acdis: "قبول اختلاف",
  withdis: "انصراف از اختلاف",
  diRaisedByU: "اختلافی که توسط شما مطرح شده است",
  disRaisedAgainstU: "دعوا علیه شما",
  enterPRoj: "لطفا پروژه را وارد کنید",
  totdonation: "اهدای کل",
  totPayout: "کل پرداخت",
  donationPayout: "پرداخت کمک مالی",
  orgName: "نام سازمان",
  dontionamt: "مبلغ کمک مالی",
  enteramtUSD: "مقدار را وارد کنید (به دلار آمریکا)",
  walletBal: "موجودی کیف پول",
  mileFee: "کارمزد Milestone",
  amtPaidWithoutFees: "مبلغ پرداختی (بدون هزینه)",
  newWalletBal: "موجودی کیف پول جدید",
  amtPaidWithfees: "مبلغ قابل پرداخت (با هزینه)",
  payfrmWallet: "از کیف پول پرداخت کنید",
};

export default faTranslations;
