import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormLabel,
  FormControl,
  TextField,
} from "@mui/material";

export default function PayCrypto({ data, saveData }) {
  const [cryptoAmount, setCryptoAmount] = useState("");
  const [percentage1, setPercentage1] = useState("");
  const [percentage2, setPercentage2] = useState("");
  const [error, setError] = useState({
    cryptoAmount: "",
    percentage1: "",
    percentage2: "",
  });

  const handleCryptoAmountChange = (event) =>
    setCryptoAmount(event.target.value);
  const handlePercentage1Change = (event) => setPercentage1(event.target.value);
  const handlePercentage2Change = (event) => setPercentage2(event.target.value);

  useEffect(() => {
    setCryptoAmount(data?.cryptoAmount || "");
    setPercentage1(data?.cryptoMinPercentage || "");
    setPercentage2(data?.cryptoPercentage || "");
  }, [data]);

  const validateData = () => {
    setError({
      cryptoAmount: "",
      percentage1: "",
      percentage2: "",
    });
    let err = {};
    let isValid = true;
    if (!cryptoAmount || cryptoAmount < 0) {
      err.cryptoAmount = "Amount should be greater than or equal to 0";
      isValid = false;
    }
    if (!percentage1 || percentage1 < 0 || percentage1 > 100) {
      err.percentage1 =
        "Minimum percentage should be greater than or equal to 0 and less than or equal to 100";
      isValid = false;
    }
    if (!percentage2 || percentage2 < 0 || percentage2 > 100) {
      err.percentage2 =
        "Maximum percentage should be greater than or equal to 0 and less than or equal to 100";
      isValid = false;
    }

    setError(err);
    return isValid;
  };

  const setData = async () => {
    if (!validateData()) return;
    saveData(
      {
        cryptoAmount: cryptoAmount,
        cryptoMinPercentage: percentage1,
        cryptoPercentage: percentage2,
      },
      data._id
    );
  };

  return (
    <section>
      <Box p={1}>
        <Box width={"100%"} borderBottom={1} borderColor={"#E9E9E9"} pb={2}>
          <Typography variant="h6" fontWeight={600}>
            Payment Method - Crypto
          </Typography>
        </Box>
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel>Amount</FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  value={cryptoAmount}
                  onChange={handleCryptoAmountChange}
                  id="amount"
                  placeholder="Amount"
                  inputProps={{ "aria-label": "amount" }}
                  type="number"
                  error={!!error?.cryptoAmount}
                  helperText={error?.cryptoAmount}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>Min percentage (Less than amount)</FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  type="number"
                  value={percentage1}
                  onChange={handlePercentage1Change}
                  placeholder="Minimum percentage"
                  id="amount"
                  inputProps={{ "aria-label": "amount" }}
                  error={!!error.percentage1}
                  helperText={error.percentage1}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>
                Max percentage (Greater than or equal to amount)
              </FormLabel>
              <FormControl fullWidth variant="outlined" sx={{ my: 2 }}>
                {" "}
                <TextField
                  type="number"
                  value={percentage2}
                  onChange={handlePercentage2Change}
                  placeholder="Maximum percentage"
                  id="amount"
                  inputProps={{ "aria-label": "amount" }}
                  error={!!error.percentage2}
                  helperText={error.percentage2}
                />
              </FormControl>
            </Grid>
            <Button
              onClick={setData}
              sx={{
                ml: 2,
                display: "flex",
                fontFamily: "inherit",
                textTransform: "capitalize",
                justifyContent: "start",
                mr: "auto",
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                mt: 4,
                px: 5,
                py: 1,
                maxWidth: "fit-content",
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Box>
    </section>
  );
}
