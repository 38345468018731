import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormLabel,
  Avatar,
  TextField,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockIcon from "@material-ui/icons/Lock"; // Add this line

// rest of your code

import EditIcon from "@mui/icons-material/Edit";
import {
  isValidName,
  isValidUSDTAddress,
  LoaderHide,
  LoaderShow,
} from "../../../../../Helpers/Utility";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import { postAPI } from "../../../../../Services/Api";
import { addDoc, collection, doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../../Firebase/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { EMPLOYEE_ROLE } from "../../../../../Helpers/roles";

export default function PaymentAddressEmployee(props) {
  const [avatar, setAvatar] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const navigate = useNavigate();
  // const [lastNameError] = useState("");
  const { t } = useTranslation();

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const RegisterUser = async () => {
    LoaderShow();
    let isValid = true;

    if (firstName.trim() === "") {
      setFirstNameError(t("crytpoReq"));
      isValid = false;
    } else if (!isValidUSDTAddress(firstName)) {
      setFirstNameError(t("validCrypto"));
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (isValid) {
      LoaderShow();
      try {
        const employeeUserData = {
          firstName: props.employeeUserData.profileEmployee.firstName,
          lastName: props.employeeUserData.profileEmployee.lastName,
          userName: props.employeeUserData.aboutEmployee.userName || "",
          email: props.employeeUserData.registrationDataEmployee.email,
          isEmailVerified: false,
          password: props.employeeUserData.registrationDataEmployee.password,
          phoneNumber:
            props.employeeUserData.mobileVerificationDataEmployee.phoneNumber,
          isPhoneNumberVerified: false,
          skills:
            props.employeeUserData.skillsetIDEmployee.length > 0
              ? props.employeeUserData.skillsetIDEmployee
              : [],
          // "skills": props.employeeUserData.skillsetEmployee,
          isSubscribed: false,
          profile: "",
          aboutMe: props.employeeUserData.aboutEmployee.aboutMe || "",
          DOB: "",
          location: "",
          role: "employee",
          cryptoAddress: firstName,
        };

        const dataToSend = new FormData();

        dataToSend.append(
          "firstName",
          props.employeeUserData.profileEmployee.firstName
        );
        dataToSend.append(
          "lastName",
          props.employeeUserData.profileEmployee.lastName
        );
        dataToSend.append(
          "userName",
          props.employeeUserData.aboutEmployee.userName || ""
        );
        dataToSend.append(
          "email",
          props.employeeUserData.registrationDataEmployee.email
        );
        dataToSend.append("isEmailVerified", false);
        dataToSend.append("password", "lastName");
        dataToSend.append(
          "phoneNumber",
          props.employeeUserData.mobileVerificationDataEmployee.phoneNumber
        );
        dataToSend.append("isPhoneNumberVerified", true);
        dataToSend.append(
          "skills",
          props.employeeUserData.skillsetIDEmployee.length > 0
            ? props.employeeUserData.skillsetIDEmployee
            : []
        );
        if (props.employeeUserData.categoryIDEmployee.length > 0)
          props.employeeUserData.categoryIDEmployee.forEach((ele, index) => {
            dataToSend.append(`category[${index}]`, ele);
          });
        dataToSend.append("isSubscribed", false);
        if (props.employeeUserData.profileEmployee.profile)
          dataToSend.append(
            "profile",
            props.employeeUserData.profileEmployee.profile
          );
        dataToSend.append(
          "aboutMe",
          props.employeeUserData.aboutEmployee.aboutMe || ""
        );
        dataToSend.append("DOB", "");
        dataToSend.append("role", JSON.stringify([EMPLOYEE_ROLE]));
        dataToSend.append("cryptoAddress", firstName);
        dataToSend.append(
          "location",
          props.employeeUserData.otpVerificationDataEmployee.location || ""
        );
        const response = await postAPI("user/register", dataToSend);
        if (response.statusCode === 200) {
          await setDoc(
            doc(
              db,
              "users",
              props.employeeUserData.otpVerificationDataEmployee.response.user
                .uid
            ),
            {
              firstName: props.employeeUserData.profileEmployee.firstName || "",
              lastName: props.employeeUserData.profileEmployee.lastName || "",
              imageUrl: response.profile || "",
              createdAt: Timestamp.now(),
              lastSeen: Timestamp.now(),
              metadata: null,
              role: null,
              updatedAt: Timestamp.now(),
            }
          );
          if (localStorage.getItem("roomId")) {
            setDoc(
              doc(db, "supportChat", localStorage.getItem("roomId")),
              {
                userId:
                  props.employeeUserData.otpVerificationDataEmployee.response
                    .user.uid,
                userRoles: EMPLOYEE_ROLE,
              },
              { merge: true }
            );
            localStorage.removeItem("roomId");
          }
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.handleNextStepEmployee();
          props.handleNextStepEmployee();
          var loginDetails = response.data;
          var objLoginData = {
            userId: loginDetails._id,
            firstName: loginDetails.firstName,
            token: response.token,
            role: [EMPLOYEE_ROLE],
            userProfile: loginDetails.profile,
          };
          localStorage.setItem("loginData", JSON.stringify(objLoginData));
          navigate("/dashboard/employee");
          // window.location.reload();
        } else {
          toast.error("failed to register user!", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.error("Failed to post data:", error.message);
        toast.error("failed to register user!", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    LoaderHide();
  };

  // const handleNextStep = () => {
  //   let isValid = true;

  //   if (firstName.trim() === "") {
  //     setFirstNameError("Please enter Crypto address");
  //     isValid = false;
  //   } else {
  //     setFirstNameError("");
  //   }

  //   if (isValid) {
  //     const dataToSend = {
  //       firstName: firstName,
  //       lastName: lastName,
  //     };
  //     props.SetEmployeeUserDataObject("profileEmployee", dataToSend);
  //     props.handleNextStepEmployee();
  //   }
  // };

  return (
    <Box sx={{ p: 8, display: "flex" }} className="sideBg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ textAlign: "start", fontWeight: 700 }}>
            Payment Address
          </Typography>
          <p className="beginText invisible" style={{ visibility: "hidden" }}>
            {t("FoPurpose")}
          </p>
        </Box>

        <Box sx={{ my: 1 }}>
          <TextField
            variant="outlined"
            sx={{
              background: isFocused ? "#FFFFFF" : "transparent",
              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
              boxShadow: isFocused
                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                : "none",
            }}
            fullWidth={true}
            placeholder="Please enter your crypto Address"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!firstNameError}
            helperText={firstNameError}
          />
          {/* <FormLabel sx={{ display: "block", textAlign: "start", py: 1, mt: 2, fontSize: 14 }}>
          Last Name
        </FormLabel>
        <TextField
          variant="outlined"
          sx={{
            background: isFocused ? "#FFFFFF" : "transparent",
            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          }}
          fullWidth={true}
          placeholder="Enter Last Name"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={!!lastNameError}
          helperText={lastNameError}
        /> */}
        </Box>

        <Box sx={{ mt: 4 }}>
          <Button
            // onClick={props.handleNextStepEmployee}
            onClick={RegisterUser}
            className="SignUpBtn"
            sx={{ textTransform: "capitalize", width: 100, color: "white" }}
          >
            Finish
          </Button>
        </Box>
        <Box sx={{ my: 1 }}>
          <FormLabel
            sx={{
              display: "flex",
              textAlign: "center",
              py: 1,
              alignItems: "center",
              justifyContent: "center",
              fontSize: 14,
            }}
          >
            <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
            {t("YourInfo")}
          </FormLabel>
        </Box>
      </Box>
    </Box>
  );
}
