import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  TableContainer,
  Paper,
  IconButton,
} from "@mui/material";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";
import loader from "../../../Assets/Images/loader.svg";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import { useSearchParams } from "react-router-dom";
import CompletedProjectDetails from "./CompletedProjectDetails/CompletedProjectDetails";
import OngoingProjectDetails from "./OngoingProjectDetails/OngoingProjectDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon

// function PostedProjectDetails({ projectName, onBack }) {
//   const { t } = useTranslation();

//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const [tabValue, setTabValue] = useState(0);
//   const [value, setValue] = React.useState(2);
//   const [hover, setHover] = React.useState(-1);

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <>
//       <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//         <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
//           <Tabs
//             variant={isMobile ? "scrollable" : "standard"}
//             scrollButtons={isMobile ? "auto" : "off"}
//             allowScrollButtonsMobile={isMobile}
//             textColor="#222222"
//             sx={{
//               borderBottom: 1,
//               borderColor: "#dbdbdb",
//               "& .MuiTabs-indicator": {
//                 backgroundColor: "#000",
//               },
//             }}
//             value={tabValue}
//             onChange={handleChange}
//             aria-label="project details tabs"
//           >
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label="Project Details"
//             />
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label="All Proposals"
//             />
//           </Tabs>
//         </Box>
//         {tabValue === 0 && (
//           <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//             <Box
//               sx={{ display: "flex", justifyContent: "space-between" }}
//               className="flexColumnRespo"
//             >
//               <Typography
//                 className="employeeHead"
//                 variant="h5"
//                 sx={{ color: "#000" }}
//               >
//                 Food Delivery Mobile App
//               </Typography>
//               <Typography
//                 className="employeeHead"
//                 variant="h6"
//                 sx={{ color: "#000" }}
//               >
//                 $100 - $150
//               </Typography>
//             </Box>
//             <Box className="priceHeadEmployee" sx={{ py: 4 }}>
//               <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry. Lorem Ipsum has been the industry's standard dummy
//                 text ever since the 1500s, when an unknown printer took a galley
//                 of type and scrambled it to make a type specimen book. It has
//                 survived not only five centuries, but also the leap into
//                 electronic typesetting, remaining essentially unchanged. It was
//                 popularised in the 1960s with the release of Letraset sheets
//                 containing Lorem Ipsum passages, and more recently with desktop
//                 publishing software like Aldus PageMaker including versions.
//               </Typography>
//               <Typography
//                 variant="p"
//                 sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
//               >
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry. Lorem Ipsum has been the industry's standard dummy
//                 text ever since the 1500s, when an unknown printer took a galley
//                 of type and scrambled it to make a type specimen book. It has
//                 survived not only five centuries, but also the leap into
//                 electronic typesetting, remaining essentially unchanged. It was
//                 popularised in the 1960s with the release of Letraset sheets
//                 containing Lorem Ipsum passages, and more recently with desktop
//                 publishing software like Aldus PageMaker including versions.
//               </Typography>
//             </Box>

//             <Box sx={{ py: 4 }}>
//               <Typography variant="h6" sx={{ color: "#000" }}>
//                 Skill Required
//               </Typography>
//               <Box className="flexWrapResp" sx={{ display: "flex", py: 3 }}>
//                 <Button
//                   onClick={onBack}
//                   className="skillText catBtnTop"
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   UI/UX Design
//                 </Button>
//                 <Button
//                   onClick={onBack}
//                   className="skillText catBtnTop"
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Graphic Design
//                 </Button>
//                 <Button
//                   onClick={onBack}
//                   className="skillText catBtnTop"
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Figma
//                 </Button>
//                 <Button
//                   onClick={onBack}
//                   className="skillText catBtnTop"
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Adobe XD
//                 </Button>
//               </Box>
//             </Box>
//             <Box sx={{}}>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   color: "#000",
//                   fontWeight: 600,
//                   pb: 0,
//                   fontSize: 18,
//                   px: 0,
//                 }}
//               >
//                 {t("Attachments")}
//               </Typography>
//               <Box
//                 className="uploadSec"
//                 sx={{
//                   py: 3,
//                   pb: 0,
//                 }}
//               >
//                 <Box sx={{ display: "flex", alignItems: "center" }}>
//                   <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

//                   <Typography
//                     variant="p"
//                     sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                   >
//                     84c110f299f31bad53eee9731d43cbca.jpg (154kB)
//                   </Typography>
//                   <img src={del} alt="Delete" style={{ cursor: "pointer" }} />
//                 </Box>
//               </Box>
//             </Box>
//             <Box sx={{ my: 2 }}>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px",
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   ml: "auto",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Back
//               </Button>
//             </Box>
//           </Box>
//         )}
//         {tabValue === 1 && (
//           <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//             <Box
//               sx={{
//                 backgroundColor: "#fff",
//                 py: 1,
//                 borderBottom: 1,
//                 borderColor: "#e3e3e3",
//               }}
//             >
//               <Box
//                 sx={{
//                   p: 2.5,
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Box sx={{ display: "flex" }}>
//                   <Box sx={{ display: "flex" }}>
//                     <img src={AvatarJob} alt="dfh" />
//                   </Box>
//                   <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
//                     <Box sx={{ display: "flex" }}>
//                       <Typography
//                         variant="h5"
//                         sx={{ color: "#000", fontWeight: 500 }}
//                       >
//                         John Doe
//                       </Typography>
//                       <Typography
//                         variant="h5"
//                         sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                       >
//                         @johndoe
//                       </Typography>
//                     </Box>
//                     <Box sx={{ display: "flex", py: 1, alignItems: "center" }}>
//                       <Rating
//                         name="hover-feedback"
//                         value={value}
//                         precision={0.5}
//                         getLabelText={getLabelText}
//                         onChange={(event, newValue) => {
//                           setValue(newValue);
//                         }}
//                         onChangeActive={(event, newHover) => {
//                           setHover(newHover);
//                         }}
//                         emptyIcon={
//                           <StarIcon
//                             style={{ opacity: 0.55 }}
//                             fontSize="inherit"
//                           />
//                         }
//                       />
//                       {value !== null && (
//                         <Box sx={{ mx: 2 }}>
//                           {labels[hover !== -1 ? hover : value]}
//                         </Box>
//                       )}

//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={chatIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           200
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={dollarIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           8.7
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={pieChart} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           98%
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={indiaIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           India
//                         </Typography>
//                       </Box>
//                     </Box>
//                     <Box sx={{ display: "flex", py: 0.5 }}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                           pl: 0,
//                         }}
//                       >
//                         UI/UX Designer
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Illustrations Designer
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Figma Expert
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Typography
//                     variant="h5"
//                     sx={{ color: "#000", px: 2, fontWeight: 500 }}
//                   >
//                     $150
//                   </Typography>
//                   <Typography
//                     variant="p"
//                     sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                   >
//                     In 7 Days
//                   </Typography>
//                 </Box>
//               </Box>

//               <Grid container spacing={2}>
//                 {/* Left half */}
//                 <Grid item xs={8}>
//                   <Box
//                     sx={{
//                       p: 2.5,
//                       pt: 0,
//                       display: "flex",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="p"
//                       sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                     >
//                       I'm seeking an experienced and efficient app developer to
//                       create an innovative social networking application for iOS
//                       platform. Details about the target audience are currently
//                       confidential. Since the completion I'm seeking an
//                       experienced and efficient app developer to create more....
//                     </Typography>
//                   </Box>
//                 </Grid>

//                 {/* Right half */}
//                 <Grid
//                   item
//                   xs={4}
//                   sx={{
//                     display: "flex",
//                     alignItems: "end",
//                     justifyContent: "end",
//                     ml: "auto",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
//                     <Button
//                       sx={{
//                         display: "flex",
//                         mx: 1,
//                         textTransform: "capitalize",
//                         border: 1,
//                         borderColor: "#0a3114",
//                         fontSize: 16,
//                         color: "#000",
//                         borderRadius: 10,

//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#0a3114",
//                         },
//                       }}
//                     >
//                       Chat
//                     </Button>
//                     <Button
//                       sx={{
//                         display: "flex",
//                         fontFamily: "inherit",
//                         textTransform: "capitalize",
//                         bgcolor: "#0a3114",
//                         fontSize: 16,
//                         color: "#fff",
//                         borderRadius: 10,
//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#fff",
//                         },
//                       }}
//                     >
//                       Award
//                     </Button>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box>
//             <Box
//               sx={{
//                 backgroundColor: "#fff",
//                 py: 1,
//                 borderBottom: 1,
//                 borderColor: "#e3e3e3",
//               }}
//             >
//               <Box
//                 sx={{
//                   p: 2.5,
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Box sx={{ display: "flex" }}>
//                   <Box sx={{ display: "flex" }}>
//                     <img src={AvatarJob} alt="dfh" />
//                   </Box>
//                   <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
//                     <Box sx={{ display: "flex" }}>
//                       <Typography
//                         variant="h5"
//                         sx={{ color: "#000", fontWeight: 500 }}
//                       >
//                         John Doe
//                       </Typography>
//                       <Typography
//                         variant="h5"
//                         sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                       >
//                         @johndoe
//                       </Typography>
//                     </Box>
//                     <Box sx={{ display: "flex", py: 1, alignItems: "center" }}>
//                       <Rating
//                         name="hover-feedback"
//                         value={value}
//                         precision={0.5}
//                         getLabelText={getLabelText}
//                         onChange={(event, newValue) => {
//                           setValue(newValue);
//                         }}
//                         onChangeActive={(event, newHover) => {
//                           setHover(newHover);
//                         }}
//                         emptyIcon={
//                           <StarIcon
//                             style={{ opacity: 0.55 }}
//                             fontSize="inherit"
//                           />
//                         }
//                       />
//                       {value !== null && (
//                         <Box sx={{ mx: 2 }}>
//                           {labels[hover !== -1 ? hover : value]}
//                         </Box>
//                       )}

//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={chatIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           200
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={dollarIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           8.7
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={pieChart} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           98%
//                         </Typography>
//                       </Box>
//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={indiaIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           India
//                         </Typography>
//                       </Box>
//                     </Box>
//                     <Box sx={{ display: "flex", py: 0.5 }}>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                           pl: 0,
//                         }}
//                       >
//                         UI/UX Designer
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Illustrations Designer
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Figma Expert
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Typography
//                     variant="h5"
//                     sx={{ color: "#000", px: 2, fontWeight: 500 }}
//                   >
//                     $150
//                   </Typography>
//                   <Typography
//                     variant="p"
//                     sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                   >
//                     In 7 Days
//                   </Typography>
//                 </Box>
//               </Box>

//               <Grid container spacing={2}>
//                 {/* Left half */}
//                 <Grid item xs={8}>
//                   <Box
//                     sx={{
//                       p: 2.5,
//                       pt: 0,
//                       display: "flex",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="p"
//                       sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                     >
//                       I'm seeking an experienced and efficient app developer to
//                       create an innovative social networking application for iOS
//                       platform. Details about the target audience are currently
//                       confidential. Since the completion I'm seeking an
//                       experienced and efficient app developer to create more....
//                     </Typography>
//                   </Box>
//                 </Grid>

//                 {/* Right half */}
//                 <Grid
//                   item
//                   xs={4}
//                   sx={{
//                     display: "flex",
//                     alignItems: "end",
//                     justifyContent: "end",
//                     ml: "auto",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
//                     <Button
//                       sx={{
//                         display: "flex",
//                         mx: 1,
//                         textTransform: "capitalize",
//                         border: 1,
//                         borderColor: "#0a3114",
//                         fontSize: 16,
//                         color: "#000",
//                         borderRadius: 10,

//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#0a3114",
//                         },
//                       }}
//                     >
//                       Chat
//                     </Button>
//                     <Button
//                       sx={{
//                         display: "flex",
//                         fontFamily: "inherit",
//                         textTransform: "capitalize",
//                         bgcolor: "#0a3114",
//                         fontSize: 16,
//                         color: "#fff",
//                         borderRadius: 10,
//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#fff",
//                         },
//                       }}
//                     >
//                       Award
//                     </Button>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box>
//             <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px",
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   mr: "auto",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Back
//               </Button>
//               <Box>
//                 <CustomPagination count={10} />
//               </Box>
//             </Box>
//           </Box>
//         )}
//       </Box>
//     </>
//   );
// }

// function OngoingProjectDetails({ particularProject, onBack }) {
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const [searchParams, setSearchParams] = useSearchParams();
//   const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isFinishModalOpen, setIsFinishModalOpen] = useState(false); // New state for finish milestone modal
//   const [valuerating, setValueRating] = React.useState("");

//   const { t } = useTranslation();

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const handleFocus = () => {
//     setIsFocused(false);
//   };

//   const handleBlur = () => {
//     setIsFocused(false);
//   };

//   // const toggleFinishModal = () => {
//   //   setIsFinishModalOpen(!isFinishModalOpen);
//   // };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };
//   const handleClose = () => {
//     setIsModalOpen(false);
//   };
//   const handleCloseRating = () => {
//     setIsFinishModalOpen(false);
//   };

//   const [milestonesList, setMileStonesList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   useEffect(() => {
//     getMileStones();
//     //eslint-disable-next-line
//   }, []);

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     getMileStones(value);
//   };

//   const getMileStones = async () => {
//     try {
//       LoaderShow();
//       const token = GetUserDetails().token;

//       const dataToSend = {
//         projectId: particularProject?.project?._id,
//       };
//       const response = await postAPI(
//         "milestone/employee/get",
//         dataToSend,
//         token
//       );
//       if (response.statusCode === 200) {
//         LoaderHide();
//         if (searchParams.get("tab") && searchParams.get("tab") == "milestone") {
//           setTabValue(1);
//           searchParams.delete("tab");
//           setSearchParams(searchParams);
//         }
//         setMileStonesList(response?.data);
//         setTotalPages(response?.pagination?.totalPages);
//       }
//     } catch (error) {
//       LoaderHide();
//       // toast.error(error.message)
//     }
//   };

//   const onMileStoneStateChange = async (status, mileStone) => {
//     LoaderShow();
//     try {
//       const { token } = GetUserDetails();
//       const data = {
//         status: status,
//         milestoneId: mileStone._id,
//       };
//       const response = await postAPI("milestone/status/update", data, token);
//       if (response.statusCode == 200) {
//         setMileStonesList((mileStones) =>
//           mileStones.map((ele) => {
//             if (ele._id == mileStone._id) {
//               ele.status = status;
//             }
//             return ele;
//           })
//         );
//       }
//     } catch (err) {
//       console.log(err);
//       toast.error(err);
//     }
//     LoaderHide();
//   };

//   return (
//     <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//       <Box
//         className="plZero prZero"
//         sx={{
//           borderBottom: 1,
//           borderColor: "#d6d6d6",
//           mx: 2,
//           px: 2.5,
//           py: 2.5,
//           pb: 0,
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <Tabs
//             variant={isMobile ? "scrollable" : "standard"}
//             scrollButtons={isMobile ? "auto" : "off"}
//             allowScrollButtonsMobile={isMobile}
//             textColor="#222222"
//             sx={{
//               "& .MuiTabs-indicator": {
//                 backgroundColor: "#000",
//               },
//             }}
//             value={tabValue}
//             onChange={handleChange}
//             aria-label="project details tabs"
//           >
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("ProjectDetailsOne")}
//             />
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("Milestones")}
//             />
//           </Tabs>
//           {tabValue == 1 && (
//             <Button onClick={getMileStones} sx={{ borderRadius: "0" }}>
//               <RefreshIcon sx={{ color: "#000" }} />
//             </Button>
//           )}
//         </Box>
//       </Box>
//       {tabValue === 0 && (
//         <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//           <Box
//             className="flexColumnRespo"
//             sx={{ display: "flex", justifyContent: "space-between" }}
//           >
//             <Typography
//               className="employeeHead"
//               variant="h5"
//               sx={{ color: "#000" }}
//             >
//               {particularProject?.project?.title}
//             </Typography>
//             <Typography
//               className="employeeHead"
//               variant="h6"
//               sx={{ color: "#000" }}
//             >
//               ${particularProject?.project?.budget?.min} - $
//               {particularProject?.project?.budget?.max}
//             </Typography>
//           </Box>
//           <Box className="priceHeadEmployee" sx={{ py: 4 }}>
//             <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//               {particularProject?.project?.description}
//             </Typography>
//           </Box>

//           <Box sx={{ py: 4 }}>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               {t("Skill")}
//             </Typography>
//             <Box
//               className="flexWrapResp"
//               sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
//             >
//               {particularProject?.project?.skillsRequired?.map(
//                 (skill, index) => (
//                   <Button
//                     onClick={onBack}
//                     className="skillText catBtnTop"
//                     sx={{
//                       fontFamily: "inherit",
//                       px: 4,
//                       mx: 1,
//                       fontSize: "16px",
//                       borderRadius: 10,
//                       pb: 1,
//                       backgroundColor: "#0B3013",
//                       width: "auto",
//                       maxWidth: "fit-content",
//                       textTransform: "capitalize",
//                       color: "#fff",
//                       display: "flex",
//                       fontWeight: "400",

//                       "&:hover": {
//                         backgroundColor: "#0B3013", // Keep the same color as default
//                       },
//                     }}
//                   >
//                     {skill?.name}
//                   </Button>
//                 )
//               )}
//             </Box>
//           </Box>
//           <Box sx={{}}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: "#000",
//                 fontWeight: 600,
//                 pb: 0,
//                 fontSize: 18,
//                 px: 0,
//               }}
//             >
//               {t("Attachments")}
//             </Typography>
//             <Box
//               className="uploadSec"
//               sx={{
//                 py: 3,
//                 pb: 0,
//               }}
//             >
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 {particularProject?.project?.documents?.length === 0 ? (
//                   ""
//                 ) : (
//                   <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />
//                 )}
//                 <Typography
//                   variant="p"
//                   sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                 >
//                   {particularProject?.project?.documents?.map((url, index) => (
//                     <div key={index}>
//                       <h3>Document {index + 1}</h3>
//                       <a
//                         href={url}
//                         key={index}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         {url}
//                       </a>
//                     </div>
//                   ))}
//                 </Typography>
//                 {/* <img
//                   src={del}
//                   alt="Delete"
//                   style={{ cursor: "pointer" }}
//                 /> */}
//               </Box>
//             </Box>
//           </Box>
//           <Box sx={{ my: 2 }}>
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 ml: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               {t("BackButton")}
//             </Button>
//           </Box>
//         </Box>
//       )}
//       {tabValue === 1 && (
//         <>
//           <Box sx={{ py: 3 }}>
//             <TableContainer component={Paper} style={{ overflowX: "auto" }}>
//               <Table sx={{ fontFamily: "inherit" }}>
//                 <TableHead sx={{ borderBottom: "0!important" }}>
//                   <TableRow>
//                     <TableCell
//                       sx={{
//                         px: 5,
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("MileStoneName")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         px: 5,
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Status")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Duration")} {t("inDays")}
//                     </TableCell>

//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Cost")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Action")}
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {milestonesList?.length === 0 ? (
//                     <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
//                       <NodataFound />
//                     </Box>
//                   ) : (
//                     milestonesList?.map((milestone, index) => (
//                       <TableRow
//                         sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
//                       >
//                         <TableCell
//                           sx={{
//                             px: 5,
//                             fontWeight: 500,
//                             fontSize: 16,
//                             fontFamily: "inherit",
//                             cursor: "pointer",
//                           }}
//                         >
//                           {milestone?.title}
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             px: 5,
//                             fontWeight: 500,
//                             fontSize: 16,
//                             fontFamily: "inherit",
//                             cursor: "pointer",
//                           }}
//                         >
//                           <Box
//                             sx={{
//                               backgroundColor: "#d30100",
//                               color: "#fff",
//                               borderRadius: 10,
//                               p: 2,
//                               width: "auto",
//                               maxWidth: "fit-content",
//                               py: 1,
//                               fontWeight: "400",
//                             }}
//                           >
//                             {MILESTONE_STATUS[milestone?.status]}
//                           </Box>
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             fontWeight: 500,
//                             fontSize: 16,
//                             fontFamily: "inherit",
//                           }}
//                         >
//                           {milestone?.dueDate}
//                         </TableCell>

//                         <TableCell
//                           sx={{
//                             fontWeight: 500,
//                             fontSize: 16,
//                             fontFamily: "inherit",
//                           }}
//                         >
//                           <Box
//                             sx={{ display: "flex", flexDirection: "column" }}
//                           >
//                             <Box>
//                               ${" "}
//                               {Number(milestone?.amount) -
//                               Number(milestone?.fee || 0)
//                                 ? Number(milestone?.amount) -
//                                   Number(milestone?.fee || 0)
//                                 : Number(milestone?.amount)}
//                             </Box>
//                             <Typography
//                               variant="small"
//                               sx={{ color: "#000", fontWeight: 300 }}
//                             ></Typography>
//                           </Box>
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             fontWeight: 500,
//                             fontSize: 16,
//                             fontFamily: "inherit",
//                           }}
//                         >
//                           {milestone?.status == "pending" && (
//                             <Button
//                               className="borderRadNone"
//                               sx={{
//                                 backgroundColor: "#2bb95e",
//                                 color: "#fff",
//                                 borderRadius: 10,
//                                 p: 2,
//                                 width: "auto",
//                                 maxWidth: "fit-content",
//                                 py: 1,
//                                 fontWeight: "400",
//                                 "&:hover": {
//                                   backgroundColor: "#2bb95e", // Keep the same color as default
//                                 },
//                               }}
//                               onClick={(ev) =>
//                                 onMileStoneStateChange("in-process", milestone)
//                               }
//                             >
//                               Start
//                             </Button>
//                           )}
//                           {milestone?.status == "in-process" && (
//                             <Button
//                               className="borderRadNone"
//                               sx={{
//                                 backgroundColor: "#2bb95e",
//                                 color: "#fff",
//                                 borderRadius: 10,
//                                 p: 2,
//                                 width: "auto",
//                                 maxWidth: "fit-content",
//                                 py: 1,
//                                 fontWeight: "400",
//                                 "&:hover": {
//                                   backgroundColor: "#2bb95e", // Keep the same color as default
//                                 },
//                               }}
//                               onClick={(ev) =>
//                                 onMileStoneStateChange("complete", milestone)
//                               }
//                             >
//                               Complete
//                             </Button>
//                           )}
//                           {!["pending", "in-process"].includes(
//                             milestone?.status
//                           ) && "-"}
//                         </TableCell>
//                       </TableRow>
//                     ))
//                   )}

//                   {/* <TableRow
//                     sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <TableCell
//                       sx={{
//                         px: 5,
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                         cursor: "pointer",
//                       }}
//                     >
//                       Food Delivery Mobile App
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       10 May
//                     </TableCell>

//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       <Box sx={{ display: "flex", flexDirection: "column" }}>
//                         <Box>$150</Box>
//                         <Typography
//                           variant="small"
//                           sx={{ color: "#000", fontWeight: 300 }}
//                         >
//                           Hourly Rate
//                         </Typography>
//                       </Box>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       <Box
//                         className='borderRadNone'
//                         sx={{
//                           backgroundColor: "#2bb95e",
//                           color: "#fff",
//                           borderRadius: 10,
//                           p: 2,
//                           width: "auto",
//                           maxWidth: "fit-content",
//                           py: 1,
//                           fontWeight: "400",
//                         }}
//                       >
//                         Pay Now
//                       </Box>
//                     </TableCell>
//                   </TableRow> */}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </Box>
//           <Box
//             className="flexColumnRespo"
//             sx={{ display: "flex", px: 4, justifyContent: "center", pb: 4 }}
//           >
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 mr: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               {t("BackButton")}
//             </Button>

//             <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
//               {milestonesList?.length > 0 ? (
//                 <CustomPagination
//                   count={totalPages}
//                   page={currentPage}
//                   onChange={handlePageChange}
//                 />
//               ) : (
//                 ""
//               )}
//             </Box>
//           </Box>
//         </>
//       )}
//       <Box className="ModalSection">
//         {isModalOpen && (
//           <Modal
//             open={isModalOpen}
//             onClose={toggleModal}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box
//               sx={{
//                 position: "absolute",
//                 width: 550,
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 bgcolor: "background.paper",
//                 boxShadow: 24,
//                 borderRadius: 4,
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   py: 2,
//                   px: 3,
//                   alignItems: "center",
//                   borderBottom: 1,
//                   borderColor: "#d8d8d8",
//                 }}
//               >
//                 <Typography variant="h6">Add Milestone</Typography>
//                 <IconButton onClick={handleClose}>
//                   <img src={close} alt="db" />
//                 </IconButton>
//               </Box>
//               <Box sx={{ py: 0, pb: 4 }}>
//                 <Box sx={{ px: 3 }}>
//                   <Grid container>
//                     <Grid item xs={12}>
//                       <Box>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 2,
//                             fontWeight: "600",
//                             color: "#000",
//                             fontSize: 17,
//                           }}
//                         >
//                           {t("MileStoneDescription")}
//                         </FormLabel>
//                         <Textarea
//                           sx={{
//                             width: "100%!important",
//                             border: 1,
//                             borderColor: "#cbcbcb",
//                             borderRadius: 1,
//                           }}
//                           minRows={6}
//                           maxRows={6}
//                           placeholder={t("MileStoneDescription")}
//                         />
//                       </Box>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={2}>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("TimeDuration")}
//                         </FormLabel>
//                         <TextField
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("TimeDuration")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                       </Box>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("DepositeAmount")}
//                         </FormLabel>
//                         <TextField
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("DepositeAmount")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-around",
//                     mt: 4,
//                   }}
//                 >
//                   <Button
//                     fullWidth={true}
//                     variant="contained"
//                     sx={{
//                       backgroundColor: "#0B3013!important",
//                       fontSize: 16,
//                       borderRadius: 2,
//                       textTransform: "capitalize",
//                       px: 6,
//                       py: 1,
//                       mx: 3,
//                     }}
//                   >
//                     Save
//                   </Button>
//                 </Box>
//               </Box>
//             </Box>
//           </Modal>
//         )}

//         <Modal
//           open={isFinishModalOpen}
//           onClose={handleCloseRating}
//           aria-labelledby="finish-modal-title"
//           aria-describedby="finish-modal-description"
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               width: 550,
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               borderRadius: 4,
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 py: 2,
//                 px: 3,
//                 alignItems: "center",
//                 borderBottom: 1,
//                 borderColor: "#d8d8d8",
//               }}
//             >
//               <Typography variant="h6">Leave a Review for John Doe</Typography>
//               <IconButton onClick={handleCloseRating}>
//                 <img src={close} alt="db" />
//               </IconButton>
//             </Box>
//             <Box sx={{ px: 4 }}>
//               <Typography
//                 variant="p"
//                 sx={{
//                   textAlign: "center",
//                   fontSize: 17,
//                   color: "#000",
//                   display: "block",
//                   py: 2,
//                 }}
//               >
//                 How would you rate working at John Doe ?
//               </Typography>
//             </Box>
//             <Box sx={{ display: "flex", justifyContent: "center" }}>
//               <Rating
//                 name="simple-controlled"
//                 value={valuerating}
//                 onChange={(event, newValue) => {
//                   setValueRating(newValue);
//                 }}
//                 sx={{ fontSize: "36px" }} // Adjust the font size as needed
//               />
//             </Box>
//             <Grid container sx={{ px: 4 }}>
//               <Grid item xs={12}>
//                 <Box>
//                   <FormLabel
//                     sx={{
//                       display: "block",
//                       textAlign: "start",
//                       py: 2,
//                       fontWeight: "600",
//                       color: "#000",
//                       fontSize: 17,
//                     }}
//                   >
//                     {t("MileStoneDescription")}
//                   </FormLabel>
//                   <Textarea
//                     sx={{
//                       width: "100%!important",
//                       border: 1,
//                       borderColor: "#cbcbcb",
//                       borderRadius: 1,
//                     }}
//                     minRows={6}
//                     maxRows={6}
//                     placeholder={t("MileStoneDescription")}
//                   />
//                 </Box>
//               </Grid>
//             </Grid>

//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 my: 4,
//                 px: 4,
//               }}
//             >
//               <Button
//                 fullWidth={true}
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#0B3013!important",
//                   fontSize: 16,
//                   borderRadius: 3,
//                   textTransform: "capitalize",

//                   py: 1,
//                 }}
//               >
//                 Submit Review
//               </Button>
//             </Box>
//           </Box>
//         </Modal>
//       </Box>
//     </Box>
//   );
// }

// function CompletedProjectDetails({ particularProject, onBack }) {
//   const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const { t } = useTranslation();

//   return (
//     <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//       <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
//         <Tabs
//           variant={isMobile ? "scrollable" : "standard"}
//           scrollButtons={isMobile ? "auto" : "off"}
//           allowScrollButtonsMobile={isMobile}
//           textColor="#222222"
//           sx={{
//             borderBottom: 1,
//             borderColor: "#dbdbdb",
//             "& .MuiTabs-indicator": {
//               backgroundColor: "#000",
//             },
//           }}
//           value={tabValue}
//           onChange={handleChange}
//           aria-label="project details tabs"
//         >
//           <Tab
//             sx={{
//               textTransform: "capitalize",
//               pl: 0,
//               mx: 1,
//               fontSize: 17,
//               fontFamily: "inherit",
//             }}
//             label="Project Details"
//           />
//         </Tabs>
//       </Box>
//       {tabValue === 0 && (
//         <Box sx={{ px: 4, py: 3 }}>
//           <Box
//             className="flexColumnRespo"
//             sx={{ display: "flex", justifyContent: "space-between" }}
//           >
//             <Typography
//               variant="h5"
//               sx={{ color: "#000" }}
//               className="employeeHead"
//             >
//               {particularProject?.project?.title}
//             </Typography>
//             <Typography
//               variant="h6"
//               sx={{ color: "#000" }}
//               className="employeeHead"
//             >
//               $ {particularProject?.project?.budget?.min} - ${" "}
//               {particularProject?.project?.budget?.max}
//             </Typography>
//           </Box>
//           <Box className="priceHeadEmployee" sx={{ py: 4 }}>
//             <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//               {particularProject?.project?.description}
//             </Typography>
//           </Box>

//           <Box sx={{ py: 4 }}>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               Skill Required
//             </Typography>
//             <Box
//               className="flexWrapResp"
//               sx={{ display: "flex", flexWrap: "wrap", py: 3 }}
//             >
//               {particularProject?.project?.skillsRequired?.map(
//                 (skill, index) => (
//                   <Button
//                     onClick={onBack}
//                     className="skillText catBtnTop"
//                     sx={{
//                       fontFamily: "inherit",
//                       px: 4,
//                       mx: 1,
//                       fontSize: "16px",
//                       borderRadius: 10,
//                       pb: 1,
//                       backgroundColor: "#0B3013",
//                       width: "auto",
//                       maxWidth: "fit-content",
//                       textTransform: "capitalize",
//                       color: "#fff",
//                       display: "flex",
//                       fontWeight: "400",

//                       "&:hover": {
//                         backgroundColor: "#0B3013", // Keep the same color as default
//                       },
//                     }}
//                   >
//                     {skill?.name}
//                   </Button>
//                 )
//               )}
//             </Box>
//           </Box>
//           <Box sx={{}}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: "#000",
//                 fontWeight: 600,
//                 pb: 0,
//                 fontSize: 18,
//                 px: 0,
//               }}
//             >
//               {t("Attachments")}
//             </Typography>
//             <Box
//               className="uploadSec"
//               sx={{
//                 py: 3,
//                 pb: 0,
//               }}
//             >
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

//                 <Typography
//                   variant="p"
//                   sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                 >
//                   {particularProject?.project?.documents?.map((url, index) => (
//                     <div key={index}>
//                       <h3>Document {index + 1}</h3>
//                       <a
//                         href={url}
//                         key={index}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         {url}
//                       </a>
//                     </div>
//                   ))}
//                 </Typography>
//                 {/* <img
//                   src={del}
//                   alt="Delete"
//                   style={{ cursor: "pointer" }}
//                 /> */}
//               </Box>
//             </Box>
//           </Box>
//           <Box sx={{ my: 2 }}>
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 ml: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               Back
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// }

export default function EmployeeMyProjects() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(0);
  const [selectedProjectPosted, setSelectedProjectPosted] = useState(null);
  const [selectedProjectOngoing, setSelectedProjectOngoing] = useState(null);
  const [selectedProjectCompleted, setSelectedProjectCompleted] =
    useState(null);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const handleProjectPostedClick = (projectName) => {
  //   setSelectedProjectPosted(projectName);
  //   setSelectedProjectOngoing(null);
  //   setSelectedProjectCompleted(null);
  // };

  const handleProjectOngoingClick = (projectNameOngoing) => {
    setSelectedProjectOngoing(projectNameOngoing);
    setSelectedProjectPosted(null);
    setSelectedProjectCompleted(null);
  };

  const handleProjectCompletedClick = (projectNameCompleted) => {
    setSelectedProjectCompleted(projectNameCompleted);
    setSelectedProjectPosted(null);
    setSelectedProjectOngoing(null);
  };

  const handleBack = () => {
    setSelectedProjectPosted(null);
    setSelectedProjectOngoing(null);
    setSelectedProjectCompleted(null);
  };

  // const postedProjects = [
  //   { name: "Project 1", proposals: 25, status: "Active", cost: "$1000" },
  //   { name: "Project 2", proposals: 32, status: "Active", cost: "$300" },
  //   { name: "Project 3", proposals: 12, status: "Active", cost: "$500" },
  //   { name: "Project 4", proposals: 90, status: "Active", cost: "$800" },
  // ];

  const ongoingProjects = [
    { name: "Project 5", proposals: 25, status: "Active", cost: "$1000" },
    { name: "Project 6", proposals: 32, status: "Active", cost: "$300" },
  ];

  const completedProjects = [
    { name: "Project 7", proposals: 25, status: "Active", cost: "$1000" },
    { name: "Project 8", proposals: 32, status: "Active", cost: "$300" },
    { name: "Project 9", proposals: 32, status: "Active", cost: "$300" },
  ];

  const [allProposals, setAllProposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
    getAllPraposals();
    if (searchParams.get("project_type") == "completed") {
      setTabValue(1);
      searchParams.delete("project_type");
      setSearchParams(searchParams);
    }
    LoaderShow();
    //eslint-disable-next-line
  }, [tabValue]);

  const getAllPraposals = async (pageNumber) => {
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      LoaderShow();

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const response = await getAPI(
        `proposal/myproposals?page=${pn}&limit=10&status=${
          tabValue == 1 ? 0 : 2
        }`,
        // `proposal/myproposals?page=1&limit=10&status=`,
        token
      );

      if (response.statusCode === 200) {
        setAllProposals(response.data);
        if (searchParams.get("project_id")) {
          const id = searchParams.get("project_id");
          const selectedProject = response.data.find(
            (project) => project?.project?._id == id
          );
          if (selectedProject) {
            handleProjectOngoingClick(selectedProject);
          }
          searchParams.delete("project_id");
          setSearchParams(searchParams);
        }
        LoaderHide();
        setTotalPages(response.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const onGoingProjects = allProposals?.filter(
    (item) => item.project.status === "2"
  );
  const completedProjectsList = allProposals?.filter(
    (item) => item.project.status === "0"
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAllPraposals(value);
  };

  return (
    <section className="EmployeerCreateProject">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {(selectedProjectPosted ||
          selectedProjectOngoing ||
          selectedProjectCompleted) && (
          <IconButton onClick={handleBack} sx={{ mr: 1, mb: 1 }}>
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {t("MyProj")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      {!selectedProjectPosted &&
        !selectedProjectOngoing &&
        !selectedProjectCompleted && (
          <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
            <Box
              sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }}
              className="plZero prZero"
            >
              <Tabs
                variant={isMobile ? "scrollable" : "standard"}
                scrollButtons={isMobile ? "auto" : "off"}
                allowScrollButtonsMobile={isMobile}
                value={tabValue}
                onChange={handleChange}
                aria-label="project tabs"
                textColor="#222222"
                sx={{
                  borderBottom: 1,
                  borderColor: "#dbdbdb",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#000",
                  },
                }}
              >
                {/* <Tab
                  sx={{
                    textTransform: "capitalize",
                    pl: 0,
                    mx: 1,
                    textAlign: "start!important",
                    fontSize: 17,
                    fontFamily: "inherit",
                  }}
                  label="Posted Projects"
                /> */}
                <Tab
                  sx={{
                    textTransform: "capitalize",
                    pl: 0,
                    mx: 1,
                    fontSize: 17,
                    fontFamily: "inherit",
                  }}
                  label={t("Ongoing")}
                />
                <Tab
                  sx={{
                    textTransform: "capitalize",
                    pl: 0,
                    mx: 1,
                    fontSize: 17,
                    fontFamily: "inherit",
                  }}
                  label={t("CompletedProjects")}
                />
              </Tabs>
            </Box>

            <Box sx={{ my: 3, py: 4, pt: 0 }}>
              {tabValue === 0 && (
                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("ProjectName")}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Cost")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Status")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Duration")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {onGoingProjects?.length === 0 ? (
                        <Box
                          sx={{ py: 2, display: "flex", justifyContent: "end" }}
                        >
                          <NodataFound />
                        </Box>
                      ) : (
                        onGoingProjects?.map((data, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            onClick={() => handleProjectOngoingClick(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data?.project?.title}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box>$ {data?.maxAmount}</Box>
                                {/* <Typography
                                  variant="small"
                                  sx={{ color: "#000", fontWeight: 300 }}
                                >
                                  {data?.project?.paymentType}
                                </Typography> */}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.project?.status === "2" ? (
                                <Box
                                  sx={{
                                    backgroundColor: "#2bb95e",
                                    color: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    py: 1,
                                    fontWeight: "400",
                                  }}
                                >
                                  Ongoing
                                </Box>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data?.project?.duration?.name}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {tabValue === 1 && (
                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("ProjectName")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Cost")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Status")}
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Action")}
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {completedProjectsList?.length === 0 ? (
                        <Box
                          sx={{ py: 2, display: "flex", justifyContent: "end" }}
                        >
                          <NodataFound />
                        </Box>
                      ) : (
                        completedProjectsList?.map((data, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            onClick={() => handleProjectCompletedClick(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                            >
                              {data?.project?.title}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box>
                                  {data?.project?.averageCost}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#2bb95e",
                                  color: "#fff",
                                  borderRadius: 10,
                                  p: 2,
                                  width: "auto",
                                  maxWidth: "fit-content",
                                  py: 1,
                                  fontWeight: "400",
                                }}
                              >
                                Completed
                              </Box>
                            </TableCell>

                            {/* <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <IconButton>
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                              <IconButton>
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                            </TableCell> */}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
                <Box>
                  <CustomPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}

      {/* Render selected project details here */}
      {/* Render selected project details here */}
      {/* {selectedProjectPosted && (
        <PostedProjectDetails
          projectName={selectedProjectPosted}
          onBack={handleBack}
        />
      )} */}
      {selectedProjectOngoing && (
        <OngoingProjectDetails
          particularProject={selectedProjectOngoing}
          onBack={handleBack}
        />
      )}
      {selectedProjectCompleted && (
        <CompletedProjectDetails
          particularProject={selectedProjectCompleted}
          onBack={handleBack}
        />
      )}
    </section>
  );
}
