import React, { useState } from "react";
import { FormControl, MenuItem, Box, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import israel from "../../Assets/Images/isarelFlag.png"; // Corrected the typo in the file name
import usa from "../../Assets/Images/usalfag.avif"; // Ensure you have a local USA flag image or use a URL
import caret from "../../Assets/Images/caret.png"; // Ensure you have a local USA flag image or use a URL
import "./CountryFlag.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
import zIndex from "@mui/material/styles/zIndex";

const CustomSelect = styled(Select)({
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    outline: "none",
  },
});

const CountryFlag = () => {
  const { i18n } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState("usa");

  const handleSelectCountry = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    if (country === "israel") {
      i18n.changeLanguage("fa"); // Assuming 'he' for Hebrew (Israel)
    } else if (country === "usa") {
      i18n.changeLanguage("en");
    }
  };

  return (
    <FormControl sx={{ m: 0, p: 0, border: 0, position: "relative" }}>
      <CustomSelect
        value={selectedCountry}
        onChange={handleSelectCountry}
        displayEmpty
        IconComponent={ArrowDropDownIcon}
        sx={{
          position: "relative",
          zIndex: 10,
          "& .MuiSelect-select": {
            paddingLeft: "0px !important", // Remove padding-right
            paddingRight: "20px !important",
          },
          "& .MuiSelect-icon": { color: "white" },
          "&.MuiInputBase-root": {
            padding: "0 !important",
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center",
            border: "0 !important",
            outline: "none",
            "&:before": {
              borderBottom: "none !important",
            },
            "&:after": {
              borderBottom: "none !important",
            },
          },
          "&:focus": { boxShadow: "none !important", border: 0 },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              border: "none !important",
              boxShadow: "none !important",
            },
          },
        }}
      >
        <MenuItem value="usa">
          <img
            src={usa}
            alt="USA flag"
            style={{ marginRight: "5px", width: "35px", height: "auto" }}
          />
        </MenuItem>
        <MenuItem value="israel">
          <img
            src={israel}
            alt="Israel flag"
            style={{ marginRight: "5px", width: "35px", height: "auto" }}
          />
        </MenuItem>
      </CustomSelect>
      <Box
        className="caretCountry"
        style={{ position: "absolute", right: "-5px", zIndex: 9 }}
      >
        <img src={caret} className="filterCommonWhite" alt="caret" />
      </Box>
    </FormControl>
  );
};

export default CountryFlag;
