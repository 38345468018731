// src/Localization/enTranslations.js
const enTranslations = {
  // home: 'Home',
  // browseJobs: 'Browse Jobs',
  // help: 'Help',
  // contactUs: 'Contact Us',
  // categories:'Categories',
  // websiteItSoftware: 'Website, IT & Software',
  // designMediaArchitecture: 'Design, Media & Architecture',
  // dataEntryAdmin: 'Data Entry & Admin',
  // education: 'Education',
  // artificialIntelligence: 'Artificial Intelligence',
  // signIn: 'Sign In',
  // join: 'Join',
  profile: "Profile",
  // account: 'Account',
  // dashboard: 'Dashboard',
  // logout: 'Logout',

  Home: "Home",
  BrowseJobs: "Browse Jobs",
  Help: "Help",
  Contact: "Contact Us",
  Contact2: "Contact",
  SignIn: "Sign in",
  Join: "Join",
  OneStop: "One-Stop Job Search Platform in Iran",
  Match: "Match your skills & interests to perfect jobs.",
  Simple: "Simple application process - one tap to apply!",
  Build: "Build a strong profile to stand out to employers.",
  Connect: "Connect with top companies & agencies in Iran.",
  TwentyM: "Project Delivered",
  Active: "Active Freelancer",
  Safe: "Safe & Secure",
  Trustworthy: "Trustworthy & Professional",
  TotalFreelance: "Total Freelancer",
  Positive: "Positive Review",
  Order: "Order Received",
  Projects: "Projects Completed",
  Files: "Files",
  File: "File",
  addAmt: "Please add amount.",
  amt: "Amount",
  suffAmt: "Please send sufficient amount for transaction fee",
  greaterThen0: "Please add amount greater than 0.",
  editProporal: "Edit Proposal",
  selectDel: "Please select delivery time.",
  coverLet: "Cover letter should be at least 10 characters long.",
  Trusted: "Trusted By The World’s Best",
  BrowseTalent: "Browse Talent By Category",
  Find: "Find the perfect skills and experience for your next project with just a few clicks. WorkWaveHub makes it simple!",
  All: "All Categories",
  Website: "Website, IT & Software",
  Design: "Design, Media & Architecture",
  Data: "Data Entry & Admin",
  Education: "Education",
  Artificial: "Artificial Intelligence",
  Talented: "Talented Pool are here for your Business – Iran Work Marketplace",
  Search:
    "Search and connect with a vast pool of skilled professionals across diverse industries. Our innovative platform streamlines the recruitment process, saving you time and resources. Find the perfect fit for your open positions – from experienced developers to marketing wizards and everything in between. Empower your business with top Iranian talent. Get started with WorkWaveHub today!",
  The: "The Best Talent",
  OurPlatform:
    "Our platform connects you with a vast pool of pre-vetted Iranian professionals across various industries.",
  Fast: "Fast Bids",
  WorkWaveHubs:
    "WorkWaveHub's streamlined process lets you quickly post jobs and receive bids from qualified candidates.",
  Quality: "Quality Work",
  Our: "Our focus on skilled professionals ensures you receive high-quality work from qualified and skilled professionals.",
  Be: "Be in Control",
  WorkWaveHub2:
    "WorkWaveHub's platform provides clear communication tools and secure payment options, giving you complete control over the entire hiring process.",
  How: "How does WorkWaveHub works?",
  Create: "Create your profile",
  Showcase:
    "Showcase your skills and experience to stand out to top Iranian companies.",
  FindPerfect: "Find perfect jobs",
  SearchBrowse:
    "Search and browse thousands of opportunities across diverse industries in Iran.",
  SimpleApp: "Simple Application",
  Apply:
    "Apply to jobs with a single click and connect with potential employers.",
  Manage: "Manage your career",
  Track:
    "Track applications, receive job alerts, and manage your career journey on WorkWaveHub.",
  Love: "Love To Earn With Workwavehub",
  Clients: "Clients rate professionals on Workwavehub",
  Customers: "95% of customers are satisfied through to see their freelancers",
  AwardWinner: "Award Winner",
  HomeOw: "Home Ownership",
  Followus: "Follow us",
  Terms: "Terms of Service",
  Privacy: "Privacy Policy",
  Site: "Site Map",
  AboutOne: "About",
  CategoriesOne: "Categories",
  WebsiteSoft: "Website, IT & Software",
  DesignMedia: "Design, Media & Architecture",
  DataEntry: "Data Entry & Admin",
  Sales: "Sales & Marketing",
  Product: "Product Sourcing & Manufacturing",
  Translation: "Translation & Languages",
  Freight: "Freight, Shipping & Transportation",
  EducationOne: "Education",
  ArtificialIntel: "Artificial Intelligence",
  Health: "Health Care Service",
  Jobs: "Jobs for Anyone",
  Support: "Support",
  HelpSupport: "Help & Support",
  Trust: "Trust & Safety",
  Subscribe: "Subscribe",
  Your: "Your email address",
  Send: "Send",
  Downloads: "Downloads From",
  AllRights: "All Rights Reserved @workwavehub",
  Explore: "Explore your career potential in Iran with WorkWaveHub.",
  FindJob: "Find exciting job opportunities across various industries.",
  ShowcaseSkill: "Showcase your skills and experience to top companies.",
  Get: "Get matched with perfect jobs based on your interests.",
  ManageJob: "Manage your job search efficiently with WorkWaveHub tools.",
  Already: "Already have an account?",
  JoinUs: "Join Us!",
  JoinGrow: "Join our growing community of professionals in Iran.",
  Employee: "Employee",
  Personal: "Personal account to manage all your activities.",
  Employer: "Employer",
  Own: "Own or belong to a company, this is for you.",
  Register: "Register Employer Account!",
  RegisterEmployee: "Register Employee Account!",
  WorkWaveHub:
    "WorkWaveHub is your one-stop application to find the perfect talent fit in Iran. We use clever tech to match skills and interests for your jobs.",
  First: "First Name*",
  Last: "Last Name",
  Email: "Email address*",
  password: "Password",
  CreatePass: "Create password*",
  I: "I agree to terms & conditions",
  SignUp: "Sign up",
  RegisterGoogle: "Register with Google",
  Please: "Please Verify Your Phone Number",
  joinWWh:
    "Join the WorkWaveHub community with confidence. Verify your number today!",
  Phone: "Phone number",
  SendCode: "Send Code",
  YourInfo: "Your Info is safely secured",
  Almost:
    "Almost There! Verify Your Phone Number to Secure Your Account. Verifying your phone number is a quick step to complete your registration.",
  We: "We have sent a verification code to your phone number. Enter the code below to complete your WorkWaveHub registration.",
  Didnts:
    "Didn't receive the code? It usually arrives within 30 seconds. You can resend it if it takes longer.",
  OTP: "OTP Verification",
  WeOTP: "We have sent an OTP to your phone number!",
  DidntOne: "Didn't receive OTP?",
  resendOTP: "Resend OTP?",
  Verify: "Verify",
  Skills: "Skills That Help You Get A Job",
  WorkWaveHubHelp:
    "WorkWaveHub can help you find jobs that match your amazing skills! Make a great profile today!",
  Suggested: "Suggested Skills",
  Save: "Save & Continue",
  Complete: "Complete Your Profile!",
  YourChance:
    "Your profile is your chance to stand out from the crowd. Show your well-rounded and interesting.",
  User: "User Name",
  Next: "Next",
  Tell: "Tell us a bit about yourself",
  Tell2: "Tell us your Skills",
  Show: "Show employers why you are unique and what you can bring to the table.",
  What: "What do you do?",
  Describe: "Describe yourself",
  IStrong: "Let's get you started on your job search journey in Iran.",
  Darius: "Darius Farhadi, CEO",
  Welcome: "Welcome Back!",
  To: "To begin this journey, tell us what type of account you’d be opening.",
  EmailAddd: "Email address*",
  EnterEmailAdd: "Enter email address",
  EnterUserName: "Enter user name",
  EnterFullName: "Enter full name",
  Password: "Password*",
  Enter: "Enter your password",
  Remember: "Remember me",
  Forgot: "Forgot Password?",
  RegisterACCC: "Register Account",
  Login: "Login with Google",
  ToReset:
    "To reset your password, you need your email or mobile number that can be authenticated",
  Reset: "Reset Password",
  Back: "Back to Login",
  Check: "Check Your Email",
  Open: "Open Your Email",
  You: "You have not received the email? Resend",
  Successfully: "Successfully",
  YourPass1:
    "Your password has been updated, please change your password regularly to avoid this happening",
  Continue: "Continue",
  DesignCreative: "Design & Creative",
  Give: "Give your visitor a smooth online experience with a solid UX design",
  WhatLook: "What are you looking for?",
  Choose: "Choose Category",
  ITandSoftware: "IT & Software",
  Medical: "Medical",
  Architecture: "Architecture",
  SearchText: "Search",
  noChatFound: "No chat found!",
  Filters: "Filters",
  ProjectTypeOne: "Project Type",
  Hourly: "Hourly Rate",
  Fixed: "Fixed Price",
  BudgetOne: "Budget",
  Location: "Location",
  Clear: "Clear",
  Top: "Top Results",
  Sort: "Sort by",
  Latest: "Latest",
  Bids: "bids",
  Budget: "Budget",
  USD: "USD",
  minutes: "minutes ago",
  ProjectDetails: "Project Details",
  Skill: "Skill Required",
  Place: "Place Proposal on this project",
  Proposal: "Proposal Amount",
  HowProj: "How long will this project take?",
  Select: "Select",
  Cover: "Cover Letter",
  Attachments: "Attachments",
  Drag: "Drag or upload project files",
  Other: "Other Proposals",
  in: "in 7 days",
  Replies: "Replies within an hour",
  Similar: "Similar Projects",
  SendProp: "Send Proposal",
  Share: "Share this project with others",
  Copy: "Copy Link",
  Report: "Report Project",
  Client: "Client Verification",
  Dashboard: "Dashboard",
  Logout: "Logout",
  My: "My Proposals",
  Submitted: "Submitted Proposals",
  Archive: "Archive Proposals",
  ProjectName: "Project Name",
  Proposals: "Proposals",
  Average: "Average Proposal",
  MyProposal: "My Proposal",
  Action: "Action",
  Status: "Status",
  MyProj: "My Projects",
  AllPop: "All Proposals",
  SkillReq: "Skill Required",
  ProjectDetailsOne: "Project Details",
  Ongoing: "Ongoing Projects",
  Cost: "Cost",
  Duration: "Duration",
  selectCategoryFirst: "Please select category first",
  coinPayment: "Coin Payments",
  closeIn: "Close in",
  close: "Close",
  firstNameReq: "First Name is required",
  lastNameReq: "Last Name is required",
  stateNameReq: "State Name is required",
  crytpoReq: "Please enter Crypto address",
  Address: "Address",
  PaymentId: "Payment ID",
  timeLeft: "Time Left",
  Note: "Note",
  doNotReload:
    "PLEASE DO NOT GO BACK OR RELOAD THIS PAGE WHILE TRANSACTION IS IN PROCESS",
  hourRateReq: "Per hour rate cannot be less than 0",
  validCrypto: "Please enter valid USDT Crypto address",
  firstNameGreater3: "First name should be more than 3 characters",
  lastNameGreater3: "Last name should be more than 3 characters",
  inDays: "(in days)",
  MyBook: "My Bookmark",
  Bookmarked: "Bookmarked Projects",
  Message: "Message",
  SendMessage: "Send Message",
  Type: "Type a Message",
  perHour: "Per Hour",
  emailVerified: "Email verified",
  phoneVerified: "Phone number verified",
  phoneNotVerified: "Phone number not verified",
  Notifications: "Notifications",
  emailNotVerified: "Email not verified",
  AwardData:
    "The more your work on the WorkWaveHub platform, and complete tasks on time; the more you will make strong profile. The strong profile will help you to move different levels. For each job, you should get reviews from the job recruiter. These reviews can you give an advantage when bidding on projects and completing them successfully.",
  Delete: "Delete Chat",
  ActiveOne: "Active",
  MyProf: "My Profile",
  Profile: "Profile Details",
  Max: '"Max file size is 1MB, Minimum dimension: 330x300 And Suitable files are .jpg & .png"',
  Introduce: "Introduce Yourself",
  Languages: "Languages Level",
  Language: "Language",
  City: "City",
  Country: "Country",
  TypeOne: "Type",
  Specialization: "Specialization",
  Gender: "Gender",
  HourlyRate: "Hourly Rate",
  PhoneNo: "Phone number",
  EmailAdd: "Email Address",
  LastName: "Last Name",
  FirstName: "First Name",
  SkillsOne: "Skills",
  SkillsTwo: "Skills 1",
  Point: "Point",
  Are: "Are you sure you want to logout?",
  Cancel: "Cancel",
  Yes: "Yes",
  RegisterEmployee: "Register Employee Account!",
  WorkWaveHubIran:
    "WorkWaveHub is your one-stop application to find the perfect talent fit in Iran. We use clever tech to match skills and interests for your jobs.",
  Full: "Full Name",
  UserNameOne: "User Name",
  PhoneOne: "Phone number",
  TermsAnd: "I agree to terms & conditions",
  SignupOne: "Sign up",
  OTPVerify: "OTP Verification",
  WeVerify:
    "We will send you a verification code via SMS. Just enter it in the app, and you are good to go.",
  SkillsInterst: "Skills and Interest You Are Looking For",
  Post: "Post jobs and hire the best talent for your job – it is that easy!",
  SuggestedSkill: "Suggested Skills",
  SaveContinue: "Save & Continue",
  SavePost: "Save & Post",
  CreateProj: "Create Project",
  BrowseProf: "Browse Profile",
  HireSkill:
    "Hire skilled independent professionals for any project, over the internet.",
  LargestHub: "Iran's largest hub for independent professionals.",
  JobOpputunities: "All types of job opportunities",
  EnjoySavings: "Enjoy savings of up to 90% and receive free quotes.",
  PayOnly: "Pay only upon your satisfaction.",
  FoPurpose:
    "For the purpose of industry regulation, your details are required.",
  MaxRow: "Maximum 4 Rows",
  Submite: "Submit",
  EnterPass: "Enter password",
  ThiHelp: "This helps us recommend jobs for you.",
  ShownEmployee:
    "Show employers why you are unique and what can you bring to the table.",
  WriteLine: "Write a one line description about yourself.",
  BeginJourney:
    "To begin this journey, tell us what type of account you’d be opening",
  LoginEmployee: "Login",
  SignUpEmployee: "Sign Up",
  DontHavAccount: " Don’t have an account?",
  BackToHome: "Back to home",
  FullNameIsRequired: "Full Name is required helllo",
  EmailIsRequired: "Email is required",
  PasswordIsRequired: "Password is required",
  FullNameAtleastThree: "Full name should be more than 3 characters",
  InvalidEmailFormat: "Invalid email format",
  detailsPRojectErr: "Project Detail should be at least 10 characters long.",
  PassWordMustBe8Characters:
    "Password must be at least 8 characters long and include one capital letter. one small letter and one special character",
  YouMustAgree: "You must agree to the Terms & Conditions",
  UserNameIsRequired: "User Name is required",
  UserNameIs3Characters: "User name should be more than 3 characters",
  PleaseEnterPhoneNumber: "Please enter phone number",
  PleaseEnterValidNumber: "Please enter a valid phone number",
  Browse: "Browse",
  CreateProjectAndStartActivities: "Create Projects and start your activities.",
  BrowseProfileAndStartActivities: "Browse Profiles and start your activities.",
  UploadAttachments: "Upload Attachments",
  NoDocumentsFound: "No Documents found",
  BackButton: "Back",
  FileName: "FileName",
  CompletedProjects: "Completed Projects",
  Milestones: "Milestones",
  Milestone: "Milestone",
  Balance: "Balance",
  addBillingMethod: "Add Billing Method",
  paypalPaymentDes: "Secure online payment through the PayPal portal",
  stripePaymentDes: "Secure online payment through the Stripe portal",
  cryptoPaymentDes: "Secure online payment through the Crypto portal",
  deleteProject: "Are you sure you want to delete project?",
  Payment: "Payment",
  MileStoneName: "Milestone Name",
  hireFor: "Hire for",
  subtotal: "Subtotal",
  marketPlaceFee: "Marketplace Fee",
  pay: "Pay",
  estimatedTotal: "Estimated Total",
  addNewMilestone: "Add New Milestone",
  Document: "Document",
  Filter: "Filter",
  MyBookmarks: "My Bookmarks",
  BookmarkProjects: "Bookmark Projects",
  MyProfile: "My Profile",
  Skills: "Skills",
  ChangePassword: "Change Password",
  DeleteAccount: "Delete Account",
  VerifyEmail: "Verify Email",
  State: "State",
  Save: "Save",
  WhatAreTheMainSkills: "What are the main skill required for your work ?",
  onceDeleted: `Once deleted, recovering the data may not be possible. Click "Yes" to confirm, or "Cancel" to return.`,
  areYouSure: "Are you sure you want to delete?",
  acceptProject: "Are you sure you want to accept the project?",
  rejectProject: "Are you sure you want to reject the project?",
  UpdateSkill: "Update Skill",
  OldPassword: "Old Password",
  EnterOldPassword: "Enter Old Password",
  NewPasswod: "New Password",
  EnterNewPassword: "Enter New Password",
  ConfirmPassword: "Confirm New Password",
  EnterConfirmPassword: "Enter Confirm New Password",
  CloseAccount: "Close account",
  WarningCloseAccount:
    "Warning: If you close your account, you will be  unsubscribed from all your subscription and will lose access forever.",
  DeleteAccountEmployee: "Delete",
  EmailAlreadyVerified: "Email Already Verified",
  SendOTP: "Send OTP",
  PleaseEnterOpt: "Please enter the OTP",
  OTP6Digit: "OTP should be 6 digits",
  NewPasswodRequired: "New Password is required",
  ConfirmPasswordRequired: "Confirm Password is required",
  PassWordNotMatch: "Passwords do not match",
  SentOPT: "We have sent an OTP to your phone number !",
  PleaseAddSkills: "Please add skills",
  BrowseProject: "Browse Project",
  DashboardBookMark: "Bookmark",
  DashboardProfile: "Profile",
  Bids: "Project ends in ",
  SkillsRequired: "Skills Required",
  TimeDuration: "Time Duration (in days)",
  AwardProject: "Award project to",
  sendAnOffer: "Send an offer",
  projectMilestone: "Project milestones",
  CoverLetter: "Cover Letter",
  EnterProjectDetails: "Enter Your Project Detail",
  NoProposalFound: "No Proposal found",
  UpdateProposal: "Update Proposal",
  PostedProjects: "Posted Projects",
  Chat: "Chat",
  Award: "Award",
  NoDataFound: "No Data Found",
  EditMileStone: "Edit Milestone",
  MileStoneTitle: "Milestone Title",
  MileStoneDescription: "Milestone Description",
  DepositeAmount: "Deposit Amount",
  UpdateMileStone: "Update",
  DeleteMilestone: " Are you sure you want to delete milestone?",
  Finish: "Finish",
  AddMileStone: "Add Milestone",
  LeaveAReview: "Leave a Review",
  HowToYouRating: "How would you rate working ?",
  WriteAReview: "Write a review...",
  SubmitReview: "Submit Review",
  BasicInformation: "Basic Information",
  Category: "Category",
  selectOpt: "Please select any one option",
  enterTitle: "Please enter title",
  enterMsg: "Please enter message",
  titleCharErr: "Title should be at least 3 characters long.",
  cryptoAdd: "Crypto wallet address",
  getOTP: "Get OTP",
  ProjectTitle: "Project Title",
  ProjectDescription: "Project Description",
  ProjectDuration: "Project Duration",
  EstimateBudget: "Estimate Budget",
  MinBudegte: "Min. Budget",
  MaxBudget: "Max. Budget",
  ProjectTitle3: "Project Title should be at least 3 characters long.",
  ProjectDetail25: "Project description should be at least 25 characters long.",
  PleaseSelectCategory: "Please select a category.",
  PleaseSelectPaymentType: "Please select payment type",
  PleaseSelectProjectType: "Please select project type",
  PleaseSelectSkills: "Please select skills.",
  PleaseSelectLocation: "Please select location",
  PleaseSelectDuration: "Please select project duration",
  PleaseSelectPrice: "Please select price",
  PleaseSelectEstimateBudget: "Please select estimate Budget",
  PleaseSelectMinBudget: "Please select min budget",
  PleaseSelectMaxBudget: "Please select max budget",
  DragandDrop: "Drag and drop an image here or click",
  MaxBudgetShould:
    "Max Budget should not be greater than the selected Estimate Budget",
  EnterValidNumber: "Please enter a valid number",
  MaxMinGreater: "Max Budget should be greater than Min Budget",
  MaxBudgetGreater: "Please enter max budget greater than estimate budget",
  MinBudgetShouldBe:
    "Min Budget should not be greater than the selected Estimate Budget",
  EditProject: "Edit Project",
  ProjectType: "Please select project type",
  DepositeAmount: "Deposit Amount",
  PnoneAlreadyExists: "PleaseEnterValidNumber",
  SessionExpires:
    "Your session will end. To return, you will need to log back in. Are you sure you want to proceed?",
  Settings: "Settings",
  BillingandPayments: "Billing & Payments",
  EmployeeAbout: "Employee About",
  Reviews: "Reviews",
  NoReviews: "No reviews present at the moment.",
  AwardEmployee: "Award Employee",
  AwardEmployeeSeek:
    "I'm seeking an experienced and efficient app developer to create an innovative social networking application for iOS platform.",
  AwardRequest: "Award Request",
  Verification: "Verification",
  TopSkills: "Top Skills",
  TitleShouldBe5Characters: "Title should be at least 5 characters long.",
  DescriptionShouldBe5Characters:
    "Description should be at least 5 characters long.",
  PleaseAddTime: "Please add time.",
  validDepoistAmt: "Please add a valid deposit amount.",
  greaterDepoistAmt: "Please add deposit amount greater than 0.",
  PleaseAddDeposite: "Please add deposit amount.",
  PleaseGiveStar: "Please give atleast 1 star.",
  ReviewShouldbe5: "Review should be at least 5 characters long.",
  LoadMore: "Load more",
  DonateOneTake: "Help Bring Light to Young Lives in Iran",
  DonateTwoTake:
    "Let us make a positive impact on the lives of deserving children together. Donate today and help light the way for a brighter tomorrow.",
  Dontae: "Donate",
  FindSol: "Find Solutions Fast to Get Started on WorkWaveHub",
  LetsGet: "Let's Get You Some Help.",
  HaveTrouble:
    "If you're having trouble with your account, a project, or payments, don't worry! Our support team is always happy to help.",
  HowWeCan: "How we can help you?",
  TitleContact: "Title",
  describePlease: "Please describe your needs",
  contactone:
    " Is there a problem, or is it just a question? You can inform or ask us anything.",
  contacttwo: "Get in Touch",
  PricingFees: "Pricing & Plans",
  privacyPolicy: "Privacy Policy",
  privacyPolicy1:
    "This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
  privacyPolicy2: `We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.`,
  privacyPolicy3: `Interpretation and Definitions`,
  privacyPolicy4: `Interpretation`,
  privacyPolicy5: `The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.`,
  privacyPolicy6: `Definitions`,
  privacyPolicy7: `For the purposes of this Privacy Policy:`,
  privacyPolicy8: `Account`,
  privacyPolicy9: `means a unique account
                created for You to access our Service or parts of our Service.`,
  privacyPolicy10: `Affiliate`,
  privacyPolicy11: `means an entity that
                controls, is controlled by, or is under common control with a
                party, where "control" means ownership of 50% or more of the
                shares, equity interest, or other securities entitled to vote
                for election of directors or other managing authority.`,
  privacyPolicy12: `Application`,
  privacyPolicy13: `refers to WorkWaveHub,
                the software program provided by the Company.`,
  privacyPolicy14: `Company`,
  privacyPolicy15: `(referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to WorkWaveHub,
                xxxxxx.`,
  privacyPolicy16: `Cookies`,
  privacyPolicy17: `are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.`,
  privacyPolicy18: `Country`,
  privacyPolicy19: `refers to Iran.`,
  privacyPolicy20: `Device`,
  privacyPolicy21: `means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.`,
  privacyPolicy22: `Personal Data`,
  privacyPolicy23: `is any information that relates
                to an identified or identifiable individual.`,
  privacyPolicy24: `Service`,
  privacyPolicy25: `refers to the Application or the
                Website or both.`,
  privacyPolicy26: `Service Provider`,
  privacyPolicy27: `means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.`,
  privacyPolicy28: `Third-party Social Media Service`,
  privacyPolicy29: `refers to any
                website or any social network website through which a User can
                log in or create an account to use the Service.`,
  privacyPolicy30: `Usage Data`,
  privacyPolicy31: `refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).`,
  privacyPolicy32: `Website`,
  privacyPolicy33: `refers to WorkWaveHub, accessible from`,
  privacyPolicy34: `You`,
  privacyPolicy35: `means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.`,
  privacyPolicy36: `Collecting and Using Your Personal Data`,
  privacyPolicy37: `Types of Data Collected`,
  privacyPolicy38: `Personal Data`,
  privacyPolicy39: `While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:`,
  privacyPolicy40: `Email address`,
  privacyPolicy41: `First name and last name`,
  privacyPolicy42: `Phone number`,
  privacyPolicy43: `Physical Address`,
  privacyPolicy44: `Usage Data is collected automatically when using the Service.`,
  privacyPolicy45: `Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.`,
  privacyPolicy46: ` When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.`,
  privacyPolicy47: `We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.`,
  privacyPolicy48: `Information from Third-Party Social Media Services`,
  privacyPolicy49: `The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:`,
  privacyPolicy50: `Google`,
  privacyPolicy51: `Facebook`,
  privacyPolicy52: `Instagram`,
  privacyPolicy53: `Twitter`,
  privacyPolicy54: `LinkedIn`,
  privacyPolicy55: `If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.`,
  privacyPolicy56: `You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.`,
  privacyPolicy57: `Information Collected while Using the Application`,
  privacyPolicy58: `While using Our Application, in order to provide features of Our
              Application, We may collect, with Your prior permission:`,
  privacyPolicy59: `Information regarding your location`,
  privacyPolicy60: `We use this information to provide features of Our Service, to
              improve and customize Our Service. The information may be uploaded
              to the Company's servers and/or a Service Provider's server or it
              may be simply stored on Your device.`,
  privacyPolicy61: `You can enable or disable access to this information at any time,
              through Your Device settings.`,
  privacyPolicy62: `Tracking Technologies and Cookies`,
  privacyPolicy63: `We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:`,
  privacyPolicy64: `Cookies or Browser Cookies:`,
  privacyPolicy65: `A cookie is a small
                file placed on Your Device. You can instruct Your browser to
                refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to
                use some parts of our Service. Unless you have adjusted Your
                browser setting so that it will refuse Cookies, our Service may
                use Cookies.`,
  privacyPolicy66: `Web Beacons:`,
  privacyPolicy67: `Certain sections of our Service
                and our emails may contain small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).`,
  privacyPolicy68: `Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. You can learn more about cookies on TermsFeed
              website article.`,
  privacyPolicy69: `We use both Session and Persistent Cookies for the purposes set
              out below:`,
  privacyPolicy70: `Necessary / Essential Cookies:`,
  privacyPolicy71: `Type: Session
                Cookies; Administered by: Us; Purpose: These Cookies are
                essential to provide You with services available through the
                Website and to enable You to use some of its features. They help
                to authenticate users and prevent fraudulent use of user
                accounts. Without these Cookies, the services that You have
                asked for cannot be provided, and We only use these Cookies to
                provide You with those services.`,
  privacyPolicy72: `Cookies Policy / Notice Acceptance Cookies:`,
  privacyPolicy73: `Type: Persistent Cookies; Administered by: Us; Purpose: These
                Cookies identify if users have accepted the use of cookies on
                the Website.`,
  privacyPolicy74: `Functionality Cookies:`,
  privacyPolicy75: `Type: Persistent
                Cookies; Administered by: Us; Purpose: These Cookies allow us to
                remember choices You make when You use the Website, such as
                remembering your login details or language preference. The
                purpose of these Cookies is to provide You with a more personal
                experience and to avoid You having to re-enter your preferences
                every time You use the Website.`,
  privacyPolicy76: `For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.`,
  privacyPolicy77: `Use of Your Personal Data`,
  privacyPolicy78: `The Company may use Personal Data for the following purposes:`,
  privacyPolicy79: `To provide and maintain our Service, including to monitor the
                usage of our Service.`,
  privacyPolicy80: ` To manage Your Account: to manage Your registration as a user of
                the Service.`,
  privacyPolicy81: `For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us
                through the Service.`,
  privacyPolicy82: `To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.`,
  privacyPolicy83: `To provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.`,
  privacyPolicy84: ` To manage Your requests: To attend and manage Your requests to
                Us.`,
  privacyPolicy85: `To deliver targeted advertising to You.`,
  privacyPolicy86: `For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.`,
  privacyPolicy87: `For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.`,
  privacyPolicy88: `We may share Your personal information in the following
              situations:`,
  privacyPolicy89: `With Service Providers: We may share Your personal information
                with Service Providers to monitor and analyze the use of our
                Service, to show advertisements to You to help support and
                maintain Our Service, to contact You, to advertise on third
                party websites to You after You visited our Service or for
                payment processing.`,
  privacyPolicy90: `For business transfers: We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of our business to another company.`,
  privacyPolicy91: `With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.`,
  privacyPolicy92: ` With business partners: We may share Your information with Our
                business partners to offer You certain products, services or
                promotions.`,
  privacyPolicy93: `With other users: when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see You
                name, profile, pictures and description of Your activity.`,
  privacyPolicy94: `With Your consent: We may disclose Your personal information for
                any other purpose with Your consent.`,
  privacyPolicy95: `Retention of Your Personal Data`,
  privacyPolicy96: `The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.`,
  privacyPolicy97: `The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.`,
  privacyPolicy98: `Transfer of Your Personal Data`,
  privacyPolicy99: `Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.`,
  privacyPolicy100: `Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.`,
  privacyPolicy101: `The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.`,
  privacyPolicy102: `Disclosure of Your Personal Data`,
  privacyPolicy103: `Business Transactions`,
  privacyPolicy104: `If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.`,
  privacyPolicy105: `Law enforcement`,
  privacyPolicy106: ` Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).`,
  privacyPolicy107: `Other legal requirements`,
  privacyPolicy108: ` The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:`,
  privacyPolicy109: `Comply with a legal obligation`,
  privacyPolicy110: `Protect and defend the rights or property of the Company`,
  privacyPolicy111: ` Prevent or investigate possible wrongdoing in connection with
                the Service`,
  privacyPolicy112: `Protect the personal safety of Users of the Service or the
                public`,
  privacyPolicy113: `Protect against legal liability`,
  privacyPolicy114: `Security of Your Personal Data`,
  privacyPolicy115: `The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.`,
  privacyPolicy116: `Children's Privacy`,
  privacyPolicy117: `Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.`,
  privacyPolicy118: ` If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.`,
  privacyPolicy119: `Links to Other Websites`,
  privacyPolicy120: `Our Service may contain links to other websites that are not
              operated by Us. If You click on a third-party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.`,
  privacyPolicy121: `We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third-party sites or
              services.`,
  privacyPolicy122: `Changes to this Privacy Policy`,
  privacyPolicy123: `We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.`,
  privacyPolicy124: `You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.`,
  privacyPolicy125: `Contact Us`,
  privacyPolicy126: `If you have any questions about this Privacy Policy, You can
              contact us:`,
  privacyPolicy127: `By email:`,
  privacyPolicy128: `By visiting this page on our website:`,
  privacyPolicy129: `By phone number:`,
  privacyPolicy130: `We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.`,
  privacyPolicy131: `Delete Your Personal Data`,
  privacyPolicy132: `You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.`,
  privacyPolicy133: `Our Service may give You the ability to delete certain information
              about You from within the Service.`,
  privacyPolicy134: `You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.`,
  privacyPolicy135: `Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.`,
  pricingPlans: `Pricing & Fees`,
  deletionPolicy: `Deletion Policy`,
  deletionPolicy1: `Account Deletion: You may choose to delete your account on the
                Platform (i.e. the website `,
  deletionPolicy2: `and/or the mobile application workwavehub) and any information
                associated with it, at any point of time. Such a request for
                deletion must be sent in writing to `,
  deletionPolicy3: ` or you can login into your account and check for delete account
                option where you can delete your existing account . In the event
                that You make a request for deletion of Your account or data,
                you will no longer be able to access the Platform and/or use the
                Services, and we reserve the right to not provide You any
                Services through the Platform. In the event of your request, We
                may delete Your information (Personal or otherwise) or
                de-identity it so that it is anonymous and no longer
                attributable to You. Notwithstanding anything contained in the
                Privacy Policy, We may retain some or all of Your information in
                accordance with our policies and/or requirements under
                applicable law. Terms not defined herein shall have the meaning
                assigned to them in the Privacy Policy.`,
  termsAndCondition: "Terms & condition",
  trustAndSafety: "Trust And Safety",
  trustAndSafety1: `Finding the Perfect Service Provider Just Got Easier`,
  trustAndSafety2: `When it comes to finding the best tools and services for your
            business, reviews, and reputation matter. That is why the
            WorkWaveHub marketplace makes it easy to compare, choose, and trust
            the vendors you work with.`,
  trustAndSafety3: `Here is how:`,
  trustAndSafety4: `Seller Ratings & Levels:`,
  trustAndSafety5: `See what other
              businesses think! WorkWaveHub displays clear seller ratings and
              levels, giving you a quick snapshot of each vendor's performance.`,
  trustAndSafety6: `Buyer Feedback:`,
  trustAndSafety7: `Go beyond ratings by reading real
              customer reviews. This lets you see firsthand what other
              businesses have experienced working with a particular seller.`,
  trustAndSafety8: `With all this information at your fingertips, you can make informed
            decisions and find the perfect service providers to help your
            business thrive!`,
  trustAndSafety9: `Making informed decisions is key to getting the most out of
            WorkWaveHub. That is why we make it easy to see what other
            businesses think of the sellers you are considering.`,
  trustAndSafety10: `Seller Ratings:`,
  trustAndSafety11: `After a seller finishes a project
              (called a Gig on WorkWaveHub), buyers can rate their experience.
              These ratings are based on clear criteria, so you can see exactly
              how the seller performed in different areas.`,
  trustAndSafety12: `Buyer Reviews:`,
  trustAndSafety13: `Want more than just a star rating?
              No problem! Buyers can also leave written reviews about their
              experience working with a seller. This lets you see what other
              businesses liked (or did not like) about working with them.`,
  trustAndSafety14: `Buy and Sell with Confidence`,
  trustAndSafety15: `Millions of transactions happen on WorkWaveHub every day. We know
            security is important, so we use the latest and greatest technology
            to keep your money and information safe. That means you can focus on
            finding the perfect service or gig, without worrying about scams or
            data breaches.`,
  trustAndSafety16: `Information Stays Private:`,
  trustAndSafety17: `We value your privacy
              and will never share your personal details with anyone outside of
              WorkWaveHub. Your data is secure with us, always.`,
  trustAndSafety18: `Safe and Secure Payments:`,
  trustAndSafety19: `No need to worry about
              your money or credit card information. All transactions happen
              securely on our platform, whether you pay with credit card,
              PayPal, or another method. We follow the highest security
              standards to keep your information safe.`,
  trustAndSafety20: `Communicate with Confidence:`,
  trustAndSafety21: `Our secure messaging
              system lets you chat and share files with any seller on
              WorkWaveHub without worry.`,
  pricingFees: `For employees`,
  pricingFees1: `As a freelance employee on WorkWaveHub, you will incur a service
                fee on your earnings. The fee structure is as follows:`,
  pricingFees2: `Projects equal to or exceeding $200`,
  pricingFees3: `A 7% service charge is applied.`,
  pricingFees4: `Example`,
  pricingFees5: `For a $200 project, the service charge is
                      7%, which amounts to $14. Your final payment will be $186.`,
  pricingFees6: `For a $300 project, the
                      service charge is 7%, which amounts to $21. Your final
                      payment will be $279.`,
  pricingFees7: `Projects under $200`,
  pricingFees8: `A 9% service charge is applied.`,
  pricingFees9: `For a $150 project, the
                      service charge is 9%, which amounts to $13.50. Your final
                      payment will be $136.50.`,
  pricingFees10: `You must provide a valid crypto address when creating your work
                account. Once you complete a project and receive valid approval
                from the employer, your payment will be processed within 24
                hours to your crypto account in USDT.`,
  pricingFees11: `For employers`,
  pricingFees12: ` As an employer on WorkWaveHub, you will incur a service fee on
                the project cost. The fee structure is as follows:`,
  pricingFees14: `A 7% service charge is applied.`,
  pricingFees15: `For a $200 project, the
                      service charge is 7%, which amounts to $14. Your total
                      amount to add will be $214.`,
  pricingFees16: `For a $300 project, the
                      service charge is 7%, which amounts to $21. Your total
                      amount to add will be $321.`,
  pricingFees17: `A 9% service charge is applied.`,
  pricingFees18: `For a $150 project, the
                      service charge is 9%, which amounts to $13.50. Your total
                      amount to add will be $163.50.`,
  pricingFees19: `When creating a project, you will need to add the required funds
                via PayPal, Stripe, or Crypto. After you approve the completed
                project from the employee, the amount will be deducted from your
                WorkWaveHub fund and transferred to the freelance employee's
                account.`,
  wallet: "Wallet",
  withdraw: "Withdraw",
  withdrawalHistory: "Withdrawal History",
  fees: "Fees",
  createdAt: "Created At",
  paymentMethod: "Payment Method",
  inovice: "Invoice",
  amtPaid: "Amount Paid",
  whatIsExp: "What is your expertise here?",
  disputeCase: "Dispute Cases",
  disputes: "Disputes",
  addDispute: "Add Dispute",
  dispute: "Dispute",
  selectMilestone: "Please select milestone",
  amtgreaterThan0: "Please enter amount greater than 0",
  enterReason: "Please enter reason",
  name: "Name",
  project: "Project",
  disputeAmt: "Dispute Amount",
  reason: "Reason",
  setStatus: "Set Status",
  resByU: "Response by you",
  resByDef: "Response by defendant",
  resByCom: "Response by complainant",
  fileDispute: "File Dispute",
  areUsureCon: "Are you sure you want to continue?",
  sctionSus: `This action may affect your rating and can lead to your account
              suspension.`,
  proceed: "Proceed",
  dis1: "has sent a proposal to resolve dispute for $",
  mutRes: "Mutual Resolve",
  inPrg: "In Progress",
  resMut: "Resolve mutually",
  rejdis: "Reject Dispute",
  acdis: "Accept Dispute",
  withdis: "Withdraw Dispute",
  diRaisedByU: "Dispute raised by you",
  disRaisedAgainstU: "Dispute against you",
  enterPRoj: "Please enter project.",
  totdonation: "Total Donation",
  totPayout: "Total Payout",
  donationPayout: "Donation payout",
  orgName: "Organisation name",
  dontionamt: "Donation Amount",
  enteramtUSD: "Enter amount(in USD)",
  walletBal: "Wallet balance",
  mileFee: "Milestone fee",
  amtPaidWithoutFees: "Amount to be paid (Without fees)",
  newWalletBal: "New wallet balance",
  amtPaidWithfees: "Amount to be paid (With fees)",
  payfrmWallet: "Pay from wallet",
};

export default enTranslations;
