import React from 'react';
import { Chip } from '@mui/material';

export const getStatusChip = (status) => {
  switch (status) {
    case 'Active':
      return <Chip label="Active" color="success" />;
    case 'Inactive':
      return <Chip label="Inactive" color="default" />;
    case 'Suspended':
      return <Chip label="Suspended" color="error" />;
    default:
      return <Chip label="Unknown" />;
  }
};
