import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  CHAT_ROLE,
  EMPLOYEE_ROLE,
  EMPLOYER_ROLE,
} from "../Helpers/roles";

const ProtectedRoute = ({ element }) => {
  const location = useLocation();
  try {
    // if (location.pathname.includes("admin")) {
    //   return element;
    // }
    const isLoggedIn = JSON.parse(localStorage.getItem("loginData")); // Check if token exists in local storage
    const { token, role } = isLoggedIn; // Assuming you have a way to get the token
    if (!token) {
      return <Navigate to="/" replace />;
    }

    if (
      (location.pathname.includes("admin") ||
        location.pathname.includes("accountant")) &&
      role.includes(ADMIN_ROLE)
    ) {
      return element;
    }

    if (location.pathname.includes("employer") && role.includes(EMPLOYER_ROLE))
      return element;

    if (location.pathname.includes("employee") && role.includes(EMPLOYEE_ROLE))
      return element;

    if (location.pathname.includes("chat") && role.includes(CHAT_ROLE)) {
      return element;
    }

    if (
      location.pathname.includes("accountant") &&
      role.includes(ACCOUNTANT_ROLE)
    )
      return element;

    return <Navigate to="/" replace />;
    // if (location.pathname.includes("admin")) {
    //   if (location.pathname.includes("chat") && CHAT_ROLE.includes(role)) {
    //     return element;
    //   } else if (!ADMIN_ROLE.includes(role)) {
    //     return <Navigate to="/" replace />;
    //   }
    // }

    // if (
    //   location.pathname.includes("accountant") &&
    //   !ACCOUNTANT_ROLE.includes(role)
    // )
    //   return <Navigate to="/" replace />;

    // if (location.pathname.includes("employer") && !EMPLOYER_ROLE.includes(role))
    //   return <Navigate to="/" replace />;

    // if (location.pathname.includes("employee") && !EMPLOYEE_ROLE.includes(role))
    //   return <Navigate to="/" replace />;

    // return element;
  } catch (err) {
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoute;
