import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  IconButton,
  Button,
  Grid,
  Modal,
  Rating,
  FormLabel,
  useTheme,
  TextField,
  TableContainer,
  Paper,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link, useSearchParams } from "react-router-dom"; // Import Link from react-router-dom
import DescriptionIcon from "@mui/icons-material/Description";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import del from "../../../Assets/Images/delet.png";
import doc from "../../../Assets/Images/document-attachment.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import StarIcon from "@mui/icons-material/Star";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
import indiaIco from "../../../Assets/Images/indiaFlag.png";
import EditIcon from "../../../Assets/Images/EditIcon.png";
import pieChart from "../../../Assets/Images/pie-chart.png";
import AddIcon from "@mui/icons-material/Add"; // Import the Material-UI icon component
import DeleteIcon from "../../../Assets/Images/DeleteICon.png";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import styled from "@emotion/styled";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  getCommision,
  getCountryCode,
  GetUserDetails,
  getUserDetailsFromAPI,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import EmployeerEditProject from "../EmployeerEditProject/EmployeerEditProject";
import { useNavigate } from "react-router-dom";
import loader from "../../../Assets/Images/loader.svg";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import DeleteModal from "../../EmployeeDashboardPages/DeleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import SubscriptionModal from "../../../Pages/Home/HomeMembership/SubscriptionModal/SubscriptionModal";
import {
  addDoc,
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import PostedProjectDetails from "./PostedProjectDetails/PostedProjectDetails";
import OngoingProjectDetails from "./OngoingProjectDetails/OngoingProjectDetails";
import CompletedProjectDetails from "./CompletedProjectDetails/CompletedProjectDetails";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const MILESTONE_STATUS = {
  pending: "Pending",
  "in-process": "In Progress",
  complete: "Complete",
  accept: "Approved",
  "Payment pending": "Payment pending",
  "Payment process": "Payment in process",
  paid: "Paid",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

// function PostedProjectDetails({ project, onBack, updateProject }) {
//   const { t } = useTranslation();
//   const [tabValue, setTabValue] = useState(0);
//   // const [value, setValue] = React.useState(2);
//   // const [hover, setHover] = React.useState(-1);
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const navigate = useNavigate();
//   const [searchParams, setSearchParams] = useSearchParams();

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const [praposals, setPraposals] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   useEffect(() => {
//     getAllPraposals();
//     //eslint-disable-next-line
//   }, []);

//   const getAllPraposals = async (pageNumber) => {
//     try {
//       const token = GetUserDetails().token;
//       LoaderShow();

//       var pn;
//       if (pageNumber === undefined || pageNumber === "") {
//         pn = 1;
//       } else {
//         pn = pageNumber;
//       }

//       const dataToSend = {
//         projectId: project._id,
//       };
//       const response = await postAPI(
//         `proposal/project-Proposals?page=${pn}`,
//         dataToSend,
//         token
//       );

//       if (response.statusCode === 200) {
//         if (searchParams.get("tab") && searchParams.get("tab") == "proposal") {
//           setTabValue(1);
//           searchParams.delete("tab");
//           setSearchParams(searchParams);
//         }
//         LoaderHide();
//         setPraposals(response.data);
//         setTotalPages(response.pagination.totalPages);
//       }
//     } catch (error) {
//       LoaderHide();
//       // toast.error(error.message)
//     }
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     getAllPraposals(value);
//   };

//   const redirectToCheckout = async (ev, projectData, userData) => {
//     ev.stopPropagation();
//     // LoaderShow();
//     // try {
//     //   const token = GetUserDetails()?.token;
//     //   const data = {
//     //     email: userData?.email,
//     //     projectId: projectData?._id,
//     //     employeeId: userData?._id,
//     //   };
//     //   const res = await postAPI("award/projects/award", data, token);
//     //   if (res.statusCode == 200) {
//     //     toast.success("Award appointed successfully.");
//     //     project.isAwarded = true;
//     //     updateProject(projectData?._id, true);
//     //   }
//     // } catch (err) {
//     //   console.log(err);
//     // }
//     // LoaderHide();
//     navigate("/dashboard/employer/sendanoffer", {
//       state: {
//         projectData,
//         userData,
//       },
//     });
//   };

//   const createRoom = async (ev, user) => {
//     ev.stopPropagation();
//     LoaderShow();
//     try {
//       const token = GetUserDetails().token;

//       const userResponse = await getUserDetailsFromAPI();
//       if (
//         userResponse.statusCode == 200 &&
//         userResponse?.data?.firebaseUID &&
//         user?.firebaseUID
//       ) {
//         const roomRef = collection(db, "rooms");
//         const roomQuery = query(
//           roomRef,
//           where(
//             "userIds",
//             "==",
//             [userResponse.data.firebaseUID, user.firebaseUID].sort()
//           )
//         );
//         const response = await getDocs(roomQuery);
//         if (response.docs.length > 0) {
//           navigate(
//             `/dashboard/employer/message?room_id=${response.docs[0].id}`
//           );
//         } else {
//           const data = await addDoc(collection(db, "rooms"), {
//             userIds: [userResponse.data.firebaseUID, user.firebaseUID].sort(),
//             createdAt: Timestamp.now(),
//             imageUrl: null,
//             metadata: null,
//             name: null,
//             type: "direct",
//             updatedAt: Timestamp.now(),
//             userRoles: null,
//           });
//           navigate(`/dashboard/employer/message?room_id=${data?.id}`);
//         }
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const openProfile = (proposal) => {
//     navigate("/dashboard/employer/reviewratings", {
//       state: {
//         user: proposal?.user,
//       },
//     });
//   };

//   return (
//     <>
//       <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//         <Box
//           sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0, display: "flex" }}
//           className="plZero prZero"
//         >
//           <Tabs
//             variant={isMobile ? "scrollable" : "standard"}
//             scrollButtons={isMobile ? "auto" : "off"}
//             allowScrollButtonsMobile={isMobile}
//             textColor="#222222"
//             sx={{
//               borderBottom: 1,
//               borderColor: "#dbdbdb",
//               "& .MuiTabs-indicator": {
//                 backgroundColor: "#000",
//               },
//               flex: "1",
//             }}
//             value={tabValue}
//             onChange={handleChange}
//             aria-label="project details tabs"
//           >
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("ProjectDetails")}
//             />
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("AllPop")}
//             />
//           </Tabs>
//           {tabValue == 1 && (
//             <Button
//               onClick={getAllPraposals}
//               sx={{ borderBottom: "1px solid #dbdbdb", borderRadius: "0" }}
//             >
//               <RefreshIcon sx={{ color: "#000" }} />
//             </Button>
//           )}
//         </Box>
//         {tabValue === 0 && (
//           <Box sx={{ px: 4, py: 3 }}>
//             <Box
//               sx={{ display: "flex", justifyContent: "space-between" }}
//               className="flexColumnRespo"
//             >
//               <Typography variant="h5" sx={{ color: "#000" }}>
//                 {project?.title}
//               </Typography>
//               <Typography variant="h6" sx={{ color: "#000" }}>
//                 {project?.budget?.min < project?.budget?.max
//                   ? `$${Number(project?.budget?.min)}-$${Number(
//                       project?.budget?.max
//                     )}`
//                   : `$${Number(project?.budget?.max)}`}
//               </Typography>
//             </Box>
//             <Box sx={{ py: 4 }}>
//               <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//                 {project?.description}
//               </Typography>
//               {/* <Typography
//                 variant="p"
//                 sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
//               >
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry. Lorem Ipsum has been the industry's standard dummy
//                 text ever since the 1500s, when an unknown printer took a galley
//                 of type and scrambled it to make a type specimen book. It has
//                 survived not only five centuries, but also the leap into
//                 electronic typesetting, remaining essentially unchanged. It was
//                 popularised in the 1960s with the release of Letraset sheets
//                 containing Lorem Ipsum passages, and more recently with desktop
//                 publishing software like Aldus PageMaker including versions.
//               </Typography> */}
//             </Box>

//             <Box sx={{ py: 4 }}>
//               <Typography variant="h6" sx={{ color: "#000" }}>
//                 {t("SkillReq")}
//               </Typography>
//               <Box
//                 sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
//               >
//                 {project?.skillsRequired?.map((skill, index) => (
//                   <Button
//                     sx={{
//                       fontFamily: "inherit",
//                       px: 4,
//                       mx: 1,
//                       fontSize: "16px !important",
//                       borderRadius: 10,
//                       pb: 1,
//                       backgroundColor: "#0B3013",
//                       width: "auto",
//                       maxWidth: "fit-content",
//                       textTransform: "capitalize",
//                       color: "#fff",
//                       display: "flex",
//                       fontWeight: "400",

//                       "&:hover": {
//                         backgroundColor: "#0B3013", // Keep the same color as default
//                       },
//                     }}
//                   >
//                     {skill?.name}
//                   </Button>
//                 ))}
//                 {/* <Button
//                   onClick={onBack}
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px !important",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Graphic Design
//                 </Button>
//                 <Button
//                   onClick={onBack}
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px !important",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Figma
//                 </Button>
//                 <Button
//                   onClick={onBack}
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px !important",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   Adobe XD
//                 </Button> */}
//               </Box>
//             </Box>
//             <Box sx={{}}>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   color: "#000",
//                   fontWeight: 600,
//                   pb: 0,
//                   fontSize: 18,
//                   px: 0,
//                 }}
//               >
//                 {t("Attachments")}
//               </Typography>
//               <Box
//                 className="uploadSec"
//                 sx={{
//                   py: 3,
//                   pb: 0,
//                 }}
//               >
//                 <Box sx={{ display: "flex", alignItems: "center" }}>
//                   {/* <img
//                     src={doc}
//                     alt="Delete"
//                     style={{ cursor: "pointer" }}
//                   /> */}

//                   <Typography
//                     variant="p"
//                     sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                   >
//                     {/* 84c110f299f31bad53eee9731d43cbca.jpg (154kB) */}
//                     {project?.documents?.map((url, index) => (
//                       <div key={index}>
//                         <h3>
//                           {t("Document")} {index + 1}
//                         </h3>
//                         <Link target="_blank" to={url}>
//                           {url}
//                         </Link>
//                         {/* <img
//                             src={del}
//                             alt="Delete"
//                             style={{ cursor: "pointer", marginLeft : "5px" }}
//                           /> */}
//                       </div>
//                     ))}
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//             <Box sx={{ my: 2 }}>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px !important",
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   ml: "auto",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 {t("BackButton")}
//               </Button>
//             </Box>
//           </Box>
//         )}
//         {tabValue === 1 && (
//           <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//             {praposals && praposals?.length > 0 ? (
//               praposals?.map((praposal, index) => (
//                 <Box
//                   onClick={(ev) => openProfile(praposal)}
//                   sx={{
//                     backgroundColor: "#fff",
//                     py: 1,
//                     borderBottom: 1,
//                     borderColor: "#e3e3e3",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <Box
//                     className="flexColumnRespo"
//                     sx={{
//                       p: 2.5,
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Box sx={{ display: "flex" }}>
//                       <Box sx={{ display: "flex" }}>
//                         <Avatar
//                           src={praposal?.user?.profile || AvatarJob}
//                           className="responsive-image avatarImg"
//                         />
//                       </Box>
//                       <Box
//                         sx={{ display: "flex", flexDirection: "column", px: 2 }}
//                       >
//                         <Box
//                           sx={{ display: "flex", textDecoration: "none" }}
//                           // component={Link} to="/reviewratings" // Specify the target path
//                         >
//                           <Typography
//                             variant="h5"
//                             className="userNameText"
//                             sx={{ color: "#000", fontWeight: 500 }}
//                           >
//                             {praposal?.user?.firstName}
//                           </Typography>
//                           <Typography
//                             variant="h5"
//                             className="userNameText"
//                             sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                           >
//                             {praposal?.user?.email}
//                           </Typography>
//                         </Box>
//                         <Box
//                           className="flexWrapResp"
//                           sx={{ display: "flex", py: 1, alignItems: "center" }}
//                         >
//                           <Rating
//                             className="catBtnTop"
//                             name="hover-feedback"
//                             value={praposal?.user?.averageRating}
//                             precision={0.5}
//                             getLabelText={getLabelText}
//                             // onChange={(event, newValue) => {
//                             //   setValue(newValue);
//                             // }}
//                             // onChangeActive={(event, newHover) => {
//                             //   setHover(newHover);
//                             // }}
//                             disabled
//                             emptyIcon={
//                               <StarIcon
//                                 style={{ opacity: 0.55 }}
//                                 fontSize="inherit"
//                               />
//                             }
//                           />
//                           {/* {value !== null && (
//                               <Box className="catBtnTop" sx={{ mx: 2 }}>
//                                 {labels[hover !== -1 ? hover : value]}
//                               </Box>
//                             )} */}
//                           {praposal?.user?.averageRating !== null && (
//                             <Box className="catBtnTop" sx={{ mx: 2 }}>
//                               {praposal?.user?.averageRating}
//                             </Box>
//                           )}

//                           <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             <img src={chatIco} alt="chat" />
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               {praposal?.user?.totalComments}
//                             </Typography>
//                           </Box>
//                           {/* <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             <img src={dollarIco} alt="chat" />
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               8.7
//                             </Typography>
//                           </Box> */}
//                           {/* <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             <img src={pieChart} alt="chat" />
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               98%
//                             </Typography>
//                           </Box> */}
//                           <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             {praposal?.user?.location &&
//                             getCountryCode(praposal?.user?.location) ? (
//                               <img
//                                 width={25}
//                                 height={25}
//                                 src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
//                                   praposal?.user?.location
//                                 )}.svg`}
//                                 alt="chat"
//                               />
//                             ) : (
//                               ""
//                             )}
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               {praposal?.user?.location}
//                             </Typography>
//                           </Box>
//                         </Box>
//                         <Box
//                           sx={{ display: "flex", flexWrap: "wrap", py: 0.5 }}
//                         >
//                           {praposal?.user?.skills?.map((skill, index) => (
//                             <Typography
//                               className="langText skillText"
//                               variant="h6"
//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 borderRight: 1,
//                                 fontWeight: 500,
//                                 px: 2,
//                                 pl: 0,
//                                 mr: 2,
//                                 my: 1,
//                               }}
//                             >
//                               {skill?.name}
//                             </Typography>
//                           ))}

//                           {/* <Typography
//                               className="langText skillText"

//                               variant="h6"
//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 borderRight: 1,
//                                 fontWeight: 500,
//                                 px: 2,
//                               }}
//                             >
//                               Illustrations Designer
//                             </Typography>
//                             <Typography
//                               variant="h6"
//                               className="langText skillText"

//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 fontWeight: 500,
//                                 px: 2,
//                               }}
//                             >
//                               Figma Expert
//                             </Typography> */}
//                         </Box>
//                       </Box>
//                     </Box>
//                     <Box
//                       className="flexDirectionRowResp catBtnTop"
//                       sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Typography
//                         variant="h5"
//                         sx={{
//                           color: "#000",
//                           px: 2,
//                           fontWeight: 500,
//                           width: "350px",
//                           maxWidth: "min-content",
//                           whiteSpace: "nowrap",
//                           overflow: "hidden",
//                           textOverflow: "ellipsis",
//                           display: "block",
//                         }}
//                       >
//                         ${praposal?.maxAmount || 0}
//                       </Typography>
//                       <Typography
//                         variant="p"
//                         sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                       >
//                         {praposal?.deliveryTime?.name || "One month"}
//                       </Typography>
//                     </Box>
//                   </Box>

//                   <Grid
//                     container
//                     spacing={2}
//                     className=" catBtnTop flexColumnRespo"
//                   >
//                     <Grid itemxs={12} xl={8} lg={8} md={8}>
//                       <Box
//                         sx={{
//                           p: 2.5,
//                           pt: 0,
//                           display: "flex",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <Typography
//                           variant="p"
//                           sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                         >
//                           {praposal?.description}
//                         </Typography>
//                       </Box>
//                     </Grid>

//                     <Grid
//                       item
//                       xs={4}
//                       sx={{
//                         display: "flex",
//                         alignItems: "end",
//                         justifyContent: "end",
//                         ml: "auto",
//                       }}
//                     >
//                       <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
//                         <Button
//                           onClick={(ev) => createRoom(ev, praposal?.user)}
//                           sx={{
//                             display: "flex",
//                             mx: 1,
//                             textTransform: "capitalize",
//                             border: 1,
//                             borderColor: "#0a3114",
//                             fontSize: 16,
//                             color: "#000",
//                             borderRadius: 10,

//                             px: 5,
//                             py: 1,
//                             "&:hover": {
//                               bgcolor: "#0a3114",
//                               color: "#fff",
//                             },
//                           }}
//                         >
//                           {t("Chat")}
//                         </Button>
//                         <Button
//                           // component={Link} // Use Link component
//                           // to="/reviewratings" // Specify the target path
//                           sx={{
//                             display: "flex",
//                             fontFamily: "inherit",
//                             textTransform: "capitalize",
//                             bgcolor: "#0a3114",
//                             fontSize: 16,
//                             color: "#fff",
//                             borderRadius: 10,
//                             px: 5,
//                             py: 1,
//                             "&:hover": {
//                               bgcolor: "#0a3114",
//                             },
//                             "&:disabled": {
//                               color: "#e7e7e7",
//                               // bgcolor: "#0a3114",
//                             },
//                           }}
//                           disabled={project?.isAwarded}
//                           onClick={(ev) =>
//                             redirectToCheckout(ev, project, praposal?.user)
//                           }
//                         >
//                           {project?.isAwarded ? "Awarded" : "Award"}
//                         </Button>
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ))
//             ) : (
//               <Box>
//                 <NodataFound />
//               </Box>
//             )}

//             {/* <Box

//               sx={{
//                 backgroundColor: "#fff",
//                 py: 1,
//                 borderBottom: 1,
//                 borderColor: "#e3e3e3",
//               }}
//             >
//               <Box
//                 className="flexColumnRespo"
//                 sx={{
//                   p: 2.5,
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Box sx={{ display: "flex" }}>
//                   <Box sx={{ display: "flex" }}>
//                     <img src={AvatarJob} className="responsive-image avatarImg" alt="dfh" />
//                   </Box>
//                   <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
//                     <Box sx={{ display: "flex", textDecoration: 'none' }} component={Link} to="/reviewratings" >
//                       <Typography
//                         variant="h5"
//                         className="userNameText"

//                         sx={{ color: "#000", fontWeight: 500 }}
//                       >
//                         John Doe
//                       </Typography>
//                       <Typography
//                         variant="h5"
//                         className="userNameText"

//                         sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                       >
//                         @johndoe
//                       </Typography>
//                     </Box>
//                     <Box className="flexWrapResp" sx={{ display: "flex", py: 1, alignItems: "center" }}>
//                       <Rating
//                         className="catBtnTop"
//                         name="hover-feedback"
//                         value={value}
//                         precision={0.5}
//                         getLabelText={getLabelText}
//                         onChange={(event, newValue) => {
//                           setValue(newValue);
//                         }}
//                         onChangeActive={(event, newHover) => {
//                           setHover(newHover);
//                         }}
//                         emptyIcon={
//                           <StarIcon
//                             style={{ opacity: 0.55 }}
//                             fontSize="inherit"
//                           />
//                         }
//                       />
//                       {value !== null && (
//                         <Box className="catBtnTop" sx={{ mx: 2 }}>
//                           {labels[hover !== -1 ? hover : value]}
//                         </Box>
//                       )}

//                       <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={chatIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           200
//                         </Typography>
//                       </Box>
//                       <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={dollarIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           8.7
//                         </Typography>
//                       </Box>
//                       <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={pieChart} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           98%
//                         </Typography>
//                       </Box>
//                       <Box className="catBtnTop" sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={indiaIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           India
//                         </Typography>
//                       </Box>
//                     </Box>
//                     <Box sx={{ display: "flex", py: 0.5 }}>
//                       <Typography
//                         className="langText skillText"
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                           pl: 0,
//                         }}
//                       >
//                         UI/UX Designer
//                       </Typography>
//                       <Typography
//                         className="langText skillText"

//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Illustrations Designer
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         className="langText skillText"

//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Figma Expert
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box
//                   className="flexDirectionRowResp catBtnTop"
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Typography
//                     variant="h5"
//                     sx={{ color: "#000", px: 2, fontWeight: 500 }}
//                   >
//                     $150
//                   </Typography>
//                   <Typography
//                     variant="p"
//                     sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                   >
//                     In 7 Days
//                   </Typography>
//                 </Box>
//               </Box>

//               <Grid container spacing={2} className=" catBtnTop flexColumnRespo"
//               >
//                 <Grid itemxs={12} xl={8} lg={8} md={8}>
//                   <Box
//                     sx={{
//                       p: 2.5,
//                       pt: 0,
//                       display: "flex",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="p"
//                       sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                     >
//                       I'm seeking an experienced and efficient app developer to
//                       create an innovative social networking application for iOS
//                       platform. Details about the target audience are currently
//                       confidential. Since the completion I'm seeking an
//                       experienced and efficient app developer to create more....
//                     </Typography>
//                   </Box>
//                 </Grid>

//                 <Grid
//                   item
//                   xs={4}
//                   sx={{
//                     display: "flex",
//                     alignItems: "end",
//                     justifyContent: "end",
//                     ml: "auto",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", p: 2.5, pt: 0 }}>
//                     <Button
//                       sx={{
//                         display: "flex",
//                         mx: 1,
//                         textTransform: "capitalize",
//                         border: 1,
//                         borderColor: "#0a3114",
//                         fontSize: 16,
//                         color: "#000",
//                         borderRadius: 10,

//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#0a3114",
//                           color: '#fff'
//                         },
//                       }}
//                     >
//                       Chat
//                     </Button>
//                     <Button
//                       component={Link} to="/reviewratings"
//                       sx={{
//                         display: "flex",
//                         fontFamily: "inherit",
//                         textTransform: "capitalize",
//                         bgcolor: "#0a3114",
//                         fontSize: 16,
//                         color: "#fff",
//                         borderRadius: 10,
//                         px: 5,
//                         py: 1,
//                         "&:hover": {
//                           bgcolor: "#0a3114",
//                         },
//                       }}
//                     >
//                       Award
//                     </Button>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </Box> */}

//             <Box
//               sx={{
//                 py: 2,
//                 display: "flex",
//                 justifyContent: "center",
//                 flexWrap: "wrap",
//               }}
//             >
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px !important",
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   mr: "auto",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 {t("BackButton")}
//               </Button>
//               <Box>
//                 {/* <CustomPagination className='catBtnTop' count={1} /> */}
//                 <CustomPagination
//                   count={totalPages}
//                   page={currentPage}
//                   onChange={handlePageChange}
//                 />
//               </Box>
//             </Box>
//           </Box>
//         )}
//       </Box>
//     </>
//   );
// }

// function OngoingProjectDetails({ project, onBack }) {
//   const { t } = useTranslation();

//   const [openModal, setOpenModal] = useState(false);
//   const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
//   const [estimatedTotal, setEstimatedTotal] = useState(0);
//   const [currentMilestoneId, setCurrentMilestoneId] = useState(-1);

//   const [searchParams, setSearchParams] = useSearchParams();
//   const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isFinishModalOpen, setIsFinishModalOpen] = useState(false); // New state for finish milestone modal
//   const [valuerating, setValueRating] = React.useState("");
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
//   const [milestoneDeleteModal, setMileStoneDeleteModal] = useState(false);
//   const [milestoneId, setMilestoneId] = useState("");

//   const [completeProjectModalOepn, setCompleteProjectModalOepn] =
//     useState(false);

//   useEffect(() => {
//     if (openModal) localStorage.setItem("projectData", JSON.stringify(project));
//   }, [openModal]);

//   const handleModalClose = () => {
//     setOpenModal(false);
//   };

//   const handleCryptoModalOpen = () => {
//     setCryptoModalOpen(true);
//   };

//   const handleOpenDeleteMileStone = (id) => {
//     setMileStoneDeleteModal(true);
//     setMilestoneId(id);
//   };

//   const handleCloseModal = () => {
//     setMileStoneDeleteModal(false);
//   };

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   const handleFocus = () => {
//     setIsFocused(false);
//   };

//   const handleBlur = () => {
//     setIsFocused(false);
//   };

//   const toggleFinishModal = () => {
//     setIsFinishModalOpen(!isFinishModalOpen);
//     setValueRating("");
//     setText({ textReview: "" });
//   };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const [particularMilestone, setParticularMilsetone] = useState({
//     _id: "",
//     title: "",
//     description: "",
//     dueDate: "",
//     amount: "",
//   });

//   const [milestoneEditErroMessages, setMileStoneEditErrorMessages] = useState({
//     title: "",
//     description: "",
//     dueDate: "",
//     amount: "",
//   });

//   const navigate = useNavigate();

//   const toggleEditModal = (id) => {
//     setIsEditModalOpen(!isEditModalOpen);
//     setParticularMilsetone(
//       milestonesList?.milestones?.find((e) => e._id === id)
//     );
//   };

//   const handleEditMileStoneTitle = (event) => {
//     const value = event.target.value;
//     setParticularMilsetone({ ...particularMilestone, title: value });
//     setMileStoneEditErrorMessages({
//       ...milestoneEditErroMessages,
//       title: value.length < 5 ? t("TitleShouldBe5Characters") : "",
//     });
//   };

//   const handleEditMileStoneDescription = (event) => {
//     const value = event.target.value;
//     setParticularMilsetone({ ...particularMilestone, description: value });
//     setMileStoneEditErrorMessages({
//       ...milestoneEditErroMessages,
//       description: value.length < 5 ? t("DescriptionShouldBe5Characters") : "",
//     });
//   };

//   const handleEditTimeDuration = (event) => {
//     const value = event.target.value;
//     setParticularMilsetone({ ...particularMilestone, dueDate: value });
//     setMileStoneEditErrorMessages({
//       ...milestoneEditErroMessages,
//       dueDate: !value ? t("PleaseAddTime") : "",
//     });
//   };

//   const handleEditDepositeAmount = (event) => {
//     const value = event.target.value;
//     setParticularMilsetone({ ...particularMilestone, amount: value });
//     setMileStoneEditErrorMessages({
//       ...milestoneEditErroMessages,
//       amount: value.length === 0 ? t("PleaseAddDeposite") : "",
//     });
//   };

//   const validateEditMileStoneForm = () => {
//     const errors = {};
//     let isValid = true;

//     if (
//       !isNotEmpty(particularMilestone.title) ||
//       particularMilestone.title.length < 5
//     ) {
//       errors.title = t("TitleShouldBe5Characters");
//       isValid = false;
//     }

//     if (
//       !isNotEmpty(particularMilestone.description) ||
//       particularMilestone.description.length < 5
//     ) {
//       errors.description = t("DescriptionShouldBe5Characters");
//       isValid = false;
//     }

//     if (!particularMilestone.dueDate) {
//       errors.dueDate = t("PleaseAddTime");
//       isValid = false;
//     }

//     if (particularMilestone.amount.length === 0) {
//       errors.amount = t("PleaseAddDeposite");
//       isValid = false;
//     }

//     setMileStoneEditErrorMessages(errors);
//     return isValid;
//   };

//   const updateMilestone = async () => {
//     if (validateEditMileStoneForm()) {
//       try {
//         LoaderShow();
//         const token = GetUserDetails().token;

//         const dataToSend = {
//           projectId: project?._id,
//           milestones: [
//             {
//               description: particularMilestone.description,
//               currency: "USD",
//               amount: particularMilestone.amount,
//               title: particularMilestone.title,
//               dueDate: particularMilestone.dueDate,
//             },
//           ],
//         };

//         const response = await updateAPI(
//           `milestone/${particularMilestone?._id}`,
//           dataToSend,
//           token
//         );
//         if (response.statusCode === 200) {
//           LoaderHide();
//           toast.success(response.message);
//           setIsEditModalOpen(false);
//           setParticularMilsetone({
//             title: "",
//             description: "",
//             dueDate: "",
//             amount: "",
//           });
//           getMileStones();
//         }
//       } catch (error) {
//         LoaderHide();
//         toast.error(error.message);
//       }
//     }
//   };

//   const handleClose = () => {
//     setIsModalOpen(false);
//     setMileStoneData({ depositeAmount: "", description: "", timeDuration: "" });
//   };

//   const handleCloseEditModal = () => {
//     setIsEditModalOpen(false);
//   };

//   const handleCloseRating = () => {
//     setIsFinishModalOpen(false);
//   };

//   const isNotEmpty = (value) => value.trim() !== "";

//   const [text, setText] = useState({
//     textReview: "",
//   });

//   const [errorMessages, setErrorMessages] = useState({
//     textReview: "",
//     valuerating,
//   });

//   const handleTextArea = (event) => {
//     const value = event.target.value;
//     setText({ ...text, textReview: value });
//     setErrorMessages({
//       ...errorMessages,
//       textReview: text.textReview.length < 5 ? t("ReviewShouldbe5") : "",
//     });
//   };

//   const handleStar = (event) => {
//     const value = event.target.value;
//     setValueRating(value);
//     setErrorMessages({
//       ...errorMessages,
//       valuerating:
//         event?.target?.value?.length === 0 ? t("PleaseGiveStar") : "",
//     });
//   };
//   const validateForm = () => {
//     const errors = {};
//     let isValid = true;

//     if (!isNotEmpty(text.textReview) || text.textReview.length < 5) {
//       errors.textReview = t("ReviewShouldbe5");
//       isValid = false;
//     }

//     if (valuerating.length === 0) {
//       errors.valuerating = t("PleaseGiveStar");
//       isValid = false;
//     }

//     setErrorMessages(errors);
//     return isValid;
//   };

//   const submitReview = async () => {
//     LoaderShow();
//     if (validateForm()) {
//       try {
//         const userId = GetUserDetails().userId;

//         const dataToSend = {
//           userId: project.employeeId,
//           reviewerId: userId,
//           projectId: project?._id,
//           rating: valuerating,
//           comment: text.textReview,
//         };

//         const response = await postAPI("review/create", dataToSend);
//         if (response.statusCode === 200) {
//           toast.success(response.message);
//           setIsFinishModalOpen(false);
//           window.location.reload(true);
//         }
//       } catch (error) {
//         toast.error(error.message);
//       }
//     }
//     LoaderHide();
//   };

//   const [milestoneData, setMileStoneData] = useState({
//     title: "",
//     description: "",
//     timeDuration: "",
//     depositeAmount: "",
//   });

//   const [milestoneErroMessages, setMileStoneErrorMessages] = useState({
//     title: "",
//     description: "",
//     timeDuration: "",
//     depositeAmount: "",
//   });

//   const handleMileStoneTitle = (event) => {
//     const value = event.target.value;
//     setMileStoneData({ ...milestoneData, title: value });
//     setMileStoneErrorMessages({
//       ...milestoneErroMessages,
//       title: value.length < 5 ? t("TitleShouldBe5Characters") : "",
//     });
//   };

//   const handleMileStoneDescription = (event) => {
//     const value = event.target.value;
//     setMileStoneData({ ...milestoneData, description: value });
//     setMileStoneErrorMessages({
//       ...milestoneErroMessages,
//       description:
//         milestoneData.description.length < 5
//           ? t("DescriptionShouldBe5Characters")
//           : "",
//     });
//   };

//   const handleTimeDuration = (event) => {
//     const value = event.target.value;
//     setMileStoneData({ ...milestoneData, timeDuration: value });
//     setMileStoneErrorMessages({
//       ...milestoneErroMessages,
//       timeDuration: !value
//         ? t("PleaseAddTime")
//         : milestoneData <= 0
//         ? "Please enter time duration greater than 0."
//         : "",
//     });
//   };

//   const handleDepositeAmount = (event) => {
//     const value = event.target.value;
//     setMileStoneData({
//       ...milestoneData,
//       depositeAmount: value ? parseFloat(Number(value).toFixed(3)) : "",
//     });
//     setMileStoneErrorMessages({
//       ...milestoneErroMessages,
//       depositeAmount:
//         value.length === 0
//           ? t("PleaseAddDeposite")
//           : value <= 0
//           ? "Please enter amount greater than 0"
//           : "",
//     });
//   };

//   const validateMileStoneForm = () => {
//     const errors = {};
//     let isValid = true;

//     if (!isNotEmpty(milestoneData.title) || milestoneData.title.length < 5) {
//       errors.title = t("TitleShouldBe5Characters");
//       isValid = false;
//     }

//     if (
//       !isNotEmpty(milestoneData.description) ||
//       milestoneData.description.length < 5
//     ) {
//       errors.description = t("DescriptionShouldBe5Characters");
//       isValid = false;
//     }

//     if (!milestoneData.timeDuration) {
//       errors.timeDuration = t("PleaseAddTime");
//       isValid = false;
//     } else if (milestoneData.timeDuration[0] == "-") {
//       errors.timeDuration = "Please enter valid time duration.";
//       isValid = false;
//     }

//     if (milestoneData.depositeAmount.length === 0) {
//       errors.depositeAmount = t("PleaseAddDeposite");
//       isValid = false;
//     } else if (milestoneData.depositeAmount <= 0) {
//       errors.depositeAmount = "Please enter amount greater than 0";
//       isValid = false;
//     }

//     setMileStoneErrorMessages(errors);
//     return isValid;
//   };

//   const addMileStone = async () => {
//     if (validateMileStoneForm()) {
//       try {
//         LoaderShow();
//         const { token } = GetUserDetails();

//         const dataToSend = {
//           projectId: project._id,
//           description: milestoneData.description,
//           currency: "USD",
//           amount: milestoneData.depositeAmount,
//           title: milestoneData.title,
//           dueDate: milestoneData.timeDuration,
//           assignedTo: project.employeeId,
//         };

//         const response = await postAPI("milestone/create", dataToSend, token);
//         if (response.statusCode === 200) {
//           LoaderHide();
//           // toast.success(response.message);

//           setEstimatedTotal(
//             Number(milestoneData.depositeAmount) +
//               getCommision(milestoneData.depositeAmount)
//           );
//           setCurrentMilestoneId(response.data);
//           localStorage.setItem("projectData", JSON.stringify(project));
//           setIsModalOpen(false);
//           setOpenModal(true);
//           setMileStoneData({
//             depositeAmount: "",
//             description: "",
//             timeDuration: "",
//           });
//           getMileStones();
//         }
//       } catch (error) {
//         LoaderHide();
//         toast.error(error.message);
//       }
//     }
//   };

//   const [milestonesList, setMileStonesList] = useState([]);

//   useEffect(() => {
//     getMileStones();
//     //eslint-disable-next-line
//   }, []);

//   const getMileStones = async () => {
//     try {
//       LoaderShow();
//       const token = GetUserDetails().token;
//       const dataToSend = { projectId: project?._id };
//       const response = await postAPI("milestone/get", dataToSend, token);
//       if (response.statusCode === 200) {
//         if (searchParams.get("tab") && searchParams.get("tab") == "milestone") {
//           setTabValue(1);
//           searchParams.delete("tab");
//           setSearchParams(searchParams);
//         }
//         LoaderHide();
//         setMileStonesList(response?.data);
//       }
//     } catch (error) {
//       LoaderHide();
//       // toast.error(error.message)
//     }
//   };

//   const deleteMileStone = async () => {
//     try {
//       LoaderShow();
//       const response = await deleteAPI(`milestone/${milestoneId}`);
//       if (response.statusCode === 200) {
//         LoaderHide();
//         toast.success(response?.message);
//         getMileStones();
//       }
//     } catch (error) {
//       LoaderHide();
//       // toast.error(error.message)
//     }
//   };

//   // const currencies = [
//   //   { code: 'USD', label: 'USD' },
//   //   { code: 'EUR', label: 'EUR' },
//   //   { code: 'GBP', label: 'GBP' },
//   // ];

//   // const [currency, setCurrency] = useState(currencies[0].code);

//   // const handleCurrencyChange = (event) => {
//   //   setCurrency(event.target.value);
//   // };

//   const onMileStoneStateChange = async (status, mileStone) => {
//     LoaderShow();
//     try {
//       const { token } = GetUserDetails();
//       const data = {
//         status: status,
//         milestoneId: mileStone._id,
//       };
//       const response = await postAPI("milestone/status/update", data, token);
//       if (response.statusCode == 200) {
//         if (status == "Payment process") {
//           toast.info(
//             "Payment will be added to employee account within 24 hours."
//           );
//         }
//         setMileStonesList((project) => {
//           return {
//             ...project,
//             milestones: project.milestones.map((ele) => {
//               if (ele._id == mileStone._id) {
//                 ele.status = status;
//               }
//               return ele;
//             }),
//           };
//         });
//       }
//     } catch (err) {
//       console.log(err);
//       toast.error(err);
//     }
//     LoaderHide();
//   };

//   const payForMilestone = async (mileStone) => {
//     setEstimatedTotal(
//       Number(mileStone.amount) + getCommision(mileStone.amount)
//     );
//     setCurrentMilestoneId(mileStone);
//     setOpenModal(true);
//   };

//   const confirmProject = async () => {
//     LoaderShow();
//     try {
//       const data = {
//         projectId: project._id,
//         status: "0",
//       };
//       const res = await updateAPI(
//         "project/update-status",
//         data,
//         GetUserDetails().token
//       );
//       if (res.statusCode == 200) {
//         setCompleteProjectModalOepn(false);
//         setIsFinishModalOpen(true);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     LoaderHide();
//   };

//   const downloadInvoice = async (pdfUrl) => {
//     const link = document.createElement("a");
//     link.href = pdfUrl;
//     link.download = "invoice.pdf"; // specify the filename
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     return;
//   };

//   return (
//     <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//       <Dialog
//         open={completeProjectModalOepn}
//         onClose={() => setCompleteProjectModalOepn(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">Are you Sure</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Do you want to finish the project.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button color="success" onClick={confirmProject}>
//             Confirm
//           </Button>
//           <Button
//             color="success"
//             onClick={() => setCompleteProjectModalOepn(false)}
//             autoFocus
//           >
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <SubscriptionModal
//         totalFee={getCommision(currentMilestoneId.amount)}
//         open={openModal}
//         onClose={handleModalClose}
//         onCryptoSelected={handleCryptoModalOpen}
//         balance={estimatedTotal}
//         transactionId={null}
//         projectId={project?._id}
//         userId={project?.employee}
//         milestoneId={currentMilestoneId?._id ? [currentMilestoneId._id] : ""}
//         isNew={true}
//       />
//       <Modal
//         open={milestoneDeleteModal}
//         onClick={handleCloseModal}
//         aria-labelledby="delete-modal"
//         aria-describedby="delete-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             bgcolor: "background.paper",
//             boxShadow: 24,
//             p: 2,
//             borderRadius: 4,
//           }}
//         >
//           {/* <IconButton
//             onClick={handleCloseModal}
//             sx={{
//               position: "absolute",
//               top: 10,
//               right: 10,
//             }}
//           >
//             <img src={close} alt="db" />
//           </IconButton> */}
//           <Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 margin: "auto",
//                 justifyContent: "center",
//                 py: 1,
//               }}
//             >
//               {/* <img className="deleteVec" src={DeleteIcon} alt="db" /> */}
//             </Box>
//             <Typography
//               className="employeeHead1"
//               id="logout-modal-description"
//               variant="h5"
//               sx={{
//                 fontWeight: 700,
//                 fontSize: 32,
//                 textAlign: "center",
//                 py: 2,
//                 pb: 1,
//               }}
//               gutterBottom
//             >
//               {t("DeleteMilestone")}
//             </Typography>
//             <Typography
//               className="employeeHead"
//               id="logout-modal-description"
//               variant="body1"
//               sx={{ fontSize: 18, textAlign: "center", display: "block" }}
//               gutterBottom
//             ></Typography>
//           </Box>
//           <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
//             <Button
//               variant="contained"
//               onClick={handleCloseModal}
//               sx={{
//                 backgroundColor: "#0B3013!important",
//                 fontSize: 16,
//                 borderRadius: 20,
//                 textTransform: "capitalize",
//                 px: 4,
//                 py: 1,
//               }}
//             >
//               {t("Cancel")}
//             </Button>
//             <Button
//               onClick={deleteMileStone}
//               sx={{
//                 backgroundColor: "#0B3013!important",
//                 fontSize: 16,
//                 borderRadius: 20,
//                 textTransform: "capitalize",
//                 px: 6,
//                 py: 1,
//               }}
//               variant="contained"
//             >
//               {t("Yes")}
//             </Button>
//           </Box>
//         </Box>
//       </Modal>
//       <Box
//         className="plZero prZero"
//         sx={{
//           borderBottom: 1,
//           borderColor: "#d6d6d6",
//           mx: 2,
//           px: 2.5,
//           py: 2.5,
//           pb: 0,
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <Tabs
//             variant={isMobile ? "scrollable" : "standard"}
//             scrollButtons={isMobile ? "auto" : "off"}
//             allowScrollButtonsMobile={isMobile}
//             textColor="#222222"
//             sx={{
//               "& .MuiTabs-indicator": {
//                 backgroundColor: "#000",
//                 flex: 1,
//               },
//             }}
//             value={tabValue}
//             onChange={handleChange}
//             aria-label="project details tabs"
//           >
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("ProjectDetails")}
//             />
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("Milestones")}
//             />
//           </Tabs>
//           {tabValue == 1 && (
//             <Button onClick={getMileStones} sx={{ borderRadius: "0" }}>
//               <RefreshIcon sx={{ color: "#000" }} />
//             </Button>
//           )}
//         </Box>
//       </Box>
//       {tabValue === 0 && (
//         <Box sx={{ px: 4, py: 3 }}>
//           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//             <Typography variant="h5" sx={{ color: "#000" }}>
//               {project.title}
//             </Typography>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               {project?.estimatedBudget?.name}$
//             </Typography>
//           </Box>
//           <Box sx={{ py: 4 }}>
//             <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//               {project.description}
//             </Typography>
//             {/* <Typography
//               variant="p"
//               sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
//             >
//               Lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industry's standard dummy text
//               ever since the 1500s, when an unknown printer took a galley of
//               type and scrambled it to make a type specimen book. It has
//               survived not only five centuries, but also the leap into
//               electronic typesetting, remaining essentially unchanged. It was
//               popularised in the 1960s with the release of Letraset sheets
//               containing Lorem Ipsum passages, and more recently with desktop
//               publishing software like Aldus PageMaker including versions.
//             </Typography> */}
//           </Box>

//           <Box sx={{ py: 4 }}>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               {t("SkillReq")}
//             </Typography>
//             <Box
//               sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
//             >
//               {project.skillsRequired.map((skill, index) => (
//                 <Button
//                   onClick={onBack}
//                   sx={{
//                     fontFamily: "inherit",
//                     px: 4,
//                     mx: 1,
//                     fontSize: "16px !important",
//                     borderRadius: 10,
//                     pb: 1,
//                     backgroundColor: "#0B3013",
//                     width: "auto",
//                     maxWidth: "fit-content",
//                     textTransform: "capitalize",
//                     color: "#fff",
//                     display: "flex",
//                     fontWeight: "400",

//                     "&:hover": {
//                       backgroundColor: "#0B3013", // Keep the same color as default
//                     },
//                   }}
//                 >
//                   {skill?.name}
//                 </Button>
//               ))}
//               {/* <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Graphic Design
//               </Button>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Figma
//               </Button>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Adobe XD
//               </Button> */}
//             </Box>
//           </Box>
//           <Box sx={{}}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: "#000",
//                 fontWeight: 600,
//                 pb: 0,
//                 fontSize: 18,
//                 px: 0,
//               }}
//             >
//               {t("Attachments")}
//             </Typography>
//             <Box
//               className="uploadSec"
//               sx={{
//                 py: 3,
//                 pb: 0,
//               }}
//             >
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 {/* <img
//                   src={doc}
//                   alt="Delete"
//                   style={{ cursor: "pointer" }}
//                 /> */}

//                 <Typography
//                   variant="p"
//                   sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                 >
//                   {/* 84c110f299f31bad53eee9731d43cbca.jpg (154kB) */}
//                   {project?.documents?.map((url, index) => (
//                     <div key={index}>
//                       <h3>
//                         {t("Document")} {index + 1}
//                       </h3>
//                       <a
//                         href={url}
//                         key={index}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                       >
//                         {url}
//                       </a>
//                     </div>
//                   ))}
//                 </Typography>
//                 {/* <img
//                   src={del}
//                   alt="Delete"
//                   style={{ cursor: "pointer" }}
//                 /> */}
//               </Box>
//             </Box>
//           </Box>
//           <Box sx={{ my: 2 }}>
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px !important",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 ml: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               {t("BackButton")}
//             </Button>
//           </Box>
//         </Box>
//       )}
//       {tabValue === 1 && (
//         <>
//           <Box sx={{ py: 3 }}>
//             <TableContainer component={Paper} style={{ overflowX: "auto" }}>
//               <Table sx={{ fontFamily: "inherit" }}>
//                 <TableHead sx={{ borderBottom: "0!important" }}>
//                   <TableRow>
//                     <TableCell
//                       sx={{
//                         px: 5,
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("MileStoneName")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Duration")} {t("inDays")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Status")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Cost")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       {t("Action")}
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 600,
//                         fontSize: 18,
//                         borderBottom: "0!important",
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       Invoice
//                     </TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {milestonesList?.milestones?.map((milestone, index) => (
//                     <TableRow
//                       sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
//                     >
//                       <TableCell
//                         sx={{
//                           px: 5,
//                           fontWeight: 500,
//                           fontSize: 16,
//                           fontFamily: "inherit",
//                         }}
//                       >
//                         {milestone?.title}
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           fontWeight: 500,
//                           fontSize: 16,
//                           fontFamily: "inherit",
//                         }}
//                       >
//                         {milestone?.dueDate}
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           fontWeight: 500,
//                           fontSize: 16,
//                           fontFamily: "inherit",
//                         }}
//                       >
//                         <Box
//                           sx={{
//                             backgroundColor: "#d30100",
//                             color: "#fff",
//                             borderRadius: 10,
//                             p: 2,
//                             width: "auto",
//                             maxWidth: "fit-content",
//                             py: 1,
//                             fontWeight: "400",
//                           }}
//                         >
//                           {MILESTONE_STATUS[milestone?.status]}
//                         </Box>
//                       </TableCell>
//                       <TableCell
//                         sx={{
//                           fontWeight: 500,
//                           fontSize: 16,
//                           fontFamily: "inherit",
//                         }}
//                       >
//                         <Box sx={{ display: "flex", flexDirection: "column" }}>
//                           <Box>$ {Number(milestone?.amount)}</Box>
//                           <Typography
//                             variant="small"
//                             sx={{ color: "#000", fontWeight: 300 }}
//                           >
//                             {/* Hourly Rate */}
//                           </Typography>
//                         </Box>
//                       </TableCell>

//                       <TableCell
//                         sx={{
//                           fontWeight: 500,
//                           fontSize: 16,
//                           fontFamily: "inherit",
//                         }}
//                       >
//                         {milestone?.status == "complete" && (
//                           <Button
//                             sx={{
//                               backgroundColor: "#2bb95e",
//                               color: "#fff",
//                               borderRadius: 10,
//                               p: 2,
//                               width: "auto",
//                               maxWidth: "fit-content",
//                               py: 1,
//                               fontWeight: "400",
//                               "&:hover": {
//                                 backgroundColor: "#2bb95e", // Keep the same color as default
//                               },
//                             }}
//                             onClick={(ev) =>
//                               onMileStoneStateChange("accept", milestone)
//                             }
//                           >
//                             Approve
//                           </Button>
//                         )}
//                         {milestone?.status == "accept" && (
//                           <Button
//                             sx={{
//                               backgroundColor: "#2bb95e",
//                               color: "#fff",
//                               borderRadius: 10,
//                               p: 2,
//                               width: "auto",
//                               maxWidth: "fit-content",
//                               py: 1,
//                               fontWeight: "400",
//                               "&:hover": {
//                                 backgroundColor: "#2bb95e", // Keep the same color as default
//                               },
//                             }}
//                             onClick={(ev) =>
//                               onMileStoneStateChange(
//                                 "Payment process",
//                                 milestone
//                               )
//                             }
//                           >
//                             Pay now
//                           </Button>
//                         )}
//                         {milestone?.status == "Payment pending" && (
//                           <>
//                             <Button
//                               sx={{
//                                 backgroundColor: "#2bb95e",
//                                 color: "#fff",
//                                 borderRadius: 10,
//                                 p: 2,
//                                 width: "auto",
//                                 maxWidth: "fit-content",
//                                 py: 1,
//                                 fontWeight: "400",
//                                 "&:hover": {
//                                   backgroundColor: "#2bb95e", // Keep the same color as default
//                                 },
//                               }}
//                               onClick={(ev) => payForMilestone(milestone)}
//                             >
//                               Pay
//                             </Button>
//                             <IconButton>
//                               <img
//                                 src={EditIcon}
//                                 alt="Edit"
//                                 style={{ width: 33, height: 33 }}
//                                 onClick={() => toggleEditModal(milestone._id)}
//                               />
//                             </IconButton>
//                             <IconButton
//                               onClick={() =>
//                                 handleOpenDeleteMileStone(milestone._id)
//                               }
//                               // onClick={() => deleteMileStone(milestone._id)}
//                             >
//                               <img
//                                 src={DeleteIcon}
//                                 alt="Delete"
//                                 style={{ width: 33, height: 33 }}
//                               />
//                             </IconButton>
//                           </>
//                         )}
//                         {!["accept", "complete", "Payment pending"].includes(
//                           milestone?.status
//                         ) && "-"}
//                       </TableCell>
//                       <TableCell>
//                         {milestone?.invoicePDFs?.employer?.length > 0 ? (
//                           <DescriptionIcon
//                             onClick={(ev) =>
//                               downloadInvoice(
//                                 milestone?.invoicePDFs?.employer[0]
//                               )
//                             }
//                             title="Invoice"
//                             style={{ color: "#0B3013", cursor: "pointer" }}
//                           />
//                         ) : (
//                           "-"
//                         )}
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                   {/* <TableRow
//                     sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <TableCell
//                       sx={{
//                         px: 5,
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                         cursor: "pointer",
//                       }}
//                     >
//                       Food Delivery Mobile App
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       10 May
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           backgroundColor: "#2bb95e",
//                           color: "#fff",
//                           borderRadius: 10,
//                           p: 2,
//                           width: "auto",
//                           maxWidth: "fit-content",
//                           py: 1,
//                           fontWeight: "400",
//                         }}
//                       >
//                         Active
//                       </Box>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       <Box sx={{ display: "flex", flexDirection: "column" }}>
//                         <Box sx={{ px: 2 }}>$36</Box>
//                         <Typography
//                           variant="small"
//                           sx={{ color: "#000", fontWeight: 300 }}
//                         >
//                           Hourly Rate
//                         </Typography>
//                       </Box>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         fontWeight: 500,
//                         fontSize: 16,
//                         fontFamily: "inherit",
//                       }}
//                     >
//                       <IconButton>
//                         <img
//                           src={EditIcon}
//                           alt="Edit"
//                           style={{ width: 33, height: 33 }}
//                         />
//                       </IconButton>
//                       <IconButton>
//                         <img
//                           src={DeleteIcon}
//                           alt="Delete"
//                           style={{ width: 33, height: 33 }}
//                         />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow> */}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Box
//               className="flexColumnRespo"
//               sx={{
//                 display: "flex",
//                 px: 2,
//                 py: 3,
//                 ml: "auto",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Button
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px !important",
//                   pb: 1,
//                   fontWeight: 400,
//                   borderRadius: 10,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   alignItems: "center", // Align icon vertically with text
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//                 onClick={toggleModal}
//               >
//                 <AddIcon style={{ marginRight: "8px" }} /> {t("AddMileStone")}{" "}
//                 {/* Icon with margin for spacing */}
//               </Button>
//               <Button
//                 className="catBtnTop"
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontWeight: 400,
//                   mx: 2,
//                   fontSize: "16px !important",
//                   pb: 1,
//                   borderRadius: 10,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                   "&:disabled": {
//                     color: "#ffffff", // Keep the same color as default
//                   },
//                 }}
//                 disabled={
//                   !milestonesList?.milestones?.every(
//                     (milestone) => milestone.status == "paid"
//                   )
//                 }
//                 onClick={(ev) => setCompleteProjectModalOepn(true)} // Open Finish Milestone Modal
//               >
//                 {t("Finish")}
//               </Button>
//             </Box>
//           </Box>
//           <Box
//             sx={{
//               display: "flex",
//               px: 2,
//               justifyContent: "center",
//               pb: 3,
//               flexWrap: "wrap",
//             }}
//           >
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px !important",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 mr: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               {t("BackButton")}
//             </Button>
//             <Box className="catBtnTop">
//               <CustomPagination count={1} />
//             </Box>
//           </Box>
//         </>
//       )}
//       <Box className="ModalSection">
//         {isModalOpen && (
//           <Modal
//             open={isModalOpen}
//             onClose={toggleModal}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box
//               sx={{
//                 position: "absolute",
//                 width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 bgcolor: "background.paper",
//                 boxShadow: 24,
//                 borderRadius: 4,
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   py: 2,
//                   px: 3,
//                   alignItems: "center",
//                   borderBottom: 1,
//                   borderColor: "#d8d8d8",
//                 }}
//               >
//                 <Typography variant="h6">{t("AddMileStone")}</Typography>
//                 <IconButton onClick={handleClose}>
//                   <img src={close} alt="db" />
//                 </IconButton>
//               </Box>
//               <Box sx={{ py: 0, pb: 4 }}>
//                 <Box sx={{ px: 3 }}>
//                   <Box sx={{ py: 1 }}>
//                     <FormLabel
//                       sx={{
//                         display: "block",
//                         textAlign: "start",
//                         py: 1.4,
//                         fontSize: 16,
//                         fontWeight: 600,
//                         color: "#000",
//                       }}
//                     >
//                       {t("MileStoneTitle")}
//                     </FormLabel>
//                     <TextField
//                       value={milestoneData.title}
//                       onChange={handleMileStoneTitle}
//                       variant="outlined"
//                       sx={{
//                         borderColor: "#8692A6!important",
//                         borderRadius: 4,
//                       }}
//                       fullWidth={true}
//                       placeholder={t("MileStoneTitle")}
//                     />
//                     {milestoneErroMessages.title && (
//                       <Typography variant="p" sx={{ color: "red" }}>
//                         {milestoneErroMessages.title}
//                       </Typography>
//                     )}
//                   </Box>

//                   <Grid container>
//                     <Grid item xs={12}>
//                       <Box>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 2,
//                             fontWeight: "600",
//                             color: "#000",
//                             fontSize: 17,
//                           }}
//                         >
//                           {t("MileStoneDescription")}
//                         </FormLabel>
//                         <Textarea
//                           value={milestoneData.description}
//                           onChange={handleMileStoneDescription}
//                           sx={{
//                             width: "100%!important",
//                             border: 1,
//                             borderColor: "#cbcbcb",
//                             borderRadius: 1,
//                           }}
//                           minRows={6}
//                           maxRows={6}
//                           placeholder={t("MileStoneDescription")}
//                         />
//                         {milestoneErroMessages.description && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneErroMessages.description}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={2}>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("TimeDuration")}
//                         </FormLabel>
//                         <TextField
//                           type="number"
//                           value={milestoneData.timeDuration}
//                           onChange={handleTimeDuration}
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("TimeDuration")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                         {milestoneErroMessages.timeDuration && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneErroMessages.timeDuration}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("DepositeAmount")}
//                         </FormLabel>
//                         {/* <InputLabel>Currency</InputLabel>
//                         <Select
//                           value={currency}
//                           onChange={handleCurrencyChange}
//                           label="Currency"
//                         >
//                           {currencies.map((currency) => (
//                             <MenuItem key={currency.code} value={currency.code}>
//                               {currency.label}
//                             </MenuItem>
//                           ))}
//                         </Select> */}
//                         <TextField
//                           type="number"
//                           value={milestoneData.depositeAmount}
//                           onChange={handleDepositeAmount}
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("DepositeAmount")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                         {milestoneErroMessages.depositeAmount && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneErroMessages.depositeAmount}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-around",
//                     mt: 4,
//                   }}
//                 >
//                   <Button
//                     fullWidth={true}
//                     variant="contained"
//                     sx={{
//                       backgroundColor: "#0B3013!important",
//                       fontSize: 16,
//                       borderRadius: 2,
//                       textTransform: "capitalize",
//                       px: 6,
//                       py: 1,
//                       mx: 3,
//                     }}
//                     onClick={addMileStone}
//                   >
//                     {t("Save")}
//                   </Button>
//                 </Box>
//               </Box>
//             </Box>
//           </Modal>
//         )}

//         {isEditModalOpen && (
//           <Modal
//             open={isEditModalOpen}
//             onClose={toggleEditModal}
//             aria-labelledby="modal-modal-title"
//             aria-describedby="modal-modal-description"
//           >
//             <Box
//               sx={{
//                 position: "absolute",
//                 width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 bgcolor: "background.paper",
//                 boxShadow: 24,
//                 borderRadius: 4,
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   py: 2,
//                   px: 3,
//                   alignItems: "center",
//                   borderBottom: 1,
//                   borderColor: "#d8d8d8",
//                 }}
//               >
//                 <Typography variant="h6">{t("EditMileStone")}</Typography>
//                 <IconButton onClick={handleCloseEditModal}>
//                   <img src={close} alt="db" />
//                 </IconButton>
//               </Box>
//               <Box sx={{ py: 0, pb: 4 }}>
//                 <Box sx={{ px: 3 }}>
//                   <Box sx={{ py: 1 }}>
//                     <FormLabel
//                       sx={{
//                         display: "block",
//                         textAlign: "start",
//                         py: 1.4,
//                         fontSize: 16,
//                         fontWeight: 600,
//                         color: "#000",
//                       }}
//                     >
//                       {t("MileStoneTitle")}
//                     </FormLabel>
//                     <TextField
//                       value={particularMilestone?.title}
//                       onChange={handleEditMileStoneTitle}
//                       variant="outlined"
//                       sx={{
//                         borderColor: "#8692A6!important",
//                         borderRadius: 4,
//                       }}
//                       fullWidth={true}
//                       placeholder={t("MileStoneTitle")}
//                     />
//                     {milestoneEditErroMessages.title && (
//                       <Typography variant="p" sx={{ color: "red" }}>
//                         {milestoneEditErroMessages.title}
//                       </Typography>
//                     )}
//                   </Box>

//                   <Grid container>
//                     <Grid item xs={12}>
//                       <Box>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 2,
//                             fontWeight: "600",
//                             color: "#000",
//                             fontSize: 17,
//                           }}
//                         >
//                           {t("MileStoneDescription")}
//                         </FormLabel>
//                         <Textarea
//                           value={particularMilestone?.description}
//                           onChange={handleEditMileStoneDescription}
//                           sx={{
//                             width: "100%!important",
//                             border: 1,
//                             borderColor: "#cbcbcb",
//                             borderRadius: 1,
//                           }}
//                           minRows={6}
//                           maxRows={6}
//                           placeholder={t("MileStoneDescription")}
//                         />
//                         {milestoneEditErroMessages.description && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneEditErroMessages.description}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                   </Grid>

//                   <Grid container spacing={2}>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("TimeDuration")}
//                         </FormLabel>
//                         <TextField
//                           value={particularMilestone?.dueDate}
//                           onChange={handleEditTimeDuration}
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("Duration")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                         {milestoneEditErroMessages.dueDate && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneEditErroMessages.dueDate}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <Box sx={{ my: 1 }}>
//                         <FormLabel
//                           sx={{
//                             display: "block",
//                             textAlign: "start",
//                             py: 1.4,
//                             fontSize: 17,
//                             color: "#000",
//                             fontWeight: "600",
//                           }}
//                         >
//                           {t("DepositeAmount")}
//                         </FormLabel>
//                         {/* <InputLabel>Currency</InputLabel>
//                         <Select
//                           value={currency}
//                           onChange={handleCurrencyChange}
//                           label="Currency"
//                         >
//                           {currencies.map((currency) => (
//                             <MenuItem key={currency.code} value={currency.code}>
//                               {currency.label}
//                             </MenuItem>
//                           ))}
//                         </Select> */}
//                         <TextField
//                           value={particularMilestone?.amount}
//                           onChange={handleEditDepositeAmount}
//                           variant="outlined"
//                           sx={{
//                             background: isFocused ? "#FFFFFF" : "transparent",
//                             borderColor: isFocused ? "#0B3013" : "#E0E0E0",
//                             boxShadow: isFocused
//                               ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
//                               : "none",
//                           }}
//                           fullWidth={true}
//                           placeholder={t("DepositeAmount")}
//                           onFocus={handleFocus}
//                           onBlur={handleBlur}
//                         />
//                         {milestoneEditErroMessages.amount && (
//                           <Typography variant="p" sx={{ color: "red" }}>
//                             {milestoneEditErroMessages.amount}
//                           </Typography>
//                         )}
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-around",
//                     mt: 4,
//                   }}
//                 >
//                   <Button
//                     fullWidth={true}
//                     variant="contained"
//                     sx={{
//                       backgroundColor: "#0B3013!important",
//                       fontSize: 16,
//                       borderRadius: 2,
//                       textTransform: "capitalize",
//                       px: 6,
//                       py: 1,
//                       mx: 3,
//                     }}
//                     onClick={updateMilestone}
//                   >
//                     {t("UpdateMileStone")}
//                   </Button>
//                 </Box>
//               </Box>
//             </Box>
//           </Modal>
//         )}

//         <Modal
//           open={isFinishModalOpen}
//           onClose={() => {}}
//           aria-labelledby="finish-modal-title"
//           aria-describedby="finish-modal-description"
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               borderRadius: 4,
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 py: 2,
//                 px: 3,
//                 alignItems: "center",
//                 borderBottom: 1,
//                 borderColor: "#d8d8d8",
//               }}
//             >
//               <Typography variant="h6">{t("LeaveAReview")}</Typography>
//               {/* <IconButton onClick={handleCloseRating}>
//                 <img src={close} alt="db" />
//               </IconButton> */}
//             </Box>
//             <Box sx={{ px: 4 }}>
//               <Typography
//                 variant="p"
//                 sx={{
//                   textAlign: "center",
//                   fontSize: 17,
//                   color: "#000",
//                   display: "block",
//                   py: 2,
//                 }}
//               >
//                 {t("HowToYouRating")}
//               </Typography>
//             </Box>
//             <Box sx={{ display: "flex", justifyContent: "center" }}>
//               <Rating
//                 name="simple-controlled"
//                 value={valuerating}
//                 onChange={handleStar}
//                 sx={{ fontSize: "36px" }} // Adjust the font size as needed
//               />
//             </Box>
//             {errorMessages.valuerating && (
//               <Typography
//                 variant="p"
//                 sx={{ color: "red", textAlign: "center", marginLeft: "32px" }}
//               >
//                 {errorMessages.valuerating}
//               </Typography>
//             )}
//             <Grid container sx={{ px: 4 }}>
//               <Grid item xs={12}>
//                 <Box>
//                   <FormLabel
//                     sx={{
//                       display: "block",
//                       textAlign: "start",
//                       py: 2,
//                       fontWeight: "600",
//                       color: "#000",
//                       fontSize: 17,
//                     }}
//                   ></FormLabel>
//                   <Textarea
//                     value={text.textReview}
//                     onChange={handleTextArea}
//                     sx={{
//                       width: "100%!important",
//                       border: 1,
//                       borderColor: "#cbcbcb",
//                       borderRadius: 1,
//                     }}
//                     minRows={6}
//                     maxRows={6}
//                     placeholder={t("WriteAReview")}
//                   />
//                   {errorMessages.textReview && (
//                     <Typography variant="p" sx={{ color: "red" }}>
//                       {errorMessages.textReview}
//                     </Typography>
//                   )}
//                 </Box>
//               </Grid>
//             </Grid>

//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 my: 4,
//                 px: 4,
//               }}
//             >
//               <Button
//                 fullWidth={true}
//                 variant="contained"
//                 onClick={submitReview}
//                 sx={{
//                   backgroundColor: "#0B3013!important",
//                   fontSize: 16,
//                   borderRadius: 3,
//                   textTransform: "capitalize",

//                   py: 1,
//                 }}
//               >
//                 {t("SubmitReview")}
//               </Button>
//             </Box>
//           </Box>
//         </Modal>
//       </Box>
//     </Box>
//   );
// }

// function CompletedProjectDetails({ projectNameCompleted, onBack }) {
//   const { t } = useTranslation();

//   const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };
//   return (
//     <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//       <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
//         <Tabs
//           variant={isMobile ? "scrollable" : "standard"}
//           scrollButtons={isMobile ? "auto" : "off"}
//           allowScrollButtonsMobile={isMobile}
//           textColor="#222222"
//           sx={{
//             borderBottom: 1,
//             borderColor: "#dbdbdb",
//             "& .MuiTabs-indicator": {
//               backgroundColor: "#000",
//             },
//           }}
//           value={tabValue}
//           onChange={handleChange}
//           aria-label="project details tabs"
//         >
//           <Tab
//             sx={{
//               textTransform: "capitalize",
//               pl: 0,
//               mx: 1,
//               fontSize: 17,
//               fontFamily: "inherit",
//             }}
//             label="Project Details"
//           />
//         </Tabs>
//       </Box>
//       {tabValue === 0 && (
//         <Box sx={{ px: 4, py: 3 }}>
//           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//             <Typography variant="h5" sx={{ color: "#000" }}>
//               Food Delivery Mobile App
//             </Typography>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               $100 - $150
//             </Typography>
//           </Box>
//           <Box sx={{ py: 4 }}>
//             <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//               Lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industry's standard dummy text
//               ever since the 1500s, when an unknown printer took a galley of
//               type and scrambled it to make a type specimen book. It has
//               survived not only five centuries, but also the leap into
//               electronic typesetting, remaining essentially unchanged. It was
//               popularised in the 1960s with the release of Letraset sheets
//               containing Lorem Ipsum passages, and more recently with desktop
//               publishing software like Aldus PageMaker including versions.
//             </Typography>
//             <Typography
//               variant="p"
//               sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
//             >
//               Lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industry's standard dummy text
//               ever since the 1500s, when an unknown printer took a galley of
//               type and scrambled it to make a type specimen book. It has
//               survived not only five centuries, but also the leap into
//               electronic typesetting, remaining essentially unchanged. It was
//               popularised in the 1960s with the release of Letraset sheets
//               containing Lorem Ipsum passages, and more recently with desktop
//               publishing software like Aldus PageMaker including versions.
//             </Typography>
//           </Box>

//           <Box sx={{ py: 4 }}>
//             <Typography variant="h6" sx={{ color: "#000" }}>
//               Skill Required
//             </Typography>
//             <Box sx={{ display: "flex", py: 3 }}>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 UI/UX Design
//               </Button>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Graphic Design
//               </Button>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Figma
//               </Button>
//               <Button
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   mx: 1,
//                   fontSize: "16px !important",
//                   borderRadius: 10,
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   fontWeight: "400",

//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 Adobe XD
//               </Button>
//             </Box>
//           </Box>
//           <Box sx={{}}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: "#000",
//                 fontWeight: 600,
//                 pb: 0,
//                 fontSize: 18,
//                 px: 0,
//               }}
//             >
//               {t("Attachments")}
//             </Typography>
//             <Box
//               className="uploadSec"
//               sx={{
//                 py: 3,
//                 pb: 0,
//               }}
//             >
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

//                 <Typography
//                   variant="p"
//                   sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                 >
//                   84c110f299f31bad53eee9731d43cbca.jpg (154kB)
//                 </Typography>
//                 <img src={del} alt="Delete" style={{ cursor: "pointer" }} />
//               </Box>
//             </Box>
//           </Box>
//           <Box sx={{ my: 2 }}>
//             <Button
//               onClick={onBack}
//               sx={{
//                 fontFamily: "inherit",
//                 px: 4,
//                 fontSize: "16px !important",
//                 pb: 1,
//                 backgroundColor: "#0B3013",
//                 width: "auto",
//                 maxWidth: "fit-content",
//                 textTransform: "capitalize",
//                 color: "#fff",
//                 display: "flex",
//                 ml: "auto",
//                 "&:hover": {
//                   backgroundColor: "#0B3013", // Keep the same color as default
//                 },
//               }}
//             >
//               Back
//             </Button>
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// }

export default function EmployeerMyProjects() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [tabValue, setTabValue] = useState(0);
  const [selectedProjectPosted, setSelectedProjectPosted] = useState(null);
  const [selectedProjectOngoing, setSelectedProjectOngoing] = useState(null);
  const [selectedProjectCompleted, setSelectedProjectCompleted] =
    useState(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isLogoutActive, setIsLogoutActive] = useState(false);
  const [projectID, setProjectID] = useState("");

  const [status, setStatus] = useState(1);
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const LogoutModal = ({ open, handleClose }) => {
    // Add logout functionality here
    const handleDelteProject = async () => {
      LoaderShow();
      try {
        // const token = GetUserDetails().token
        const token = GetUserDetails().token;

        const dataToSend = { id: projectID };

        const response = await deleteAPI("project/delete", dataToSend, token);
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(response.message);
          getMyProjects();
          handleClose();
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    };

    const handleCloseModal = () => {
      handleClose(); // Close the modal after logout
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices

            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={handleClose}
          >
            <img src={close} alt="db" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            ></Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("deleteProject")}
            </Typography>
            <Typography
              id="logout-modal-description"
              className="employeeHead"
              variant="p"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has. */}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleDelteProject}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleOpenLogoutModal = (project) => {
    setProjectID(project._id);
    setLogoutModalOpen(true);
    setIsLogoutActive(true); // Set logout menu item as active
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  // '1': 'active',
  // '2': 'ongoing',
  // '0': 'completed'

  const handleChange = (event, newValue) => {
    setTabValue(newValue);

    var sendStatus;
    if (newValue === 0) {
      sendStatus = 1;
    } else if (newValue === 1) {
      sendStatus = 2;
    } else if (newValue === 2) {
      sendStatus = 0;
    }

    setStatus(sendStatus);

    getMyProjects("", sendStatus);
  };

  const handleProjectPostedClick = (project) => {
    setSelectedProjectPosted(project);
    setSelectedProjectOngoing(null);
    setSelectedProjectCompleted(null);
  };

  const handleProjectOngoingClick = (project) => {
    setSelectedProjectOngoing(project);
    setSelectedProjectPosted(null);
    setSelectedProjectCompleted(null);
  };

  const handleProjectCompletedClick = (projectNameCompleted) => {
    setSelectedProjectCompleted(projectNameCompleted);
    setSelectedProjectPosted(null);
    setSelectedProjectOngoing(null);
  };

  const handleBack = () => {
    setSelectedProjectPosted(null);
    setSelectedProjectOngoing(null);
    setSelectedProjectCompleted(null);
  };

  // const postedProjects = [
  //   { name: "Project 1", proposals: 25, status: "Active", cost: "$1000" },
  //   { name: "Project 2", proposals: 32, status: "Active", cost: "$300" },
  //   { name: "Project 3", proposals: 12, status: "Active", cost: "$500" },
  //   { name: "Project 4", proposals: 90, status: "Active", cost: "$800" },
  // ];

  // const ongoingProjects = [
  //   { name: "Project 5", proposals: 25, status: "Active", cost: "$1000" },
  //   { name: "Project 6", proposals: 32, status: "Active", cost: "$300" },
  // ];

  // const completedProjects = [
  //   { name: "Project 7", proposals: 25, status: "Active", cost: "$1000" },
  //   { name: "Project 8", proposals: 32, status: "Active", cost: "$300" },
  //   { name: "Project 9", proposals: 32, status: "Active", cost: "$300" },
  // ];

  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const setTransactionDetails = async () => {
    LoaderShow();
    const projectDetails =
      JSON.parse(localStorage.getItem("projectData")) || "";
    try {
      const dataToSend = {
        amount: Number(searchParams.get("amt")) || 0,
        remainingAmount: Math.abs(Number(searchParams.get("paidAmt")) || 0),
        projectId: projectDetails?._id ? projectDetails?._id : "",
        method: "Credit card",
        peyeeId: projectDetails?.user?._id ? projectDetails?.user?._id : "",
        transectionId: searchParams.get("payment_intent"),
        milestoneIds: searchParams.get("milestone_id").split(","),
        totalFee: searchParams.get("total_fee"),
      };
      const token = GetUserDetails().token;
      const id = GetUserDetails().userId;

      const response = await postAPI("transection/create", dataToSend, token);
      const data = {
        email: searchParams.get("email"),
        projectId: projectDetails?._id,
        employeeId: searchParams.get("user_id"),
      };
      if (response.statusCode === 200) {
        try {
          const response = await postAPI(
            "invoice/create",
            {
              ...dataToSend,
              type: "employer",
              payeeId: dataToSend.peyeeId,
              payerId: id,
              currency: "USD",
              milestoneId: dataToSend.milestoneIds[0],
              paymentMethod: "Credit card",
            },
            token
          );
        } catch (err) {}
        if (searchParams.get("isNew") == "false")
          await postAPI("award/projects/award", data, token);
        window.location.replace("/dashboard/employer/myprojects");
      }
    } catch (error) {
      // toast.error(error.message)
      window.location.replace("/dashboard/employer/myprojects");
    }
  };

  useEffect(() => {
    if (searchParams.get("payment_intent")) {
      setTransactionDetails();
      return;
    }
    if (searchParams.get("project_type")) {
      if (searchParams.get("project_type") == "ongoing") {
        setTabValue(1);
        searchParams.delete("project_type");
        setSearchParams(searchParams);
        getMyProjects("", 2, true);
      }
    } else {
      getMyProjects();
    }
  }, []);

  const getMyProjects = async (
    pageNumber = 1,
    st = status,
    isOngoing = false
  ) => {
    LoaderShow();
    try {
      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      var pageStatus;
      if (st === undefined || st === "") {
        pageStatus = 1;
      } else if (st === 0) {
        pageStatus = "0";
      } else {
        pageStatus = st;
      }

      const token = GetUserDetails().token;
      const dataToSend = { status: pageStatus };

      const response = await getAPI(
        `project/status?page=${pn}&status=${pageStatus}`,
        token
      );
      if (response.statusCode === 200) {
        setProjects(response?.data);

        if (searchParams.get("project_id")) {
          const id = searchParams.get("project_id");
          const selectedProject = response.data.find(
            (project) => project._id == id
          );
          if (selectedProject) {
            isOngoing
              ? handleProjectOngoingClick(selectedProject)
              : handleProjectPostedClick(selectedProject);
          }
          searchParams.delete("project_id");
          setSearchParams(searchParams);
        }
        LoaderHide();
        setTotalPages(response?.pagination?.totalPages);
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getMyProjects(value);
  };

  // '1': 'active',
  // '2': 'ongoing',
  // '0': 'completed'

  const completedProjects = useMemo(
    () => projects?.filter((e) => e.status === "0"),
    [projects]
  );
  const activeProjects = useMemo(
    () => projects?.filter((e) => e.status === "1"),
    [projects]
  );
  const ongoingProjects = useMemo(
    () => projects?.filter((e) => e.status === "2"),
    [projects]
  );

  const [editProject, setEditProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");

  const showEditProjects = (project) => {
    setEditProject(false);
    navigate("/dashboard/employer/editproject", {
      state: {
        project,
      },
    });
    setSelectedProject(project);
  };

  const updateProject = (projectId, isAwarded) => {
    setProjects((projects) =>
      projects.map((ele) => {
        if (ele._id == projectId) ele.isAwarded = isAwarded;
        return ele;
      })
    );
  };

  return (
    <>
      <>
        <div id="hideloding" className="loding-display">
          <img src={loader} alt="loader-img" />
        </div>
        <section className="EmployeerCreateProject">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {(selectedProjectPosted ||
              selectedProjectOngoing ||
              selectedProjectCompleted) && (
              <IconButton onClick={handleBack} sx={{ mr: 1, mb: 1 }}>
                <ArrowBackIcon sx={{ color: "#000" }} />
              </IconButton>
            )}
            <Typography
              className="employeeHead1"
              variant="h4"
              sx={{ pb: 1, fontWeight: 600 }}
            >
              {t("MyProj")}
            </Typography>
            {!selectedProjectPosted &&
              !selectedProjectOngoing &&
              !selectedProjectCompleted && (
                <Link to="/dashboard/employer/createproject">
                  <IconButton
                    sx={{
                      mb: 1,
                    }}
                    onClick={handleBack}
                  >
                    <AddCircleIcon
                      sx={{ color: "#0B3013", width: "30px", height: "30px" }}
                    />
                  </IconButton>
                </Link>
              )}
            <Typography
              variant="p"
              sx={{ fontSize: 18, color: "#777" }}
            ></Typography>
          </Box>

          {!selectedProjectPosted &&
            !selectedProjectOngoing &&
            !selectedProjectCompleted && (
              <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
                <Box
                  sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0, display: "flex" }}
                  className="plZero prZero"
                >
                  <Tabs
                    variant={isMobile ? "scrollable" : "standard"}
                    scrollButtons={isMobile ? "auto" : "off"}
                    allowScrollButtonsMobile={isMobile}
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="project tabs"
                    textColor="#222222"
                    sx={{
                      flex: 1,
                      borderBottom: 1,
                      borderColor: "#dbdbdb",
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#000",
                      },
                    }}
                  >
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        pl: 0,
                        mx: 1,
                        textAlign: "start!important",
                        fontSize: 17,
                        fontFamily: "inherit",
                      }}
                      label={t("PostedProjects")}
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        pl: 0,
                        mx: 1,
                        fontSize: 17,
                        fontFamily: "inherit",
                      }}
                      label={t("Ongoing")}
                    />
                    <Tab
                      sx={{
                        textTransform: "capitalize",
                        pl: 0,
                        mx: 1,
                        fontSize: 17,
                        fontFamily: "inherit",
                      }}
                      label={t("CompletedProjects")}
                    />
                  </Tabs>
                  <Button
                    onClick={(ev) => getMyProjects()}
                    sx={{
                      borderBottom: "1px solid #dbdbdb",
                      borderRadius: "0",
                    }}
                  >
                    <RefreshIcon sx={{ color: "#000" }} />
                  </Button>
                </Box>

                <Box sx={{ my: 3, py: 4, pt: 0 }}>
                  {tabValue === 0 && (
                    <TableContainer
                      component={Paper}
                      style={{ overflowX: "auto" }}
                    >
                      <Table sx={{ fontFamily: "inherit" }}>
                        <TableHead sx={{ borderBottom: "0!important" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("ProjectName")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Proposals")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Status")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Cost")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Action")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {activeProjects?.length === 0 ? (
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                sx={{ textAlign: "center", py: 4 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                  }}
                                >
                                  <NodataFound />
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : (
                            activeProjects?.map((project, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:hover": { backgroundColor: "#f5f5f5" },
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  sx={{
                                    px: 5,
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleProjectPostedClick(project)
                                  }
                                >
                                  {project.title}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectPostedClick(project)
                                  }
                                >
                                  {project.proposalCount}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectPostedClick(project)
                                  }
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "#2bb95e",
                                      color: "#fff",
                                      borderRadius: 10,
                                      p: 2,
                                      width: "auto",
                                      maxWidth: "fit-content",
                                      py: 1,
                                      fontWeight: "400",
                                    }}
                                    onClick={() =>
                                      handleProjectPostedClick(project)
                                    }
                                  >
                                    {!project?.isAwarded
                                      ? project.status === "1"
                                        ? "active"
                                        : ""
                                      : "Waiting for approval"}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectPostedClick(project)
                                  }
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Box sx={{ px: 2 }}></Box>
                                    <Typography
                                      variant="small"
                                      sx={{ color: "#000", fontWeight: 300 }}
                                    >
                                      ${project.budget.min || 0} - $
                                      {project.budget.max || 0}{" "}
                                      {/* {project.currency} */}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => showEditProjects(project)}
                                  >
                                    <img
                                      src={EditIcon}
                                      alt="Edit"
                                      style={{ width: 33, height: 33 }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleOpenLogoutModal(project)
                                    }
                                  >
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      style={{ width: 33, height: 33 }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CustomPagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </Box>
                    </TableContainer>
                  )}
                  {tabValue === 1 && (
                    <TableContainer
                      component={Paper}
                      style={{ overflowX: "auto" }}
                    >
                      <Table sx={{ fontFamily: "inherit" }}>
                        <TableHead sx={{ borderBottom: "0!important" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("ProjectName")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Proposals")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Status")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Cost")}
                            </TableCell>
                            {/* <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Action")}
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ongoingProjects?.length === 0 ? (
                            // <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                            //   No Data Found
                            // </Box>
                            <Box
                              sx={{
                                py: 2,
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <NodataFound />
                            </Box>
                          ) : (
                            ongoingProjects?.map((project, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:hover": { backgroundColor: "#f5f5f5" },
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  sx={{
                                    px: 5,
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleProjectOngoingClick(project)
                                  }
                                >
                                  {project.title}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectOngoingClick(project)
                                  }
                                >
                                  {project.proposalCount}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectOngoingClick(project)
                                  }
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "#2bb95e",
                                      color: "#fff",
                                      borderRadius: 10,
                                      p: 2,
                                      width: "auto",
                                      maxWidth: "fit-content",
                                      py: 1,
                                      fontWeight: "400",
                                    }}
                                  >
                                    {project.status === "2" ? "ongoing" : ""}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                  onClick={() =>
                                    handleProjectOngoingClick(project)
                                  }
                                >
                                  ${project?.estimatedBudget?.name || 0}
                                </TableCell>
                                {/* <TableCell
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    fontFamily: "inherit",
                                  }}
                                >
                                  - */}
                                  {/* <IconButton
                                    onClick={() => showEditProjects(project)}
                                  >
                                    <img
                                      src={EditIcon}
                                      alt="Edit"
                                      style={{ width: 33, height: 33 }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleOpenLogoutModal(project)
                                    }
                                  >
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      style={{ width: 33, height: 33 }}
                                    />
                                  </IconButton> */}
                                {/* </TableCell> */}
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CustomPagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </Box>
                    </TableContainer>
                  )}
                  {tabValue === 2 && (
                    <TableContainer
                      component={Paper}
                      style={{ overflowX: "auto" }}
                    >
                      <Table sx={{ fontFamily: "inherit" }}>
                        <TableHead sx={{ borderBottom: "0!important" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("ProjectName")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Proposals")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Status")}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 600,
                                fontSize: 18,
                                borderBottom: "0!important",
                                fontFamily: "inherit",
                              }}
                            >
                              {t("Cost")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            // isLoading ?
                            //   ""
                            //   :
                            completedProjects?.length === 0 ? (
                              // <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                              //   No Data Found
                              // </Box>
                              <Box
                                sx={{
                                  py: 2,
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <NodataFound />
                              </Box>
                            ) : (
                              completedProjects?.map((project, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:hover": { backgroundColor: "#f5f5f5" },
                                  }}
                                  onClick={() =>
                                    handleProjectCompletedClick(project)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <TableCell
                                    sx={{
                                      px: 5,
                                      fontWeight: 500,
                                      fontSize: 16,
                                      fontFamily: "inherit",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {project.title}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: 16,
                                      fontFamily: "inherit",
                                    }}
                                  >
                                    {project.proposalCount}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: 16,
                                      fontFamily: "inherit",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: "#2bb95e",
                                        color: "#fff",
                                        borderRadius: 10,
                                        p: 2,
                                        width: "auto",
                                        maxWidth: "fit-content",
                                        py: 1,
                                        fontWeight: "400",
                                      }}
                                    >
                                      {project.status === "0"
                                        ? "completed"
                                        : ""}
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      fontWeight: 500,
                                      fontSize: 16,
                                      fontFamily: "inherit",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Box sx={{ px: 2 }}>{project.cost}</Box>
                                      <Typography
                                        variant="small"
                                        sx={{ color: "#000", fontWeight: 300 }}
                                      >
                                        ${project?.estimatedBudget?.name || 0}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))
                            )
                          }
                        </TableBody>
                      </Table>
                      <Box
                        sx={{
                          py: 2,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CustomPagination
                          count={totalPages}
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </Box>
                    </TableContainer>
                  )}
                </Box>
              </Box>
            )}
          {selectedProjectPosted && (
            <>
              <PostedProjectDetails
                project={selectedProjectPosted}
                onBack={handleBack}
                updateProject={updateProject}
              />
            </>
          )}
          {selectedProjectOngoing && (
            <OngoingProjectDetails
              project={selectedProjectOngoing}
              onBack={handleBack}
            />
          )}
          {selectedProjectCompleted && (
            <OngoingProjectDetails
              project={selectedProjectCompleted}
              onBack={handleBack}
              isCompleted={true}
            />
          )}

          {editProject && "Hello edit projects"}
        </section>
        <LogoutModal
          open={logoutModalOpen}
          handleClose={handleCloseLogoutModal}
        />
      </>
      {editProject && (
        <EmployeerEditProject selectedProject={selectedProject} />
      )}
    </>
  );
}
