import { Box, Typography, FormLabel, Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./MobileVerificationEmployee.css";
import {
  isValidPhoneNumber,
  LoaderHide,
  LoaderShow,
} from "../../../../../Helpers/Utility";
import { postAPI } from "../../../../../Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import { auth } from "../../../../Firebase/firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithCredential,
} from "firebase/auth";

export default function MobileVerificationEmployee(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const appVerifier = useMemo(() => props.appVerifier, [props]);
  // const [appVerifier, setAppVerifier] = useState(false);
  const { t } = useTranslation();

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberError("");
  };

  const getPhoneNumberExistance = async () => {
    try {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
        role: "employee",
      };
      const response = await postAPI("user/checkExistence", dataToSend);
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSendCode = async () => {
    const pnoneNumberExists = await getPhoneNumberExistance();

    if (pnoneNumberExists?.userStatus == "Suspended") {
      toast.error("This account has been suspended!");
      return;
    }
    if (pnoneNumberExists?.userStatus == "Inactive") {
      toast.error("This account has been Deactivated!");
      return;
    }
    if (pnoneNumberExists === undefined) {
      return;
    }

    if (!phoneNumber) {
      setPhoneNumberError("Please enter phone number");
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError("Please enter valid phone number");
    } else {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
      };
      LoaderShow();
      try {
        // const response = await postAPI('auth/send-otp', dataToSend);
        // console.log('Post response:===', response.otp);

        // if (response.statusCode === 200) {
        //   localStorage.setItem("mobile", response.otp);
        //   localStorage.setItem("phoneNumber", phoneNumber);
        //   setPhoneNumberError('');
        //   props.SetEmployeeUserDataObject('mobileVerificationDataEmployee', dataToSend);
        //   props.handleNextStepEmployee(); // Proceed to the next step
        //   toast.success(response.message);
        // } else {
        //   toast.error(response.message);
        // }

        let newAppVerifier;
        if (!appVerifier) {
          newAppVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved - will proceed with submit function
            },
            "expired-callback": () => {
              // Response expired. Ask user to solve reCAPTCHA again.
            },
          });
          // setAppVerifier(newAppVerifier);
        }

        const response = await signInWithPhoneNumber(
          auth,
          "+" + phoneNumber,
          appVerifier || newAppVerifier
        );

        if (response !== undefined) {
          localStorage.setItem("mobile", response.otp);
          localStorage.setItem("phoneNumber", phoneNumber);
          localStorage.setItem("optconfirm", JSON.stringify(response));
          setPhoneNumberError("");
          const dataToSendNextPage = {
            response: response,
            phoneNumber: `+${phoneNumber}`,
          };
          props.SetEmployeeUserDataObject(
            "mobileVerificationDataEmployee",
            dataToSendNextPage
          );
          props.handleNextStepEmployee(); // Proceed to the next step
          toast.success(response.message);
        }
      } catch (error) {
        if (error.message.includes("auth/too")) {
          toast.error(
            "Too many request sent at one time. Please try again later!"
          );
        } else if (error.message.includes("invalid")) {
          toast.error("Invalid request");
        } else if (error.message.includes("Firebase")) {
          toast.error("Something went wrong!!!");
        } else {
          toast.error(error.message);
        }
      }
      LoaderHide();
      // setPhoneNumberError('');
      // props.SetEmployeeUserDataObject('mobileVerificationDataEmployee', dataToSend);
      // props.handleNextStepEmployee(); // Proceed to the next step
    }
  };

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={{
          background: isFocused ? "#FFFFFF" : "transparent",
          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
          boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
      <span style={{ marginLeft: "5px", fontSize: "16px" }}>
        {phoneNumber ? `(+${phoneNumber.replace(/\D/g, "")})` : ""}
      </span>
    </div>
  );

  return (
    <Box
      className="sideBg"
      sx={{
        p: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 700, pb: 1 }}>
          {t("Please")}{" "}
        </Typography>
        <p className="beginText">{t("FoPurpose")}</p>
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <div>
            <FormLabel
              sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
            >
              {" "}
              {t("Phone")}
            </FormLabel>
            <PhoneInput
              country={"us"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              inputComponent={renderInput}
            />
            {phoneNumberError && (
              <Typography variant="body2" sx={{ color: "red" }}>
                {phoneNumberError}
              </Typography>
            )}
          </div>
          <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
            {/* <div id="recaptcha-container"></div> */}
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              onClick={handleSendCode}
              // onClick={props.handleNextStepEmployee}
              className="SignUpBtn"
              sx={{ textTransform: "capitalize", width: 100, color: "white" }}
            >
              {t("SendCode")}
            </Button>
          </Box>
          <Box sx={{ my: 1 }}>
            <FormLabel
              sx={{
                display: "flex",
                textAlign: "center",
                py: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
              }}
            >
              <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
              {t("YourInfo")}
            </FormLabel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
