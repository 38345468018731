import React from "react";
import { Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const TrustAndSafety = () => {
  const { t } = useTranslation();
  return (
    <section className="mainSection">
      <Container maxWidth="xl" sx={{ px: { md: 8, xl: 4, xxl: 4 }, py: 3 }}>
        <Box sx={{ py: 2 }}>
          <Box className="terms-main" sx={{ py: 2 }}>
            <h4 className="terms-heading">{t("trustAndSafety")}</h4>
          </Box>
        </Box>

        <Box
          className="additional-content"
          style={{
            backgroundColor: "#fff",
            borderRadius: "20px",
            marginTop: "0rem",
            padding: "2rem",
          }}
        >
          <h2 style={{ marginBottom: "1rem" }}>{t("trustAndSafety1")}</h2>
          <p style={{ marginBottom: "1rem" }}>{t("trustAndSafety2")}</p>
          <p>{t("trustAndSafety3")}</p>
          <ul>
            <li style={{ marginBottom: "1rem" }}>
              <strong>{t("trustAndSafety4")}</strong> {t("trustAndSafety5")}
            </li>
            <li>
              <strong>{t("trustAndSafety6")}</strong> {t("trustAndSafety7")}
            </li>
          </ul>
          <p style={{ marginBottom: "1rem" }}>{t("trustAndSafety8")}</p>
          <p>{t("trustAndSafety9")}</p>
          <ul>
            <li style={{ marginBottom: "1rem" }}>
              <strong>{t("trustAndSafety10")}</strong> {t("trustAndSafety11")}
            </li>
            <li>
              <strong>{t("trustAndSafety12")}</strong> {t("trustAndSafety13")}
            </li>
          </ul>
          <h2 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            {t("trustAndSafety14")}
          </h2>
          <p>{t("trustAndSafety15")}</p>
          <ul>
            <li style={{ marginBottom: "1rem" }}>
              <strong>{t("trustAndSafety16")}</strong> {t("trustAndSafety17")}
            </li>
            <li style={{ marginBottom: "1rem" }}>
              <strong>{t("trustAndSafety18")}</strong> {t("trustAndSafety19")}
            </li>
            <li>
              <strong>{t("trustAndSafety20")}</strong> {t("trustAndSafety21")}
            </li>
          </ul>
        </Box>
      </Container>
    </section>
  );
};

export default TrustAndSafety;
