import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Menu,
  Typography,
  IconButton,
  Rating,
  InputBase,
  Accordion,
  FormLabel,
  Modal,
  AccordionSummary,
  AccordionDetails,
  Radio,
  useMediaQuery,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  Avatar,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import BrowseJobsBanner from "../../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import loader from "../../../Assets/Images/loader.svg";
import { useNavigate } from "react-router-dom";
import {
  getCountryCode,
  GetUserDetails,
  getUserDetailsFromAPI,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import indiaIco from "../../../Assets/Images/indiaFlag.png";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import FilterModal from "./FilterModal/FilterModal";
import NodataFound from "../../../Components/NodataFound/NodataFound.jsx";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import Dropdown from "../../../Components/Dropdown/Dropdown.jsx";
import { countries } from "../../../Helpers/common_constants.js";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  addDoc,
  collection,
  getDocs,
  where,
  query as firebaseQuery,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase.js";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "#F7F7F7",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function EmployeerBrowseProfileDashboard() {
  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default

  const [anchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [rateType, setRateType] = useState(""); // Define rateType state
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(t("Choose"));
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [isInSearch, setIsInSearch] = useState(false);
  const [showCustomPagination, setShowCustomPagintaion] = useState(true);

  const handleFilterModalOpen = () => {
    setExpanded("panel1");
    setRateType("");
    if (!isfilterApplied) setFormData({});
    setFilterModalOpen(true);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };
  const [formData, setFormData] = useState({});
  const [isfilterApplied, setIsFilterApplied] = useState(false);

  const handleBudget = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      perHourAmount: value,
    }));
    // getFilteredEmployeeList();
  };

  const handleLocation = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: value,
    }));
    // getFilteredEmployeeList();
  };

  const budgetValues = [10, 20, 30, 40, 50, 60, 70, 80, 90];

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    if (isfilterApplied) {
      setFormData({});
      setShowCustomPagintaion(false);
      await getEmployeeList(1, "", query, "", "");
      setShowCustomPagintaion(true);
      setIsFilterApplied(false);
    }
    handleFilterModalClose();
  };

  // useEffect(() => {
  //   getFilteredEmployeeList();
  // }, [formData]);

  useEffect(() => {
    getEmployeeList();
    // LoaderHide();
  }, []);

  const navigate = useNavigate();

  const onfilterClicked = async () => {
    setIsFilterApplied(true);
    handleFilterModalClose();
    setShowCustomPagintaion(false);
    getEmployeeList();
    setShowCustomPagintaion(true);
  };

  const getEmployeeList = async (
    pageNumber = 1,
    perHourAmount = formData?.perHourAmount,
    search = query,
    location = formData?.location,
    rateType = formData?.rateType
  ) => {
    LoaderShow();
    try {
      LoaderShow();
      const token = GetUserDetails().token;

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const response = await getAPI(
        `user/list?page=${pn}&search=${search || ""}&rateType=${
          rateType || ""
        }&location=${location || ""}&perHourAmount=${perHourAmount || ""}`,
        token
      );
      if (response.statusCode === 200) {
        LoaderHide();
        setEmployeeList(response.data);
        setTotalPages(response?.pagination?.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getEmployeeList(value);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setFormData((prevData) => {
      return { ...prevData, rateType: event.target.value };
    });
    // getFilteredEmployeeList();
  };

  const navigateToPraposeEmployer = (user) => {
    navigate("/dashboard/employer/reviewratings", {
      state: {
        user,
      },
    });
  };

  const [query, setQuery] = useState("");

  const onSearchKeyDown = (ev) => {
    if (ev.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (!query.trim()) {
      // toast.error("Please add text");
      return;
    }
    setIsInSearch(true);
    setShowCustomPagintaion(false);
    getEmployeeList();
    setShowCustomPagintaion(true);
  };

  const handleAllClear = () => {
    setQuery("");
    document.getElementById("search-query").value = "";
    setFormData({
      perHourAmount: "",
      location: "",
      rateType: "",
    });
    if (isInSearch || isfilterApplied) {
      setShowCustomPagintaion(false);
      getEmployeeList(1, "", "", "", "");
      setShowCustomPagintaion(true);
    }
    setIsInSearch(false);
    setIsFilterApplied(false);
  };

  const handleSearchClear = () => {
    if (query) {
      setQuery("");
      document.getElementById("search-query").value = "";
      if (isInSearch) {
        setShowCustomPagintaion(false);
        getEmployeeList(
          1,
          formData?.perHourAmount,
          "",
          formData?.location,
          formData?.rateType
        );
        setShowCustomPagintaion(true);
      }
      setIsInSearch(false);
    }
  };

  const getFilteredEmployeeList = async () => {
    try {
      // const dataToSend = { rateType: filters }
      if (Object.keys(formData).length <= 0) {
        // handleFilterModalClose();
        return;
      }
      LoaderShow();
      // const dataToSend = {
      //   rateType: filters,
      // };
      const response = await postAPI("user/filter", formData);
      if (response.statusCode === 200) {
        LoaderHide();
        setEmployeeList(response.data);
        setTotalPages(response?.pagination?.totalPages);
        setIsFilterApplied(true);
        handleFilterModalClose();
      }
    } catch (error) {
      LoaderHide();
      handleFilterModalClose();
      // toast.error(error.message);
      setEmployeeList([]);
      // Handle error (e.g., show a toast message)
      console.error("Error fetching filtered employees:==", error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const createRoom = async (ev, user) => {
    ev.stopPropagation();
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      const userResponse = await getUserDetailsFromAPI();
      if (
        userResponse.statusCode == 200 &&
        userResponse?.data?.firebaseUID &&
        user?.firebaseUID
      ) {
        const roomRef = collection(db, "rooms");
        const roomQuery = firebaseQuery(
          roomRef,
          where(
            "userIds",
            "==",
            [userResponse.data.firebaseUID, user.firebaseUID].sort()
          )
        );
        const response = await getDocs(roomQuery);
        if (response.docs.length > 0) {
          navigate(
            `/dashboard/employer/message?room_id=${response.docs[0].id}`
          );
        } else {
          const data = await addDoc(collection(db, "rooms"), {
            userIds: [userResponse.data.firebaseUID, user.firebaseUID].sort(),
            createdAt: Timestamp.now(),
            imageUrl: null,
            metadata: null,
            name: null,
            type: "direct",
            updatedAt: Timestamp.now(),
            userRoles: null,
          });
          navigate(`/dashboard/employer/message?room_id=${data?.id}`);
        }
      } else {
        toast.error("Chat not found");
        LoaderHide();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="messageEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{}}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("BrowseProf")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>
      <Box sx={{ py: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <InputBase
              id="search-query"
              placeholder={t("SearchText")}
              startAdornment={<SearchIcon sx={{ fontSize: 25 }} />}
              endAdornment={
                query.length > 0 ? (
                  <CloseIcon
                    onClick={() => handleSearchClear()}
                    sx={{ fontSize: 25, cursor: "pointer" }}
                  />
                ) : (
                  ""
                )
              }
              value={query}
              classes={{ input: "noFocusBorder" }}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={onSearchKeyDown}
              sx={{
                FontFace: "inherit",
                backgroundColor: "#fff",
                border: 0,
                borderRadius: 5,
                padding: "8px 12px",
                width: "400px",
              }}
            />
            <Button
              sx={{
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 5,
                py: 1,
                ml: 2,
                mb: 3,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              onClick={handleSearch}
            >
              {t("SearchText")}
            </Button>
            <Button
              sx={{
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                py: 1,
                ml: 2,
                mb: 3,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              onClick={handleAllClear}
            >
              <RefreshIcon />
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleFilterModalOpen}
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                textTransform: "capitalize",
                bgcolor: "#0a3114",
                fontSize: 17,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              startIcon={<FilterAltOutlinedIcon color="#fff" />}
            >
              {t("Filter")}
            </Button>{" "}
          </Box>
        </Box>
      </Box>
      <Grid item md={8} lg={8} xl={8} xs={12}>
        <Box>
          <Box>
            <Box
              sx={{
                // backgroundColor: "#fff",
                py: 3,
                borderRadius: 3,
              }}
            >
              <Box
                className="filterTop"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // borderBottom: 1,
                  // borderColor: "#dadbda",
                  p: 2.5,
                  pt: 0,
                  pb: 1.5,
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    className="langText  "
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("Top")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className="langText "
                    variant="p"
                    sx={{
                      color: "#000",
                      fontSize: 17,
                      fontWeight: 500,
                      display: "block",
                      px: 2,
                    }}
                  >
                    {/* Sort by */}
                  </Typography>
                  {/* <div>
                    <Button
                      className="filterDrop"
                      sx={{
                        backgroundColor: "#F7F7F7!important",
                        color: "#000",
                        fontSize: 17,
                        textTransform: "capitalize",
                        borderRadius: 12,
                      }}
                      id="demo-customized-button1"
                      aria-controls={open ? "demo-customized-menu1" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Latest
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu1"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button1",
                      }}
                      anchorEl={anchorEl1}
                      open={open1}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose} disableRipple>
                        Default
                      </MenuItem>
                      <MenuItem onClick={handleClose} disableRipple>
                        Duplicate
                      </MenuItem>
                    </StyledMenu>
                  </div> */}
                </Box>
              </Box>

              {/* <Box
                className="flexColumnRespo"
                sx={{
                  borderTop: 1,
                  borderColor: "#dadbda",
                  p: 2.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={AvatarJob}
                      className="responsive-image avatarImg"
                      alt="dfh"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      px: 2,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h5"
                        className="userNameText"
                        sx={{ color: "#000", fontWeight: 500 }}
                      >
                        John Doe
                      </Typography>
                      <Typography
                        variant="h5"
                        className="userNameText"
                        sx={{ color: "#222", fontWeight: 400, px: 2 }}
                      >
                        @johndoe
                      </Typography>
                    </Box>
                    <Box
                      className="flexWrapResp"
                      sx={{
                        display: "flex",
                        py: 1,
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box sx={{ mx: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={chatIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          200
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          py: 1,
                          alignItems: "center",
                        }}
                      >
                        <img src={dollarIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          8.7
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          py: 1,
                          alignItems: "center",
                        }}
                      >
                        <img src={indiaIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          India
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="" sx={{ display: "flex", py: 0.5 }}>
                      <Typography
                        variant="h6"
                        className="langText skillText"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                          pl: 0,
                        }}
                      >
                        UI/UX Designer
                      </Typography>
                      <Typography
                        variant="h6"
                        className="langText skillText"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Illustrations Designer
                      </Typography>
                      <Typography
                        variant="h6"
                        className="langText skillText"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Figma Expert
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="flexDirectionRowResp catBtnTop"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    className="userNameText "
                    sx={{ color: "#000", px: 2, fontWeight: 500 }}
                  >
                    $150
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#000", px: 2, fontWeight: 500 }}
                  >
                    Per Hour
                  </Typography>
                  <Box sx={{ pt: 2 }}>
                    {isFavorite ? (
                      <FavoriteIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={handleFavoriteClick}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        sx={{ color: "#000", cursor: "pointer" }}
                        onClick={handleFavoriteClick}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <Grid container spacing={2} className="flexColumnRespo">
                <Grid item xs={12} xl={8} lg={8} md={8}>
                  <Box
                    sx={{
                      p: 2.5,
                      pt: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: 400,
                        color: "#222",
                        fontSize: 16,
                      }}
                    >
                      I'm seeking an experienced and efficient app developer to
                      create an innovative social networking application for iOS
                      platform. Details about the target audience are currently
                      confidential. Since the completion I'm seeking an
                      experienced and efficient app developer to create more....
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                    ml: "auto",
                  }}
                >
                  <Box sx={{ p: 2.5, pt: 0 }}>
                    <Button
                      component={Link} // Use Link component
                      to="/reviewratings" // Specify the target path
                      sx={{
                        display: "flex",

                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                    >
                      Contact
                    </Button>
                  </Box>
                </Grid>
              </Grid> */}

              {employeeList?.length === 0 ? (
                <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
                  <NodataFound />
                </Box>
              ) : (
                employeeList?.map((user, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#fff",
                      my: 4,
                      py: 1,
                      borderRadius: 10,
                      border: 1,
                      borderColor: "#e3e3e3",
                      cursor: "pointer",
                    }}
                    onClick={() => navigateToPraposeEmployer(user)}
                  >
                    <Box
                      className="flexColumnRespo"
                      sx={{
                        p: 2.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                            src={user?.profile || AvatarJob}
                            className="responsive-image avatarImg"
                            alt={user?.firstName}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            px: 2,
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              variant="h5"
                              className="userNameText"
                              sx={{ color: "#000", fontWeight: 500 }}
                            >
                              {user?.firstName}
                            </Typography>
                            <Typography
                              variant="h5"
                              className="userNameText"
                              sx={{ color: "#222", fontWeight: 400, px: 2 }}
                            >
                              {user?.email}
                            </Typography>
                          </Box>
                          <Box
                            className="flexWrapResp"
                            sx={{
                              display: "flex",
                              py: 1,
                              alignItems: "center",
                            }}
                          >
                            <Rating
                              disabled
                              name="hover-feedback"
                              value={user?.averageRating}
                              precision={0.5}
                              getLabelText={getLabelText}
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                              onChangeActive={(event, newHover) => {
                                setHover(newHover);
                              }}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                            />
                            {user?.averageRating !== null && (
                              <Box sx={{ mx: 2 }}>{user?.averageRating}</Box>
                            )}

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img src={chatIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                {user?.totalComments || "0"}
                              </Typography>
                            </Box>
                            {/* <Box
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              <img src={dollarIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                8.7
                              </Typography>
                            </Box> */}
                            <Box
                              sx={{
                                display: "flex",
                                py: 1,
                                alignItems: "center",
                              }}
                            >
                              {user?.location &&
                              getCountryCode(user.location) ? (
                                <img
                                  width={25}
                                  height={25}
                                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                    user.location
                                  )}.svg`}
                                  alt="chat"
                                />
                              ) : (
                                ""
                              )}
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                {user.location}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className=""
                            sx={{ display: "flex", flexWrap: "wrap", py: 0.5 }}
                          >
                            {user?.skills.map((skill, index) => (
                              <Typography
                                variant="h6"
                                className="langText skillText"
                                key={skill?._id}
                                sx={{
                                  color: "#222",
                                  fontSize: 18,
                                  borderRight: 1,
                                  fontWeight: 500,
                                  px: 2,
                                  my: 1,
                                  pl: 0,
                                  mr: 2,
                                }}
                              >
                                {skill?.name}
                              </Typography>
                            ))}
                            {/* <Typography
                                variant="h6"
                                className="langText skillText"
                                sx={{
                                  color: "#222",
                                  fontSize: 18,
                                  borderRight: 1,
                                  fontWeight: 500,
                                  px: 2,
                                }}
                              >
                                Illustrations Designer
                              </Typography>
                              <Typography
                                variant="h6"
                                className="langText skillText"
                                sx={{
                                  color: "#222",
                                  fontSize: 18,
                                  fontWeight: 500,
                                  px: 2,
                                }}
                              >
                                Figma Expert
                              </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="flexDirectionRowResp catBtnTop"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          className="userNameText "
                          sx={{ color: "#000", px: 2, fontWeight: 500 }}
                        >
                          ${user?.perHourAmount || 30}/Hr
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ color: "#000", px: 2, fontWeight: 500 }}
                        >
                          {user?.rateType}
                        </Typography>
                        {/* <Box sx={{ pt: 2 }}>
                            {isFavorite ? (
                              <FavoriteIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={handleFavoriteClick}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                sx={{ color: "#000", cursor: "pointer" }}
                                onClick={handleFavoriteClick}
                              />
                            )}
                          </Box> */}
                      </Box>
                    </Box>

                    <Grid container spacing={2} className="flexColumnRespo">
                      <Grid item xs={12} xl={8} lg={8} md={8}>
                        <Box
                          sx={{
                            p: 2.5,
                            pt: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 8 /* number of lines to show */,
                              lineClamp: 8,
                              WebkitBoxOrient: "vertical",
                            }}
                            sx={{
                              fontWeight: 400,
                              color: "#222",
                              fontSize: 16,
                            }}
                          >
                            {user?.aboutMe}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                          ml: "auto",
                        }}
                      >
                        <Box sx={{ p: 2.5, pt: 0 }}>
                          <Button
                            component={Link} // Use Link component
                            onClick={(ev) => createRoom(ev, user)}
                            sx={{
                              display: "flex",
                              textTransform: "capitalize",
                              bgcolor: "#0a3114",
                              fontSize: 16,
                              color: "#fff",
                              borderRadius: 10,
                              px: 5,
                              py: 1,
                              "&:hover": {
                                bgcolor: "#0a3114",
                              },
                            }}
                          >
                            {t("Contact2")}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          {employeeList.length > 0
            ? showCustomPagination && (
                <CustomPagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              )
            : ""}
        </Box>
        {/* <FilterModal
          open={filterModalOpen}
          handleClose={handleFilterModalClose}
        /> */}
        <Modal
          open={filterModalOpen}
          onClose={handleFilterModalClose}
          aria-labelledby="logout-modal"
          aria-describedby="logout-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
              {t("Filter")}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: 14,
                right: 10,
                pb: 4,
              }}
              onClick={handleFilterModalClose}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box sx={{ borderTop: 1, borderColor: "#dededf" }}>
              <Box>
                <div>
                  <StyledAccordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("ProjectType")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="rate-type"
                          name="rate-type"
                          value={formData.rateType}
                          onChange={handleRateTypeChange}
                        >
                          <FormControlLabel
                            value="Hourly Rate"
                            control={
                              <Radio sx={{ color: "#0B3013!important" }} />
                            }
                            label={t("Hourly")}
                          />
                          <FormControlLabel
                            value="Fixed Price"
                            control={
                              <Radio sx={{ color: "#0B3013!important" }} />
                            }
                            label={t("Fixed")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography variant="p" sx={{ color: "#000" }}>
                        {t("Budget")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography> */}
                      <Grid item xs={12} md={6}>
                        <Box>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1.4,
                              fontSize: 14,
                            }}
                          >
                            {t("Budget")}
                          </FormLabel>
                          <Dropdown
                            value={formData.perHourAmount || ""}
                            onChange={handleBudget}
                            options={budgetValues?.map((value) => ({
                              value,
                              label: `${value}`,
                            }))}
                            label={t("Budget")}
                          />
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                  <StyledAccordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      <Typography variant="p" sx={{ color: "#000" }}>
                        {t("Location")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography> */}
                      <Grid item xs={12} md={6}>
                        <Box>
                          {/* <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1.4,
                              fontSize: 14,
                            }}
                          >
                            Location
                          </FormLabel>
                          <Dropdown
                            value={formData.location}
                            onChange={handleLocation}
                            options={countries}
                            label="Location"
                          /> */}
                          <FormControl fullWidth>
                            <InputLabel id="gender-select-label">
                              {t("Country")}
                            </InputLabel>
                            <Select
                              labelId="gender-select-label"
                              id="gender-select"
                              value={formData?.location}
                              label={t("Country")}
                              onChange={handleLocation}
                            >
                              {countries?.map((option) => (
                                <MenuItem
                                  key={option.label}
                                  value={option.label}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>

                  <Box sx={{ my: 3, display: "flex" }}>
                    <Button
                      onClick={(ev) => onfilterClicked()}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                      endIcon={<ArrowOutwardIcon color="#fff" />}
                    >
                      {t("Filter")}
                    </Button>
                    <Button
                      onClick={handleClose}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                      endIcon={<ArrowOutwardIcon color="#fff" />}
                    >
                      {t("Clear")}
                    </Button>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </section>
  );
}
