import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useSearchParams } from "react-router-dom";
import Logo from "../../Assets/Images/Logo.png";
import catMenu from "../../Assets/Icons/menu-square.png";
import "./Header.css";
import CountryFlag from "../../Components/CountryFlag/CountryFlag";
import Signup from "../../Auth/Signup/Signup";
import Signin from "../../Auth/Signin/Signin";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
// import profile from "../../Assets/Images/DefaultProfile.png";
import { GetUserDetails } from "../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
// import caret from "../../Assets/Images/caret.png"; // Ensure you have a local USA flag image or use a URL
import loader from "../../Assets/Images/loader.svg";
import { Avatar, Modal, useMediaQuery } from "@mui/material";
import { getAPI, postAPI } from "../../Services/Api";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import logoutVect from "../../Assets/Images/LogoutVector.png";
import close from "../../Assets/Images/cancel-circle-half-dot.png";
import { signOut } from "firebase/auth";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  CHAT_ROLE,
  EMPLOYEE_ROLE,
  EMPLOYER_ROLE,
} from "../../Helpers/roles";

const pages = ["Home", "BrowseJobs", "Help", "Contact", "PricingFees"]; // Use keys from the translation files
const pages2 = [
  { name: "BrowseJobs", url: "/browsejobs" },
  { name: "Help", url: "/help" },
  { name: "Contact", url: "/contact" },
  { name: "PricingFees", url: "/pricingfees" },
]; // Use keys from the translation files

function Header() {
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [anchorElCat, setAnchorElCat] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  let location = useLocation();

  const navigate = useNavigate();

  const token = GetUserDetails();

  const [userDetails, setUserDetails] = useState("");

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const [projectData, setProjectData] = React.useState([]);
  React.useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setProjectData(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  useEffect(() => {
    getUserDetails();
    if (searchParams.get("is_signup")) {
      setJoinModalOpen(true);
    }
    if (
      searchParams.get("is_signin") ||
      searchParams.get("change_password_token")
    ) {
      setLoginModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (joinModalOpen) {
      setSearchParams({ is_signup: true });
    } else if (searchParams.get("is_signup")) {
      searchParams.delete("is_signup");
      setSearchParams(searchParams);
    }
  }, [joinModalOpen]);

  useEffect(() => {
    if (loginModalOpen) {
      setSearchParams({ is_signin: true });
    } else if (searchParams.get("is_signin")) {
      searchParams.delete("is_signin");
      setSearchParams(searchParams);
    }
  }, [loginModalOpen]);

  const getUserDetails = async () => {
    try {
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
      }
    } catch (error) {
      // toast.error(error.message)
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigateToDashboard = () => {
    if (token?.role.includes(ADMIN_ROLE)) {
      navigate("/dashboard/admin/dashboard");
      return;
    }
    if (token?.role.includes(EMPLOYEE_ROLE)) {
      navigate("/dashboard/employee");
      return;
    }
    if (token?.role.includes(EMPLOYER_ROLE)) {
      navigate("/dashboard/employer/browseprofiles");
      return;
    }
    if (token?.role.includes(ACCOUNTANT_ROLE)) {
      navigate("/dashboard/accountant/payments");
      return;
    }
    if (token?.role.includes(CHAT_ROLE)) {
      navigate("/dashboard/admin/chat");
      return;
    } else {
      navigate("/home");
    }
  };

  const handleClick = (event) => {
    setAnchorElCat(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElCat(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSignInButtonClick = () => {
    setLoginModalOpen(true);
  };

  const handleClickJoin = () => {
    setJoinModalOpen(true);
  };

  const openSignupModal = () => {
    setJoinModalOpen(true);
    setLoginModalOpen(false);
  };

  const openSigninModal = () => {
    setJoinModalOpen(false);
    setLoginModalOpen(true);
  };

  const handleCloseJoinModal = () => {
    setJoinModalOpen(false);
  };

  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };

  const openProfileMenu = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setProfileMenuAnchor(null);
  };

  const logoutUser = () => {
    setProfileMenuAnchor(null);
    try {
      postAPI("user/logout", {}, GetUserDetails().token);
      signOut();
    } catch (err) {
      console.log(err);
    }
    localStorage.removeItem("loginData");
    localStorage.removeItem("notif-token");
    localStorage.removeItem("seenNotification");
    sessionStorage.removeItem("userData");
    navigate("/home");
  };

  const LogoutModal = ({ open, handleClose }) => {
    // Add logout functionality here
    const handleLogout = async () => {
      try {
        await postAPI("user/logout", {}, GetUserDetails().token);
        await signOut();
      } catch (err) {
        console.log(err);
      }
      localStorage.removeItem("notif-token");
      localStorage.removeItem("loginData");
      localStorage.removeItem("seenNotification");
      sessionStorage.removeItem("userData");
      navigate("/");
      window.location.reload();
      handleClose();
    };

    const handleCloseModal = () => {
      handleClose(); // Close the modal after logout
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices

            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={handleClose}
          >
            <img src={close} alt="db" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={logoutVect} alt="db" />
            </Box>
            <Typography
              id="logout-modal-description"
              className="employeeHead1"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("Are")}
            </Typography>
            <Typography
              id="logout-modal-description"
              className="employeeHead"
              variant="p"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("SessionExpires")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <AppBar
      position={isSticky ? "fixed" : "static"}
      className={`HomeBannerBg ${isSticky ? "stickyHeader" : ""}`}
    >
      <LogoutModal
        open={logoutModalOpen}
        handleClose={handleCloseLogoutModal}
      />
      {location && location?.pathname === "/sendanoffer" ? (
        ""
      ) : location?.pathname === "/projectdetails" ? (
        ""
      ) : location?.pathname === "/reviewratings" ? (
        ""
      ) : location?.pathname === "/browsejobs" ? (
        ""
      ) : location?.pathname === "/home" ? (
        ""
      ) : location?.pathname === "/helpsupport" ? (
        ""
      ) : location?.pathname === "/contact" ? (
        ""
      ) : location?.pathname === "/services" ? (
        ""
      ) : location?.pathname === "/" ? (
        ""
      ) : (
        <div
          id="hideloding"
          className="loding-display"
          style={{ display: "none" }}
        >
          <img src={loader} alt="loader-img" />
        </div>
      )}

      <Container
        sx={{
          px: { md: 8, xl: 4, xxl: 4 },
        }}
        maxWidth="xl"
      >
        <Toolbar disableGutters className="mainHeader">
          <Link to="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img src={Logo} alt="Logo" className="" />
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages2
                .filter((pg) => (token ? pg.name != "BrowseJobs" : true))
                .map((page) => (
                  <Link
                    to={page.url}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Typography className="customTypography">
                        {t(page.name)}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
              {token ? (
                <>
                  <MenuItem onClick={navigateToDashboard}>
                    <Typography className="customTypography">
                      {t("dashboard")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={(ev) => setLogoutModalOpen(true)}>
                    <Typography className="customTypography">
                      {t("logout")}
                    </Typography>
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleSignInButtonClick}>
                    <Typography className="customTypography">
                      {t("signIn")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleClickJoin}>
                    <Typography className="customTypography">
                      {t("join")}
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={Logo} alt="Logo" className="" />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", alignItems: "center" },
            }}
          >
            <Button
              id="fade-button"
              aria-controls={Boolean(anchorElCat) ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElCat) ? "true" : undefined}
              onClick={handleClick}
              className="pageMenuHeader"
              sx={{ my: 2, color: "white" }}
            >
              <img src={catMenu} className="catmenu" alt="catmenu" />
              {t("CategoriesOne")}
            </Button>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              sx={{ mt: 1 }}
              anchorEl={anchorElCat}
              open={Boolean(anchorElCat)}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {projectData?.categories?.map((ele) => (
                <Link
                  key={ele?._id}
                  to="/browsejobs"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <MenuItem
                    sx={{ fontFamily: "inherit", textDecoration: "none" }}
                    onClick={handleClose}
                  >
                    {ele?.name}
                  </MenuItem>
                </Link>
              ))}
            </Menu>

            {pages2
              .filter((pg) => (token ? pg.name != "BrowseJobs" : true))
              .map((page) => {
                return (
                  <Button
                    className={
                      location.pathname == page.url
                        ? "catButton"
                        : "pageMenuHeader"
                    }
                    key={page.name}
                    component={Link}
                    to={page.url}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {t(page.name)}
                  </Button>
                );
              })}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                alignItems: "center",
                justifyContent: "end",
              },
            }}
          >
            {token ? (
              <section className="DashboardHeader">
                <Box sx={{ display: "flex", width: "100%", py: 0.4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                      boxShadow: "none !important",
                    }}
                  >
                    <Box sx={{ px: 1 }}>
                      <IconButton onClick={openProfileMenu}>
                        {/* <img src={profile} alt="profile" /> */}
                        {/* <img
                          src={caret}
                          className="filterCommonWhite"
                          alt="caret"
                        /> */}
                        <Avatar
                          alt="Profile Picture"
                          src={userDetails?.profileImageUrl}
                          sx={{
                            width: 50,
                            height: 50,
                            border: 1,
                            borderColor: "#ccc",
                          }}
                        />
                      </IconButton>
                      <Menu
                        anchorEl={profileMenuAnchor}
                        open={Boolean(profileMenuAnchor)}
                        onClose={closeProfileMenu}
                      >
                        <MenuItem>{token.firstName}</MenuItem>
                        <MenuItem onClick={navigateToDashboard}>
                          {t("Dashboard")}
                        </MenuItem>
                        <MenuItem onClick={(ev) => setLogoutModalOpen(true)}>
                          {t("Logout")}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              </section>
            ) : (
              <>
                <Button
                  className="SignInBtn"
                  onClick={handleSignInButtonClick}
                  sx={{ textTransform: "capitalize", color: "white" }}
                >
                  {t("SignIn")}
                </Button>
                <Button className="JoinBtn" onClick={handleClickJoin}>
                  {t("Join")}
                </Button>
              </>
            )}

            <CountryFlag />
          </Box>
        </Toolbar>
      </Container>
      <Signup
        open={joinModalOpen}
        handleClose={handleCloseJoinModal}
        toggleSignUpModal={openSigninModal}
      />
      <Signin
        open={loginModalOpen}
        handleClose={handleCloseLoginModal}
        toggleSignUpModal={openSignupModal}
      />
    </AppBar>
  );
}

export default Header;
