import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import loader from "../../Assets/Images/loader.svg";
import { useEffect, useState } from "react";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI } from "../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";

export default function WithdrawalHistory() {
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    getWithdrawalList();
  }, []);

  const getWithdrawalList = async (ev = {}, pagenumber = 1) => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI(`wallet/history?page=${pagenumber}`, token);
        if (res?.statusCode == 200) {
          setWithdrawalList(res?.data?.withdrawals);
          setTotalPages(res?.data?.pagination?.totalPages || 1);
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
  };

  function formatDateToDDMMYYYY(dt) {
    const date = new Date(dt);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const downloadInvoice = async (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    link.download = "invoice.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("withdrawalHistory")}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        {withdrawalList.length > 0 && (
          <Table
            aria-label="user management table"
            sx={{ borderCollapse: "separate", borderSpacing: "0" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("createdAt")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("amtPaid")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("fees")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("paymentMethod")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("Status")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {t("inovice")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawalList.map((withdrawal, index) => (
                <TableRow key={withdrawal._id}>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {withdrawal?.createdAt
                      ? formatDateToDDMMYYYY(withdrawal?.createdAt)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    ${withdrawal?.amount || 0}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    ${withdrawal?.fee || 0}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {withdrawal?.method || "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {withdrawal?.status ? (
                      <Chip
                        label={withdrawal?.status}
                        sx={{
                          cursor: "default",
                          textTransform: "capitalize",
                          py: 1.1,
                          px: 1,
                          backgroundColor: "#2ab95f",
                          color: "#fff",
                          borderRadius: "30px",
                          fontSize: "14px",
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === withdrawalList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {withdrawal?.invoiceURL ? (
                      <DescriptionIcon
                        onClick={(ev) =>
                          downloadInvoice(withdrawal?.invoiceURL)
                        }
                        title={t("inovice")}
                        style={{ color: "#0B3013", cursor: "pointer" }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {withdrawalList.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
            <CustomPagination count={totalPages} onChange={getWithdrawalList} />
          </Box>
        )}
        {withdrawalList.length <= 0 && <NodataFound />}
      </TableContainer>
    </section>
  );
}
