import React from "react";
import { Navigate } from "react-router-dom";
import EmployeerDashboard from "./EmployeerDashboardPages/EmployeerDashboard/EmployeerDashboard";
import EmployeeDashboard from "./EmployeeDashboardPages/EmployeeDashboard/EmployeeDashboard";
import { GetUserDetails } from "../Helpers/Utility";
import { EMPLOYEE_ROLE } from "../Helpers/roles";

// Assuming you have a way to determine the user's role, let's call it `userRole`
// const userRole = "employee"; // or "employer" based on actual user data

const userDetails = GetUserDetails();

export default function Dashboard() {
  if (userDetails?.role.includes(EMPLOYEE_ROLE)) {
    return <EmployeeDashboard />;
  } else if (userDetails?.role === "employer") {
    return <EmployeerDashboard />;
  } else {
    // Handle other roles or scenarios
    return <Navigate to="/signin" />;
  }
}
