import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Coin from "../../../../Assets/Images/Dumi.png";
import { postAPI } from "../../../../Services/Api";
import { GetUserDetails } from "../../../../Helpers/Utility";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CoinPayments = ({ open, onClose, coinsPayment, onComplete }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [timeLeft, setTimeLeft] = useState(0);
  const [hasClose, setHasClose] = useState(true);
  const [timer, setTimer] = useState(120);
  const [HasTimeOutStarted, setHasTimeOutStarted] = useState(false);
  const [response, setResponse] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (open) {
      setHasClose(true);
      setTimer(120);
    }
  }, [open]);

  useEffect(() => {
    if (timer <= 0) setHasClose(false);
  }, [timer]);

  useEffect(() => {
    const tt = setInterval(() => {
      setTimer((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(tt);
  }, []);

  useEffect(() => {
    if (coinsPayment?.timeout) {
      setTimeLeft(coinsPayment.timeout);
      if (coinsPayment?.timeout > 0) {
        setHasTimeOutStarted(true);
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer); // Clean up the interval on component unmount
      }
    }
  }, [coinsPayment]);

  useEffect(() => {
    if (HasTimeOutStarted && timeLeft <= 0) {
      setHasTimeOutStarted(false);
      toast.error("PAyment timeout, please try again!");
      onclose();
    }
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const formatedTimeLeft = useMemo(() => formatTime(timeLeft), [timeLeft]);
  const formatedTimerLeft = useMemo(() => formatTime(timer), [timer]);

  useEffect(() => {
    let intervalId;

    if (coinsPayment?.txn_id !== undefined) {
      fetchApiData(); // Call immediately on mount
      intervalId = setInterval(fetchApiData, 5000); // Call every 5 seconds
    }

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const fetchApiData = async () => {
    try {
      const response = await postAPI(
        "coinpayment/coinpayments-callback",
        {
          ipn_type: "api",
          ipn_id: coinsPayment?.txn_id ?? "",
        },
        GetUserDetails()?.token
      );
      if (response.message == "canceled") {
        toast.error("Payment canceled!");
        onClose();
      }
      if (response.message == "completed") {
        onComplete(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="new-modal-title"
        aria-describedby="new-modal-description"
      >
        <Box
          className="membership-grid1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box>
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="new-modal-title"
                sx={{
                  borderBottom: 1,
                  py: 2,
                  pt: 0,
                  mb: 1,
                  borderColor: "#E6E6E6",
                }}
                gutterBottom
              >
                {t("coinPayment")}
                <Button
                  color="success"
                  onClick={onClose}
                  disabled={hasClose}
                  sx={{ float: "right" }}
                >
                  {hasClose
                    ? `${t("closeIn")} ${formatedTimerLeft}`
                    : t("Close")}
                </Button>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={`${coinsPayment?.qrcode_url}`} alt="fi" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 1,
                }}
              >
                <Typography variant="p" sx={{ fontWeight: 500 }}>
                  {t("amt")}
                </Typography>
                <Typography variant="p" sx={{ fontWeight: 500 }}>
                  {coinsPayment?.amount} USDT
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="p"
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: 17,
                pt: 1,
              }}
            >
              {t("suffAmt")}
            </Typography>
            <Box sx={{ py: 3 }}>
              <InputLabel htmlFor="coin-input" sx={{ color: "#000" }}>
                {t("Address")}
              </InputLabel>
              <FormControl fullWidth={true} sx={{ mb: 2 }}>
                <OutlinedInput
                  id="coin-input"
                  value={coinsPayment?.address}
                  sx={{ fontFamily: "inherit!important", fontSize: 16 }}
                />
              </FormControl>
              <Typography
                variant="p"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 17,
                  py: 1,
                }}
              >
                {t("PaymentId")} : {coinsPayment?.txn_id}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 17,
                    py: 1,
                  }}
                >
                  {t("timeLeft")}:{" "}
                  <span style={{ color: "#000", paddingLeft: "2px" }}>
                    {formatedTimeLeft}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    color: "#D20000",
                    justifyContent: "center",
                    fontSize: 17,
                    pt: 4,
                    textAlign: "center",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  {t("Note")}:-
                  <span style={{ fontWeight: "bold" }}>{t("doNotReload")}</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CoinPayments;
