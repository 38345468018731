import React, {
  // useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { FaPaperclip } from "react-icons/fa";
// import Avatar from "react-avatar";
// import moreIco from "../../Assets/Images/moreIco.png";
// import caretDrop from "../../Assets/Images/caretDrop.png";
// import chatbotHeader from "../../Assets/Images/chatbotheader.png";
import SendIcon from "@mui/icons-material/Send";
import logo from "../../Assets/Images/Logo.png";
import "./ChatBotMessage.css";
import ChatCircleIcon from "./ChatCircleIcon";
import { GetUserDetails, getUserDetailsFromAPI } from "../../Helpers/Utility";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import { EMPLOYEE_ROLE, EMPLOYER_ROLE } from "../../Helpers/roles";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../Auth/Firebase/firebase";
import { decryptText, encryptText } from "../../Helpers/crypto";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
// import { FaPaperclip } from "react-icons/fa";
// import { FaXmark } from "react-icons/fa6";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFilePdf,
//   faFileWord,
//   faFileExcel,
//   faFileImage,
//   faFile,
//   faDownload,
// } from "@fortawesome/free-solid-svg-icons";

export default function ChatBotMessage() {
  const [room, setRoom] = useState("");
  const [currentUserDetail, setCurrentUserDetail] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [unseenMessage, setUnSeenMessages] = useState(0);
  const chatContainerRef = useRef(null);

  const fileRef = useRef(null);

  const handleFileChange = async (ev) => {
    try {
      const storage = getStorage();
      const file = ev.target.files[0];
      const validImgTypes = ["image/jpeg", "image/gif"];
      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      let messageData = {};
      if (validImgTypes.includes(file.type)) {
        messageData = {
          authorId: currentUserDetail.firebaseUID || "",
          createdAt: Timestamp.now(),
          name: file.name,
          type: "image",
          status: "sent",
          updatedAt: Timestamp.now(),
          uri: url,
          size: file.size,
          width: 500,
          height: 500,
        };
      } else {
        messageData = {
          authorId: currentUserDetail.firebaseUID || "",
          createdAt: Timestamp.now(),
          name: file.name,
          type: "file",
          status: "sent",
          updatedAt: Timestamp.now(),
          uri: url,
          mimeType: file.type,
          size: file.size,
        };
      }
      setDoc(
        doc(db, "supportChat", room),
        {
          updatedAt: Timestamp.now(),
          hasMsg: true,
        },
        { merge: true }
      );
      await addDoc(
        collection(db, "supportChat", room, "messages"),
        messageData
      );
      // sendNotif();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRoom();
  }, []);

  useEffect(() => {
    if (room) {
      const chatRef = collection(db, "supportChat", room, "messages");
      const q = query(chatRef, orderBy("createdAt"));
      const unsubscrib = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUnSeenMessages(
          data.filter(
            (ele) =>
              ele.authorId != "" &&
              ele.authorId != currentUserDetail?.firebaseUID &&
              ele.status == "sent"
          ).length
        );
        setMessages([
          {
            authorId: Symbol,
            status: "seen",
            text: "Welcome to WWH Chatbot",
            id: Symbol,
          },
          ...data.map((ele) => {
            if (ele?.text) ele.text = decryptText(ele.text);
            return ele;
          }),
        ]);
      });

      return () => unsubscrib();
    }
  }, [room]);

  const getRoom = async () => {
    try {
      const userDetails = GetUserDetails();
      if (userDetails?.userId) {
        const userResponse = await getUserDetailsFromAPI();
        if (userResponse.statusCode == 200 && userResponse?.data?.firebaseUID) {
          setCurrentUserDetail(userResponse?.data);
          const roomRef = collection(db, "supportChat");
          const roomQuery = query(
            roomRef,
            where("userId", "==", userResponse.data.firebaseUID)
          );
          const response = await getDocs(roomQuery);
          if (response.docs.length > 0) {
            setRoom(response.docs[0].id);
            const messagesCollection = collection(
              db,
              "supportChat",
              response.docs[0].id,
              "messages"
            );
            const messagesQuery = query(
              messagesCollection,
              where("status", "==", "sent"),
              where("authorId", "!=", userResponse?.data?.firebaseUID || "")
            );
            const messagesSnapshot = await getDocs(messagesQuery);
            setUnSeenMessages(messagesSnapshot.size);
          } else {
            const data = await addDoc(collection(db, "supportChat"), {
              userId: userResponse.data.firebaseUID,
              createdAt: Timestamp.now(),
              imageUrl: null,
              metadata: null,
              name: null,
              type: "direct",
              updatedAt: Timestamp.now(),
              userRoles: userDetails?.role,
              hasMsg: false,
            });
            setRoom(data?.id);
          }
        }
      } else {
        if (localStorage.getItem("roomId")) {
          setRoom(localStorage.getItem("roomId"));
          const messagesCollection = collection(
            db,
            "supportChat",
            localStorage.getItem("roomId"),
            "messages"
          );
          const messagesQuery = query(
            messagesCollection,
            where("status", "==", "sent"),
            where("authorId", "!=", "")
          );
          const messagesSnapshot = await getDocs(messagesQuery);
          setUnSeenMessages(messagesSnapshot.size);
        } else {
          const data = await addDoc(collection(db, "supportChat"), {
            userId: "",
            createdAt: Timestamp.now(),
            imageUrl: null,
            metadata: null,
            name: null,
            type: "direct",
            updatedAt: Timestamp.now(),
            userRoles: "",
            hasMsg: false,
          });
          setRoom(data?.id);
          localStorage.setItem("roomId", data?.id);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    if (isChatBotVisible) {
      setUnSeenMessages(0);
      messages.map((ele) => {
        if (
          ele?.status == "sent" &&
          ele.authorId != currentUserDetail?.firebaseUID &&
          ele.authorId != ""
        ) {
          setDoc(
            doc(db, "supportChat", room, "messages", ele.id),
            {
              status: "seen",
            },
            { merge: true }
          );
        }
      });
    }
  }, [isChatBotVisible, messages]);

  useEffect(() => {
    const userDetails = GetUserDetails();
    if (
      userDetails &&
      !userDetails.role.includes(EMPLOYEE_ROLE) &&
      !userDetails.role.includes(EMPLOYER_ROLE)
    ) {
      setShowChat(false);
      return;
    }
    setShowChat(true);
  }, []);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const toggleChatBotVisibility = () => {
    setIsChatBotVisible(!isChatBotVisible);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
      return;
    }
  };

  const downloadfile = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = url.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSendMessage = async () => {
    const timestamp = Timestamp.fromDate(new Date());

    if (newMessage.trim() !== "") {
      let messageData = {
        updatedAt: serverTimestamp(),
        authorId: currentUserDetail.firebaseUID || "",
        createdAt: timestamp,
        type: "text",
        status: "sent",
      };

      if (newMessage.trim() !== "") {
        messageData.text = encryptText(newMessage);
      }
      setDoc(
        doc(db, "supportChat", room),
        {
          updatedAt: Timestamp.now(),
          hasMsg: true,
        },
        { merge: true }
      );
      await addDoc(
        collection(db, "supportChat", room, "messages"),
        messageData
      );
      // sendNotif();
      setNewMessage(""); // Clear the input field
    }
  };

  return showChat ? (
    <>
      {unseenMessage > 0 && (
        <span
          style={{
            position: "fixed",
            bottom: "53px",
            fontSize: "14px",
            right: "18px",
            zIndex: 214748365,
            background: "red",
            color: "#fff",
            padding: "1px 5px",
            borderRadius: "100%",
            fontFamily: "monospace !important",
          }}
        >
          {unseenMessage}
        </span>
      )}
      <ChatCircleIcon onClick={toggleChatBotVisibility} />
      <div
        style={{
          ...styles.chatBotContainer,
          display: isChatBotVisible ? "flex" : "none",
        }}
      >
        <div className="chatbot-header p-3 pb-2">
          <div className="d-flex align-items-center">
            <div>
              <img
                src={logo}
                className="img-fluid"
                alt="header"
                width={40}
                height={40}
                style={{
                  borderRadius: "100%",
                  float: "left",
                  paddingLeft: "4px",
                }}
              />
              <CloseIcon
                onClick={(ev) => setIsChatBotVisible(false)}
                style={{
                  cursor: " pointer",
                  borderRadius: "100%",
                  float: "right",
                  paddingRight: "4px",
                }}
              />
            </div>
            <div className="ps-3">
              <div className="fw-medium font-inherit">Customer Support</div>
              <div className="text-start fw-light">Ask us anything!</div>
            </div>
            <div className="d-flex ms-auto">
              {/* <div className="px-2">
                  <img
                    src={moreIco}
                    className="cursor-pointer img-fluid"
                    alt="more"
                  />
                </div> */}
              <div className="px-2" onClick={toggleChatBotVisibility}>
                {/* <img
                    src={caretDrop}
                    className="cursor-pointer img-fluid"
                    alt="caret"
                  /> */}
              </div>
            </div>
          </div>
        </div>
        <div
          ref={chatContainerRef}
          id="floatingMsgContainer"
          style={{
            ...styles.inputContainer,
            flexGrow: 1,
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingRight: "5px",
              overflowX: "hidden",
            }}
          >
            {messages.map((msg, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  justifyContent: `${
                    msg?.authorId === currentUserDetail?.firebaseUID ||
                    msg?.authorId == ""
                      ? "flex-end"
                      : "flex-start"
                  }`, // Align the receiver div to the right
                }}
                className="receiverDiv" // Add a className for styling purposes
              >
                <Box
                  sx={{
                    maxWidth: "70%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                      msg?.authorId === currentUserDetail?.firebaseUID ||
                      msg?.authorId == ""
                        ? "end"
                        : "start",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: `${
                        msg?.authorId === currentUserDetail.firebaseUID ||
                        msg?.authorId == ""
                          ? "#86BB71"
                          : "#94C2ED"
                      }`,
                      color: "#fff",
                      p: 2,
                      marginBottom: "10px",
                      borderRadius: 2,
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        color: "#fff",
                        fontSize: 15,
                        overflowWrap: "break-word",
                        wordBreak: "break-word",
                        textAlign:
                          msg?.authorId === currentUserDetail.firebaseUID ||
                          msg?.authorId == ""
                            ? "right"
                            : "left",
                        display: "block",
                      }}
                    >
                      <div
                        key={msg?.id}
                        className={`message ${
                          msg?.authorId === currentUserDetail.firebaseUID ||
                          msg?.authorId == ""
                            ? "sender"
                            : "receiver"
                        }`}
                      >
                        {msg?.uri ? (
                          <div>
                            {msg?.type == "image" ? (
                              <img
                                onClick={(ev) => downloadfile(msg?.uri)}
                                style={{ width: "100%", cursor: "pointer" }}
                                src={msg?.uri}
                                alt={msg?.name}
                              />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={(ev) => downloadfile(msg?.uri)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 100 100"
                                  width="90px"
                                  height="90px"
                                >
                                  <path
                                    fill="#fefdef"
                                    d="M29.614,12.307h-1.268c-4.803,0-8.732,3.93-8.732,8.732v61.535c0,4.803,3.93,8.732,8.732,8.732h43.535c4.803,0,8.732-3.93,8.732-8.732v-50.02C72.74,24.68,68.241,20.182,60.367,12.307H41.614"
                                  />
                                  <path
                                    fill="#1f212b"
                                    d="M71.882,92.307H28.347c-5.367,0-9.732-4.366-9.732-9.732V21.04c0-5.367,4.366-9.732,9.732-9.732h1.268c0.552,0,1,0.448,1,1s-0.448,1-1,1h-1.268c-4.264,0-7.732,3.469-7.732,7.732v61.535c0,4.264,3.469,7.732,7.732,7.732h43.535c4.264,0,7.732-3.469,7.732-7.732V32.969L59.953,13.307H41.614c-0.552,0-1-0.448-1-1s0.448-1,1-1h18.752c0.265,0,0.52,0.105,0.707,0.293l20.248,20.248c0.188,0.188,0.293,0.442,0.293,0.707v50.02C81.614,87.941,77.248,92.307,71.882,92.307z"
                                  />
                                  <path
                                    fill="#fef6aa"
                                    d="M60.114,12.807v10.986c0,4.958,4.057,9.014,9.014,9.014h11.986"
                                  />
                                  <path
                                    fill="#1f212b"
                                    d="M81.114 33.307H69.129c-5.247 0-9.515-4.268-9.515-9.515V12.807c0-.276.224-.5.5-.5s.5.224.5.5v10.985c0 4.695 3.82 8.515 8.515 8.515h11.985c.276 0 .5.224.5.5S81.391 33.307 81.114 33.307zM75.114 51.307c-.276 0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5s.5.224.5.5v3C75.614 51.083 75.391 51.307 75.114 51.307zM75.114 59.307c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6C75.614 59.083 75.391 59.307 75.114 59.307zM67.956 86.307H32.272c-4.223 0-7.658-3.45-7.658-7.689V25.955c0-2.549 1.264-4.931 3.382-6.371.228-.156.54-.095.695.132.155.229.096.54-.132.695-1.844 1.254-2.944 3.326-2.944 5.544v52.663c0 3.688 2.987 6.689 6.658 6.689h35.685c3.671 0 6.658-3.001 6.658-6.689V60.807c0-.276.224-.5.5-.5s.5.224.5.5v17.811C75.614 82.857 72.179 86.307 67.956 86.307z"
                                  />
                                  <path
                                    fill="#1f212b"
                                    d="M39.802 14.307l-.117 11.834c0 2.21-2.085 3.666-4.036 3.666-1.951 0-4.217-1.439-4.217-3.649l.037-12.58c0-1.307 1.607-2.451 2.801-2.451 1.194 0 2.345 1.149 2.345 2.456l.021 10.829c0 0-.083.667-1.005.645-.507-.012-1.145-.356-1.016-.906v-9.843h-.813l-.021 9.708c0 1.38.54 1.948 1.875 1.948s1.959-.714 1.959-2.094V13.665c0-2.271-1.36-3.5-3.436-3.5s-3.564 1.261-3.564 3.532l.032 12.11c0 3.04 2.123 4.906 4.968 4.906 2.845 0 5-1.71 5-4.75V14.307H39.802zM53.114 52.307h-23c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h23c.276 0 .5.224.5.5S53.391 52.307 53.114 52.307zM44.114 59.307h-14c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h14c.276 0 .5.224.5.5S44.391 59.307 44.114 59.307zM70.114 59.307h-24c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h24c.276 0 .5.224.5.5S70.391 59.307 70.114 59.307zM61.114 66.307h-11c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h11c.276 0 .5.224.5.5S61.391 66.307 61.114 66.307zM71.114 66.307h-8c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5S71.391 66.307 71.114 66.307zM48.114 66.307h-18c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h18c.276 0 .5.224.5.5S48.391 66.307 48.114 66.307zM70.114 73.307h-13c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h13c.276 0 .5.224.5.5S70.391 73.307 70.114 73.307zM54.114 73.307h-24c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h24c.276 0 .5.224.5.5S54.391 73.307 54.114 73.307z"
                                  />
                                </svg>
                                <div style={{ textAlign: "center" }}>
                                  {msg?.name}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          msg?.text && <div>{msg?.text}</div>
                        )}
                      </div>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
        </div>
        <div
          style={{
            ...styles.inputContainer,
            display: "flex",
            overflow: "inherit",
          }}
        >
          {/* <label htmlFor="fileUpload" className="pt-2">
              <FaPaperclip style={styles.uploadIcon} />
            </label>
            {selectedFile && (
              <div className="selected-file">
                <FontAwesomeIcon icon={getFileIcon(selectedFile.type)} />
                <a href={selectedFile.url} target="_blank" rel="noopener noreferrer">
                  {selectedFile.name}
                </a>
                <button onClick={handleRemoveFile}>Remove</button>
              </div>
            )} */}
          <textarea
            type="text"
            value={newMessage}
            onChange={(ev) => setNewMessage(ev.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
            rows={1}
            style={{ ...styles.input, resize: "none" }}
            disabled={!room}
          />
          {/* <input
              type="file"
              id="fileUpload"
              style={styles.fileInput}
              onChange={handleFileChange}
            />  */}
          <button
            onClick={(ev) => fileRef?.current?.click()}
            style={{
              borderRadius: "100%",
              // backgroundColor: "#0B3013",
              border: "none",
              backgroundColor: "#fff",
              color: "#0B3013",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px",
              cursor: "pointer",
            }}
            disabled={!room}
          >
            <AttachFileIcon width={30} height={30} />
          </button>
          <input
            type="file"
            accept=".jpg,.jpeg,.gif,.pdf,.xlsx,.docx,.zip,.mp4"
            style={{ display: "none" }}
            disabled={!room}
            ref={fileRef}
            onChange={handleFileChange}
          />
          <button
            style={{
              borderRadius: "100%",
              // backgroundColor: "#0B3013",
              border: "none",
              backgroundColor: "#fff",
              color: "#0B3013",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "7px",
              cursor: "pointer",
            }}
            onClick={handleSendMessage}
          >
            <SendIcon width={30} height={30} />
          </button>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

const styles = {
  chatBotContainer: {
    maxWidth: "95vw",
    maxHeight: "80dvh",
    position: "fixed",
    bottom: "80px",
    right: "20px",
    width: "360px",
    height: "500px", // Adjusted height here
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 214748364,
  },
  messagesContainer: {
    flex: 1,
    overflowY: "auto",
    marginBottom: "10px",
    padding: "20px 10px",
  },
  botMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  userMessageContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "21px",
    justifyContent: "flex-start",
    flexDirection: "row-reverse",
  },
  avatarContainer: {
    marginRight: "10px",
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  botMessage: {
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    padding: "9px 10px 8px 10px",
    borderRadius: "10px",
    maxWidth: "60%",
  },
  userMessage: {
    position: "relative",
    backgroundColor: "#16A34A",
    color: "#fff",
    padding: "8px",
    borderRadius: "10px",
    maxWidth: "60%",
  },
  userMessageTime: {
    // position: "absolute",
    position: "relative",
    right: "5px",
    bottom: "0px",
    color: "#414141",
    fontSize: "11px",
    whiteSpace: "nowrap",
  },
  botMessageTime: {
    // position: "absolute",
    position: "relative",
    left: "6px",
    bottom: "0px",
    color: "#414141",
    fontSize: "11px",
    whiteSpace: "nowrap",
  },
  inputContainer: {
    // display: "flex",
    // alignItems: "center",
    overflow: "auto",
    padding: "10px 10px",
    backgroundColor: "#f8faff",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  input: {
    flex: 1,
    padding: "8px",
    borderRadius: "20px",
    border: "1px solid #16A34A",
    marginRight: "10px",
  },
  fileInput: {
    display: "none",
  },
  uploadIcon: {
    fontSize: "20px",
    marginRight: "10px",
    cursor: "pointer",
  },
  sendButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "20px",
    padding: "8px 16px",
    cursor: "pointer",
  },
  fileMessage: {
    display: "flex",
    alignItems: "center",
  },
  botFileMessageLink: {
    marginLeft: "5px",
    textDecoration: "none",
    color: "#16a34a",
  },
  userFileMessageLink: {
    marginLeft: "5px",
    textDecoration: "none",
    color: "#f8f9fa",
  },
};
