import React, { useMemo, useState } from "react";
import {
  List,
  ListItem,
  Box,
  ListItemText,
  Divider,
  Modal,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import settings from "../../../Assets/Images/settings.png";
import dashIcon from "../../../Assets/Images/dashboard-square-01.png";
import proposalIco from "../../../Assets/Images/note-edit.png";
import WithdrawalIcon from "../../../Assets/Images/transaction.png";
import projectIcon from "../../../Assets/Images/project-management.png";
import rev from "../../../Assets/Images/revenue.png";

import dashboardIco from "../../../Assets/Images/dashboard.png";
import userGroup from "../../../Assets/Images/user-group.png";
import roleMng from "../../../Assets/Images/user-account.png";
import fees from "../../../Assets/Images/fees.png";
import support from "../../../Assets/Images/customer-support.png";
import call from "../../../Assets/Images/calling (1).png";
import don from "../../../Assets/Images/donate.png";
import donPay from "../../../Assets/Images/donation-payout.png";
import dis from "../../../Assets/Images/dispute.png";
import adl from "../../../Assets/Images/advertisement-logo.png";

// import bookmark from "../../../Assets/Images/favourite.png";
import saved from "../../../Assets/Images/bookmark-02.png";
import myproj from "../../../Assets/Images/MyProject.png";
import message from "../../../Assets/Images/message-01 (1).png";
import user from "../../../Assets/Images/user.png";
import logoutIcon from "../../../Assets/Images/logout-01.png"; // Import logout image
import logoutVect from "../../../Assets/Images/LogoutVector.png";
import awardrequest from "../../../Assets/Images/championAward.png";

import createproject from "../../../Assets/Images/property-add.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import { GetUserDetails } from "../../../Helpers/Utility";
import { postAPI } from "../../../Services/Api";
import { signOut } from "firebase/auth";
import { useBrowserJob } from "../../../context/BrowserJob";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  CHAT_ROLE,
  EMPLOYEE_ROLE,
  EMPLOYER_ROLE,
} from "../../../Helpers/roles";

const userDetails = GetUserDetails();
const isAdmin = !userDetails?.role?.includes(ADMIN_ROLE); // Check if the user is an admin

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  icon: {
    minWidth: "auto",
  },
  ListItem: {
    marginBottom: "12px!important",
    marginTop: "12px!important",
  },
  activeListItem: (props) => ({
    backgroundColor: "#fff !important",
    width: "100% !important",
    maxWidth: "95% !important", // Conditional width
    borderTopRightRadius: "12px!important",
    borderBottomRightRadius: "12px!important",
    "&:hover": {
      backgroundColor: "#1E90FF",
    },
  }),
  activeText: {
    color: "#0B3013 !important",
  },
  iconImage: {
    color: "#0B3013",
  },
  sidebar: {
    zIndex: 9999, // Ensure sidebar appears above other content
  },
}));

const DashboardSidebar = ({ userRole, closeSidebar }) => {
  const classes = useStyles();
  const location = useLocation();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const userDetails = useMemo(() => {
    return GetUserDetails();
  }, []);

  const { rooms, isNew } = useBrowserJob();
  const [isLogoutActive, setIsLogoutActive] = useState(false); // State to track logout menu item active status
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LogoutModal = ({ open, handleClose }) => {
    // Add logout functionality here
    const handleLogout = async () => {
      try {
        await postAPI("user/logout", {}, GetUserDetails().token);
        await signOut();
      } catch (err) {
        console.log(err);
      }
      localStorage.removeItem("notif-token");
      localStorage.removeItem("loginData");
      localStorage.removeItem("seenNotification");
      sessionStorage.removeItem("userData");
      navigate("/");
      window.location.reload();
      handleClose();
    };

    const handleCloseModal = () => {
      handleClose(); // Close the modal after logout
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices

            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={handleClose}
          >
            <img src={close} alt="db" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={logoutVect} alt="db" />
            </Box>
            <Typography
              id="logout-modal-description"
              className="employeeHead1"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("Are")}
            </Typography>
            <Typography
              id="logout-modal-description"
              className="employeeHead"
              variant="p"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("SessionExpires")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
    setIsLogoutActive(true); // Set logout menu item as active
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeSettingsSubmenu, setActiveSettingsSubmenu] = useState(null); // State to track active settings submenu

  const handleSubmenuClick = (label) => {
    if (label === t("Settings")) {
      // Toggle settings submenu
      setActiveSettingsSubmenu(activeSettingsSubmenu === null ? label : null);
    } else {
      setActiveSubmenu(activeSubmenu === label ? null : label);
      // Always close the settings submenu when clicking on other submenus
      setActiveSettingsSubmenu(null);
    }
  };

  const handleCloseSubmenus = () => {
    setActiveSubmenu(null);
  };

  const handleMenuItemClick = (item) => {
    if (item.label === t("Settings")) {
      // Toggle settings submenu
      handleSubmenuClick(item.label);
    } else {
      handleCloseSubmenus();
      if (isMobile) {
        closeSidebar();
      }
    }
  };

  const isMenuItemActive = (menuItem) => {
    if (menuItem.submenus) {
      return (
        menuItem.submenus.some((submenu) =>
          location.pathname.startsWith(submenu.path)
        ) || location.pathname.startsWith(menuItem.path)
      );
    } else {
      // return location.pathname.startsWith(menuItem.path);
      return location.pathname === menuItem.path;
    }
  };

  // Define menu items for employee and employer
  const employeeMenuItems = [
    {
      label: t("BrowseProject"),
      path: "/dashboard/employee",
      icon: (
        <img
          src={dashIcon}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("My"),
      path: "/dashboard/employee/myproposals",
      icon: (
        <img
          src={proposalIco}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/myproposals"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("MyProj"),
      path: "/dashboard/employee/myprojects",
      onClick: () => {
        return window.location.pathname == "/dashboard/employee/myprojects"
          ? window.location.replace("/dashboard/employee/myprojects")
          : "";
      },
      icon: (
        <img
          src={myproj}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/myprojects"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("AwardRequest"),
      path: "/dashboard/employee/awardrequest",
      icon: (
        <img
          src={awardrequest}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/awardrequest"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("DashboardBookMark"),
      path: "/dashboard/employee/bookmark",
      icon: (
        <img
          src={saved}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/bookmark"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("Message"),
      path: "/dashboard/employee/message",
      hasDot: true,
      dotCondition: () => isNew > 0 && rooms.length > 0,
      icon: (
        <img
          src={message}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/message"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("DashboardProfile"),
      path: "/dashboard/employee/profile",
      icon: (
        <img
          src={user}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/profile"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("disputeCase"),
      path: "/dashboard/employee/dispute",
      icon: (
        <img
          src={dis}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/dispute"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("withdrawalHistory"),
      path: "/dashboard/employee/withdrawal-history",
      icon: (
        <img
          src={WithdrawalIcon}
          alt="withdrawal"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employee/withdrawal-history"
                ? "none"
                : "invert(1)",
          }}
        />
      ),
    },
  ];

  const employerMenuItems = [
    {
      label: t("BrowseProf"),
      path: "/dashboard/employer/browseprofiles",
      icon: (
        <img
          src={dashIcon}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/browseprofiles"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("MyProj"),
      path: "/dashboard/employer/myprojects",
      onClick: () => {
        return window.location.pathname == "/dashboard/employer/myprojects"
          ? window.location.replace("/dashboard/employer/myprojects")
          : "";
      },
      icon: (
        <img
          src={myproj}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/myprojects"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    // {
    //   label: "Saved",
    //   path: "/dashboard/employer/bookmark",
    //   icon: (
    //     <img
    //       src={bookmark}
    //       alt="Dashboard"
    //       className={classes.iconImage}
    //       style={{
    //         filter:
    //           location.pathname === "/dashboard/employer/bookmark"
    //             ? "none"
    //             : "invert(1)",
    //       }}
    //     />

    //   ),
    // },
    {
      label: t("Message"),
      path: "/dashboard/employer/message",
      hasDot: true,
      dotCondition: () => isNew > 0 && rooms.length > 0,
      icon: (
        <img
          src={message}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/message"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("CreateProj"),
      path: "/dashboard/employer/createproject",
      icon: (
        <img
          src={createproject}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/createproject"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("Settings"),
      icon: (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <img src={settings} alt="settings" className={classes.iconImage} />
          {/* <Box sx={{ flexGrow: 1, textAlign: "right" }}>
            {activeSubmenu === "settings" ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </Box> */}
        </Box>
      ),
      submenus: [
        {
          label: t("Profile"),
          path: "/dashboard/employer/profile",
        },
        {
          label: t("VerifyEmail"),
          path: "/dashboard/employer/verifyemail",
        },
        // {
        //   label: "Change Password",
        //   path: "/dashboard/employer/changepassword",
        // },
        {
          label: t("BillingandPayments"),
          path: "/dashboard/employer/bilingpayments",
        },
        // {
        //   label: "Terms & Condition",
        //   path: "/dashboard/employer/settings/termscondition",
        // },
      ],
    },
    {
      label: t("disputeCase"),
      path: "/dashboard/employer/dispute",
      icon: (
        <img
          src={dis}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/dispute"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: t("withdrawalHistory"),
      path: "/dashboard/employer/withdrawal-history",
      icon: (
        <img
          src={WithdrawalIcon}
          alt="withdrawal"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/employer/withdrawal-history"
                ? "none"
                : "invert(1)",
          }}
        />
      ),
    },
    // {
    //   label: "Profile",
    //   path: "/dashboard/employer/profile",
    //   icon: (
    //     <img
    //       src={user}
    //       alt="Dashboard"
    //       className={classes.iconImage}
    //       style={{
    //         filter:
    //           location.pathname === "/dashboard/employer/profile"
    //             ? "invert(1)"
    //             : "none",
    //       }}
    //     />

    //   ),
    // },
    // Add more employer-specific menu items here
  ];

  const accountantMenuItem = [
    {
      label: "Payments",
      path: "/dashboard/accountant/payments",
      icon: (
        <img
          src={proposalIco}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/accountant/payments"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
  ];

  const chatMenuItem = [
    {
      label: "Message",
      path: "/dashboard/admin/chat",
      icon: (
        <img
          src={message}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/chat"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
  ];

  const adminMenuItem = [
    {
      label: "Dashboard",
      path: "/dashboard/admin/dashboard",
      icon: (
        <img
          src={dashboardIco}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/dashboard"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "WWH User Management",
      path: "/dashboard/admin/wwh-user-management",
      icon: (
        <img
          src={userGroup}
          alt="WWH User Management"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/wwh-user-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "User Management",
      path: "/dashboard/admin/user-management",
      icon: (
        <img
          src={userGroup}
          alt="User Management"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/user-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Roles Management",
      path: "/dashboard/admin/roles-management",
      icon: (
        <img
          src={roleMng}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/roles-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Payments",
      path: "/dashboard/accountant/payments",
      icon: (
        <img
          src={proposalIco}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/accountant/payments"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Payment Fees Management",
      path: "/dashboard/admin/fees-management",
      icon: (
        <img
          src={fees}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/fees-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },

    {
      label: "Revenue Reports",
      path: "/dashboard/admin/revenue-reports",
      icon: (
        <img
          src={rev}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/revenue-reports"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },

    {
      label: "Help & Support",
      path: "/dashboard/admin/support-management",
      icon: (
        <img
          src={support}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/support-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },

    {
      label: "Contact Us",
      path: "/dashboard/admin/contact-management",
      icon: (
        <img
          src={call}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/contact-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Donation Management",
      path: "/dashboard/admin/donation-management",
      icon: (
        <img
          src={don}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/donation-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Donation Payout",
      path: "/dashboard/admin/donation-payout-management",
      icon: (
        <img
          src={donPay}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname ===
              "/dashboard/admin/donation-payout-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Dispute Case Management",
      path: "/dashboard/admin/dispute-management",
      icon: (
        <img
          src={dis}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/dispute-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },

    {
      label: "Message",
      path: "/dashboard/admin/chat",
      icon: (
        <img
          src={message}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/chat"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Advertisement",
      path: "/dashboard/admin/advertisement",
      icon: (
        <img
          src={adl}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/advertisement"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
    {
      label: "Projects",
      path: "/dashboard/admin/project-management",
      icon: (
        <img
          src={projectIcon}
          alt="Dashboard"
          className={classes.iconImage}
          style={{
            filter:
              location.pathname === "/dashboard/admin/project-management"
                ? "invert(1)"
                : "none",
          }}
        />
      ),
    },
  ];

  // Determine which set of menu items to display based on the user's role
  const menuItems = () => {
    let allMenus = [];
    if (userDetails?.role.includes(ADMIN_ROLE)) return adminMenuItem;
    if (userDetails?.role.includes(EMPLOYER_ROLE)) return employerMenuItems;
    if (userDetails?.role.includes(EMPLOYEE_ROLE)) return employeeMenuItems;
    if (userDetails?.role.includes(ACCOUNTANT_ROLE))
      allMenus = [...allMenus, ...accountantMenuItem];
    if (userDetails?.role.includes(CHAT_ROLE))
      allMenus = [...allMenus, ...chatMenuItem];
    return allMenus;
  };

  return (
    <div>
      <div className={classes.sidebar}>
        <div className={classes.toolbar} />
        <Divider />
        <List sx={{ py: 5 }}>
          {menuItems().map((item, index) => (
            <React.Fragment key={index}>
              {item.submenus ? (
                <>
                  <ListItem
                    sx={{ color: "#fff" }}
                    button
                    onClick={() => handleSubmenuClick(item.label)}
                    className={classes.ListItem}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ px: 2, position: "relative" }}>
                          {item.icon}
                        </Box>
                        <ListItemText
                          sx={{ color: "#fff !important" }}
                          primary={item.label}
                          className={""}
                        />
                        <Box sx={{ pl: 7, mt: 1 }}>
                          {item.label === "Settings" ? (
                            activeSettingsSubmenu ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          ) : activeSubmenu === item.label ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                  {item.label === t("Settings") && activeSettingsSubmenu && (
                    <List component="div" disablePadding>
                      {item.submenus.map((submenu, subIndex) => (
                        <ListItem
                          button
                          key={subIndex}
                          component={Link}
                          to={submenu.path}
                          onClick={() => handleMenuItemClick(submenu)}
                          className={`${
                            location.pathname === submenu.path
                              ? classes.activeListItem
                              : ""
                          } ${classes.ListItem}`}
                        >
                          <ListItemText
                            sx={{ color: "#fff", pl: 7 }}
                            primary={submenu.label}
                            className={
                              location.pathname === submenu.path
                                ? classes.activeText
                                : ""
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </>
              ) : (
                <ListItem
                  button
                  component={item.path ? Link : "div"}
                  to={item.path}
                  onClick={() => {
                    item.onClick && item.onClick();
                    handleMenuItemClick(item);
                  }}
                  sx={{
                    position: "relative",
                  }}
                  className={`${
                    isMenuItemActive(item) ? classes.activeListItem : ""
                  } ${classes.ListItem}`}
                >
                  {item.hasDot && item.dotCondition() ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "49px",
                        top: "9px",
                        width: "8px",
                        height: "8px",
                        borderRadius: "100%",
                        backgroundColor: "red",
                        zIndex: 9999,
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                  <Box sx={{ px: 2, position: "relative" }}>{item.icon}</Box>
                  <ListItemText
                    sx={{ color: "#fff" }}
                    primary={item.label}
                    className={isMenuItemActive(item) ? classes.activeText : ""}
                  />
                </ListItem>
              )}
            </React.Fragment>
          ))}
          <ListItem
            button
            component="div"
            onClick={handleOpenLogoutModal}
            sx={{
              position: "relative",
            }}
            className={classes.ListItem}
          >
            <Box sx={{ px: 2, position: "relative" }}>
              <img
                src={logoutIcon}
                alt="Logout"
                className={`${classes.iconImage} ${
                  isLogoutActive ? classes.activeIcon : ""
                }`}
              />
            </Box>
            <ListItemText
              sx={{ color: "#fff" }}
              primary={t("Logout")}
              className={""}
            />
          </ListItem>
        </List>

        <LogoutModal
          open={logoutModalOpen}
          handleClose={handleCloseLogoutModal}
        />
      </div>
    </div>
  );
};

export default DashboardSidebar;
