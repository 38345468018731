import React, { useState } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import Con from "../../Assets/Images/Contact1.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PhoneInput from "react-phone-input-2";
import "./ContactUs.css";
import Dropdown from "../../Components/Dropdown/Dropdown";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import {
  isValidEmail,
  isValidPhoneNumber,
  LoaderHide,
  LoaderShow,
} from "../../Helpers/Utility";
import { postAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import loader from "../../Assets/Images/loader.svg";

export default function ContactUs() {
  const [helpOption, setHelpOption] = React.useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setfirstName] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [errors, setError] = useState({});
  const { t } = useTranslation();

  const handleChange = (event) => {
    setHelpOption(event.target.value);
  };

  const validateForm = () => {
    let isValid = true;
    setError({});
    if (!firstName.trim()) {
      setError((dt) => {
        return { ...dt, firstName: t("UserNameIsRequired") };
      });
      isValid = false;
    }

    if (!message.trim()) {
      setError((dt) => {
        return { ...dt, message: t("enterMsg") };
      });
      isValid = false;
    }

    if (!email.trim()) {
      setError((dt) => {
        return { ...dt, email: t("EnterEmailAdd") };
      });
      isValid = false;
    } else if (!isValidEmail(email)) {
      setError((dt) => {
        return { ...dt, email: t("InvalidEmailFormat") };
      });
      isValid = false;
    }

    if (!phoneNumber.trim()) {
      setError((dt) => {
        return { ...dt, phoneNumber: t("PleaseEnterPhoneNumber") };
      });
      isValid = false;
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setError((dt) => {
        return { ...dt, phoneNumber: t("PleaseEnterValidNumber") };
      });
      isValid = false;
    }
    return isValid;
  };

  const handleFocus = () => {
    validateForm();
  };

  const onSubmit = async () => {
    if (!validateForm()) return;
    LoaderShow();
    try {
      const res = await postAPI("contactus/contact-us", {
        firstName,
        message,
        phoneNumber,
        status: "pending",
        email,
        type: "contact",
      });
      if (res.statusCode == 200) {
        toast.success("Your response has been noted.");
        setfirstName("");
        setmessage("");
        setemail("");
        setPhoneNumber("");
        setError({});
      }
    } catch (err) {
      toast.error(err.message);
    }
    LoaderHide();
  };

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onBlur={onBlur}
        inputStyle={{
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
    </div>
  );

  const options = [
    { value: "account", label: "Account Issues" },
    { value: "project", label: "Project Issues" },
    { value: "payments", label: "Payment Issues" },
    { value: "other", label: "Other" },
  ];

  return (
    <section className="helpSupport">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Container maxWidth="xl" sx={{ px: 2 }}>
        <Box sx={{ py: 10, px: 1, position: "relative" }} className="">
          <Typography variant="h3" sx={{ color: "#fff", fontWeight: "medium" }}>
            {t("Contact")}
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#fff", py: 2, display: "block", fontWeight: "light" }}
          >
            {t("contactone")}
          </Typography>

          <Grid
            container
            className="contactBg"
            md={{ px: 8 }}
            sx={{
              maxWidth: "1300px",
              mx: "auto",
              px: { md: 8, sx: 0 },
              my: 5,
              pr: { md: 0, sx: 0 },
              backgroundColor: "#fff",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "block", m: "auto", py: 8, pb: 2 }}
            >
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  textAlign: "center",
                  color: "#0B3013",
                  fontWeight: "medium",
                }}
              >
                {t("contacttwo")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, py: 5 }}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel htmlFor="title-input" sx={{ py: 2 }}>
                    {t("First")}
                  </FormLabel>
                  <TextField
                    onChange={(ev) => setfirstName(ev.target.value)}
                    value={firstName}
                    id="title-input"
                    placeholder={t("First")}
                    variant="outlined"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel htmlFor="title-input" sx={{ py: 2 }}>
                    {t("Email")}
                  </FormLabel>
                  <TextField
                    onChange={(ev) => setemail(ev.target.value)}
                    value={email}
                    type="email"
                    id="title-input"
                    placeholder={t("Email")}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                  />
                </FormControl>
                <div>
                  <FormLabel
                    sx={{
                      display: "block",
                      textAlign: "start",
                      py: 1,
                    }}
                  >
                    {t("Phone")}
                  </FormLabel>
                  <PhoneInput
                    country={"us"}
                    onChange={(ev) => setPhoneNumber(ev)}
                    value={phoneNumber}
                    inputComponent={renderInput}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                  />
                  <span style={{ color: "red" }}>{errors.phoneNumber}</span>
                </div>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormLabel htmlFor="needs-input" sx={{ py: 2 }}>
                    {t("Message")}
                  </FormLabel>
                  <TextField
                    onChange={(ev) => setmessage(ev.target.value)}
                    value={message}
                    id="needs-input"
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder={t("Message")}
                    error={!!errors.message}
                    helperText={errors.message}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                  />
                </FormControl>

                <FormControl fullWidth sx={{}}></FormControl>
                <Box sx={{ display: "flex", justifyContent: "start", mt: 5 }}>
                  <Button
                    className="catBtnTop"
                    sx={{
                      textTransform: "capitalize",
                      bgcolor: "#0B3013",
                      fontSize: 16,
                      color: "#fff",
                      borderRadius: 10,
                      px: 5,
                      py: 1,
                      "&:hover": {
                        bgcolor: "#0B3013",
                      },
                    }}
                    endIcon={<ArrowOutwardIcon color="#fff" />}
                    onClick={onSubmit}
                  >
                    {t("SendMessage")}
                  </Button>{" "}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: "block", m: "auto" }}>
              <Box
                md={{ display: "block" }}
                sx={{ display: { md: "block", display: "none" } }}
              >
                <Box
                  sx={{
                    backgroundColor: "#0B3013",
                    p: 3,
                    display: "flex",
                    alignItems: "center",
                    borderBottomLeftRadius: 25,
                    borderTopLeftRadius: 25,
                    justifyContent: "end",
                    width: "auto",
                    maxWidth: "fit-content",
                    ml: "auto",
                  }}
                >
                  <MailOutlineIcon
                    sx={{ color: "#fff", fontSize: "3rem", px: 4 }}
                  />
                  <Typography variant="h4" sx={{ color: "#fff", pr: 5 }}>
                    contact@workwavehub.com
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
