import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Chip,
  InputBase,
  Menu,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "../../../Assets/Images/resolvedVec.png"; // Import the delete icon

import close from "../../../Assets/Images/cancel-circle-half-dot.png";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import editICO from "../../../Assets/Images/Eye.png";
import tickICO from "../../../Assets/Images/tick.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { getAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import loader from "../../../Assets/Images/loader.svg";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";

export default function DonationManagement() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [selectedCase, setSelectedCase] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { t } = useTranslation();
  const [donations, setDonations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  const [timeOutId, setTimeOutId] = useState(false);

  const [totalDonation, setTotalDonation] = useState({});

  const fetchSearchedDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const response = await getAPI(
          `donation/getAll?page=${pageNumber}&paymentMethod=${paymentMethod}&transactionId=${search}`,
          token
        );
        if (response.statusCode == 200) {
          setTotalPages(response?.pagination?.totalPages || 1);
          setDonations(response?.data || []);
          setTotalDonation(response?.totals || {});
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const fetchDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    LoaderShow();
    await fetchSearchedDonations(ev, pageNumber, paymentMethod, search);
    LoaderHide();
  };

  useEffect(() => {
    fetchDonations();
  }, []);

  const handleViewCase = (disputeCase) => {
    setSelectedCase(disputeCase);
  };
  const handleEditOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
  };
  const handleBack = () => {
    setSelectedCase(null);
  };

  const handleSearchChange = (event) => {
    if (timeOutId) clearTimeout(timeOutId);
    setTimeOutId(
      setTimeout(async () => {
        setShowPagination(false);
        setSearchText(event.target.value);
        await fetchSearchedDonations(
          {},
          1,
          selectedPaymentMethod,
          event.target.value
        );
        setShowPagination(true);
      }, 500)
    );
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClick = async (paymentMethod) => {
    setAnchorEl(null);
    setSelectedPaymentMethod(paymentMethod);
    setShowPagination(false);
    await fetchDonations({}, 1, paymentMethod);
    setShowPagination(true);
  };

  const clearFilter = async () => {
    if (selectedPaymentMethod) {
      setShowPagination(false);
      await fetchDonations({}, 1, "");
      setSelectedPaymentMethod("");
      setShowPagination(true);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract the day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };

  return (
    <Box>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Donation Management
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 3,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ pb: 1, fontWeight: 600 }}>
              Total Donation : ${totalDonation?.totalAmountOfAllDonations || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
            }}
          >
            <InputBase
              placeholder="Search…"
              onChange={handleSearchChange}
              sx={{
                maxWidth: "400px",
                ml: 1,
                flex: 1,
                backgroundColor: "#fff",
                color: "#000",
                borderRadius: "24px",
                py: "8px",
                px: "20px",
                justifyContent: "flex-end",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#000" }} />
                </InputAdornment>
              }
            />
            <IconButton
              aria-label="filter"
              onClick={handleMenuOpen}
              sx={{
                textTransform: "capitalize",
                fontSize: "16px",
                ml: 2,
                backgroundColor: "#0B3013",
                color: "#fff",
                borderRadius: "24px",
                py: "9px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {selectedPaymentMethod ? selectedPaymentMethod : "Sort By"}
              <ExpandMoreIcon sx={{ ml: 1 }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                style={{ paddingLeft: "38px", paddingRight: "38px" }}
                onClick={(ev) => handleFilterClick("stripe")}
              >
                Stripe
              </MenuItem>
              <MenuItem
                style={{ paddingLeft: "38px", paddingRight: "38px" }}
                onClick={(ev) => handleFilterClick("paypal")}
              >
                Paypal
              </MenuItem>
              <MenuItem
                style={{ paddingLeft: "38px", paddingRight: "38px" }}
                onClick={(ev) => handleFilterClick("crypto")}
              >
                Crypto
              </MenuItem>
            </Menu>
            <Button
              onClick={clearFilter}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                ml: 1,
                backgroundColor: "#0B3013",
                color: "#fff",
                borderRadius: "24px",
                py: "9px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            mt: 4,
          }}
        >
          {donations.length > 0 ? (
            <>
              <div style={{ overflow: "auto" }}>
                <Table
                  aria-label="dispute case management table"
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0",
                    marginTop: "0px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Transaction ID
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Date
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Payment Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {donations.map((donation, index) => (
                      <TableRow key={donation.id}>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {donation?.transactionId || "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {formatDate(donation.createdAt)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {donation.amount} {donation.currency}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {donation.paymentMethod}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Box
                sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}
              >
                {showPagination && (
                  <CustomPagination
                    count={totalPages}
                    onChange={fetchDonations}
                  />
                )}
              </Box>
            </>
          ) : (
            <NodataFound />
          )}
        </Box>

        {selectedPaymentMethod && (
          <Typography
            variant="h5"
            sx={{
              py: 4,
              fontWeight: 600,
              textAlign: "right",
              textTransform: "capitalize",
            }}
          >
            {selectedPaymentMethod} Donation : $
            {totalDonation?.totalAmountForMethod || 0}
          </Typography>
        )}
      </>
      <Dialog
        open={editModalOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "30px",
          },
        }}
      >
        <DialogTitle id="edit-dialog-title">
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="db" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "auto",
              }}
            >
              <img className="" src={DeleteIcon} alt="db" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              Are you sure it is Resolved?
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 2 }}
        >
          <Box>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
                mx: 3,
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                mx: 3,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
