import {
  Box,
  Button,
  Chip,
  FormLabel,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import loader from "../../../Assets/Images/loader.svg";
import { useEffect, useState } from "react";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI } from "../../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useTheme } from "@emotion/react";
import Dropdown from "../../../Components/Dropdown/Dropdown";

export default function ProjectManagement() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [projectList, setProjectList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [timeOutId, setTimeOutId] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [status, setStatus] = useState(""); // Default to 'active'

  const statusOptions = [
    { value: 0, label: "Posted" },
    { value: 2, label: "Ongoing" },
    { value: 1, label: "Completed" },
  ];

  useEffect(() => {
    getProjectList();
  }, []);

  const getProjectList = async (
    ev = {},
    pagenumber = 1,
    title = searchText,
    st = status
  ) => {
    LoaderShow();
    await getSearchedProjectList(ev, pagenumber, title, st);
    LoaderHide();
  };

  const getSearchedProjectList = async (
    ev = {},
    pagenumber = 1,
    title = searchText,
    st = status
  ) => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI(
          `project/all?page=${pagenumber}&title=${title}&status=${st}`,
          token
        );
        if (res?.statusCode == 200) {
          setProjectList(res?.data);
          setTotalPages(res?.totalPages || 1);
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    setFilterModalOpen(false);
  };

  const clearFilter = async () => {
    if (status || !isNaN(status)) {
      setStatus("");
      setShowPagination(false);
      await getProjectList({}, 1, "", "");
      setShowPagination(true);
    }
  };

  const filter = async () => {
    setShowPagination(false);
    await getProjectList();
    setShowPagination(true);
  };

  function formatDateToDDMMYYYY(dt) {
    const date = new Date(dt);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleSearchChange = (event) => {
    if (timeOutId) clearTimeout(timeOutId);
    setTimeOutId(
      setTimeout(async () => {
        setShowPagination(false);
        setSearchText(event.target.value);
        await getSearchedProjectList({}, 1, event.target.value, status);
        setShowPagination(true);
      }, 500)
    );
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Projects
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 3,
          justifyContent: "end",
        }}
      >
        <InputBase
          placeholder="Search…"
          onChange={handleSearchChange}
          sx={{
            maxWidth: "400px",
            ml: 1,
            flex: 1,
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "24px",
            py: "8px",
            px: "20px",
            justifyContent: "flex-end",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#000" }} />
            </InputAdornment>
          }
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        {projectList.length > 0 && (
          <Table
            aria-label="user management table"
            sx={{ borderCollapse: "separate", borderSpacing: "0" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Created At
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Name
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Status
                  <IconButton
                    onClick={(ev) => setFilterModalOpen(true)}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    <FilterAltOutlinedIcon sx={{ color: "#0B3013" }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Employee Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Employer Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Budget
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectList.map((project, index) => (
                <TableRow key={project._id}>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {project?.createdAt
                      ? formatDateToDDMMYYYY(project?.createdAt)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {project?.title || "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {!isNaN(project?.status) ? (
                      <Chip
                        label={
                          statusOptions.find(
                            (ele) => ele.value == project?.status
                          )?.label
                        }
                        sx={{
                          cursor: "default",
                          textTransform: "capitalize",
                          py: 1.1,
                          px: 1,
                          backgroundColor: "#2ab95f",
                          color: "#fff",
                          borderRadius: "30px",
                          fontSize: "14px",
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {project?.employee?.name || "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    {project?.user?.name || "-"}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === projectList.length - 1
                          ? "0"
                          : "1px solid #e0e0e0",
                    }}
                  >
                    ${project?.estimatedBudget?.name || "0"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {projectList.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
            {showPagination && (
              <CustomPagination count={totalPages} onChange={getProjectList} />
            )}
          </Box>
        )}
        {projectList.length <= 0 && <NodataFound />}
      </TableContainer>
      {/* Filter modal */}
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
            Filter
          </Typography>
          <IconButton
            sx={{
              position: "absolute",
              top: 14,
              right: 10,
            }}
            onClick={() => setFilterModalOpen(false)}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box sx={{ borderTop: 1, borderColor: "#dededf" }}>
            <FormLabel
              sx={{
                display: "block",
                textAlign: "start",
                py: 1.4,
                fontSize: 14,
              }}
            >
              Status
            </FormLabel>
            <Dropdown
              value={status}
              onChange={(ev) => setStatus(ev.target.value)}
              options={statusOptions}
              label="Status"
            />
          </Box>
          <Box sx={{ my: 3, display: "flex" }}>
            <Button
              onClick={clearFilter}
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                textTransform: "capitalize",
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 5,
                py: 1,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              endIcon={<ArrowOutwardIcon color="#fff" />}
            >
              clear
            </Button>{" "}
            <Button
              onClick={filter}
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                textTransform: "capitalize",
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 5,
                py: 1,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              endIcon={<ArrowOutwardIcon color="#fff" />}
            >
              Filter
            </Button>{" "}
          </Box>
        </Box>
      </Modal>
    </section>
  );
}
