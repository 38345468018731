import { useEffect, useMemo, useState } from "react";
import { GetUserDetails } from "../../../Helpers/Utility";
import { getAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import "./DashboardAdvertisement.css";
import { EMPLOYEE_ROLE, EMPLOYER_ROLE } from "../../../Helpers/roles";

export default function DashboardAdvertisement() {
  const [advertisements, setAdvertisements] = useState([]);
  const [interval, setInt] = useState(0);

  const role = useMemo(() => GetUserDetails()?.role, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (advertisements.length) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex == advertisements.length - 1 ? 0 : prevIndex + 1
        );
      }, interval * 1000);
      return () => clearInterval(timer);
    }
  }, [advertisements.length, interval]);

  useEffect(() => {
    fetchAdvertisement();
  }, []);

  const getFilteredAdvertisement = (advertisements) => {
    const currentDate = new Date(); // Get the current date
    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    return advertisements.filter((advertisement) => {
      const displayStart = new Date(advertisement.displayStart);
      const displayEnd = new Date(advertisement.displayEnd);

      const displayStartOnly = new Date(
        displayStart.getFullYear(),
        displayStart.getMonth(),
        displayStart.getDate()
      );
      const displayEndOnly = new Date(
        displayEnd.getFullYear(),
        displayEnd.getMonth(),
        displayEnd.getDate()
      );

      // Check if the current date is between the start and end date, inclusive
      return (
        currentDateOnly >= displayStartOnly && currentDateOnly <= displayEndOnly
      );
    });
  };

  const fetchAdvertisement = async (ev = {}) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const response = await getAPI(`advertisement/get`, token);
        if (response.statusCode == 200) {
          setAdvertisements(
            getFilteredAdvertisement(
              response?.data?.sort((a, b) => a?.order - b?.order) || []
            )
          );
          setInt(response?.defaultInterval);
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  if (advertisements.length <= 0) return <div></div>;

  // if (![...EMPLOYEE_ROLE, ...EMPLOYER_ROLE].includes(role)) return <div></div>;
  if (!role.includes(EMPLOYEE_ROLE) && !role.includes(EMPLOYER_ROLE))
    return <div></div>;

  return (
    <div style={{ margin: "0 0 30px 0" }}>
      <div className="carousel">
        {advertisements.map((advertisement, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={`carousel-item ${
              index === currentIndex ? "active" : ""
            }`}
            // style={{ backgroundImage: `url(${advertisement.url})` }}
          >
            <img
              style={{
                width: "500px",
                maxWidth: "90vw",
                boxShadow: "rgb(0 0 0 / 64%) 2px 2px 14px 0px",
                height: "100px",
                objectFit: "fill",
              }}
              src={advertisement.url}
              alt={advertisement.altText}
            />
            {/* Optionally add overlay text or buttons */}
          </div>
        ))}
      </div>
    </div>
  );
}
