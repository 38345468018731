import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import delete1 from "../../Assets/Images/delet.png";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { postAPI, updateAPI } from "../../Services/Api";
import deleteVec from "../../Assets/Images/resolvedVec.png";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "material-ui-dropzone";
import { useTheme } from "@emotion/react";
import close from "../../Assets/Images/cancel-circle-half-dot.png";
import { styled } from "@mui/system";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }



  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function RejectDisputeModal({ open, onClose, dispute }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [reason, setReason] = useState("");
  const [error, setError] = useState({});

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [showMsg, setShowMsg] = useState(false);

  const { t } = useTranslation();

  const isGuilty = useMemo(() => {
    const userId = GetUserDetails()?.userId;
    return dispute?.against?._id == userId;
  }, [dispute]);

  useEffect(() => {
    if (!open) {
      setShowMsg(false);
      setReason("");
      setError({});
      setFileNames([]);
      setFiles([]);
    }
  }, [open]);

  const validateForm = () => {
    let isValid = true;
    let err = {};

    if (!reason.trim()) {
      isValid = false;
      err = { ...err, reason: "Please enter reason." };
    }

    setError(err);
    return isValid;
  };

  const handleFileChange = (newFiles) => {
    if (
      [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
      1_048_576
    ) {
      toast.warn("Maximum size of attachments should be less than 2mb.");
      return;
    }
    setFiles((dt) => [...dt, ...newFiles]);
    const names = newFiles.map((file) => file.name);
    setFileNames((dt) => [...dt, ...names]);
  };

  const handleDeleteFile = (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => index != i));
    setFileNames((prevFiles) => prevFiles.filter((f, i) => index != i));
  };

  // Handle input focus
  const handleFocus = () => {
    // Logic when input is focused
    validateForm();
  };

  const onSave = async (ev) => {
    ev.preventDefault();
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const userId = GetUserDetails()?.userId;
      if (token) {
        const data = new FormData();
        data.append("userId", userId);
        data.append("reason", reason);
        if (files.length > 0) {
          files.forEach((file, index) => {
            data.append("proofs", file);
          });
        }
        const response = await postAPI(
          isGuilty
            ? `dispute/reject/${dispute._id}`
            : `dispute/respond/${dispute._id}`,
          data,
          token
        );
        if (response.statusCode == 200) {
          toast.success("Status changed successfully");
          window.location.reload();
        } else {
          console.log(response);
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err.message || "Something went wrong!");
      toast.error(err.message || "Something went wrong!");
    }
    LoaderHide();
  };

  const onSubmit = (ev) => {
    if (!validateForm()) return;
    ev.preventDefault();
    setShowMsg(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="finish-modal-title"
        aria-describedby="finish-modal-description"
      >
        {!showMsg ? (
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
              maxHeight: "90dvh",
            }}
          >
            <Box className="SubHeader">
              <Typography
                variant="h5"
                id="subscribe-modal-title"
                sx={{
                  borderBottom: 1,
                  py: 2,
                  pt: 0,
                  borderColor: "#E6E6E6",
                }}
                gutterBottom
              >
                {isGuilty ? "Reject Dispute" : "Apply to admin"}
                <img
                  onClick={onClose}
                  src={close}
                  alt="Close"
                  style={{ cursor: "pointer", float: "right" }}
                />
              </Typography>
            </Box>
            <Box component="form" sx={{ m: 3 }} onSubmit={onSubmit}>
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 2,
                    gap: 2,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <FormLabel
                      sx={{
                        display: "block",
                        mb: 1,
                        color: "black",
                        fontSize: 14,
                      }}
                    >
                      Reason
                    </FormLabel>
                    <Textarea
                      //   disabled={isShowing}
                      sx={{
                        width: "100%",
                        borderRadius: 1,
                        minHeight: "70px",
                      }}
                      maxRows={6}
                      fullWidth={true}
                      aria-label="maximum height"
                      placeholder="Reason"
                      onChange={(ev) => setReason(ev.target.value)}
                      value={reason}
                      rows={4}
                      onFocus={handleFocus}
                      onBlur={handleFocus}
                      required
                    />
                    {error.reason && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                          lineHeight: "1.66",
                          letterSpacing: "0.0333em",
                        }}
                      >
                        {error.reason}
                      </span>
                    )}
                  </Box>
                  <Box className="dropdown" sx={{ flex: 1, mt: 3 }}>
                    <DropzoneArea
                      acceptedFiles={["image/*", ".pdf", ".txt"]} // Define accepted file types
                      onChange={handleFileChange}
                      showPreviewsInDropzone={false}
                      showAlerts={false}
                      sx={{
                        minHeight: "150px",
                      }}
                      dropzoneclassName="custom-dropzone"
                      filesLimit={1}
                    />
                    {error.files && (
                      <span style={{ color: "red" }}>{error.files}</span>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {fileNames.map((file, index) => (
                      <Typography
                        key={index}
                        variant="p"
                        sx={{
                          color: "#000",
                          px: 3,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* {file.name} ({file.size / 1024}kB) */}
                        {t("FileName")} : {file}
                        <img
                          src={delete1}
                          alt="Delete"
                          onClick={() => handleDeleteFile(file, index)}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </Typography>
                      //  {fileNames.map((name, index) => (
                      //   <div key={index}>{name}</div>
                      // ))}
                    ))}
                  </Box>
                </Box>
                <Box sx={{ my: 3, display: "flex" }}>
                  <Button
                    onClick={onClose}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      textTransform: "capitalize",
                      bgcolor: "#0a3114",
                      fontSize: 16,
                      color: "#fff",
                      borderRadius: 10,
                      px: 5,
                      py: 1,
                      "&:hover": {
                        bgcolor: "#0a3114",
                      },
                    }}
                    endIcon={<ArrowOutwardIcon color="#fff" />}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    type="submit"
                    // onClick={handleSave}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      textTransform: "capitalize",
                      bgcolor: "#0a3114",
                      fontSize: 16,
                      color: "#fff",
                      borderRadius: 10,
                      px: 5,
                      py: 1,
                      "&:hover": {
                        bgcolor: "#0a3114",
                      },
                    }}
                    endIcon={<ArrowOutwardIcon color="#fff" />}
                  >
                    Proceed
                  </Button>{" "}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
              maxHeight: "90dvh",
            }}
          >
            <IconButton
              onClick={onClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={deleteVec}
                  alt="Delete Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                Are you sure you want to continue?
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                This action cannot be undone and some amount will be deducted
                from your wallet as contingency against scam, this amount will
                be returned if you win this dispute.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={onClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={onSave}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
}
