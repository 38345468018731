import React, { useEffect, useMemo, useState } from "react";
import UserManagementTable from "./UserManagementTable";
import EditUserForm from "./EditUserForm";
import { Box, Typography, IconButton, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import AddUserForm from "./AddUserForm";
import loader from "../../../Assets/Images/loader.svg";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { EMPLOYEE_ROLE, EMPLOYER_ROLE } from "../../../Helpers/roles";

const rows = [
  // Your data here
  { user: "John Doe", role: "Admin", status: "Active" },
  { user: "Jane Smith", role: "Editor", status: "Inactive" },
  { user: "Alice Johnson", role: "Viewer", status: "Suspended" },
];

export default function UserManagement({ isWWH }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchrole();
  }, []);

  useEffect(() => {
    setIsAdding(false);
    setIsEditing(false);
  }, [isWWH]);

  const fetchrole = async () => {
    try {
      const token = GetUserDetails()?.token;
      const response = await getAPI("role/getAll", token);
      if (response?.statusCode == 200) {
        setRoles(
          response.data.map((ele) => ({ value: ele._id, label: ele.name }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const filteredRoles = useMemo(() => {
    if (isWWH) return roles;
    // return roles.filter(
    //   (ele) => ![EMPLOYEE_ROLE, EMPLOYER_ROLE].includes(ele.value)
    // );
    return roles.filter(
      (ele) => EMPLOYEE_ROLE == ele.value || EMPLOYER_ROLE == ele.value
    );
  }, [isWWH, roles]);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleBackClick = () => {
    setIsEditing(false);
    setIsAdding(false);
    setSelectedUser(null);
  };

  const handleDeleteClick = (user) => {
    // Handle delete logic
    console.log(`Deleting user: ${user}`);
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        {(isEditing || isAdding) && (
          <IconButton onClick={handleBackClick} sx={{ mr: 2, mb: 1 }}>
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {isEditing
            ? "Edit User"
            : isAdding
            ? "Add User"
            : isWWH
            ? "WWH User Management"
            : "User Management"}
        </Typography>
        {!isEditing && !isAdding && (
          <Box style={{ flexGrow: 1 }}>
            <Button
              onClick={(ev) => setIsAdding(true)}
              sx={{
                backgroundColor: "#0B3013",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                color: "#fff",
                px: 4,
                "&:hover": {
                  backgroundColor: "#0B3013", // Same as default background
                  boxShadow: "none", // Remove default box shadow
                },
                float: "right",
              }}
            >
              Add User
            </Button>
          </Box>
        )}
      </Box>
      {isEditing ? (
        <EditUserForm
          roles={filteredRoles}
          user={selectedUser}
          onBackClick={handleBackClick}
          isWWH={isWWH}
        />
      ) : isAdding ? (
        <AddUserForm
          roles={filteredRoles}
          onBackClick={handleBackClick}
          isWWH={isWWH}
        />
      ) : (
        <UserManagementTable
          roles={
            isWWH
              ? filteredRoles.filter(
                  (ele) => ![EMPLOYEE_ROLE, EMPLOYER_ROLE].includes(ele.value)
                )
              : filteredRoles
          }
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          isWWH={isWWH}
        />
      )}
    </section>
  );
}
