import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormLabel,
  Button,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function WithdrawMoney({ data, saveData }) {
  const [flatWithdrawal, setFlatWithdrawal] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setFlatWithdrawal(data?.flatWithdrawal);
  }, [data]);

  const setData = async () => {
    if (!flatWithdrawal || flatWithdrawal <= 0) {
      setError("Withdrawal fee sould be greater than or equal to 0.");
      return;
    }
    setError("");
    saveData({ flatWithdrawal: flatWithdrawal }, data._id);
  };

  return (
    <section>
      <Box p={1}>
        <Box width={"100%"} borderBottom={1} borderColor={"#E9E9E9"} pb={2}>
          <Typography variant="h6" fontWeight={600}>
            Withdrawal Amount Fee
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          my={3}
          justifyContent={"start"}
        >
          <FormLabel fontFamily={"inherit"}>Amount</FormLabel>
          <FormControl sx={{ my: 2 }} variant="outlined">
            <TextField
              onChange={(ev) => setFlatWithdrawal(ev.target.value)}
              value={flatWithdrawal}
              type="number"
              id="outlined-adornment-weight"
              variant="outlined"
              placeholder="Flat Fee"
              error={!!error}
              helperText={error}
            />
          </FormControl>

          <Button
            onClick={setData}
            sx={{
              display: "flex",
              fontFamily: "inherit",
              textTransform: "capitalize",
              bgcolor: "#0a3114",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              mt: 4,
              px: 5,
              py: 1,
              maxWidth: "fit-content",
              "&:hover": {
                bgcolor: "#0a3114",
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </section>
  );
}
