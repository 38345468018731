import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Button,
  Grid,
  Rating,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import doc from "../../../../Assets/Images/document-attachment.png";
import StarIcon from "@mui/icons-material/Star";
import AvatarJob from "../../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../../Assets/Images/ChatIco.png";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import { postAPI } from "../../../../Services/Api";
import {
  getCountryCode,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import NodataFound from "../../../../Components/NodataFound/NodataFound";
import styled from "@emotion/styled";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../../Localization/i18n";
import Markdown from "markdown-to-jsx";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function PostedProjectDetails({ project, onBack }) {
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const particularProject = project?.project;
  const coverLetter = project;

  const [allProposals, setAllProposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getAllPraposals();
    LoaderShow();
    //eslint-disable-next-line
  }, []);

  const getAllPraposals = async (pageNumber) => {
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      LoaderShow();

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const dataToSend = {
        projectId: particularProject?._id,
      };
      const response = await postAPI(
        `proposal/project-Proposals?page=${pn}`,
        dataToSend,
        token
      );

      if (response.statusCode === 200) {
        LoaderHide();
        setAllProposals(response.data);
        setTotalPages(response.pagination.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getAllPraposals(value);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
        <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              borderBottom: 1,
              borderColor: "#dbdbdb",
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("ProjectDetails")}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("AllPop")}
            />
          </Tabs>
        </Box>
        {tabValue === 0 && (
          <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              className="flexColumnRespo"
            >
              <Typography
                className="talentHeadingMain"
                variant="h5"
                sx={{ color: "#000" }}
              >
                {particularProject?.title}
              </Typography>
              <Typography
                className="priceHeadEmployee  catBtnTop"
                variant="h6"
                sx={{ color: "#000" }}
              >
                ${particularProject?.budget?.min} - $
                {particularProject?.budget?.max}
              </Typography>
            </Box>
            <Box className="skillText catBtnTop" sx={{ py: 4 }}>
              <Typography
                className="markdown"
                variant="p"
                sx={{ color: "#000", fontSize: 17 }}
              >
                <Markdown>{particularProject?.description}</Markdown>
              </Typography>
            </Box>

            <Box sx={{ py: 4 }}>
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("SkillReq")}
              </Typography>
              <Box
                className="flexWrapResp"
                sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
              >
                {particularProject?.skillsRequired?.map((skill, index) => (
                  <Button
                    className="skillText catBtnTop"
                    onClick={onBack}
                    sx={{
                      fontFamily: "inherit",
                      px: 4,
                      mx: 1,
                      fontSize: "16px",
                      borderRadius: 10,
                      pb: 1,
                      backgroundColor: "#0B3013",
                      width: "auto",
                      maxWidth: "fit-content",
                      textTransform: "capitalize",
                      color: "#fff",
                      display: "flex",
                      fontWeight: "400",

                      "&:hover": {
                        backgroundColor: "#0B3013", // Keep the same color as default
                      },
                    }}
                  >
                    {skill?.name}
                  </Button>
                ))}
              </Box>
            </Box>

            <Box sx={{}}>
              <Typography
                variant="h6"
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  pb: 0,
                  fontSize: 18,
                  px: 0,
                }}
              >
                {t("Attachments")}
              </Typography>
              <Box
                className="uploadSec"
                sx={{
                  py: 3,
                  pb: 0,
                }}
              >
                {particularProject?.documents?.length === 0 ? (
                  <Box
                    sx={{ py: 2, display: "flex", justifyContent: "center" }}
                  >
                    {t("NoDocumentsFound")}
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                    >
                      <Typography variant="p" sx={{ color: "#000", px: 3 }}>
                        {t("FileName")} :{" "}
                        {particularProject?.documents.map((e, index) => (
                          <a
                            href={e}
                            key={index}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {e}
                          </a>
                        ))}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              <Button
                className="skillText catBtnTop"
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  fontSize: "16px",
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  ml: "auto",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("BackButton")}
              </Button>
            </Box>
          </Box>
        )}
        {tabValue === 1 && (
          <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
            {allProposals?.length === 0 ? (
              <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
                <NodataFound />
              </Box>
            ) : (
              allProposals?.map((proposal, index) => (
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    py: 1,
                    borderBottom: 1,
                    borderColor: "#e3e3e3",
                  }}
                >
                  <Box
                    className="flexColumnRespo"
                    sx={{
                      p: 2.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box className="" sx={{ display: "flex" }}>
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          alt={
                            proposal?.user?.firstName +
                            " " +
                            proposal?.user?.lastName
                          }
                          src={proposal?.user?.profile || AvatarJob}
                          className="responsive-image avatarImg"
                          width={70}
                          height={70}
                        />
                      </Box>
                      <Box
                        sx={{ display: "flex", flexDirection: "column", px: 2 }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="h5"
                            className="userNameText"
                            sx={{ color: "#000", fontWeight: 500 }}
                          >
                            {proposal?.user?.firstName}{" "}
                            {proposal?.user?.lastName}
                          </Typography>
                          <Typography
                            variant="h5"
                            className="userNameText"
                            sx={{ color: "#222", fontWeight: 400, px: 2 }}
                          >
                            {proposal?.user?.email}
                          </Typography>
                        </Box>
                        <Box
                          className="flexWrapResp"
                          sx={{ display: "flex", py: 1, alignItems: "center" }}
                        >
                          <Rating
                            disabled
                            name="hover-feedback"
                            value={proposal?.user?.averageRating}
                            precision={0.5}
                            getLabelText={getLabelText}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                              setHover(newHover);
                            }}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {proposal?.user?.averageRating && (
                            <Box sx={{ mx: 2 }}>
                              {proposal?.user?.averageRating}
                            </Box>
                          )}

                          {proposal?.user?.ratingCount && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                pl: 2,
                              }}
                            >
                              <img src={chatIco} alt="chat" />
                              <Typography
                                variant="p"
                                sx={{ color: "#000", px: 1 }}
                              >
                                {proposal?.user?.ratingCount}
                              </Typography>
                            </Box>
                          )}
                          {/* <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={dollarIco} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              8.7
                            </Typography>
                          </Box> */}
                          {/* <Box
                            className="catBtnTop"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={pieChart} alt="chat" />
                            <Typography
                              variant="p"
                              sx={{ color: "#000", px: 1 }}
                            >
                              98%
                            </Typography>
                          </Box> */}
                          <Box
                            className="catBtnTop"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pl: 2,
                            }}
                          >
                            {proposal?.user?.location &&
                            getCountryCode(proposal?.user.location) ? (
                              <img
                                width={25}
                                height={25}
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                  proposal?.user.location
                                )}.svg`}
                                alt="chat"
                              />
                            ) : (
                              ""
                            )}
                            <Typography
                              variant="p"
                              sx={{ color: "#000", flexWrap: "wrap", px: 1 }}
                            >
                              {proposal?.user?.location}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          className="catBtnTop"
                          sx={{ display: "flex", flexWrap: "wrap", py: 0.5 }}
                        >
                          {proposal?.user?.skills?.map((skill, index) => (
                            <Typography
                              className="langText skillText"
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                                pl: 0,
                                my: 1,
                                mr: 2,
                              }}
                            >
                              {skill?.name}
                            </Typography>
                          ))}
                          {/* <Typography
                              className="langText skillText"
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                borderRight: 1,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Illustrations Designer
                            </Typography>
                            <Typography
                              className="langText skillText"
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontSize: 18,
                                fontWeight: 500,
                                px: 2,
                              }}
                            >
                              Figma Expert
                            </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className="flexDirectionRowResp catBtnTop"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        className="userNameText "
                        variant="h5"
                        sx={{
                          color: "#000",
                          px: 2,
                          fontWeight: 500,
                          width: "350px",
                          maxWidth: "min-content",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "block",
                        }}
                      >
                        ${proposal?.maxAmount}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: "#222", px: 2, fontWeight: 500 }}
                      >
                        {proposal?.deliveryTime?.name || ""}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid
                    container
                    spacing={2}
                    className=" catBtnTop flexColumnRespo"
                  >
                    <Grid itemxs={12} xl={8} lg={8} md={8}>
                      <Box
                        sx={{
                          p: 2.5,
                          pt: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          className="markdown"
                          variant="p"
                          sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                        >
                          <Markdown>{proposal?.description}</Markdown>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            )}

            <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
              {allProposals?.length > 0 ? (
                <CustomPagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              ) : (
                ""
              )}
            </Box>

            {/* <Box
              sx={{
                backgroundColor: "#fff",
                py: 1,
                borderBottom: 1,
                borderColor: "#e3e3e3",
              }}
            >
              <Box
                className="flexColumnRespo"
                sx={{
                  p: 2.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box className="" sx={{ display: "flex" }}>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={AvatarJob}
                      className="responsive-image avatarImg"
                      alt="dfh"
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h5"
                        className="userNameText"
                        sx={{ color: "#000", fontWeight: 500 }}
                      >
                        John Doe
                      </Typography>
                      <Typography
                        variant="h5"
                        className="userNameText"
                        sx={{ color: "#222", fontWeight: 400, px: 2 }}
                      >
                        @johndoe
                      </Typography>
                    </Box>
                    <Box
                      className="flexWrapResp"
                      sx={{ display: "flex", py: 1, alignItems: "center" }}
                    >
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      {value !== null && (
                        <Box sx={{ mx: 2 }}>
                          {labels[hover !== -1 ? hover : value]}
                        </Box>
                      )}

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={chatIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          200
                        </Typography>
                      </Box>
                      <Box
                        className="catBtnTop"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={dollarIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          8.7
                        </Typography>
                      </Box>
                      <Box
                        className="catBtnTop"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={pieChart} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          98%
                        </Typography>
                      </Box>
                      <Box
                        className="catBtnTop"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <img src={indiaIco} alt="chat" />
                        <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                          India
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="catBtnTop"
                      sx={{ display: "flex", py: 0.5 }}
                    >
                      <Typography
                        className="langText skillText"
                        variant="h6"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                          pl: 0,
                        }}
                      >
                        UI/UX Designer
                      </Typography>
                      <Typography
                        className="langText skillText"
                        variant="h6"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          borderRight: 1,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Illustrations Designer
                      </Typography>
                      <Typography
                        className="langText skillText"
                        variant="h6"
                        sx={{
                          color: "#222",
                          fontSize: 18,
                          fontWeight: 500,
                          px: 2,
                        }}
                      >
                        Figma Expert
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="flexDirectionRowResp catBtnTop"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className="userNameText "
                    variant="h5"
                    sx={{ color: "#000", px: 2, fontWeight: 500 }}
                  >
                    $150
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "#222", px: 2, fontWeight: 500 }}
                  >
                    In 7 Days
                  </Typography>
                </Box>
              </Box>

              <Grid
                container
                spacing={2}
                className=" catBtnTop flexColumnRespo"
              >
                <Grid itemxs={12} xl={8} lg={8} md={8}>
                  <Box
                    sx={{
                      p: 2.5,
                      pt: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
                    >
                      I'm seeking an experienced and efficient app developer to
                      create an innovative social networking application for iOS
                      platform. Details about the target audience are currently
                      confidential. Since the completion I'm seeking an
                      experienced and efficient app developer to create more....
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
            </Box> */}

            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mr: "auto",
                py: 4,
                pb: 0,
              }}
            >
              <CustomPagination count={2} />
            </Box> */}
          </Box>
        )}
      </Box>
    </>
  );
}
