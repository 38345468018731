import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Paypal from "../../Assets/Images/Paypal.png";
import Stripe from "../../Assets/Images/Stripe.svg";
import Crypto from "../../Assets/Images/Crypto.png";
import { loadStripe } from "@stripe/stripe-js";
import loader from "../../Assets/Images/loader.svg";
import { getAPI, postAPI } from "../../Services/Api";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const BalanceWithdrawDialog = ({
  open,
  onClose,
  data,
  balance,
  isEmployee = false,
}) => {
  const [selectedValue, setSelectedValue] = React.useState("Stripe");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  useEffect(() => {
    if (isEmployee) {
      setSelectedValue("Crypto");
    } else {
      setSelectedValue("Stripe");
    }
  }, [isEmployee]);

  const handlePaymentSubmit = async () => {
    if (balance - data?.flatWithdrawal <= 0) {
      return;
    }
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const res = await postAPI(
        "wallet/withdraw",
        {
          method: selectedValue,
          fee: data?.flatWithdrawal,
          amount: balance - data?.flatWithdrawal,
        },
        token
      );
      if (res.statusCode == 200) {
        toast.success(
          "Balance will be deposited in your account within 24 hours."
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
  };
  return (
    <>
      <>
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="subscribe-modal-title"
          aria-describedby="subscribe-modal-description"
        >
          <Box
            className="employeeProjdetails"
            sx={{
              position: "absolute",
              width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 4,
              borderRadius: 5,
            }}
          >
            <Box>
              <>
                <Box className="SubHeader">
                  <Typography
                    variant="h5"
                    id="subscribe-modal-title"
                    sx={{
                      borderBottom: 1,
                      py: 2,
                      pt: 0,
                      borderColor: "#E6E6E6",
                    }}
                    gutterBottom
                  >
                    {t("Payment")}
                  </Typography>
                </Box>
                {!isEmployee && (
                  <>
                    <Box
                      sx={{
                        py: 1,
                        border: 1,
                        my: 2,
                        borderColor: "#E6E6E6",
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        className="alignBaselineRsp flexColumnRespo"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            {...controlProps("Paypal")}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                                color: "#0B3013",
                                m: 0,
                                p: 0,
                              },
                            }}
                          />
                          <img src={Paypal} alt="Paypal" />
                        </Box>
                        <Typography sx={{ px: 2 }}>
                          {t("paypalPaymentDes")}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        py: 1,
                        border: 1,
                        my: 2,
                        borderColor: "#E6E6E6",
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        className="alignBaselineRsp flexColumnRespo"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            {...controlProps("Stripe")}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                                color: "#0B3013",
                              },
                            }}
                          />
                          <img
                            src={Stripe}
                            alt="Stripe"
                            style={{
                              width: "auto",
                              height: "auto",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                          />
                        </Box>
                        <Typography sx={{ px: 2 }}>
                          {t("stripePaymentDes")}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    py: 1,
                    border: 1,
                    my: 2,
                    borderColor: "#E6E6E6",
                    borderRadius: 2,
                    mb: 2.5,
                  }}
                >
                  <Box
                    className="alignBaselineRsp flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        {...controlProps("Crypto")}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            color: "#0B3013",
                          },
                        }}
                      />
                      <img src={Crypto} alt="Crypto" />
                    </Box>
                    <Typography sx={{ px: 2 }}>
                      {t("cryptoPaymentDes")}
                    </Typography>
                  </Box>
                </Box>
              </>
              <hr />
              <Box
                sx={{
                  py: 1,
                  my: 2,
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    <span>SubTotal</span>
                    <span>${balance}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      alignItems: "center",
                    }}
                  >
                    <span>Withdrawal Fee</span>
                    <span>${data?.flatWithdrawal}</span>
                  </div>
                  <span style={{ color: "red" }}>
                    {balance - data?.flatWithdrawal <= 0
                      ? "Dont have enough balance to withdraw"
                      : ""}
                  </span>
                </Box>
              </Box>
              {/* You can add more content or form inputs here */}
              <Button
                variant="contained"
                fullWidth={true}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 18,
                  bgcolor: "#0B3013!important",
                  color: "#fff",
                  borderRadius: 2,
                }}
                onClick={handlePaymentSubmit}
              >
                {t("withdraw")} $
                {balance - data?.flatWithdrawal <= 0
                  ? 0
                  : balance - data?.flatWithdrawal}
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    </>
  );
};

export default BalanceWithdrawDialog;
