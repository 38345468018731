import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Chip,
  InputBase,
  Menu,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "../../../Assets/Images/resolvedVec.png"; // Import the delete icon

import editICO from "../../../Assets/Images/EditIcon.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { deleteAPI, getAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import loader from "../../../Assets/Images/loader.svg";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import AddPayoutDialog from "./AddPayoutDialog";

export default function DonationPayoutManagement() {
  const [selectedCase, setSelectedCase] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const { t } = useTranslation();
  const [donations, setDonations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [showPagination, setShowPagination] = useState(true);
  const [timeOutId, setTimeOutId] = useState(false);

  const [totalDonation, setTotalDonation] = useState({});

  const handleAddOpen = () => {
    setSelectedPayout({});
    setEditModalOpen(true);
  };

  const handleEditOpen = (donation) => {
    setSelectedPayout(donation);
    setEditModalOpen(true);
  };

  const handleDeleteOpen = (donation) => {
    setSelectedPayout(donation);
    setDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedPayout(null);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const fetchSearchedDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const response = await getAPI(
          `donation-investments/list?page=${pageNumber}`,
          token
        );
        if (response?.investments) {
          setTotalPages(response?.totalPages || 1);
          setDonations(response?.investments || []);
          setTotalDonation({
            totalAmountOfAllDonations: response?.totalInvestmentAmount,
            totalAmountForMethod: response?.difference,
          });
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  const fetchDonations = async (
    ev = {},
    pageNumber = 1,
    paymentMethod = selectedPaymentMethod,
    search = searchText
  ) => {
    LoaderShow();
    await fetchSearchedDonations(ev, pageNumber, paymentMethod, search);
    LoaderHide();
  };

  useEffect(() => {
    fetchDonations();
  }, []);

  const handleViewCase = (disputeCase) => {
    setSelectedCase(disputeCase);
  };

  const clearFilter = async () => {
    if (selectedPaymentMethod) {
      setShowPagination(false);
      await fetchDonations({}, 1, "");
      setSelectedPaymentMethod("");
      setShowPagination(true);
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Extract the day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const data = {};
      if (token && selectedPayout?._id) {
        const response = await deleteAPI(
          `donation-investments/delete/${selectedPayout?._id}`,
          data,
          token
        );
        if (response.statusCode == 200) {
          setDonations((users) =>
            users.filter((ele) => ele?._id != selectedPayout?._id)
          );
          setTotalDonation((TD) => ({
            totalAmountOfAllDonations:
              TD.totalAmountOfAllDonations - selectedPayout.amount,
            totalAmountForMethod:
              TD.totalAmountForMethod - selectedPayout.amount,
          }));
          toast.success("Donation payout deleted successfully");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
    handleClose();
  };

  return (
    <Box>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Donation Payout Management
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 3,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ pb: 1, fontWeight: 600 }}>
              Total Payout : ${totalDonation?.totalAmountOfAllDonations || 0}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Button
              onClick={handleAddOpen}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                ml: 1,
                backgroundColor: "#0B3013",
                color: "#fff",
                borderRadius: "24px",
                py: "9px",
                px: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              Add payout
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            mt: 4,
          }}
        >
          {donations.length > 0 ? (
            <>
              <div style={{ overflow: "auto" }}>
                <Table
                  aria-label="dispute case management table"
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0",
                    marginTop: "0px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Organisation name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Donation Amount
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {donations.map((donation, index) => (
                      <TableRow key={donation.id}>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {donation?.organizationName || "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          ${donation.amount || 0}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          {formatDate(donation.date)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "24px",
                            fontFamily: "inherit",
                            fontSize: "16px",
                            borderBottom:
                              index === donations.length - 1
                                ? "0"
                                : "1px solid #e0e0e0",
                          }}
                        >
                          <IconButton
                            sx={{
                              backgroundColor: "#f7fdf9",
                              borderRadius: "4px",
                              p: 0.5,
                            }}
                            color="primary"
                            aria-label="delete"
                            onClick={() => handleEditOpen(donation)}
                          >
                            <img src={editICO} alt="Edit" />
                          </IconButton>
                          <IconButton
                            onClick={(ev) => handleDeleteOpen(donation)}
                            sx={{
                              backgroundColor: "#f7fdf9",
                              borderRadius: "4px",
                              mx: 1,
                              p: 0.5,
                            }}
                            aria-label="delete"
                          >
                            <img src={deleteICO} alt="Delete" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <Box
                sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}
              >
                {showPagination && (
                  <CustomPagination
                    count={totalPages}
                    onChange={fetchDonations}
                  />
                )}
              </Box>
            </>
          ) : (
            <NodataFound />
          )}
        </Box>

        <Typography
          variant="h5"
          sx={{
            py: 4,
            fontWeight: 600,
            textAlign: "right",
            textTransform: "capitalize",
          }}
        >
          Remaining Donation : ${totalDonation?.totalAmountForMethod || 0}
        </Typography>
      </>
      <Dialog
        open={deleteModalOpen}
        onClose={handleClose}
        aria-labelledby="edit-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "30px",
          },
        }}
      >
        <DialogTitle id="edit-dialog-title">
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="db" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "auto",
              }}
            >
              <img className="" src={DeleteIcon} alt="db" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              Are you sure you want to delete?
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              This action cannot be undone.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 2 }}
        >
          <Box>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
                mx: 3,
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                mx: 3,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <AddPayoutDialog
        open={editModalOpen}
        onClose={handleClose}
        onSave={fetchDonations}
        payout={selectedPayout}
      />
    </Box>
  );
}
