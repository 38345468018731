import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  FormLabel,
  Select,
  OutlinedInput,
  Stack,
  MenuItem,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./SkillSetEmployee.css";
import { getAPI } from "../../../../../Services/Api";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import LockIcon from "@material-ui/icons/Lock"; // Add this line
import { LoaderHide, LoaderShow } from "../../../../../Helpers/Utility";

// rest of your code

export default function SkillSetEmployee(props) {
  const [selectedSkills, setSelectedSkills] = useState(["HTML/CSS"]); // Initialize with "UX Design"
  const [skillError, setSkillError] = useState(""); // State for skill validation error
  const [skills, setSkills] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    LoaderShow();
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setProjectData(response?.data);
        setSelectedCategory(
          response.data.categories.map((ele) => ele._id).slice(0, 2)
        );
      }
    } catch (error) {
      console.log("error===", error);
    }
    LoaderHide();
  };

  const filteredSkills = useMemo(() => {
    let arr = [];
    if (projectData?.categories) {
      selectedCategory.forEach((category) => {
        const categ = projectData.categories.find((ele) => ele._id == category);
        if (categ && categ?.skills?.length) arr = [...arr, ...categ.skills];
      });
    }
    return arr;
  }, [selectedCategory, projectData]);

  const allSkills = useMemo(() => {
    let arr = [];
    if (projectData?.categories) {
      projectData?.categories.forEach((category) => {
        if (category?.skills?.length) arr = [...arr, ...category.skills];
      });
    }
    return arr;
  }, [projectData]);

  const GetSkills = async () => {
    const response = await getAPI("skills/all");
    if (response.statusCode === 200) {
      setSkills(response?.data);
    } else {
      console.log("error ");
    }
  };

  useEffect(() => {
    const filteredskillsName = filteredSkills.map((ele) => ele.name);
    setSelectedSkills((skills) =>
      skills.filter((ele) => filteredskillsName.includes(ele))
    );
  }, [filteredSkills]);

  const selectedIds = [];
  selectedSkills.forEach((skillName) => {
    const skill = allSkills.find((s) => s.name.trim() === skillName.trim());
    if (skill) {
      selectedIds.push(skill._id);
    }
  });

  const handleChipClick = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((item) => item !== skill));
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
    setSkillError(""); // Reset skill validation error
  };

  const handleSaveAndContinue = () => {
    if (selectedSkills.length === 0) {
      setSkillError("Please select at least one skill"); // Set skill validation error message
    } else {
      props.SetEmployeeUserDataObject("skillsetIDEmployee", selectedIds);
      props.SetEmployeeUserDataObject("categoryIDEmployee", selectedCategory);
      props.SetEmployeeUserDataObject("skillsetEmployee", selectedSkills);
      props.handleNextStepEmployee();
    }
  };

  return (
    <Box
      sx={{
        p: 8,
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
      }}
      className="sideBg"
    >
      <Box>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 700 }}>
          {t("whatIsExp")}
        </Typography>
        <p className="beginText" style={{ marginBottom: "10px" }}>
          {" "}
          Please choose single or multiple options
        </p>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Select
          fullWidth
          multiple
          value={selectedCategory}
          onChange={(ev) => setSelectedCategory(ev.target.value)}
          input={<OutlinedInput label={t("SkillsOne")} />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected?.map((value) => (
                // <Chip key={value} label={"React"} />
                <Chip
                  key={value}
                  label={
                    projectData?.categories?.find(
                      (skill) => skill?._id === value
                    )?.name || ""
                  }
                />
              ))}
            </Stack>
          )}
        >
          {projectData?.categories?.map((skill) => (
            <MenuItem key={skill._id} value={skill._id}>
              {skill.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 700 }}>
          {t("Tell2")}
        </Typography>
        <p className="beginText"> {t("ThiHelp")}</p>
      </Box>

      <Box
        sx={{
          mt: 2,
          border: 1,
          p: 2,
          borderColor: "#8692A6",
          borderRadius: 2,
          color: "#fff",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "10px 0",
        }}
      >
        {selectedSkills.map((skill, index) => (
          <Chip
            key={index}
            label={skill}
            onDelete={() => handleChipClick(skill)}
            deleteIcon={<RemoveIcon />} // Remove the color prop here
            sx={{
              mr: 1,
              bgcolor: "#0B3013",
              color: "#fff",
              "& .MuiSvgIcon-root": {
                // Apply styles to the nested icon
                color: "#fff", // Change this to the desired color
              },
            }}
          />
        ))}
      </Box>

      {skillError && (
        <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
          {skillError}
        </Typography>
      )}

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ textAlign: "start", pb: 2 }}>
          {t("Suggested")}
        </Typography>
        {filteredSkills.map((skill, index) => (
          <Chip
            key={index}
            label={skill.name}
            clickable
            onClick={() => handleChipClick(skill.name)}
            deleteIcon={
              selectedSkills.includes(skill.name) ? (
                <RemoveIcon color="secondary" />
              ) : (
                <AddIcon color="secondary" />
              )
            }
            color={selectedSkills.includes(skill.name) ? "default" : "default"}
            onDelete={() => handleChipClick(skill.name)}
            sx={{
              m: 1,
              mr: 1,
              mb: 1,
              ...(selectedSkills.includes(skill.name) && {
                bgcolor: "#fff",
                border: 1,
                borderColor: "#0B3013",
                color: "#0B3013",
              }), // Apply background color for selected skills
            }}
          />
        ))}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleSaveAndContinue}
          className="SignUpBtn"
          sx={{ textTransform: "capitalize", width: 200, color: "white" }}
        >
          {t("Save")}
        </Button>
      </Box>

      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{
            display: "flex",
            textAlign: "center",
            py: 1,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
          {t("YourInfo")}
        </FormLabel>
      </Box>
    </Box>
  );
}
