import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Modal,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import deleteVec from "../../../Assets/Images/deleteVector.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import editICO from "../../../Assets/Images/EditIcon.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { getStatusChip } from "./utils"; // Ensure this path is correct
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { ROLES } from "../../../Helpers/roles";

const RolesManagementTable = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [newRole, setNewRole] = useState("");
  const [selectedRole, setSelectedRole] = useState({});
  const [roles, setRoles] = useState([]);
  // Modal state for delete confirmation
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetchrole();
  }, []);

  const fetchrole = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const response = await getAPI("role/getAll", token);
      if (response?.statusCode == 200) {
        setRoles(response.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteModalOpen(false);
    setNewRole("");
    setSelectedRole({});
  };

  const onEditClick = (role) => {
    setSelectedRole(role);
    setNewRole(role.name);
    setOpen(true);
  };

  const onDeleteClick = (role) => {
    setSelectedRole(role);
    setNewRole(role.name);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const data = {
        id: selectedRole?._id,
      };
      if (token && data.id) {
        const response = await deleteAPI("role/delete", data, token);
        if (response.statusCode == 200) {
          setRoles((roles) =>
            roles.filter((ele) => ele?._id != selectedRole?._id)
          );
          toast.success("Role deleted successfully");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
    handleClose();
  };

  const handleAddRoleClick = () => {
    // Create a new role object with default values
    const newRole = { role: "", user: null };
    onEditClick(newRole); // Trigger the edit form with the new role object
  };

  const changeRole = async (ev) => {
    ev.preventDefault();
    LoaderShow();
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        if (selectedRole?._id) {
          const response = await updateAPI(
            "role/update",
            {
              id: selectedRole?._id,
              name: newRole,
            },
            token
          );
          if (response.statusCode == 200) {
            setRoles((role) =>
              role.map((ele) => {
                if (ele._id == response.data._id) ele.name = newRole;
                return ele;
              })
            );
            handleClose();
          }
        } else {
          const response = await postAPI(
            "role/create",
            { name: newRole },
            token
          );
          if (response.statusCode == 200) {
            setRoles((role) => [...role, response.data]);
            handleClose();
          }
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
    LoaderHide();
    return;
  };

  return (
    <Box sx={{ p: 0 }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="subscribe-modal-title"
        aria-describedby="subscribe-modal-description"
      >
        <Box
          className="employeeProjdetails"
          sx={{
            position: "absolute",
            width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <Box className="SubHeader">
            <Typography
              variant="h5"
              id="subscribe-modal-title"
              sx={{
                borderBottom: 1,
                py: 2,
                pt: 0,
                borderColor: "#E6E6E6",
              }}
              gutterBottom
            >
              {selectedRole._id ? "Update" : "Add"} Role
              <CloseIcon
                sx={{ cursor: "pointer", float: "right" }}
                onClick={handleClose}
              />
            </Typography>
          </Box>
          <form onSubmit={(ev) => changeRole(ev)}>
            <FormControl
              sx={{
                mt: 1,
              }}
              fullWidth
            >
              <FormLabel
                sx={{
                  display: "block",
                  textAlign: "start",
                  // py: 1.4,
                  mt: -1,
                  fontSize: 20,
                  color: "#343a40",
                }}
              >
                Role
              </FormLabel>
              <TextField
                required
                variant="outlined"
                min={0.01}
                sx={{
                  background: "transparent",
                  borderColor: "#E0E0E0",
                  boxShadow: "none",
                }}
                fullWidth={true}
                placeholder="Enter Role"
                id="perHourAmount"
                name="perHourAmount"
                onChange={(ev) => setNewRole(ev.target.value)}
                value={newRole}
              />
            </FormControl>
            <Button
              type="submit"
              className="SignUpBtn"
              sx={{
                mt: 2,
                textTransform: "capitalize",
                py: "8px !important",
                px: "24px !important",
                width: "auto !important",
                borderRadius: 12,
                maxWidth: "auto !important",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              Save
            </Button>
          </form>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          mb: 2,
        }}
      >
        <Button
          onClick={(ev) => setOpen(true)} // Call the handler for adding a role
          sx={{
            backgroundColor: "#0B3013",
            fontSize: 16,
            borderRadius: 20,
            textTransform: "capitalize",
            color: "#fff",
            px: 4,
            "&:hover": {
              backgroundColor: "#0B3013", // Same as default background
              boxShadow: "none", // Remove default box shadow
            },
          }}
        >
          Add Role
        </Button>
      </Box>

      {roles.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            overflow: "hidden",
            mb: 4,
          }}
        >
          <Table
            aria-label="user management table"
            sx={{ borderCollapse: "separate", borderSpacing: "0" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Role
                  {/* <IconButton size="small" sx={{ ml: 1 }}>
                    <FilterAltOutlinedIcon sx={{ color: "#0B3013" }} />
                  </IconButton> */}
                </TableCell>

                <TableCell
                  align="center"
                  sx={{
                    padding: "24px 24px",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "16px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role, index) => (
                <TableRow key={role._id}>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === roles.length - 1 ? "0" : "1px solid #e0e0e0",
                    }}
                  >
                    {role.name}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      padding: "24px 24px",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      borderBottom:
                        index === roles.length - 1 ? "0" : "1px solid #e0e0e0",
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "#f7fdf9",
                        borderRadius: "4px",
                        p: 0.5,
                      }}
                      color="primary"
                      aria-label="edit"
                      onClick={() => onEditClick(role)} // Pass the entire row object
                    >
                      <img src={editICO} alt="Edit" />
                    </IconButton>
                    {!ROLES.map((ele) => ele._id).includes(role._id) && (
                      <IconButton
                        sx={{
                          backgroundColor: "#f7fdf9",
                          borderRadius: "4px",
                          p: 0.5,
                        }}
                        color="primary"
                        aria-label="edit"
                        onClick={() => onDeleteClick(role)} // Pass the entire row object
                      >
                        <img src={deleteICO} alt="Delete" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
            <CustomPagination count={2} />
          </Box> */}
        </TableContainer>
      ) : (
        <NodataFound />
      )}
      {/* Delete Confirmation Modal */}
      <Modal
        open={deleteModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={deleteVec} alt="Delete Vector" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              Are you sure you want to delete?
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              This action cannot be undone.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
              onClick={handleConfirmDelete}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RolesManagementTable;
