import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Grid,
  Rating,
  TableContainer,
  useMediaQuery,
  FormLabel,
  TextField,
  Paper,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import del from "../../../Assets/Images/delet.png";
import doc from "../../../Assets/Images/document-attachment.png";
import StarIcon from "@mui/icons-material/Star";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
import indiaIco from "../../../Assets/Images/indiaFlag.png";
import EditIcon from "../../../Assets/Images/EditIcon.png";
import pieChart from "../../../Assets/Images/pie-chart.png";
import DeleteIcon from "../../../Assets/Images/DeleteICon.png";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import DeleteModal from "../DeleteModal/DeleteModal";
import { getAPI, postAPI, updateAPI } from "../../../Services/Api";
import {
  getCountryCode,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import loader from "../../../Assets/Images/loader.svg";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import styled from "@emotion/styled";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import PostedProjectDetails from "./PostedProjectDetails/PostedProjectDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

// function PostedProjectDetails({ project, onBack }) {
//   const [tabValue, setTabValue] = useState(0);
//   const [value, setValue] = React.useState(2);
//   const [hover, setHover] = React.useState(-1);
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
//   const { t } = useTranslation();

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const particularProject = project?.project;
//   const coverLetter = project;

//   const [allProposals, setAllProposals] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);

//   useEffect(() => {
//     getAllPraposals();
//     LoaderShow();
//     //eslint-disable-next-line
//   }, []);

//   const getAllPraposals = async (pageNumber) => {
//     LoaderShow();
//     try {
//       const token = GetUserDetails().token;
//       LoaderShow();

//       var pn;
//       if (pageNumber === undefined || pageNumber === "") {
//         pn = 1;
//       } else {
//         pn = pageNumber;
//       }

//       const dataToSend = {
//         projectId: particularProject?._id,
//       };
//       const response = await postAPI(
//         `proposal/project-Proposals?page=${pn}`,
//         dataToSend,
//         token
//       );

//       if (response.statusCode === 200) {
//         LoaderHide();
//         setAllProposals(response.data);
//         setTotalPages(response.pagination.totalPages);
//       }
//     } catch (error) {
//       LoaderHide();
//       // toast.error(error.message)
//     }
//   };

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//     getAllPraposals(value);
//   };

//   return (
//     <>
//       <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
//         <Box sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }} className="plZero prZero">
//           <Tabs
//             variant={isMobile ? "scrollable" : "standard"}
//             scrollButtons={isMobile ? "auto" : "off"}
//             allowScrollButtonsMobile={isMobile}
//             textColor="#222222"
//             sx={{
//               borderBottom: 1,
//               borderColor: "#dbdbdb",
//               "& .MuiTabs-indicator": {
//                 backgroundColor: "#000",
//               },
//             }}
//             value={tabValue}
//             onChange={handleChange}
//             aria-label="project details tabs"
//           >
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("ProjectDetails")}
//             />
//             <Tab
//               sx={{
//                 textTransform: "capitalize",
//                 pl: 0,
//                 mx: 1,
//                 fontSize: 17,
//                 fontFamily: "inherit",
//               }}
//               label={t("AllPop")}
//             />
//           </Tabs>
//         </Box>
//         {tabValue === 0 && (
//           <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//             <Box
//               sx={{ display: "flex", justifyContent: "space-between" }}
//               className="flexColumnRespo"
//             >
//               <Typography
//                 className="talentHeadingMain"
//                 variant="h5"
//                 sx={{ color: "#000" }}
//               >
//                 {particularProject?.title}
//               </Typography>
//               <Typography
//                 className="priceHeadEmployee  catBtnTop"
//                 variant="h6"
//                 sx={{ color: "#000" }}
//               >
//                 ${particularProject?.budget?.min} - $
//                 {particularProject?.budget?.max}
//               </Typography>
//             </Box>
//             <Box className="skillText catBtnTop" sx={{ py: 4 }}>
//               <Typography variant="p" sx={{ color: "#000", fontSize: 17 }}>
//                 {particularProject?.description}
//               </Typography>
//             </Box>

//             <Box sx={{ py: 4 }}>
//               <Typography variant="h6" sx={{ color: "#000" }}>
//                 {t("SkillReq")}
//               </Typography>
//               <Box
//                 className="flexWrapResp"
//                 sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
//               >
//                 {particularProject?.skillsRequired?.map((skill, index) => (
//                   <Button
//                     className="skillText catBtnTop"
//                     onClick={onBack}
//                     sx={{
//                       fontFamily: "inherit",
//                       px: 4,
//                       mx: 1,
//                       fontSize: "16px",
//                       borderRadius: 10,
//                       pb: 1,
//                       backgroundColor: "#0B3013",
//                       width: "auto",
//                       maxWidth: "fit-content",
//                       textTransform: "capitalize",
//                       color: "#fff",
//                       display: "flex",
//                       fontWeight: "400",

//                       "&:hover": {
//                         backgroundColor: "#0B3013", // Keep the same color as default
//                       },
//                     }}
//                   >
//                     {skill?.name}
//                   </Button>
//                 ))}
//               </Box>
//             </Box>

//             <Box sx={{}}>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   color: "#000",
//                   fontWeight: 600,
//                   pb: 0,
//                   fontSize: 18,
//                   px: 0,
//                 }}
//               >
//                 {t("Attachments")}
//               </Typography>
//               <Box
//                 className="uploadSec"
//                 sx={{
//                   py: 3,
//                   pb: 0,
//                 }}
//               >
//                 {particularProject?.documents?.length === 0 ? (
//                   <Box
//                     sx={{ py: 2, display: "flex", justifyContent: "center" }}
//                   >
//                     {t("NoDocumentsFound")}
//                   </Box>
//                 ) : (
//                   <Box sx={{ display: "flex", alignItems: "center" }}>
//                     <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />

//                     <Typography
//                       variant="p"
//                       sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
//                     >
//                       <Typography variant="p" sx={{ color: "#000", px: 3 }}>
//                         {t("FileName")} :{" "}
//                         {particularProject?.documents.map((e, index) => (
//                           <a
//                             href={e}
//                             key={index}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                             {e}
//                           </a>
//                         ))}
//                       </Typography>
//                     </Typography>
//                   </Box>
//                 )}
//               </Box>
//             </Box>
//             <Box sx={{ my: 2 }}>
//               <Button
//                 className="skillText catBtnTop"
//                 onClick={onBack}
//                 sx={{
//                   fontFamily: "inherit",
//                   px: 4,
//                   fontSize: "16px",
//                   pb: 1,
//                   backgroundColor: "#0B3013",
//                   width: "auto",
//                   maxWidth: "fit-content",
//                   textTransform: "capitalize",
//                   color: "#fff",
//                   display: "flex",
//                   ml: "auto",
//                   "&:hover": {
//                     backgroundColor: "#0B3013", // Keep the same color as default
//                   },
//                 }}
//               >
//                 {t("BackButton")}
//               </Button>
//             </Box>
//           </Box>
//         )}
//         {tabValue === 1 && (
//           <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
//             {allProposals?.length === 0 ? (
//               <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
//                 <NodataFound />
//               </Box>
//             ) : (
//               allProposals?.map((proposal, index) => (
//                 <Box
//                   sx={{
//                     backgroundColor: "#fff",
//                     py: 1,
//                     borderBottom: 1,
//                     borderColor: "#e3e3e3",
//                   }}
//                 >
//                   <Box
//                     className="flexColumnRespo"
//                     sx={{
//                       p: 2.5,
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Box className="" sx={{ display: "flex" }}>
//                       <Box sx={{ display: "flex" }}>
//                         <Avatar
//                           alt={
//                             proposal?.user?.firstName +
//                             " " +
//                             proposal?.user?.lastName
//                           }
//                           src={proposal?.user?.profile || AvatarJob}
//                           className="responsive-image avatarImg"
//                           width={70}
//                           height={70}
//                         />
//                       </Box>
//                       <Box
//                         sx={{ display: "flex", flexDirection: "column", px: 2 }}
//                       >
//                         <Box sx={{ display: "flex" }}>
//                           <Typography
//                             variant="h5"
//                             className="userNameText"
//                             sx={{ color: "#000", fontWeight: 500 }}
//                           >
//                             {proposal?.user?.firstName}{" "}
//                             {proposal?.user?.lastName}
//                           </Typography>
//                           <Typography
//                             variant="h5"
//                             className="userNameText"
//                             sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                           >
//                             {proposal?.user?.email}
//                           </Typography>
//                         </Box>
//                         <Box
//                           className="flexWrapResp"
//                           sx={{ display: "flex", py: 1, alignItems: "center" }}
//                         >
//                           <Rating
//                             disabled
//                             name="hover-feedback"
//                             value={proposal?.user?.averageRating}
//                             precision={0.5}
//                             getLabelText={getLabelText}
//                             onChange={(event, newValue) => {
//                               setValue(newValue);
//                             }}
//                             onChangeActive={(event, newHover) => {
//                               setHover(newHover);
//                             }}
//                             emptyIcon={
//                               <StarIcon
//                                 style={{ opacity: 0.55 }}
//                                 fontSize="inherit"
//                               />
//                             }
//                           />
//                           {proposal?.user?.averageRating && (
//                             <Box sx={{ mx: 2 }}>
//                               {proposal?.user?.averageRating}
//                             </Box>
//                           )}

//                           {proposal?.user?.ratingCount && (
//                             <Box
//                               sx={{
//                                 display: "flex",
//                                 alignItems: "center",
//                                 pl: 2,
//                               }}
//                             >
//                               <img src={chatIco} alt="chat" />
//                               <Typography
//                                 variant="p"
//                                 sx={{ color: "#000", px: 1 }}
//                               >
//                                 {proposal?.user?.ratingCount}
//                               </Typography>
//                             </Box>
//                           )}
//                           {/* <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             <img src={dollarIco} alt="chat" />
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               8.7
//                             </Typography>
//                           </Box> */}
//                           {/* <Box
//                             className="catBtnTop"
//                             sx={{ display: "flex", alignItems: "center" }}
//                           >
//                             <img src={pieChart} alt="chat" />
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", px: 1 }}
//                             >
//                               98%
//                             </Typography>
//                           </Box> */}
//                           <Box
//                             className="catBtnTop"
//                             sx={{
//                               display: "flex",
//                               alignItems: "center",
//                               pl: 2,
//                             }}
//                           >
//                             {proposal?.user?.location &&
//                             getCountryCode(proposal?.user.location) ? (
//                               <img
//                                 width={25}
//                                 height={25}
//                                 src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
//                                   proposal?.user.location
//                                 )}.svg`}
//                                 alt="chat"
//                               />
//                             ) : (
//                               ""
//                             )}
//                             <Typography
//                               variant="p"
//                               sx={{ color: "#000", flexWrap: "wrap", px: 1 }}
//                             >
//                               {proposal?.user?.location}
//                             </Typography>
//                           </Box>
//                         </Box>
//                         <Box
//                           className="catBtnTop"
//                           sx={{ display: "flex", flexWrap: "wrap", py: 0.5 }}
//                         >
//                           {proposal?.user?.skills?.map((skill, index) => (
//                             <Typography
//                               className="langText skillText"
//                               variant="h6"
//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 borderRight: 1,
//                                 fontWeight: 500,
//                                 px: 2,
//                                 pl: 0,
//                                 my: 1,
//                                 mr: 2,
//                               }}
//                             >
//                               {skill?.name}
//                             </Typography>
//                           ))}
//                           {/* <Typography
//                               className="langText skillText"
//                               variant="h6"
//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 borderRight: 1,
//                                 fontWeight: 500,
//                                 px: 2,
//                               }}
//                             >
//                               Illustrations Designer
//                             </Typography>
//                             <Typography
//                               className="langText skillText"
//                               variant="h6"
//                               sx={{
//                                 color: "#222",
//                                 fontSize: 18,
//                                 fontWeight: 500,
//                                 px: 2,
//                               }}
//                             >
//                               Figma Expert
//                             </Typography> */}
//                         </Box>
//                       </Box>
//                     </Box>
//                     <Box
//                       className="flexDirectionRowResp catBtnTop"
//                       sx={{
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Typography
//                         className="userNameText "
//                         variant="h5"
//                         sx={{
//                           color: "#000",
//                           px: 2,
//                           fontWeight: 500,
//                           width: "350px",
//                           maxWidth: "min-content",
//                           whiteSpace: "nowrap",
//                           overflow: "hidden",
//                           textOverflow: "ellipsis",
//                           display: "block",
//                         }}
//                       >
//                         ${proposal?.maxAmount}
//                       </Typography>
//                       <Typography
//                         variant="p"
//                         sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                       >
//                         {proposal?.deliveryTime?.name || ""}
//                       </Typography>
//                     </Box>
//                   </Box>

//                   <Grid
//                     container
//                     spacing={2}
//                     className=" catBtnTop flexColumnRespo"
//                   >
//                     <Grid itemxs={12} xl={8} lg={8} md={8}>
//                       <Box
//                         sx={{
//                           p: 2.5,
//                           pt: 0,
//                           display: "flex",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <Typography
//                           variant="p"
//                           sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                         >
//                           {proposal?.description}
//                         </Typography>
//                       </Box>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ))
//             )}

//             <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
//               {allProposals?.length > 0 ? (
//                 <CustomPagination
//                   count={totalPages}
//                   page={currentPage}
//                   onChange={handlePageChange}
//                 />
//               ) : (
//                 ""
//               )}
//             </Box>

//             {/* <Box
//               sx={{
//                 backgroundColor: "#fff",
//                 py: 1,
//                 borderBottom: 1,
//                 borderColor: "#e3e3e3",
//               }}
//             >
//               <Box
//                 className="flexColumnRespo"
//                 sx={{
//                   p: 2.5,
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Box className="" sx={{ display: "flex" }}>
//                   <Box sx={{ display: "flex" }}>
//                     <img
//                       src={AvatarJob}
//                       className="responsive-image avatarImg"
//                       alt="dfh"
//                     />
//                   </Box>
//                   <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
//                     <Box sx={{ display: "flex" }}>
//                       <Typography
//                         variant="h5"
//                         className="userNameText"
//                         sx={{ color: "#000", fontWeight: 500 }}
//                       >
//                         John Doe
//                       </Typography>
//                       <Typography
//                         variant="h5"
//                         className="userNameText"
//                         sx={{ color: "#222", fontWeight: 400, px: 2 }}
//                       >
//                         @johndoe
//                       </Typography>
//                     </Box>
//                     <Box
//                       className="flexWrapResp"
//                       sx={{ display: "flex", py: 1, alignItems: "center" }}
//                     >
//                       <Rating
//                         name="hover-feedback"
//                         value={value}
//                         precision={0.5}
//                         getLabelText={getLabelText}
//                         onChange={(event, newValue) => {
//                           setValue(newValue);
//                         }}
//                         onChangeActive={(event, newHover) => {
//                           setHover(newHover);
//                         }}
//                         emptyIcon={
//                           <StarIcon
//                             style={{ opacity: 0.55 }}
//                             fontSize="inherit"
//                           />
//                         }
//                       />
//                       {value !== null && (
//                         <Box sx={{ mx: 2 }}>
//                           {labels[hover !== -1 ? hover : value]}
//                         </Box>
//                       )}

//                       <Box sx={{ display: "flex", alignItems: "center" }}>
//                         <img src={chatIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           200
//                         </Typography>
//                       </Box>
//                       <Box
//                         className="catBtnTop"
//                         sx={{ display: "flex", alignItems: "center" }}
//                       >
//                         <img src={dollarIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           8.7
//                         </Typography>
//                       </Box>
//                       <Box
//                         className="catBtnTop"
//                         sx={{ display: "flex", alignItems: "center" }}
//                       >
//                         <img src={pieChart} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           98%
//                         </Typography>
//                       </Box>
//                       <Box
//                         className="catBtnTop"
//                         sx={{ display: "flex", alignItems: "center" }}
//                       >
//                         <img src={indiaIco} alt="chat" />
//                         <Typography variant="p" sx={{ color: "#000", px: 1 }}>
//                           India
//                         </Typography>
//                       </Box>
//                     </Box>
//                     <Box
//                       className="catBtnTop"
//                       sx={{ display: "flex", py: 0.5 }}
//                     >
//                       <Typography
//                         className="langText skillText"
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                           pl: 0,
//                         }}
//                       >
//                         UI/UX Designer
//                       </Typography>
//                       <Typography
//                         className="langText skillText"
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           borderRight: 1,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Illustrations Designer
//                       </Typography>
//                       <Typography
//                         className="langText skillText"
//                         variant="h6"
//                         sx={{
//                           color: "#222",
//                           fontSize: 18,
//                           fontWeight: 500,
//                           px: 2,
//                         }}
//                       >
//                         Figma Expert
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </Box>
//                 <Box
//                   className="flexDirectionRowResp catBtnTop"
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Typography
//                     className="userNameText "
//                     variant="h5"
//                     sx={{ color: "#000", px: 2, fontWeight: 500 }}
//                   >
//                     $150
//                   </Typography>
//                   <Typography
//                     variant="p"
//                     sx={{ color: "#222", px: 2, fontWeight: 500 }}
//                   >
//                     In 7 Days
//                   </Typography>
//                 </Box>
//               </Box>

//               <Grid
//                 container
//                 spacing={2}
//                 className=" catBtnTop flexColumnRespo"
//               >
//                 <Grid itemxs={12} xl={8} lg={8} md={8}>
//                   <Box
//                     sx={{
//                       p: 2.5,
//                       pt: 0,
//                       display: "flex",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="p"
//                       sx={{ fontWeight: 400, color: "#222", fontSize: 16 }}
//                     >
//                       I'm seeking an experienced and efficient app developer to
//                       create an innovative social networking application for iOS
//                       platform. Details about the target audience are currently
//                       confidential. Since the completion I'm seeking an
//                       experienced and efficient app developer to create more....
//                     </Typography>
//                   </Box>
//                 </Grid>

//               </Grid>
//             </Box> */}

//             {/* <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "end",
//                 mr: "auto",
//                 py: 4,
//                 pb: 0,
//               }}
//             >
//               <CustomPagination count={2} />
//             </Box> */}
//           </Box>
//         )}
//       </Box>
//     </>
//   );
// }

export default function EmployeeMyProposals() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [selectedProjectPosted, setSelectedProjectPosted] = useState(null);
  useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    LoaderShow();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleProjectPostedClick = (projectName) => {
    setSelectedProjectPosted(projectName);
  };

  const handleBack = () => {
    setSelectedProjectPosted(null);
  };

  const postedProjects = [
    { name: "Project 1", proposals: 25, status: "Closed", cost: "$1000" },
    { name: "Project 2", proposals: 32, status: "Closed", cost: "$300" },
    { name: "Project 3", proposals: 12, status: "Closed", cost: "$500" },
    { name: "Project 4", proposals: 90, status: "Closed", cost: "$800" },
  ];

  const ongoingProjects = [
    // { name: "Project 5", proposals: 25, status: "Closed", cost: "$1000" },
    // { name: "Project 6", proposals: 32, status: "Closed", cost: "$300" },
  ];

  const [proposals, setProposals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getMyProposals();
    LoaderShow();
  }, []);

  const getMyProposals = async (pageNumber) => {
    // setProposals([]);
    LoaderShow();
    try {
      const token = GetUserDetails().token;
      LoaderShow();

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const response = await getAPI(
        `proposal/myproposals?page=${pn}&limit=10&status=${
          tabValue == 1 ? "0,2" : 1
        }&show=true`,
        // `proposal/myproposals?page=1&limit=10&status=`,
        token
      );

      if (response.statusCode === 200) {
        setProposals(response.data);
        LoaderHide();
        setTotalPages(response.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    getMyProposals();
  }, [tabValue]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getMyProposals(value);
  };

  const [formData, setFormData] = useState({
    _id: "",
    maxAmount: "",
    deliveryTime: "",
    description: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    maxAmount: "",
    deliveryTime: "",
    description: "",
  });

  const [dropDownValues, setDropDownValues] = useState([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [particularProposal, setParticularProposal] = useState("");

  const handleOpenDeleteModal = (proposal) => {
    setParticularProposal(proposal);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenEditModal = (proposal) => {
    // setParticularProposal(proposal)
    // setFormData(
    //   proposals?.find((e) => e._id === proposal._id)
    // )
    // setEditModalOpen(true);
    setParticularProposal(proposal);
    const selectedProposal = proposals.find((e) => e._id === proposal._id);
    // setFormData(selectedProposal);
    setFormData({
      _id: selectedProposal?._id,
      maxAmount: selectedProposal?.maxAmount,
      deliveryTime: selectedProposal?.deliveryTime?._id,
      description: selectedProposal?.description,
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setDropDownValues(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  const handleAmount = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      maxAmount: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      maxAmount: value ? "" : t("addAmt"),
    }));
  };

  const handleDeliveryTime = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      deliveryTime: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      deliveryTime: isDropdownSelected(value) ? "" : t("selectDel"),
    }));
  };

  const handleCoverLetter = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: value,
    }));

    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      description: value.length < 10 ? t("coverLet") : "",
    }));
  };

  const isDropdownSelected = (value) => value !== "";

  const isNotEmpty = (value) => value.trim() !== "";

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!isNotEmpty(formData.maxAmount)) {
      errors.maxAmount = t("addAmt");
      isValid = false;
    }

    if (
      !isNotEmpty(formData?.description) ||
      formData?.description?.length < 10
    ) {
      errors.description = t("coverLet");
      isValid = false;
    }

    if (!isDropdownSelected(formData.deliveryTime)) {
      errors.deliveryTime = t("selectDel");
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  const updateProposal = async () => {
    if (validateForm()) {
      LoaderShow();
      try {
        const token = GetUserDetails().token;

        const dataToSend = {
          description: formData?.description,
          minAmount: "1",
          maxAmount: formData?.maxAmount,
          currency: "USD",
          deliveryTime: formData?.deliveryTime,
          // "projectId": projectDetail?._id
        };

        const response = await updateAPI(
          `proposal/${formData?._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(response.message);
          setFormData({ maxAmount: "", deliveryTime: "", description: "" });
          setEditModalOpen(false);
          getMyProposals();
        }
      } catch (error) {
        toast.error(error.message);
        LoaderHide();
      }
    } else {
      LoaderHide();
    }
  };

  return (
    <section className="EmployeerCreateProject">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box>
        <Typography
          className="employeeHead1"
          variant="h4"
          sx={{ pb: 1, fontWeight: 600 }}
        >
          {selectedProjectPosted && (
            <IconButton onClick={handleBack} sx={{ mr: 1, mb: 1 }}>
              <ArrowBackIcon sx={{ color: "#000" }} />
            </IconButton>
          )}
          {t("My")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>

      {!selectedProjectPosted && (
        // !selectedProjectOngoing &&
        <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
          <Box
            sx={{ mx: 2, px: 2.5, py: 2.5, pb: 0 }}
            className="plZero prZero"
          >
            <Tabs
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons={isMobile ? "auto" : "off"}
              allowScrollButtonsMobile={isMobile}
              value={tabValue}
              onChange={handleChange}
              aria-label="project tabs"
              textColor="#222222"
              sx={{
                borderBottom: 1,
                borderColor: "#dbdbdb",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#000",
                },
              }}
            >
              <Tab
                sx={{
                  textTransform: "capitalize",
                  pl: 0,
                  mx: 1,
                  textAlign: "start!important",
                  fontSize: 17,
                  fontFamily: "inherit",
                }}
                label={t("Submitted")}
              />
              <Tab
                sx={{
                  textTransform: "capitalize",
                  pl: 0,
                  mx: 1,
                  fontSize: 17,
                  fontFamily: "inherit",
                }}
                label={t("Archive")}
              />
            </Tabs>
          </Box>

          <Box sx={{ my: 3, py: 4, pt: 0 }}>
            {tabValue === 0 && (
              <>
                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("ProjectName")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Proposals")}
                        </TableCell>
                        {/* <TableCell
                        sx={{
                          fontWeight: 600,
                          fontSize: 18,
                          borderBottom: "0!important",
                          fontFamily: "inherit",
                        }}
                      >
                        Status
                      </TableCell> */}
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Average")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("MyProposal")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Action")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {proposals?.length === 0 ? (
                        <Box
                          sx={{
                            py: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <NodataFound />
                        </Box>
                      ) : (
                        proposals?.map((proposal, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                              onClick={() => handleProjectPostedClick(proposal)}
                            >
                              {proposal?.project?.title}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {proposal?.project?.totalProposals}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box sx={{ px: 2 }}>
                                ${proposal?.project?.averageCost || 0}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box sx={{ px: 2 }}>
                                  ${proposal?.maxAmount || 0}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <IconButton
                                onClick={() => handleOpenEditModal(proposal)}
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => handleOpenDeleteModal(proposal)}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    mr: "auto",
                    py: 4,
                    pb: 0,
                  }}
                >
                  {proposals?.length > 0 ? (
                    <CustomPagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </>
            )}

            {tabValue === 1 && (
              <>
                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("ProjectName")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Proposals")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Status")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Average")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("MyProposal")}
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            fontWeight: 600,
                            fontSize: 18,
                            borderBottom: "0!important",
                            fontFamily: "inherit",
                          }}
                        >
                          {t("Action")}
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {proposals?.length === 0 ? (
                        <Box
                          sx={{
                            py: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <NodataFound />
                        </Box>
                      ) : (
                        proposals?.map((proposal, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                                cursor: "pointer",
                              }}
                              onClick={() => handleProjectPostedClick(proposal)}
                            >
                              {proposal?.project?.title}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {proposal?.project?.totalProposals}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#2bb95e",
                                  color: "#fff",
                                  borderRadius: 10,
                                  p: 2,
                                  width: "auto",
                                  maxWidth: "fit-content",
                                  py: 1,
                                  fontWeight: "400",
                                }}
                              >
                                {proposal?.project?.status == "0"
                                  ? "Completed"
                                  : "Ongoing"}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box sx={{ px: 2 }}>
                                ${proposal?.project?.averageCost}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box sx={{ px: 2 }}>${proposal?.maxAmount}</Box>
                              </Box>
                            </TableCell>
                            {/* <TableCell
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              <IconButton
                                onClick={() => handleOpenEditModal(proposal)}
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => handleOpenDeleteModal(proposal)}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt="Delete"
                                  style={{ width: 33, height: 33 }}
                                />
                              </IconButton>
                            </TableCell> */}
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    mr: "auto",
                    py: 4,
                    pb: 0,
                  }}
                >
                  {proposals?.length > 0 ? (
                    <CustomPagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}

      {/* Render selected project details here */}
      {/* Render selected project details here */}
      {selectedProjectPosted && (
        <PostedProjectDetails
          project={selectedProjectPosted}
          onBack={handleBack}
        />
      )}
      {/* {selectedProjectOngoing && (
        <OngoingProjectDetails
          projectName={selectedProjectOngoing}
          onBack={handleBack}
        />
      )} */}
      {editModalOpen && (
        <Modal
          open={editModalOpen}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 2,
                px: 3,
                alignItems: "center",
                borderBottom: 1,
                borderColor: "#d8d8d8",
              }}
            >
              <Typography variant="h6">{t("editProporal")}</Typography>
              <IconButton onClick={handleCloseEditModal}>
                <img src={close} alt="db" />
              </IconButton>
            </Box>

            <Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  py: 3,
                  borderRadius: 10,
                  border: 1,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "#dadbda",
                    p: 2.5,
                    pt: 0,
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 500, color: "#000" }}
                    >
                      {t("Place")}
                    </Typography>
                  </Box>
                </Box>

                <Grid container spacing={2}>
                  {/* Left half */}
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        px: 2.5,
                        my: 2,
                      }}
                    >
                      <Box>
                        <Grid container spacing={2}>
                          {/* Left half */}
                          <Grid item xs={12} md={6}>
                            <Box sx={{ py: 1.5 }}>
                              <FormLabel
                                sx={{
                                  display: "block",
                                  textAlign: "start",
                                  py: 1,
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                {t("Proposal")}
                              </FormLabel>
                              <TextField
                                type="number"
                                value={formData?.maxAmount}
                                onChange={handleAmount}
                                variant="outlined"
                                sx={{
                                  borderColor: "#8692A6!important",
                                  borderRadius: 4,
                                }}
                                fullWidth={true}
                                placeholder="Enter Amount"
                              />
                            </Box>
                            {errorMessages.maxAmount && (
                              <Typography variant="p" sx={{ color: "red" }}>
                                {errorMessages.maxAmount}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {/* <Box>
                              <FormLabel
                                sx={{
                                  display: "block",
                                  textAlign: "start",
                                  py: 1.7,
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#000",
                                }}
                              >
                                How long will this project take?
                              </FormLabel>
                              <Dropdown
                                value={formData?.deliveryTime}
                                onChange={handleDeliveryTime}
                                options={dropDownValues?.durationEnumValues?.map(value => ({
                                  value: value._id,
                                  label: `${value?.name}`
                                }))}
                                label="Price"
                              />
                            </Box> */}
                            <FormControl sx={{ mt: 6 }} fullWidth>
                              <InputLabel id="time-select-label">
                                {t("Duration")}
                              </InputLabel>
                              <Select
                                labelId="time-select-label"
                                id="time-select"
                                value={formData?.deliveryTime}
                                label={t("Duration")}
                                onChange={handleDeliveryTime}
                              >
                                {dropDownValues?.durationEnumValues?.map(
                                  (option) => (
                                    <MenuItem
                                      key={option?._id}
                                      value={option?._id}
                                    >
                                      {option?.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            {errorMessages.deliveryTime && (
                              <Typography variant="p" sx={{ color: "red" }}>
                                {errorMessages.deliveryTime}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            px: 2.5,
                          }}
                        >
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1.4,
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#000",
                            }}
                          >
                            {t("CoverLetter")}
                          </FormLabel>
                          <Textarea
                            sx={{
                              width: "100%",
                              border: 1,
                              borderColor: "#cbcbcb",
                              borderRadius: 1,
                            }}
                            minRows={6}
                            maxRows={6}
                            placeholder="Enter Your Project Detail"
                            value={formData?.description}
                            onChange={handleCoverLetter}
                          />
                        </Box>
                        {errorMessages.description && (
                          <Typography variant="p" sx={{ color: "red", ml: 3 }}>
                            {errorMessages.description}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={updateProposal}
                sx={{
                  textTransform: "capitalize",
                  my: 2,
                  bgcolor: "#0B3013",
                  fontSize: 16,
                  color: "#fff",
                  borderRadius: 10,
                  px: 4,
                  py: 1,
                  fontWeight: 400,
                  "&:hover": {
                    bgcolor: "#0B3013",
                  },
                }}
              >
                {t("UpdateProposal")}
              </Button>{" "}
            </Box>
          </Box>
        </Modal>
      )}
      <DeleteModal
        getMyProposals={getMyProposals}
        proposals={particularProposal}
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
      />
    </section>
  );
}
