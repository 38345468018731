import lookup from "country-code-lookup";
import { getAPI } from "../Services/Api";
import { countries } from "./common_constants";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { EMPLOYEE_ROLE } from "./roles";

export function GetUserDetails() {
  const loginData = localStorage.getItem("loginData");
  if (loginData) {
    try {
      return JSON.parse(loginData);
    } catch (error) {
      console.error("Error parsing login data:", error);
      return null;
    }
  }
  return null;
}

export function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const invalidPattern = /[.]{2,}|[.-]{2,}|[@]{2,}/; // Invalid patterns to check against
  return emailPattern.test(email) && !invalidPattern.test(email);
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // return emailRegex.test(email);
}

export function isValidName(name) {
  // const nameRegex = /^[a-zA-Z0-9_ -]{3,}$/;
  // return nameRegex.test(name);
  const trimmedUsername = name.trim();

  // Regular expression to allow special characters and minimum 3 characters
  const usernameRegex = /^[a-zA-Z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~ -]{3,}$/;

  // Test the trimmed username against the regex
  return usernameRegex.test(trimmedUsername);
}

export function hasSpecialCharacter(password) {
  // const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  const specialCharRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return specialCharRegex.test(password);
}

export function isValidPhoneNumber(phoneNumber) {
  // const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/;
  // return phoneRegex.test(phoneNumber);

  // const indianPhoneRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;

  // // Regular expression for Canadian phone numbers
  // const canadianPhoneRegex = /^(\+?1[-. ]?)?\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
  return /^\d+$/.test(phoneNumber);
  // return (
  //   indianPhoneRegex.test(phoneNumber) || canadianPhoneRegex.test(phoneNumber)
  // );

  // return true;
}

export function LoaderShow() {
  var element = document.getElementById("hideloding");
  if (element !== null) {
    element.style.display = "flex";
  }
}

export function LoaderHide() {
  var element = document.getElementById("hideloding");

  if (element !== null) {
    element.style.display = "none";
  }
}

export const getCountryCode = (location) => {
  if (location) {
    const country = lookup.byCountry(location);
    if (country?.iso2) {
      return country?.iso2;
    }
    return "";
  }
  return "";
};

export const getCountryName = (location) => {
  if (location) {
    const country = lookup.byFips(location);
    if (country?.country) {
      return country?.country;
    }
    return "";
  }
  return "";
};

export function getUrl(payload) {
  try {
    if (GetUserDetails()?.token) {
      switch (payload.type) {
        case "PROJECT_CREATED":
          return "/dashboard/employee";
        case "NEW_PROPOSAL":
          return "/dashboard/employer/myprojects";
        case "PROJECT_AWARDED":
          return `/dashboard/employee/awardrequest`;
        case "MILESTONE_CREATED":
          if (GetUserDetails().role.includes(EMPLOYEE_ROLE))
            return `/dashboard/employee/myprojects?project_id=${payload.projectId}&tab=milestone`;
          return `/dashboard/employer/myprojects?project_type=ongoing`;
        case "PROJECT_ACCEPTED":
          if (GetUserDetails().role.includes(EMPLOYEE_ROLE))
            return `/dashboard/employee/myprojects`;
          return `/dashboard/employer/myprojects?project_type=ongoing`;
        case "PROJECT_REJECTED":
          return "/dashboard/employer/myprojects";
        case "MILESTONE_STATUS_CHANGED":
        case "MILESTONE_COMPLETED":
          if (GetUserDetails().role.includes(EMPLOYEE_ROLE))
            return `/dashboard/employee/myprojects?project_id=${payload.projectId}&tab=milestone`;
          return `/dashboard/employer/myprojects?project_type=ongoing&project_id=${payload.projectId}&tab=milestone`;
        case "PROJECT_COMPLETED":
          return `#`;
        default:
          return "/";
      }
    }
    return "/";
  } catch (err) {
    console.log(err);
    return "/";
  }
}

export async function getUserDetailsFromAPI() {
  try {
    if (sessionStorage.getItem("userData")) {
      return JSON.parse(sessionStorage.getItem("userData"));
    }
    const token = GetUserDetails().token;
    const response = await getAPI("user/getdetails", token);

    if (response.statusCode === 200) {
      sessionStorage.setItem("userData", JSON.stringify(response));
      return response;
    }
  } catch (err) {}
}

export function getCoutryFromPhoneCode(phoneNumber) {
  const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
  if (!phoneNumberObj) {
    return "";
  }
  const countryCallingCode = phoneNumberObj.country;
  const country = lookup.byIso(phoneNumberObj.country);
  if (!country) {
    return "";
  }
  return country.country;
}

function isValidERC20Address(address) {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

function isValidTRC20Address(address) {
  return /^T[a-zA-Z0-9]{33}$/.test(address);
}

export function isValidUSDTAddress(address) {
  return isValidERC20Address(address) || isValidTRC20Address(address);
}

export function getCommision(amount) {
  if (amount >= 200) return (amount * 7) / 100;
  if (amount >= 10) return (amount * 9) / 100;
  return 0;
}
