import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useMediaQuery,
  IconButton,
  Button,
  Grid,
  Modal,
  Rating,
  FormLabel,
  useTheme,
  TextField,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSearchParams } from "react-router-dom"; // Import Link from react-router-dom
import DescriptionIcon from "@mui/icons-material/Description";
import close from "../../../../Assets/Images/cancel-circle-half-dot.png";
import EditIcon from "../../../../Assets/Images/EditIcon.png";
import AddIcon from "@mui/icons-material/Add"; // Import the Material-UI icon component
import DeleteIcon from "../../../../Assets/Images/DeleteICon.png";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import {
  deleteAPI,
  getAPI,
  postAPI,
  updateAPI,
} from "../../../../Services/Api";
import { toast } from "react-toastify";
import {
  getCommision,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../Localization/i18n";
import SubscriptionModal from "../../../../Pages/Home/HomeMembership/SubscriptionModal/SubscriptionModal";
import styled from "@emotion/styled";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import Payment from "../Payment/Payment";
import Markdown from "markdown-to-jsx";

const MILESTONE_STATUS = {
  pending: "Pending",
  "in-process": "In Progress",
  complete: "Complete",
  accept: "Approved",
  "Payment pending": "Payment pending",
  "Payment process": "Payment in process",
  paid: "Paid",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function OngoingProjectDetails({
  project,
  onBack,
  isCompleted = false,
}) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
  // const [estimatedTotal, setEstimatedTotal] = useState(0);
  const [currentMilestoneId, setCurrentMilestoneId] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false); // New state for finish milestone modal
  const [valuerating, setValueRating] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [milestoneDeleteModal, setMileStoneDeleteModal] = useState(false);
  const [milestoneId, setMilestoneId] = useState("");
  const [showPagination, setShowPagination] = useState(true);

  const [completeProjectModalOepn, setCompleteProjectModalOepn] =
    useState(false);

  useEffect(() => {
    if (openModal) localStorage.setItem("projectData", JSON.stringify(project));
  }, [openModal]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCryptoModalOpen = () => {
    setCryptoModalOpen(true);
  };

  const handleOpenDeleteMileStone = (id) => {
    setMileStoneDeleteModal(true);
    setMilestoneId(id);
  };

  const handleCloseModal = () => {
    setMileStoneDeleteModal(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const toggleFinishModal = () => {
    setIsFinishModalOpen(!isFinishModalOpen);
    setValueRating("");
    setText({ textReview: "" });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [particularMilestone, setParticularMilsetone] = useState({
    _id: "",
    title: "",
    description: "",
    dueDate: "",
    amount: "",
  });

  const [milestoneEditErroMessages, setMileStoneEditErrorMessages] = useState({
    title: "",
    description: "",
    dueDate: "",
    amount: "",
  });

  const navigate = useNavigate();

  const toggleEditModal = (id) => {
    setIsEditModalOpen(!isEditModalOpen);
    setParticularMilsetone(
      milestonesList?.milestones?.find((e) => e._id === id)
    );
  };

  const handleEditMileStoneTitle = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, title: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      title: value.length < 5 ? t("TitleShouldBe5Characters") : "",
    });
  };

  const handleEditMileStoneDescription = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, description: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      description: value.length < 5 ? t("DescriptionShouldBe5Characters") : "",
    });
  };

  const handleEditTimeDuration = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, dueDate: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      dueDate: !value ? t("PleaseAddTime") : "",
    });
  };

  const handleEditDepositeAmount = (event) => {
    const value = event.target.value;
    setParticularMilsetone({ ...particularMilestone, amount: value });
    setMileStoneEditErrorMessages({
      ...milestoneEditErroMessages,
      amount: value.length === 0 ? t("PleaseAddDeposite") : "",
    });
  };

  const validateEditMileStoneForm = () => {
    const errors = {};
    let isValid = true;

    if (
      !isNotEmpty(particularMilestone.title) ||
      particularMilestone.title.length < 5
    ) {
      errors.title = t("TitleShouldBe5Characters");
      isValid = false;
    }

    if (
      !isNotEmpty(particularMilestone.description) ||
      particularMilestone.description.length < 5
    ) {
      errors.description = t("DescriptionShouldBe5Characters");
      isValid = false;
    }

    if (!particularMilestone.dueDate) {
      errors.dueDate = t("PleaseAddTime");
      isValid = false;
    }

    if (particularMilestone.amount.length === 0) {
      errors.amount = t("PleaseAddDeposite");
      isValid = false;
    }

    setMileStoneEditErrorMessages(errors);
    return isValid;
  };

  const updateMilestone = async () => {
    if (validateEditMileStoneForm()) {
      try {
        LoaderShow();
        const token = GetUserDetails().token;

        const dataToSend = {
          projectId: project?._id,
          milestones: [
            {
              description: particularMilestone.description,
              currency: "USD",
              amount: particularMilestone.amount,
              title: particularMilestone.title,
              dueDate: particularMilestone.dueDate,
            },
          ],
        };

        const response = await updateAPI(
          `milestone/${particularMilestone?._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(response.message);
          setIsEditModalOpen(false);
          setParticularMilsetone({
            title: "",
            description: "",
            dueDate: "",
            amount: "",
          });
          setShowPagination(false);
          await getMileStones();
          setShowPagination(true);
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setMileStoneData({ depositeAmount: "", description: "", timeDuration: "" });
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleCloseRating = () => {
    setIsFinishModalOpen(false);
  };

  const isNotEmpty = (value) => value.trim() !== "";

  const [text, setText] = useState({
    textReview: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    textReview: "",
    valuerating,
  });

  const handleTextArea = (event) => {
    const value = event.target.value;
    setText({ ...text, textReview: value });
    setErrorMessages({
      ...errorMessages,
      textReview: text.textReview.length < 5 ? t("ReviewShouldbe5") : "",
    });
  };

  const handleStar = (event) => {
    const value = event.target.value;
    setValueRating(value);
    setErrorMessages({
      ...errorMessages,
      valuerating:
        event?.target?.value?.length === 0 ? t("PleaseGiveStar") : "",
    });
  };
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!isNotEmpty(text.textReview) || text.textReview.length < 5) {
      errors.textReview = t("ReviewShouldbe5");
      isValid = false;
    }

    if (valuerating.length === 0) {
      errors.valuerating = t("PleaseGiveStar");
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  const submitReview = async () => {
    LoaderShow();
    if (validateForm()) {
      try {
        const userId = GetUserDetails().userId;

        const dataToSend = {
          userId: project.employee._id,
          reviewerId: userId,
          projectId: project?._id,
          rating: valuerating,
          comment: text.textReview,
        };
        const response = await postAPI("review/create", dataToSend);
        if (response.statusCode === 200) {
          toast.success(response.message);
          setIsFinishModalOpen(false);
          window.location.reload(true);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  const [milestoneData, setMileStoneData] = useState({
    title: "",
    description: "",
    timeDuration: "",
    depositeAmount: "",
  });

  const [milestoneErroMessages, setMileStoneErrorMessages] = useState({
    title: "",
    description: "",
    timeDuration: "",
    depositeAmount: "",
  });

  const handleMileStoneTitle = (event) => {
    const value = event.target.value;
    setMileStoneData({ ...milestoneData, title: value });
    setMileStoneErrorMessages({
      ...milestoneErroMessages,
      title: value.length < 5 ? t("TitleShouldBe5Characters") : "",
    });
  };

  const handleMileStoneDescription = (event) => {
    const value = event.target.value;
    setMileStoneData({ ...milestoneData, description: value });
    setMileStoneErrorMessages({
      ...milestoneErroMessages,
      description:
        milestoneData.description.length < 5
          ? t("DescriptionShouldBe5Characters")
          : "",
    });
  };

  const handleTimeDuration = (event) => {
    const value = event.target.value;
    setMileStoneData({ ...milestoneData, timeDuration: value });
    setMileStoneErrorMessages({
      ...milestoneErroMessages,
      timeDuration: !value
        ? t("PleaseAddTime")
        : milestoneData <= 0
        ? "Please enter time duration greater than 0."
        : "",
    });
  };

  const handleDepositeAmount = (event) => {
    const value = event.target.value;
    setMileStoneData({
      ...milestoneData,
      depositeAmount: value ? parseFloat(Number(value).toFixed(3)) : "",
    });
    setMileStoneErrorMessages({
      ...milestoneErroMessages,
      depositeAmount:
        value.length === 0
          ? t("PleaseAddDeposite")
          : value <= 0
          ? "Please enter amount greater than 0"
          : "",
    });
  };

  const validateMileStoneForm = () => {
    const errors = {};
    let isValid = true;

    if (!isNotEmpty(milestoneData.title) || milestoneData.title.length < 5) {
      errors.title = t("TitleShouldBe5Characters");
      isValid = false;
    }

    if (
      !isNotEmpty(milestoneData.description) ||
      milestoneData.description.length < 5
    ) {
      errors.description = t("DescriptionShouldBe5Characters");
      isValid = false;
    }

    if (!milestoneData.timeDuration) {
      errors.timeDuration = t("PleaseAddTime");
      isValid = false;
    } else if (milestoneData.timeDuration[0] == "-") {
      errors.timeDuration = "Please enter valid time duration.";
      isValid = false;
    }

    if (milestoneData.depositeAmount.length === 0) {
      errors.depositeAmount = t("PleaseAddDeposite");
      isValid = false;
    } else if (milestoneData.depositeAmount <= 0) {
      errors.depositeAmount = "Please enter amount greater than 0";
      isValid = false;
    }

    setMileStoneErrorMessages(errors);
    return isValid;
  };

  const addMileStone = async () => {
    if (validateMileStoneForm()) {
      try {
        LoaderShow();
        const { token } = GetUserDetails();

        const dataToSend = {
          projectId: project._id,
          description: milestoneData.description,
          currency: "USD",
          amount: milestoneData.depositeAmount,
          title: milestoneData.title,
          dueDate: milestoneData.timeDuration,
          assignedTo: project.employee?._id,
        };

        const response = await postAPI("milestone/create", dataToSend, token);
        if (response.statusCode === 200) {
          LoaderHide();
          // toast.success(response.message);

          // setEstimatedTotal(
          //   Number(milestoneData.depositeAmount) +
          //     getCommision(milestoneData.depositeAmount)
          // );
          setSubTotal(Number(milestoneData.depositeAmount));
          setCurrentMilestoneId(response.data);
          localStorage.setItem("projectData", JSON.stringify(project));
          setIsModalOpen(false);
          setOpenModal(true);
          setMileStoneData({
            depositeAmount: "",
            description: "",
            timeDuration: "",
          });
          setShowPagination(false);
          await getMileStones();
          setShowPagination(true);
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    }
  };

  const [milestonesList, setMileStonesList] = useState([]);
  const [data, setData] = React.useState({});
  const [employeeData, setEmployeeData] = React.useState({});

  useEffect(() => {
    setShowPagination(false);
    getMileStones();
    setShowPagination(true);
    fetchData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {}, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employer"));
          setEmployeeData(res.data.find((ele) => ele.type == "employee"));
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const getMileStones = async (ev, pagenumber = 1) => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const dataToSend = { projectId: project?._id };
      const response = await postAPI(
        `milestone/get?page=${pagenumber}`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        if (searchParams.get("tab") && searchParams.get("tab") == "milestone") {
          setTabValue(1);
          searchParams.delete("tab");
          setSearchParams(searchParams);
        }
        LoaderHide();
        setMileStonesList(response?.data);
        setTotalPages(response?.data?.totalPages || 1);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const deleteMileStone = async () => {
    try {
      LoaderShow();
      const response = await deleteAPI(`milestone/${milestoneId}`);
      if (response.statusCode === 200) {
        LoaderHide();
        toast.success(response?.message);
        setShowPagination(false);
        await getMileStones();
        setShowPagination(true);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  // const currencies = [
  //   { code: 'USD', label: 'USD' },
  //   { code: 'EUR', label: 'EUR' },
  //   { code: 'GBP', label: 'GBP' },
  // ];

  // const [currency, setCurrency] = useState(currencies[0].code);

  // const handleCurrencyChange = (event) => {
  //   setCurrency(event.target.value);
  // };

  const calculateFee = (mileStone) => {
    if (mileStone?.amount < employeeData?.cryptoAmount)
      return (mileStone?.amount * employeeData?.cryptoMinPercentage) / 100;
    return (mileStone?.amount * employeeData?.cryptoPercentage) / 100;
  };

  const onMileStoneStateChange = async (status, mileStone) => {
    LoaderShow();
    try {
      const { token, userId } = GetUserDetails();
      const data = {
        status: status,
        milestoneId: mileStone._id,
        amtToAdd: mileStone.amount - calculateFee(mileStone),
        employeeId: project?.employee?._id || "",
      };
      const response = await postAPI("milestone/status/update", data, token);
      if (response.statusCode == 200) {
        if (status == "paid") {
          try {
            await postAPI(
              "invoice/create",
              {
                amount: mileStone.amount - calculateFee(mileStone),
                type: "employee",
                payeeId: project?.employee?._id,
                payerId: userId,
                currency: "USDT",
                milestoneId: mileStone._id,
                paymentMethod: "coinpayment",
                projectId: project?._id,
              },
              token
            );
          } catch (err) {
            console.log(err.message);
          }
          toast.info(
            `Amount transfered to ${project?.employee?.name || ""}'s wallet.`
          );
        }
        setMileStonesList((project) => {
          return {
            ...project,
            milestones: project.milestones.map((ele) => {
              if (ele._id == mileStone._id) {
                ele.status = status;
              }
              return ele;
            }),
          };
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
    LoaderHide();
  };

  const payForMilestone = async (mileStone) => {
    setSubTotal(mileStone.amount);
    setCurrentMilestoneId(mileStone);
    setOpenModal(true);
  };

  const confirmProject = async () => {
    LoaderShow();
    try {
      const data = {
        projectId: project._id,
        status: "0",
      };
      const res = await updateAPI(
        "project/update-status",
        data,
        GetUserDetails().token
      );
      if (res.statusCode == 200) {
        setCompleteProjectModalOepn(false);
        setIsFinishModalOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
    LoaderHide();
  };

  const downloadInvoice = async (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    link.download = "invoice.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  };

  const [paymentType, setPaymentType] = React.useState("Stripe");
  const [subTotal, setSubTotal] = React.useState(0);

  return (
    <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
      <Dialog
        open={completeProjectModalOepn}
        onClose={() => setCompleteProjectModalOepn(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you Sure</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to finish the project.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="success" onClick={confirmProject}>
            Confirm
          </Button>
          <Button
            color="success"
            onClick={() => setCompleteProjectModalOepn(false)}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Payment
        data={data}
        // totalFee={getCommision(currentMilestoneId.amount)}
        afterPaid={async () => {
          setShowPagination(false);
          await getMileStones();
          setShowPagination(true);
        }}
        open={openModal}
        onClose={handleModalClose}
        // onCryptoSelected={handleCryptoModalOpen}
        subTotal={subTotal}
        // transactionId={null}
        // projectId={project?._id}
        employee={project?.employee}
        milestoneId={currentMilestoneId?._id ? [currentMilestoneId._id] : ""}
        // isNew={true}
      />
      <Modal
        open={milestoneDeleteModal}
        onClick={handleCloseModal}
        aria-labelledby="delete-modal"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          {/* <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="db" />
          </IconButton> */}
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              {/* <img className="deleteVec" src={DeleteIcon} alt="db" /> */}
            </Box>
            <Typography
              className="employeeHead1"
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("DeleteMilestone")}
            </Typography>
            <Typography
              className="employeeHead"
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            ></Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={deleteMileStone}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        className="plZero prZero"
        sx={{
          borderBottom: 1,
          borderColor: "#d6d6d6",
          mx: 2,
          px: 2.5,
          py: 2.5,
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
                flex: 1,
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("ProjectDetails")}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("Milestones")}
            />
          </Tabs>
          {tabValue == 1 && (
            <Button
              onClick={async () => {
                setShowPagination(false);
                await getMileStones();
                setShowPagination(true);
              }}
              sx={{ borderRadius: "0" }}
            >
              <RefreshIcon sx={{ color: "#000" }} />
            </Button>
          )}
          {tabValue == 0 && (
            <Button
              onClick={(ev) => {
                navigate("/dashboard/employer/dispute", {
                  state: {
                    project,
                  },
                });
              }}
              sx={{
                mt: -2,
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px !important",
                borderRadius: 10,
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                fontWeight: "400",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
              startIcon={<AddIcon color="#fff" />}
            >
              Add Dispute
            </Button>
          )}
        </Box>
      </Box>
      {tabValue === 0 && (
        <Box sx={{ px: 4, py: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ color: "#000" }}>
              {project.title}
            </Typography>
            <Typography variant="h6" sx={{ color: "#000" }}>
              {project?.estimatedBudget?.name}$
            </Typography>
          </Box>
          <Box sx={{ py: 4 }}>
            <Typography
              className="markdown"
              variant="p"
              sx={{ color: "#000", fontSize: 17 }}
            >
              <Markdown>{project.description}</Markdown>
            </Typography>
            {/* <Typography
              variant="p"
              sx={{ color: "#000", fontSize: 17, py: 2, display: "block" }}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions.
            </Typography> */}
          </Box>

          <Box sx={{ py: 4 }}>
            <Typography variant="h6" sx={{ color: "#000" }}>
              {t("SkillReq")}
            </Typography>
            <Box
              sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
            >
              {project.skillsRequired.map((skill, index) => (
                <Button
                  onClick={onBack}
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    mx: 1,
                    fontSize: "16px !important",
                    borderRadius: 10,
                    pb: 1,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    fontWeight: "400",

                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                >
                  {skill?.name}
                </Button>
              ))}
              {/* <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  mx: 1,
                  fontSize: "16px !important",
                  borderRadius: 10,
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  fontWeight: "400",

                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                Graphic Design
              </Button>
              <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  mx: 1,
                  fontSize: "16px !important",
                  borderRadius: 10,
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  fontWeight: "400",

                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                Figma
              </Button>
              <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  mx: 1,
                  fontSize: "16px !important",
                  borderRadius: 10,
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  fontWeight: "400",

                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                Adobe XD
              </Button> */}
            </Box>
          </Box>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                fontWeight: 600,
                pb: 0,
                fontSize: 18,
                px: 0,
              }}
            >
              {t("Attachments")}
            </Typography>
            <Box
              className="uploadSec"
              sx={{
                py: 3,
                pb: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <img
                  src={doc}
                  alt="Delete"
                  style={{ cursor: "pointer" }}
                /> */}

                <Typography
                  variant="p"
                  sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                >
                  {/* 84c110f299f31bad53eee9731d43cbca.jpg (154kB) */}
                  {project?.documents?.map((url, index) => (
                    <div key={index}>
                      <h3>
                        {t("Document")} {index + 1}
                      </h3>
                      <a
                        href={url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                    </div>
                  ))}
                </Typography>
                {/* <img
                  src={del}
                  alt="Delete"
                  style={{ cursor: "pointer" }}
                /> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              onClick={onBack}
              sx={{
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px !important",
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                ml: "auto",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {t("BackButton")}
            </Button>
          </Box>
        </Box>
      )}
      {tabValue === 1 && (
        <>
          <Box sx={{ py: 3 }}>
            <TableContainer component={Paper} style={{ overflowX: "auto" }}>
              <Table sx={{ fontFamily: "inherit" }}>
                <TableHead sx={{ borderBottom: "0!important" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        px: 5,
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("MileStoneName")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Duration")} {t("inDays")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Status")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Cost")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Action")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("inovice")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {milestonesList?.milestones?.map((milestone, index) => (
                    <TableRow
                      sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                    >
                      <TableCell
                        sx={{
                          px: 5,
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: "inherit",
                        }}
                      >
                        {milestone?.title}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: "inherit",
                        }}
                      >
                        {milestone?.dueDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: "inherit",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#d30100",
                            color: "#fff",
                            borderRadius: 10,
                            p: 2,
                            width: "auto",
                            maxWidth: "fit-content",
                            py: 1,
                            fontWeight: "400",
                          }}
                        >
                          {milestone?.disputed
                            ? "Disputed"
                            : MILESTONE_STATUS[milestone?.status]}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: "inherit",
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box>$ {Number(milestone?.amount)}</Box>
                          <Typography
                            variant="small"
                            sx={{ color: "#000", fontWeight: 300 }}
                          >
                            {/* Hourly Rate */}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          fontFamily: "inherit",
                        }}
                      >
                        {milestone?.disputed ? (
                          <>-</>
                        ) : (
                          <>
                            {milestone?.status == "complete" && (
                              <Button
                                sx={{
                                  backgroundColor: "#2bb95e",
                                  color: "#fff",
                                  borderRadius: 10,
                                  p: 2,
                                  width: "auto",
                                  maxWidth: "fit-content",
                                  py: 1,
                                  fontWeight: "400",
                                  "&:hover": {
                                    backgroundColor: "#2bb95e", // Keep the same color as default
                                  },
                                }}
                                onClick={(ev) =>
                                  onMileStoneStateChange("accept", milestone)
                                }
                              >
                                Approve
                              </Button>
                            )}
                            {milestone?.status == "accept" && (
                              <Button
                                sx={{
                                  backgroundColor: "#2bb95e",
                                  color: "#fff",
                                  borderRadius: 10,
                                  p: 2,
                                  width: "auto",
                                  maxWidth: "fit-content",
                                  py: 1,
                                  fontWeight: "400",
                                  "&:hover": {
                                    backgroundColor: "#2bb95e", // Keep the same color as default
                                  },
                                }}
                                onClick={(ev) =>
                                  onMileStoneStateChange("paid", milestone)
                                }
                              >
                                Pay now
                              </Button>
                            )}
                            {milestone?.status == "Payment pending" && (
                              <>
                                <Button
                                  sx={{
                                    backgroundColor: "#2bb95e",
                                    color: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    py: 1,
                                    fontWeight: "400",
                                    "&:hover": {
                                      backgroundColor: "#2bb95e", // Keep the same color as default
                                    },
                                  }}
                                  onClick={(ev) => payForMilestone(milestone)}
                                >
                                  Pay
                                </Button>
                                <IconButton>
                                  <img
                                    src={EditIcon}
                                    alt="Edit"
                                    style={{ width: 33, height: 33 }}
                                    onClick={() =>
                                      toggleEditModal(milestone._id)
                                    }
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleOpenDeleteMileStone(milestone._id)
                                  }
                                  // onClick={() => deleteMileStone(milestone._id)}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt="Delete"
                                    style={{ width: 33, height: 33 }}
                                  />
                                </IconButton>
                              </>
                            )}
                            {![
                              "accept",
                              "complete",
                              "Payment pending",
                            ].includes(milestone?.status) && "-"}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {milestone?.invoicePDFs?.employer?.length > 0 ? (
                          <DescriptionIcon
                            onClick={(ev) =>
                              downloadInvoice(
                                milestone?.invoicePDFs?.employer[0]
                              )
                            }
                            title={t("inovice")}
                            style={{ color: "#0B3013", cursor: "pointer" }}
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* <TableRow
                    sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      sx={{
                        px: 5,
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      Food Delivery Mobile App
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      10 May
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#2bb95e",
                          color: "#fff",
                          borderRadius: 10,
                          p: 2,
                          width: "auto",
                          maxWidth: "fit-content",
                          py: 1,
                          fontWeight: "400",
                        }}
                      >
                        Active
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ px: 2 }}>$36</Box>
                        <Typography
                          variant="small"
                          sx={{ color: "#000", fontWeight: 300 }}
                        >
                          Hourly Rate
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      <IconButton>
                        <img
                          src={EditIcon}
                          alt="Edit"
                          style={{ width: 33, height: 33 }}
                        />
                      </IconButton>
                      <IconButton>
                        <img
                          src={DeleteIcon}
                          alt="Delete"
                          style={{ width: 33, height: 33 }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            {!isCompleted && (
              <Box
                className="flexColumnRespo"
                sx={{
                  display: "flex",
                  px: 2,
                  py: 3,
                  ml: "auto",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    fontSize: "16px !important",
                    pb: 1,
                    fontWeight: 400,
                    borderRadius: 10,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center", // Align icon vertically with text
                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                  }}
                  onClick={toggleModal}
                >
                  <AddIcon style={{ marginRight: "8px" }} /> {t("AddMileStone")}{" "}
                  {/* Icon with margin for spacing */}
                </Button>
                <Button
                  className="catBtnTop"
                  sx={{
                    fontFamily: "inherit",
                    px: 4,
                    fontWeight: 400,
                    mx: 2,
                    fontSize: "16px !important",
                    pb: 1,
                    borderRadius: 10,
                    backgroundColor: "#0B3013",
                    width: "auto",
                    maxWidth: "fit-content",
                    textTransform: "capitalize",
                    color: "#fff",
                    display: "flex",
                    "&:hover": {
                      backgroundColor: "#0B3013", // Keep the same color as default
                    },
                    "&:disabled": {
                      color: "#ffffff", // Keep the same color as default
                    },
                  }}
                  disabled={
                    !milestonesList?.milestones?.every(
                      (milestone) => milestone.status == "paid"
                    )
                  }
                  onClick={(ev) => setCompleteProjectModalOepn(true)} // Open Finish Milestone Modal
                >
                  {t("Finish")}
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              px: 2,
              justifyContent: "center",
              pb: 3,
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={onBack}
              sx={{
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px !important",
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                mr: "auto",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {t("BackButton")}
            </Button>
            <Box className="catBtnTop">
              {showPagination && (
                <CustomPagination count={totalPages} onChange={getMileStones} />
              )}
            </Box>
          </Box>
        </>
      )}
      <Box className="ModalSection">
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  px: 3,
                  alignItems: "center",
                  borderBottom: 1,
                  borderColor: "#d8d8d8",
                }}
              >
                <Typography variant="h6">{t("AddMileStone")}</Typography>
                <IconButton onClick={handleClose}>
                  <img src={close} alt="db" />
                </IconButton>
              </Box>
              <Box sx={{ py: 0, pb: 4 }}>
                <Box sx={{ px: 3 }}>
                  <Box sx={{ py: 1 }}>
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        py: 1.4,
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      {t("MileStoneTitle")}
                    </FormLabel>
                    <TextField
                      value={milestoneData.title}
                      onChange={handleMileStoneTitle}
                      variant="outlined"
                      sx={{
                        borderColor: "#8692A6!important",
                        borderRadius: 4,
                      }}
                      fullWidth={true}
                      placeholder={t("MileStoneTitle")}
                    />
                    {milestoneErroMessages.title && (
                      <Typography variant="p" sx={{ color: "red" }}>
                        {milestoneErroMessages.title}
                      </Typography>
                    )}
                  </Box>

                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 2,
                            fontWeight: "600",
                            color: "#000",
                            fontSize: 17,
                          }}
                        >
                          {t("MileStoneDescription")}
                        </FormLabel>
                        <Textarea
                          value={milestoneData.description}
                          onChange={handleMileStoneDescription}
                          sx={{
                            width: "100%!important",
                            border: 1,
                            borderColor: "#cbcbcb",
                            borderRadius: 1,
                          }}
                          minRows={6}
                          maxRows={6}
                          placeholder={t("MileStoneDescription")}
                        />
                        {milestoneErroMessages.description && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneErroMessages.description}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("TimeDuration")}
                        </FormLabel>
                        <TextField
                          type="number"
                          value={milestoneData.timeDuration}
                          onChange={handleTimeDuration}
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("TimeDuration")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                        {milestoneErroMessages.timeDuration && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneErroMessages.timeDuration}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("DepositeAmount")}
                        </FormLabel>
                        {/* <InputLabel>Currency</InputLabel>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          label="Currency"
                        >
                          {currencies.map((currency) => (
                            <MenuItem key={currency.code} value={currency.code}>
                              {currency.label}
                            </MenuItem>
                          ))}
                        </Select> */}
                        <TextField
                          type="number"
                          value={milestoneData.depositeAmount}
                          onChange={handleDepositeAmount}
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("DepositeAmount")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                        {milestoneErroMessages.depositeAmount && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneErroMessages.depositeAmount}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    mt: 4,
                  }}
                >
                  <Button
                    fullWidth={true}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0B3013!important",
                      fontSize: 16,
                      borderRadius: 2,
                      textTransform: "capitalize",
                      px: 6,
                      py: 1,
                      mx: 3,
                    }}
                    onClick={addMileStone}
                  >
                    {t("Save")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}

        {isEditModalOpen && (
          <Modal
            open={isEditModalOpen}
            onClose={toggleEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  px: 3,
                  alignItems: "center",
                  borderBottom: 1,
                  borderColor: "#d8d8d8",
                }}
              >
                <Typography variant="h6">{t("EditMileStone")}</Typography>
                <IconButton onClick={handleCloseEditModal}>
                  <img src={close} alt="db" />
                </IconButton>
              </Box>
              <Box sx={{ py: 0, pb: 4 }}>
                <Box sx={{ px: 3 }}>
                  <Box sx={{ py: 1 }}>
                    <FormLabel
                      sx={{
                        display: "block",
                        textAlign: "start",
                        py: 1.4,
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      {t("MileStoneTitle")}
                    </FormLabel>
                    <TextField
                      value={particularMilestone?.title}
                      onChange={handleEditMileStoneTitle}
                      variant="outlined"
                      sx={{
                        borderColor: "#8692A6!important",
                        borderRadius: 4,
                      }}
                      fullWidth={true}
                      placeholder={t("MileStoneTitle")}
                    />
                    {milestoneEditErroMessages.title && (
                      <Typography variant="p" sx={{ color: "red" }}>
                        {milestoneEditErroMessages.title}
                      </Typography>
                    )}
                  </Box>

                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 2,
                            fontWeight: "600",
                            color: "#000",
                            fontSize: 17,
                          }}
                        >
                          {t("MileStoneDescription")}
                        </FormLabel>
                        <Textarea
                          value={particularMilestone?.description}
                          onChange={handleEditMileStoneDescription}
                          sx={{
                            width: "100%!important",
                            border: 1,
                            borderColor: "#cbcbcb",
                            borderRadius: 1,
                          }}
                          minRows={6}
                          maxRows={6}
                          placeholder={t("MileStoneDescription")}
                        />
                        {milestoneEditErroMessages.description && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneEditErroMessages.description}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("TimeDuration")}
                        </FormLabel>
                        <TextField
                          value={particularMilestone?.dueDate}
                          onChange={handleEditTimeDuration}
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("Duration")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                        {milestoneEditErroMessages.dueDate && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneEditErroMessages.dueDate}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("DepositeAmount")}
                        </FormLabel>
                        {/* <InputLabel>Currency</InputLabel>
                        <Select
                          value={currency}
                          onChange={handleCurrencyChange}
                          label="Currency"
                        >
                          {currencies.map((currency) => (
                            <MenuItem key={currency.code} value={currency.code}>
                              {currency.label}
                            </MenuItem>
                          ))}
                        </Select> */}
                        <TextField
                          value={particularMilestone?.amount}
                          onChange={handleEditDepositeAmount}
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("DepositeAmount")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                        {milestoneEditErroMessages.amount && (
                          <Typography variant="p" sx={{ color: "red" }}>
                            {milestoneEditErroMessages.amount}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    mt: 4,
                  }}
                >
                  <Button
                    fullWidth={true}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0B3013!important",
                      fontSize: 16,
                      borderRadius: 2,
                      textTransform: "capitalize",
                      px: 6,
                      py: 1,
                      mx: 3,
                    }}
                    onClick={updateMilestone}
                  >
                    {t("UpdateMileStone")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}

        <Modal
          open={isFinishModalOpen}
          onClose={() => {}}
          aria-labelledby="finish-modal-title"
          aria-describedby="finish-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 2,
                px: 3,
                alignItems: "center",
                borderBottom: 1,
                borderColor: "#d8d8d8",
              }}
            >
              <Typography variant="h6">{t("LeaveAReview")}</Typography>
              {/* <IconButton onClick={handleCloseRating}>
                <img src={close} alt="db" />
              </IconButton> */}
            </Box>
            <Box sx={{ px: 4 }}>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  fontSize: 17,
                  color: "#000",
                  display: "block",
                  py: 2,
                }}
              >
                {t("HowToYouRating")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Rating
                name="simple-controlled"
                value={valuerating}
                onChange={handleStar}
                sx={{ fontSize: "36px" }} // Adjust the font size as needed
              />
            </Box>
            {errorMessages.valuerating && (
              <Typography
                variant="p"
                sx={{ color: "red", textAlign: "center", marginLeft: "32px" }}
              >
                {errorMessages.valuerating}
              </Typography>
            )}
            <Grid container sx={{ px: 4 }}>
              <Grid item xs={12}>
                <Box>
                  <FormLabel
                    sx={{
                      display: "block",
                      textAlign: "start",
                      py: 2,
                      fontWeight: "600",
                      color: "#000",
                      fontSize: 17,
                    }}
                  ></FormLabel>
                  <Textarea
                    value={text.textReview}
                    onChange={handleTextArea}
                    sx={{
                      width: "100%!important",
                      border: 1,
                      borderColor: "#cbcbcb",
                      borderRadius: 1,
                    }}
                    minRows={6}
                    maxRows={6}
                    placeholder={t("WriteAReview")}
                  />
                  {errorMessages.textReview && (
                    <Typography variant="p" sx={{ color: "red" }}>
                      {errorMessages.textReview}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                my: 4,
                px: 4,
              }}
            >
              <Button
                fullWidth={true}
                variant="contained"
                onClick={submitReview}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 3,
                  textTransform: "capitalize",

                  py: 1,
                }}
              >
                {t("SubmitReview")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
