// SupportManagement.jsx
import React, { useState } from "react";
import SupportManagementTable from "./SupportManagementTable";
import { Box, Typography, IconButton, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import loader from "../../../Assets/Images/loader.svg";
import EditSupportManagement from "./EditSupportManagemnt";

const tickets = [
  // Your data here
  {
    Name: "John Doe",
    Email: "s@hnkd.com",
    Queries: "Lorem Ispum ...",
    status: "Open",
  },
  {
    Name: "John Doe",
    Email: "s@hnkd.com",
    Queries: "Lorem Ispum ...",
    status: "Open",
  },
  {
    Name: "John Doe",
    Email: "s@hnkd.com",
    Queries: "Lorem Ispum ...",
    status: "Open",
  },
];

export default function SupportManagement() {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleEditClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsEditing(true);
  };

  const handleViewClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsEditing(true);
  };

  const handleBackClick = () => {
    setIsEditing(false);
    setSelectedTicket(null);
  };

  const handleDeleteClick = (ticket) => {
    // Handle delete logic
    console.log(`Deleting ticket: ${ticket}`);
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        {isEditing && (
          <IconButton onClick={handleBackClick} sx={{ mr: 2, mb: 1 }}>
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {window.location.pathname.includes("support-management")
            ? "Support Management"
            : "Contact Us"}
        </Typography>
      </Box>
      {isEditing ? (
        <EditSupportManagement
          ticket={selectedTicket}
          onBackClick={handleBackClick}
        />
      ) : (
        <SupportManagementTable
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          onViewClick={handleViewClick}
        />
      )}
    </section>
  );
}
