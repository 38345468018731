import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Grid,
  Modal,
  Rating,
  useMediaQuery,
  TableContainer,
  Paper,
  FormLabel,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import doc from "../../../../Assets/Images/document-attachment.png";
import close from "../../../../Assets/Images/cancel-circle-half-dot.png";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import styled from "@emotion/styled";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  getCommision,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../../Services/Api";
import NodataFound from "../../../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import "../../../../Localization/i18n";
import { useNavigate, useSearchParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import Markdown from "markdown-to-jsx";
import AddIcon from "@mui/icons-material/Add"; // Import the Material-UI icon component

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const MILESTONE_STATUS = {
  pending: "Pending",
  "in-process": "In Progress",
  complete: "Waiting for approval",
  accept: "Payment pending",
  "Payment pending": "Payment pending",
  "Payment process": "Payment in process",
  paid: "Paid",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function OngoingProjectDetails({ particularProject, onBack }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(0); // Change default tab value to 0
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false); // New state for finish milestone modal
  const [valuerating, setValueRating] = React.useState("");
  const [showPagination, setShowPagination] = useState(true);

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // const toggleFinishModal = () => {
  //   setIsFinishModalOpen(!isFinishModalOpen);
  // };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleCloseRating = () => {
    setIsFinishModalOpen(false);
  };

  const [milestonesList, setMileStonesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [data, setData] = React.useState({});

  useEffect(() => {
    getMileStones();
    fetchData();
    //eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employee"));
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getMileStones(value);
  };

  const getMileStones = async (ev = {}, pagenumber = 1) => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;

      const dataToSend = {
        projectId: particularProject?.project?._id,
      };
      const response = await postAPI(
        `milestone/employee/get?page=${pagenumber}`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        LoaderHide();
        if (searchParams.get("tab") && searchParams.get("tab") == "milestone") {
          setTabValue(1);
          searchParams.delete("tab");
          setSearchParams(searchParams);
        }
        setMileStonesList(response?.data?.milestones);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
  };

  const onMileStoneStateChange = async (status, mileStone) => {
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const data = {
        status: status,
        milestoneId: mileStone._id,
      };
      const response = await postAPI("milestone/status/update", data, token);
      if (response.statusCode == 200) {
        setMileStonesList((mileStones) =>
          mileStones.map((ele) => {
            if (ele._id == mileStone._id) {
              ele.status = status;
            }
            return ele;
          })
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
    LoaderHide();
  };

  const downloadInvoice = async (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank";
    link.download = "invoice.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  };

  const calculateFee = (mileStone) => {
    if (mileStone?.amount < data?.cryptoAmount)
      return (mileStone?.amount * data?.cryptoMinPercentage) / 100;
    return (mileStone?.amount * data?.cryptoPercentage) / 100;
  };

  return (
    <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5 }}>
      <Box
        className="plZero prZero"
        sx={{
          borderBottom: 1,
          borderColor: "#d6d6d6",
          mx: 2,
          px: 2.5,
          py: 2.5,
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("ProjectDetailsOne")}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={t("Milestones")}
            />
          </Tabs>
          {tabValue == 1 && (
            <Button
              onClick={async () => {
                setShowPagination(false);
                await getMileStones();
                setShowPagination(true);
              }}
              sx={{ borderRadius: "0" }}
            >
              <RefreshIcon sx={{ color: "#000" }} />
            </Button>
          )}
          {tabValue == 0 && (
            <Button
              onClick={(ev) => {
                navigate("/dashboard/employee/dispute", {
                  state: {
                    project: particularProject?.project,
                  },
                });
              }}
              sx={{
                mt: -2,
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px !important",
                borderRadius: 10,
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                fontWeight: "400",

                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
              startIcon={<AddIcon color="#fff" />}
            >
              Add Dispute
            </Button>
          )}
        </Box>
      </Box>
      {tabValue === 0 && (
        <Box className="employeeProjdetails" sx={{ px: 4, py: 3 }}>
          <Box
            className="flexColumnRespo"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              className="employeeHead"
              variant="h5"
              sx={{ color: "#000" }}
            >
              {particularProject?.project?.title}
            </Typography>
            <Typography
              className="employeeHead"
              variant="h6"
              sx={{ color: "#000" }}
            >
              ${particularProject?.project?.budget?.min} - $
              {particularProject?.project?.budget?.max}
            </Typography>
          </Box>
          <Box className="priceHeadEmployee" sx={{ py: 4 }}>
            <Typography
              className="markdown"
              variant="p"
              sx={{ color: "#000", fontSize: 17 }}
            >
              <Markdown>{particularProject?.project?.description}</Markdown>
            </Typography>
          </Box>

          <Box sx={{ py: 4 }}>
            <Typography variant="h6" sx={{ color: "#000" }}>
              {t("Skill")}
            </Typography>
            <Box
              className="flexWrapResp"
              sx={{ display: "flex", flexWrap: "wrap", py: 3, gap: "10px 0" }}
            >
              {particularProject?.project?.skillsRequired?.map(
                (skill, index) => (
                  <Button
                    onClick={onBack}
                    className="skillText catBtnTop"
                    sx={{
                      fontFamily: "inherit",
                      px: 4,
                      mx: 1,
                      fontSize: "16px",
                      borderRadius: 10,
                      pb: 1,
                      backgroundColor: "#0B3013",
                      width: "auto",
                      maxWidth: "fit-content",
                      textTransform: "capitalize",
                      color: "#fff",
                      display: "flex",
                      fontWeight: "400",

                      "&:hover": {
                        backgroundColor: "#0B3013", // Keep the same color as default
                      },
                    }}
                  >
                    {skill?.name}
                  </Button>
                )
              )}
            </Box>
          </Box>
          <Box sx={{}}>
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                fontWeight: 600,
                pb: 0,
                fontSize: 18,
                px: 0,
              }}
            >
              {t("Attachments")}
            </Typography>
            <Box
              className="uploadSec"
              sx={{
                py: 3,
                pb: 0,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {particularProject?.project?.documents?.length === 0 ? (
                  ""
                ) : (
                  <img src={doc} alt="Delete" style={{ cursor: "pointer" }} />
                )}
                <Typography
                  variant="p"
                  sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                >
                  {particularProject?.project?.documents?.map((url, index) => (
                    <div key={index}>
                      <h3>Document {index + 1}</h3>
                      <a
                        href={url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                    </div>
                  ))}
                </Typography>
                {/* <img
                  src={del}
                  alt="Delete"
                  style={{ cursor: "pointer" }}
                /> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Button
              onClick={onBack}
              sx={{
                fontFamily: "inherit",
                px: 4,
                fontSize: "16px",
                pb: 1,
                backgroundColor: "#0B3013",
                width: "auto",
                maxWidth: "fit-content",
                textTransform: "capitalize",
                color: "#fff",
                display: "flex",
                ml: "auto",
                "&:hover": {
                  backgroundColor: "#0B3013", // Keep the same color as default
                },
              }}
            >
              {t("BackButton")}
            </Button>
          </Box>
        </Box>
      )}
      {tabValue === 1 && (
        <>
          <Box sx={{ py: 3 }}>
            <TableContainer component={Paper} style={{ overflowX: "auto" }}>
              <Table sx={{ fontFamily: "inherit" }}>
                <TableHead sx={{ borderBottom: "0!important" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        px: 5,
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("MileStoneName")}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 5,
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Status")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Duration")} {t("inDays")}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Cost")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("Action")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        borderBottom: "0!important",
                        fontFamily: "inherit",
                      }}
                    >
                      {t("inovice")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {milestonesList?.length === 0 ? (
                    <Box sx={{ py: 2, display: "flex", justifyContent: "end" }}>
                      <NodataFound />
                    </Box>
                  ) : (
                    milestonesList?.map((milestone, index) => (
                      <TableRow
                        sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                      >
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: "inherit",
                            cursor: "pointer",
                          }}
                        >
                          {milestone?.title}
                        </TableCell>
                        <TableCell
                          sx={{
                            px: 5,
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: "inherit",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#d30100",
                              color: "#fff",
                              borderRadius: 10,
                              p: 2,
                              width: "auto",
                              maxWidth: "fit-content",
                              py: 1,
                              fontWeight: "400",
                            }}
                          >
                            {milestone?.disputed
                              ? "Disputed"
                              : MILESTONE_STATUS[milestone?.status]}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: "inherit",
                          }}
                        >
                          {milestone?.dueDate}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: "inherit",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box sx={{ px: 2 }}>
                              ${" "}
                              {Number(milestone?.amount)}
                            </Box>
                            <Typography
                              variant="small"
                              sx={{ color: "#000", fontWeight: 300 }}
                            ></Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 500,
                            fontSize: 16,
                            fontFamily: "inherit",
                          }}
                        >
                          {milestone?.disputed ? (
                            <>-</>
                          ) : (
                            <>
                              {milestone?.status == "pending" && (
                                <Button
                                  className="borderRadNone"
                                  sx={{
                                    backgroundColor: "#2bb95e",
                                    color: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    py: 1,
                                    fontWeight: "400",
                                    "&:hover": {
                                      backgroundColor: "#2bb95e", // Keep the same color as default
                                    },
                                  }}
                                  onClick={(ev) =>
                                    onMileStoneStateChange(
                                      "in-process",
                                      milestone
                                    )
                                  }
                                >
                                  Start
                                </Button>
                              )}
                              {milestone?.status == "in-process" && (
                                <Button
                                  className="borderRadNone"
                                  sx={{
                                    backgroundColor: "#2bb95e",
                                    color: "#fff",
                                    borderRadius: 10,
                                    p: 2,
                                    width: "auto",
                                    maxWidth: "fit-content",
                                    py: 1,
                                    fontWeight: "400",
                                    "&:hover": {
                                      backgroundColor: "#2bb95e", // Keep the same color as default
                                    },
                                  }}
                                  onClick={(ev) =>
                                    onMileStoneStateChange(
                                      "complete",
                                      milestone
                                    )
                                  }
                                >
                                  Complete
                                </Button>
                              )}
                              {!["pending", "in-process"].includes(
                                milestone?.status
                              ) && "-"}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {milestone?.invoicePDFs?.employee?.length > 0 ? (
                            <DescriptionIcon
                              onClick={(ev) =>
                                downloadInvoice(
                                  milestone?.invoicePDFs?.employee[0]
                                )
                              }
                              title={t("inovice")}
                              style={{ color: "#0B3013", cursor: "pointer" }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}

                  {/* <TableRow
                    sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      sx={{
                        px: 5,
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                        cursor: "pointer",
                      }}
                    >
                      Food Delivery Mobile App
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      10 May
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ px: 2 }}>$150</Box>
                        <Typography
                          variant="small"
                          sx={{ color: "#000", fontWeight: 300 }}
                        >
                          Hourly Rate
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontFamily: "inherit",
                      }}
                    >
                      <Box
                        className='borderRadNone'
                        sx={{
                          backgroundColor: "#2bb95e",
                          color: "#fff",
                          borderRadius: 10,
                          p: 2,
                          width: "auto",
                          maxWidth: "fit-content",
                          py: 1,
                          fontWeight: "400",
                        }}
                      >
                        Pay Now
                      </Box>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            className="flexColumnRespo"
            sx={{
              display: "flex",
              px: 4,
              justifyContent: "space-between",
              alignItems: "center",
              pb: 4,
            }}
          >
            <div>
              <Button
                onClick={onBack}
                sx={{
                  fontFamily: "inherit",
                  px: 4,
                  fontSize: "16px",
                  pb: 1,
                  backgroundColor: "#0B3013",
                  width: "auto",
                  maxWidth: "fit-content",
                  textTransform: "capitalize",
                  color: "#fff",
                  display: "flex",
                  mr: "auto",
                  "&:hover": {
                    backgroundColor: "#0B3013", // Keep the same color as default
                  },
                }}
              >
                {t("BackButton")}
              </Button>
            </div>
            <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
              {milestonesList?.length > 0 && showPagination ? (
                <CustomPagination count={totalPages} onChange={getMileStones} />
              ) : (
                ""
              )}
            </Box>
          </Box>
        </>
      )}
      <Box className="ModalSection">
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                width: 550,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 2,
                  px: 3,
                  alignItems: "center",
                  borderBottom: 1,
                  borderColor: "#d8d8d8",
                }}
              >
                <Typography variant="h6">Add Milestone</Typography>
                <IconButton onClick={handleClose}>
                  <img src={close} alt="db" />
                </IconButton>
              </Box>
              <Box sx={{ py: 0, pb: 4 }}>
                <Box sx={{ px: 3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 2,
                            fontWeight: "600",
                            color: "#000",
                            fontSize: 17,
                          }}
                        >
                          {t("MileStoneDescription")}
                        </FormLabel>
                        <Textarea
                          sx={{
                            width: "100%!important",
                            border: 1,
                            borderColor: "#cbcbcb",
                            borderRadius: 1,
                          }}
                          minRows={6}
                          maxRows={6}
                          placeholder={t("MileStoneDescription")}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("TimeDuration")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("TimeDuration")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 17,
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          {t("DepositeAmount")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("DepositeAmount")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    mt: 4,
                  }}
                >
                  <Button
                    fullWidth={true}
                    variant="contained"
                    sx={{
                      backgroundColor: "#0B3013!important",
                      fontSize: 16,
                      borderRadius: 2,
                      textTransform: "capitalize",
                      px: 6,
                      py: 1,
                      mx: 3,
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}

        <Modal
          open={isFinishModalOpen}
          onClose={handleCloseRating}
          aria-labelledby="finish-modal-title"
          aria-describedby="finish-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: 550,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                py: 2,
                px: 3,
                alignItems: "center",
                borderBottom: 1,
                borderColor: "#d8d8d8",
              }}
            >
              <Typography variant="h6">Leave a Review for John Doe</Typography>
              <IconButton onClick={handleCloseRating}>
                <img src={close} alt="db" />
              </IconButton>
            </Box>
            <Box sx={{ px: 4 }}>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  fontSize: 17,
                  color: "#000",
                  display: "block",
                  py: 2,
                }}
              >
                How would you rate working at John Doe ?
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Rating
                name="simple-controlled"
                value={valuerating}
                onChange={(event, newValue) => {
                  setValueRating(newValue);
                }}
                sx={{ fontSize: "36px" }} // Adjust the font size as needed
              />
            </Box>
            <Grid container sx={{ px: 4 }}>
              <Grid item xs={12}>
                <Box>
                  <FormLabel
                    sx={{
                      display: "block",
                      textAlign: "start",
                      py: 2,
                      fontWeight: "600",
                      color: "#000",
                      fontSize: 17,
                    }}
                  >
                    {t("MileStoneDescription")}
                  </FormLabel>
                  <Textarea
                    sx={{
                      width: "100%!important",
                      border: 1,
                      borderColor: "#cbcbcb",
                      borderRadius: 1,
                    }}
                    minRows={6}
                    maxRows={6}
                    placeholder={t("MileStoneDescription")}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                my: 4,
                px: 4,
              }}
            >
              <Button
                fullWidth={true}
                variant="contained"
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 3,
                  textTransform: "capitalize",

                  py: 1,
                }}
              >
                Submit Review
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
