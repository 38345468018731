import React, { useState } from "react";
import RolesManagementTable from "./RolesManagementTable";
import { Box, Typography, IconButton } from "@mui/material";
import loader from "../../../Assets/Images/loader.svg";
const rows = [
  // Your data here
  { user: "John Doe", role: "Admin", status: "Active" },
  { user: "Jane Smith", role: "Editor", status: "Inactive" },
  { user: "Alice Johnson", role: "Viewer", status: "Suspended" },
];

export default function RolesManagement() {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleBackClick = () => {
    setIsEditing(false);
    setSelectedUser(null);
  };

  const handleDeleteClick = (user) => {
    // Handle delete logic
    console.log(`Deleting user: ${user}`);
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Roles Management
        </Typography>
      </Box>
      <RolesManagementTable
        rows={rows}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
      />
    </section>
  );
}
