import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Chip,
  InputBase,
  Menu,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "../../../Assets/Images/resolvedVec.png"; // Import the delete icon

import close from "../../../Assets/Images/cancel-circle-half-dot.png";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import editICO from "../../../Assets/Images/Eye.png";
import tickICO from "../../../Assets/Images/tick.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { getAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import loader from "../../../Assets/Images/loader.svg";

export default function RevenueReports() {
  const [revenues, setRevenues] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    fetchRevenues();
  }, []);

  const fetchRevenues = async (ev = {}, pageNumber = 1) => {
    LoaderShow();
    await fetchSearchedRevenues(ev, pageNumber);
    LoaderHide();
  };

  const fetchSearchedRevenues = async (ev = {}, pageNumber = 1) => {
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const response = await getAPI(`revenue/get?page=${pageNumber}`, token);
        if (response.statusCode == 200) {
          setTotalPages(response?.pagination?.totalPages || 1);
          setRevenues(response?.data?.transactions || []);
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
  };

  function formatDate(dt) {
    const date = new Date(dt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, "0");

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  }

  return (
    <Box>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Revenue Reports
        </Typography>

        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            mt: 4,
          }}
        >
          {revenues.length > 0 && (
            <Table
              aria-label="dispute case management table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0",
                marginTop: "0px",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    Payer name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    Type
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      padding: "24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Fees
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      padding: "24px",
                      fontFamily: "inherit",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revenues.map((revenue, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === revenues.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {revenue?.createdAt
                        ? formatDate(revenue?.createdAt)
                        : "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        padding: "24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === revenues.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {revenue?.payerName || "-"}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        textTransform: "capitalize",
                        padding: "24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === revenues.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      {revenue?.type || "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === revenues.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      ${revenue?.fee || "0"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "24px 24px",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        borderBottom:
                          index === revenues.length - 1
                            ? "0"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      ${revenue?.amount || "0"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {revenues.length > 0 && (
            <Box sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}>
              <CustomPagination count={totalPages} onChange={fetchRevenues} />
            </Box>
          )}
          {revenues.length <= 0 && <NodataFound />}
        </Box>
      </>
    </Box>
  );
}
