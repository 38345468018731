import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  InputAdornment,
  FormLabel,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Chip,
} from "@mui/material";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import PhoneInput from "react-phone-input-2"; // Make sure you have this package installed
import {
  getCoutryFromPhoneCode,
  GetUserDetails,
  isValidEmail,
  isValidPhoneNumber,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import { count } from "firebase/firestore";
import axios from "axios";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../Auth/Firebase/firebase";
import { countries } from "../../../Helpers/common_constants";

const AddUserForm = ({ onBackClick, roles, isWWH }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFocused, setIsFocused] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  // const [roles, setRoles] = useState([]);

  // Default values for dropdowns
  const [name, setName] = useState(""); // Default to 'active'
  const [email, setEmail] = useState(""); // Default to 'active'
  const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number
  const [otp, setOTP] = useState(""); // Default to 'active'
  const [role, setRole] = useState([]); // Default to 'user'
  const [gender, setGender] = useState("Male"); // Default to 'male'
  const [city, setCity] = useState(""); // Default to 'male'
  const [country, setCountry] = useState(""); // Default to 'male'
  const [error, setError] = useState({}); // Default to 'male'
  const [appVerifier, setAppVerifier] = useState(false);
  // useEffect(() => {
  //   fetchrole();
  // }, []);

  // const fetchrole = async () => {
  //   LoaderShow();
  //   try {
  //     const token = GetUserDetails()?.token;
  //     const response = await getAPI("role/getAll", token);
  //     if (response?.statusCode == 200) {
  //       setRoles(
  //         response.data.map((ele) => ({ value: ele._id, label: ele.name }))
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err.message);
  //   }
  //   LoaderHide();
  // };

  const validateForm = () => {
    let isValid = true;
    let err = {};
    if (!name.trim()) {
      isValid = false;
      err = { ...err, name: "Please enter your name" };
    }

    if (!email.trim()) {
      isValid = false;
      err = { ...err, email: "Please enter your email" };
    } else if (!isValidEmail(email.trim())) {
      isValid = false;
      err = { ...err, email: "Please enter valid email" };
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      err = {
        ...err,
        phoneNumber: "Please enter your phone Number",
      };
    } else if (!isValidPhoneNumber(phoneNumber)) {
      isValid = false;
      err = {
        ...err,
        phoneNumber: "Please enter valid phone Number",
      };
    }

    if (role.length <= 0) {
      isValid = false;
      err = { ...err, role: "Please select your role" };
    }

    if (!gender.trim()) {
      isValid = false;
      err = { ...err, gender: "Please enter your gender" };
    }

    if (!country.trim()) {
      isValid = false;
      err = { ...err, country: "Please enter your country" };
    }

    if (!city.trim()) {
      isValid = false;
      err = { ...err, city: "Please select your state" };
    }

    // if (!isVerified && phoneNumber.trim()) {
    //   isValid = false;
    //   err = { ...err, otp: "Please verify OTP" };
    // }

    setError(err);
    return isValid;
  };

  // Sample options for dropdowns
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  // Handle phone number change
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(`+${value}`);
  };

  // Handle input focus
  const handleFocus = () => {
    // Logic when input is focused
    validateForm();
  };

  const getPhoneNumberExistance = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/checkExistence`,
        {
          phoneNumber: `${phoneNumber}`,
        }
      );
      if (response?.userStatus == "Suspended") {
        toast.error("This phone number has been suspended!");
        return false;
      }
      if (response?.userStatus == "Inactive") {
        toast.error("This phone number has been Deactivated!");
        return false;
      }
      if (response.status == 200) return true;
      toast.error("This phone number already exists");
      return false;
    } catch (err) {
      toast.error("This phone number already exists");
      return false;
    }
  };

  // const sendOTP = async () => {
  //   if (!isVerified) {
  //     if (!isValidPhoneNumber(phoneNumber)) {
  //       setError({ phoneNumber: "Enter Phone number" });
  //       return;
  //     }
  //     LoaderShow();
  //     try {
  //       const phoneNumberData = await getPhoneNumberExistance();
  //       if (!phoneNumberData) {
  //         LoaderHide();
  //         return;
  //       }
  //       let newAppVerifier;
  //       if (!appVerifier) {
  //         newAppVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
  //           size: "invisible",
  //           callback: (response) => {},
  //           "expired-callback": () => {},
  //         });
  //         setAppVerifier(newAppVerifier);
  //       }

  //       const response = await signInWithPhoneNumber(
  //         auth,
  //         "+" + phoneNumber,
  //         appVerifier || newAppVerifier
  //       );
  //       if (response !== undefined) {
  //         toast.success("OTP sent successfully");
  //       }
  //     } catch (error) {
  //       if (error.message.includes("auth/too")) {
  //         toast.error(
  //           "Too many request sent at one time. Please try again later!"
  //         );
  //       } else {
  //         toast.error(error.message);
  //       }
  //     }
  //   }
  // };

  // const verifyOTP = async () => {
  //   if (!otp.trim()) {
  //     setError((err) => ({ ...err, otp: "Please enter OTP" }));
  //     return;
  //   }
  //   LoaderShow();
  //   // const response = await confirmationResult?.confirm(otp);
  //   try {
  //   } catch (err) {
  //     if (error.message.includes("invalid")) {
  //       toast.error("Invalid OTP");
  //       return;
  //     }
  //     if (error.message.includes("expired")) {
  //       toast.error("OTP expired");
  //       return;
  //     }
  //     toast.error(error.message);
  //   }
  //   LoaderHide();
  // };

  const addUser = async (ev) => {
    ev.preventDefault();
    if (!validateForm()) return;
    LoaderShow();
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const phoneNumberData = await getPhoneNumberExistance();
        if (!phoneNumberData) {
          LoaderHide();
          return;
        }
        const dataToSend = {
          firstName: name,
          lastName: "",
          userName: name,
          email: email,
          isEmailVerified: false,
          password: "",
          phoneNumber: phoneNumber,
          isPhoneNumberVerified: true,
          skills: [],
          isSubscribed: false,
          profile: "",
          aboutMe: "",
          DOB: "",
          location: "",
          role: role,
          location: country,
          state: city,
          gender: gender,
        };
        const response = await postAPI("user/register", dataToSend);
        if (response.statusCode === 200) {
          toast.success("User created successfully");
          onBackClick();
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
    LoaderHide();
  };

  // Render input component for PhoneInput
  const renderInput = (props) => (
    <TextField {...props} variant="outlined" fullWidth />
  );

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "30px",
        padding: 4,
        position: "relative",
      }}
    >
      <div id="recaptcha-container"></div>
      <Box component="form" onSubmit={(ev) => addUser(ev)}>
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Name
              </FormLabel>
              <TextField
                onChange={(ev) => setName(ev.target.value)}
                value={name}
                error={!!error.name}
                helperText={error.name}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="Enter your name"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Email Address
              </FormLabel>
              <TextField
                type="email"
                onChange={(ev) => setEmail(ev.target.value)}
                value={email}
                error={!!error.email}
                helperText={error.email}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="Enter your email address"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              my: 2,
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Phone Number
              </FormLabel>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onFocus={handleFocus}
                onBlur={handleFocus}
                inputComponent={renderInput}
                required
              />
              {error.phoneNumber && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.phoneNumber}
                </span>
              )}
            </Box>
            {/* <Box sx={{ flex: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  OTP Verification
                </FormLabel>
                <FormLabel
                  sx={{
                    display: "block",
                    mb: 1,
                    color: "#0095FF",
                    fontSize: 13,
                  }}
                >
                  Send OTP
                </FormLabel>
              </Box>
              <TextField
                onChange={(ev) => setOTP(ev.target.value)}
                value={otp}
                error={!!error.otp}
                helperText={error.otp}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="0000"
                onFocus={handleFocus}
                onBlur={handleFocus}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          borderRadius: "0",
                          backgroundColor: "#0B3013",
                          px: 2,
                          height: "100%",
                          color: "#fff",
                          py: 2,
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          "&:hover": { bgcolor: "#0B3013" },
                        }}
                      >
                        Verify
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box> */}

            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Role
              </FormLabel>
              {isWWH ? (
                <Select
                  fullWidth
                  multiple
                  value={role}
                  onChange={(e) => setRole(e.target.value.filter(ele => ele))}
                  placeholder="Role"
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  error={!!error.role}
                  helperText={error.role}
                  required={isWWH}
                  disabled={!isWWH}
                  renderValue={(selected) => (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {role?.map(
                        (value) =>
                          // <Chip key={value} label={"React"} />
                          role?.name && (
                            <Chip
                              key={value}
                              label={
                                roles?.find((skill) => skill?.value === value)
                                  ?.label || ""
                              }
                            />
                          )
                      )}
                    </Stack>
                  )}
                >
                  {roles.map((ele) => (
                    <MenuItem value={ele?.value} key={ele?.value}>
                      {ele?.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  fullWidth
                  value={role[0]}
                  onChange={(e) => setRole([e.target.value])}
                  placeholder="Role"
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  error={!!error.role}
                  helperText={error.role}
                  required
                >
                  {roles.map((ele) => (
                    <MenuItem value={ele?.value} key={ele?.value}>
                      {ele?.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {error.role && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.role}
                </span>
              )}
              {/* <Dropdown
                value={role}
                onChange={(e) => setRole(e.target.value)}
                options={roles}
                label="Role"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
              {error.role && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.role}
                </span>
              )} */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              my: 2,
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Country
              </FormLabel>
              <Dropdown
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                options={countries.map((ele) => ({
                  value: ele.label,
                  label: ele.label,
                }))}
                label="Country"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
              {error.country && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.country}
                </span>
              )}
              {/* <TextField
                required
                error={!!error.country}
                helperText={error.country}
                onChange={(ev) => setCountry(ev.target.value)}
                value={country}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="Enter your country"
                onFocus={handleFocus}
                onBlur={handleFocus}
              /> */}
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                State
              </FormLabel>
              <TextField
                required
                error={!!error.city}
                helperText={error.city}
                onChange={(ev) => setCity(ev.target.value)}
                value={city}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="Enter your state"
                onFocus={handleFocus}
                onBlur={handleFocus}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              my: 2,
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                Gender
              </FormLabel>
              <Dropdown
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                options={genderOptions}
                label="Gender"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
              {error.gender && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.gender}
                </span>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
          <Button
            type="submit"
            sx={{
              textTransform: "capitalize",
              my: 2,
              bgcolor: "#0B3013",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 4,
              py: 1,
              fontWeight: 400,
              "&:hover": {
                bgcolor: "#0B3013",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUserForm;
