import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  FormLabel,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import { DropzoneArea } from "material-ui-dropzone";
import del from "../../../Assets/Images/delet.png";
import docsx from "../../../Assets/Images/document-attachment.png";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  GetUserDetails,
  isValidEmail,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import deleteVec from "../../../Assets/Images/deleteVector.png";

const EditSupportManagemnt = ({ user, onBackClick, ticket }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isFocused, setIsFocused] = useState(false);

  // Default values for dropdowns
  const [name, setName] = useState(""); // Default to 'active'
  const [email, setEmail] = useState(""); // Default to 'active'
  const [role, setRole] = useState(""); // Default to 'user'
  const [gender, setGender] = useState("Male"); // Default to 'male'
  const [status, setStatus] = useState("Activated"); // Default to 'active'
  const [message, setMessage] = useState(""); // Default to 'user'
  const [replyMessage, setReplyMessage] = useState(""); // Default to 'user'
  const [title, setTitle] = useState(""); // Default to 'male'

  const [roles, setRoles] = useState([]);
  const [error, setError] = useState({}); // Default to 'male'

  useEffect(() => {
    if (ticket) {
      setName(ticket.firstName);
      setEmail(ticket.email);
      setMessage(ticket.message);
      setTitle(ticket.title);
    }
  }, [ticket]);

  const validateForm = () => {
    let isValid = true;
    let err = {};
    if (!replyMessage.trim()) {
      err = { ...err, replyMessage: "Please enter message" };
      isValid = false;
    }
    setError(err);
    return isValid;
  };

  // Handle input focus
  const handleFocus = () => {
    // validateForm();
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendReply = async (ev) => {
    ev.preventDefault();
    if (!validateForm()) return;
    LoaderShow();
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        const dataToSend = new FormData();
        dataToSend.append("id", ticket._id);
        dataToSend.append("replyMessage", replyMessage);
        files.forEach((file, index) => {
          dataToSend.append(`replyDocuments`, file);
        });
        const response = await postAPI(`contactus/reply`, dataToSend, token);
        if (response.statusCode === 200) {
          toast.success("Message sent successfully");
          onBackClick();
        }
      } catch (err) {
        console.log(err.message);
        toast.error(err.message);
      }
    }
    LoaderHide();
  };

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileChange = (newFiles) => {
    if (
      [...files, ...newFiles].reduce((val, ele) => ele?.size + val, 0) >
      1_048_576
    ) {
      toast.warn("Maximum size of attachments should be less than 2mb.");
      return;
    }
    setFiles((dt) => [...dt, ...newFiles]);
    const names = newFiles.map((file) => file.name);
    setFileNames((dt) => [...dt, ...names]);
  };

  const handleDeleteFile = (file, index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => index !== i));
    setFileNames((prevFiles) => prevFiles.filter((f, i) => index !== i));
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "30px",
          padding: 4,
          position: "relative",
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #E9E9E9", py: 1.7, mb: 2, pt: 0.6 }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: 18,
              fontWeight: "semibold",
              fontFamilly: "inherit",
            }}
          >
            Query
          </Typography>
        </Box>
        <Box component="form">
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              {!window.location.pathname.includes("support-management") ? (
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    Name
                  </FormLabel>
                  <TextField
                    value={name}
                    variant="outlined"
                    sx={{
                      background: isFocused ? "#FFFFFF" : "transparent",
                      borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    }}
                    fullWidth
                    placeholder="Enter your name"
                    disabled
                    // onFocus={handleFocus}
                    // onBlur={handleFocus}
                    // error={!!error.name}
                    // helperText={error.name}
                    // required
                  />
                </Box>
              ) : (
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    Title
                  </FormLabel>
                  <TextField
                    disabled
                    value={title}
                    variant="outlined"
                    sx={{
                      background: isFocused ? "#FFFFFF" : "transparent",
                      borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                    }}
                    fullWidth
                    placeholder="Enter your name"
                  />
                </Box>
              )}
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  Email Address
                </FormLabel>
                <TextField
                  disabled
                  value={email}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder="Enter your email address"
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                my: 2,
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  Message
                </FormLabel>
                <TextField
                  disabled
                  // onChange={(ev) => setMessage(ev.target.value)}
                  value={message}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder="Enter your message"
                  multiline
                  rows={4}
                />
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
            <Button
              type="submit"
              sx={{
                textTransform: "capitalize",
                my: 2,
                bgcolor: "#0B3013",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                fontWeight: 400,
                "&:hover": {
                  bgcolor: "#0B3013",
                },
              }}
            >
              Reply
            </Button>
          </Box> */}
        </Box>

        {/* Delete Confirmation Modal */}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={deleteVec}
                  alt="Delete Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                Are you sure you want to delete?
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                This action cannot be undone.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={handleConfirmDelete}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal> */}
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "30px",
          padding: 4,
          position: "relative",
          my: 4,
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #E9E9E9", py: 1.7, mb: 2, pt: 0.6 }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: 18,
              fontWeight: "semibold",
              fontFamilly: "inherit",
            }}
          >
            Reply
          </Typography>
        </Box>
        <Box component="form">
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                my: 2,
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  Message
                </FormLabel>
                <TextField
                  onChange={(ev) => setReplyMessage(ev.target.value)}
                  value={replyMessage}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder="Enter your message"
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  error={!!error.replyMessage}
                  helperText={error.replyMessage}
                  multiline
                  rows={4}
                  required
                />
              </Box>
            </Box>

            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: "#000",
                  fontWeight: 600,
                  pb: 0,
                  fontSize: 18,
                  px: 0,
                }}
              >
                Upload Attachments
              </Typography>
              <Box
                className="uploadSec"
                sx={{
                  py: 3,
                  pb: 0,
                }}
              >
                {fileNames.map((file, index) => (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                      <img
                        src={docsx}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      />

                      <Typography
                        variant="p"
                        sx={{ color: "#000", px: 2, wordBreak: "break-all" }}
                      >
                        Filename: {file}
                      </Typography>
                      <img
                        src={del}
                        onClick={() => handleDeleteFile(file, index)}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </>
                ))}
                <Box sx={{ py: 4, pb: 0 }}>
                  <DropzoneArea
                    acceptedFiles={["image/*", ".pdf", ".txt"]} // Define accepted file types
                    dropzoneText="Drag and drop an image here or click"
                    onChange={handleFileChange}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    dropzoneclassName="custom-dropzone"
                    filesLimit={1} // Custom CSS class for DropzoneArea
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
            {/* <Button
              type="submit"
              sx={{
                textTransform: "capitalize",
                my: 2,
                mx: 2,
                bgcolor: "#0B3013",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                fontWeight: 400,
                "&:hover": {
                  bgcolor: "#0B3013",
                },
              }}
            >
              Cancel
            </Button> */}
            <Button
              onClick={sendReply}
              sx={{
                textTransform: "capitalize",
                my: 2,
                bgcolor: "#0B3013",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                fontWeight: 400,
                "&:hover": {
                  bgcolor: "#0B3013",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>

        {/* Delete Confirmation Modal */}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={deleteVec}
                  alt="Delete Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                Are you sure you want to delete?
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                This action cannot be undone.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={handleConfirmDelete}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal> */}
      </Box>
    </>
  );
};

export default EditSupportManagemnt;
