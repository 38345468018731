import { Box, IconButton, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts"; // Ensure this is installed
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function TotalInquires({ chartData }) {
  const series = useMemo(() => {
    return chartData
      ?.sort((a, b) => b?.value - a?.value)
      ?.map((ele) => ele?.value || 0)
      ?.slice(0, 5);
  }, [chartData]);
  // [44, 55, 41, 17, 15];
  const options = {
    labels: chartData
      ?.sort((a, b) => b?.value - a?.value)
      ?.map((ele) => ele?.category)
      ?.slice(0, 5),
    chart: {
      width: "100%", // Adjust width to fit container
      height: "100%",
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: "60%", // Adjust this percentage to make the inner circle smaller
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      position: "bottom", // Position legends below the chart
      horizontalAlign: "center", // Center-align legends
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      markers: {
        width: 10,
        height: 10,
        radius: 100,
      },
      labels: {
        colors: undefined, // Use default colors
        useSeriesColors: true,
      },
    },
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box>
          <Typography variant="h7" sx={{ fontWeight: 600 }}>
            Total Inquires
          </Typography>
        </Box>
        <Box ml={"auto"}>
          {/* <IconButton>
            <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
          </IconButton> */}
        </Box>
      </Box>
      <Box style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <div id="chart" style={{width: "100%"}}>
          <ReactApexChart
            options={options}
            series={series}
            // labels={chartData?.map((ele) => ele?.category)}
            type="donut"
            width="100%" // Adjust width to fit container
          />
        </div>
        <div id="html-dist"></div>
      </Box>
    </div>
  );
}
