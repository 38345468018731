import React from "react";
// import { SiChatbot } from "react-icons/si";
import chatIcon from "../../Assets/Images/bubble-chat.png";
import zIndex from "@mui/material/styles/zIndex";
const ChatCircleIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} style={styles.chatCircle}>
      <img
        style={{ filter: "invert(1)" }}
        src={chatIcon}
        width={30}
        height={30}
      />
    </div>
  );
};

const styles = {
  chatCircle: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "50px",
    height: "50px",
    backgroundColor: "#16A34A",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#fff",
    fontSize: "24px",
    zIndex: 214748364,
  },
};

export default ChatCircleIcon;
