import React from "react";
import { Chip } from "@mui/material";

export const getStatusChip = (status) => {
  switch (status) {
    case "Activated":
      return <Chip label="Active" color="success" />;
    case "Suspended":
      return <Chip label="Suspended" color="default" />;
    case "Inactive":
      return <Chip label="Inactive" color="error" />;
    default:
      return <Chip label="Unknown" />;
  }
};
