import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import loader from "../../Assets/Images/loader.svg";
import { useEffect, useState } from "react";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI, postAPI } from "../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UserDisputeTable from "./UserDisputeTable";
import AddDispute from "./AddDispute";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon

export default function UserDispute() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isShowing, setIsShowing] = useState(false);
  const [selectedDispute, setSelectedDispute] = useState(null);
  const [userBalance, setUserBalance] = useState(0);

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("user/balance", token);
        if (res?.statusCode == 200) {
          setUserBalance(res?.data);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (searchParams.get("payment_intent")) {
      afterPayment();
    }
  }, []);

  const afterPayment = async () => {
    LoaderShow();
    const transactionId = searchParams.get("payment_intent");
    const bl = Number(searchParams.get("balance"));
    const fee = Number(searchParams.get("fee"));
    const user = GetUserDetails();
    searchParams.delete("payment_intent");
    searchParams.delete("balance");
    searchParams.delete("fee");
    setSearchParams(searchParams);

    const token = GetUserDetails()?.token;
    const id = GetUserDetails()?.userId;
    const dataToSend = {
      amount: Number(bl),
      transactionId: transactionId,
      fee: Number(fee),
      method: "Credit card",
    };

    const response = await postAPI("wallet/add", dataToSend, token);
    if (response.statusCode === 200) {
      window.location.reload();
    }
    try {
    } catch (err) {
      console.log(err);
      toast.error(err.message || "Something went wrong!");
    }
    LoaderHide();
  };

  return (
    <section>
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        {(state?.project?._id || isShowing) && (
          <IconButton
            onClick={(ev) => {
              navigate(pathname);
              setIsShowing(false);
              setSelectedDispute(null);
            }}
            sx={{ mr: 2, mb: 1 }}
          >
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {isShowing
            ? t("dispute")
            : state?.project?._id
            ? t("addDispute")
            : t("disputes")}
        </Typography>
      </Box>
      {!state?.project?._id && !isShowing && (
        <UserDisputeTable
          setDispute={(dispute) => {
            setSelectedDispute(dispute);
            setIsShowing(true);
          }}
        />
      )}
      {(state?.project?._id || isShowing) && (
        <AddDispute
          project={state?.project}
          path={pathname}
          dispute={selectedDispute}
          isShowing={isShowing}
          onBack={(ev) => {
            navigate(pathname);
            setIsShowing(false);
            setSelectedDispute(null);
          }}
          userBalance={userBalance}
          getBalance={getBalance}
        />
      )}
    </section>
  );
}
