import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LoopIcon from "@mui/icons-material/Loop";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import loader from "../../../Assets/Images/loader.svg";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import {
  getCommision,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAPI, postAPI, updateAPI } from "../../../Services/Api";
import { useTranslation } from "react-i18next";
import { ACCOUNTANT_ROLE } from "../../../Helpers/roles";

export default function EmployeerPayments() {
  // Define sample data
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [paymentList, setPaymentList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPAge] = useState(1);
  const [tabValue, setTabValue] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    getPaymentData();
  }, []);

  const getPaymentData = async (page = 1, status = tabValue == 0 ? "pending": "completed") => {
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const res = await getAPI(
        `wallet/get-list?status=${status}&page=${page}`,
        {},
        token
      );
      if (res.statusCode == 200) {
        setPaymentList(res.data);
        setTotalPages(res?.pagination?.pages || 1);
        setCurrentPAge(res?.pagination?.page || 1);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const refreshStatus = async (milestone) => {
    LoaderShow();
    try {
      const data = {
        withdrawalId: milestone.withdrawal.withdrawalId,
      };
      const { token } = GetUserDetails();
      const res = await postAPI("coinpayment/getWithdrawalStatus", data, token);
      if (res.statusCode == 200) {
        const newwithdrawal = {
          ...milestone.withdrawal,
          cryptoStatus: res.data?.status_text,
        };
        setPaymentList((dt) =>
          dt.map((ele) => {
            if (ele.wallet == milestone.wallet) ele.withdrawal = newwithdrawal;
            return ele;
          })
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const PayEmployee = async (milestone) => {
    if (!milestone?.cryptoAddress) {
      toast.error("No crypto address provided");
      return;
    }
    LoaderShow();
    try {
      const data = {
        cryptoWalletAddress: milestone.cryptoAddress,
        amount: parseFloat(milestone.amountInUSDT),
        userId: milestone._id,
      };
      const { token } = GetUserDetails();
      const res = await postAPI("coinpayment/employee-payment", data, token);
      if (res.statusCode == 200) {
        milestone.withdrawal = { withdrawalId: res?.withdrawalId };
        refreshStatus(milestone);
        toast.success(res.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const MarkAsPay = async (milestone) => {
    LoaderShow();
    try {
      const { token } = GetUserDetails();
      const data = {
        status: "completed",
        transactionId: milestone.wallet,
      };
      const response = await updateAPI("wallet/status-update", data, token);
      if (response.statusCode == 200) {
        // try {
        //   const dataToSend = {
        //     payeeId: milestone.employeeId,
        //     payerId: milestone.employerId,
        //     currency: "USDT",
        //     milestoneId: milestone.milestoneId,
        //     projectId: milestone.projectId,
        //     amount: milestone.milestoneAmount.actualAmount,
        //     paymentMethod: "Crypto Payment",
        //     type: "employee",
        //   };
        //   const response = await postAPI("invoice/create", dataToSend, token);
        // } catch (err) {}
        setPaymentList((mileStones) =>
          mileStones.filter((ele) => ele.wallet != milestone.wallet)
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    getPaymentData(1, newValue == 0 ? "pending" : "completed");
  };

  useEffect(() => {
    setCurrentPAge(1);
    setTotalPages(1);
  }, [tabValue]);

  const filteredPaymentList = useMemo(() => {
    return paymentList;
  }, [paymentList, tabValue]);

  function formatDateToDDMMYYYY(dt) {
    const date = new Date(dt);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <section className="messageEmployee">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Box>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          Payments
        </Typography>
      </Box>
      <Box
        className="plZero prZero"
        sx={{
          borderBottom: 1,
          borderColor: "#d6d6d6",
          mx: 2,
          px: 2.5,
          py: 2.5,
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            textColor="#222222"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#000",
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="project details tabs"
          >
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={"New"}
            />
            <Tab
              sx={{
                textTransform: "capitalize",
                pl: 0,
                mx: 1,
                fontSize: 17,
                fontFamily: "inherit",
              }}
              label={"Paid"}
            />
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#fff", my: 4, borderRadius: 7.5, py: 2 }}>
        {tabValue == 0 && (
          <Box>
            {paymentList.length > 0 ? (
              <Box>
                <Box sx={{ overflowY: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        {[
                          "Created At",
                          "Name",
                          "Payment type",
                          "Address",
                          "Amount in USD",
                          "Converted Amount",
                          "Status",
                          "Action",
                        ].map((header) => (
                          <TableCell
                            key={header}
                            sx={{
                              px: 5,
                              fontWeight: 600,
                              fontSize: 18,
                              borderBottom: "0!important",
                              fontFamily: "inherit",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentList.map((data, index) => (
                        <TableRow
                          key={data?.wallet}
                          sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {formatDateToDDMMYYYY(data?.createdAt) || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {data?.Name || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {data?.method || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {data?.cryptoAddress || "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {data?.actualAmount
                              ? "$" + data?.actualAmount
                              : "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 5,
                              fontWeight: 500,
                              fontSize: 16,
                              fontFamily: "inherit",
                            }}
                          >
                            {data?.amountInUSDT
                              ? data?.amountInUSDT + " USDT"
                              : "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              gap: "5px",
                              flexDirection: "row",
                            }}
                          >
                            {data?.withdrawal?.cryptoStatus && (
                              <LoopIcon
                                onClick={(ev) => refreshStatus(data)}
                                sx={{ px: 1 }}
                              />
                            )}

                            <Button
                              disabled
                              sx={{
                                borderRadius: 9999,
                                textTransform: "capitalize",
                                "&:disabled": {
                                  color: "#2d2d2d",
                                },
                                marginLeft: data?.withdrawal?.cryptoStatus
                                  ? 0
                                  : "45px",
                              }}
                              variant="contained"
                              color="success"
                            >
                              {data?.withdrawal?.cryptoStatus
                                ? data?.withdrawal?.cryptoStatus
                                : "Payment not initiated"}
                            </Button>
                            {data?.status == "completed" && (
                              <Button
                                disabled
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                  "&:disabled": {
                                    color: "#2d2d2d",
                                  },
                                  marginLeft: "5px",
                                }}
                                variant="contained"
                                color="success"
                              >
                                Paid
                              </Button>
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {data?.status != "completed" &&
                            !data?.withdrawal?.cryptoStatus ? (
                              <Button
                                onClick={(ev) => PayEmployee(data)}
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                }}
                                variant="contained"
                                color="success"
                              >
                                Pay now
                              </Button>
                            ) : data?.withdrawal?.cryptoStatus == "Complete" &&
                              data?.status != "completed" ? (
                              <Button
                                onClick={(ev) => MarkAsPay(data)}
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                }}
                                variant="contained"
                                color="success"
                              >
                                Mark as paid
                              </Button>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    display: "flex",
                    ml: "auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(ev, value) => {
                      getPaymentData(value);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <NodataFound />
            )}
          </Box>
        )}
        {tabValue == 1 && (
          <Box>
            {paymentList.length > 0 ? (
              <Box>
                <Box sx={{ overflowY: "auto" }}>
                  <Table sx={{ fontFamily: "inherit" }}>
                    <TableHead sx={{ borderBottom: "0!important" }}>
                      <TableRow>
                        {[
                          "Withdrawal Id",
                          "Created At",
                          "Name",
                          "Payment type",
                          "Address",
                          "Amount in USD",
                          "Converted Amount",
                          "Status",
                          // "Action",
                        ].map((header) => (
                          <TableCell
                            key={header}
                            sx={{
                              px: 5,
                              fontWeight: 600,
                              fontSize: 18,
                              borderBottom: "0!important",
                              fontFamily: "inherit",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentList
                        // .filter(
                        //   (ele) =>
                        //     ele?.withdrawal?.cryptoStatus !=
                        //     "complete"
                        // )
                        .map((data, index) => (
                          <TableRow
                            key={data?.wallet}
                            sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.withdrawal?.withdrawalId || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {formatDateToDDMMYYYY(data?.createdAt) || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.Name || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.method || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.cryptoAddress || "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.actualAmount
                                ? "$" + data?.actualAmount
                                : "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                px: 5,
                                fontWeight: 500,
                                fontSize: 16,
                                fontFamily: "inherit",
                              }}
                            >
                              {data?.amountInUSDT
                                ? data?.amountInUSDT + " USDT"
                                : "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                gap: "5px",
                                flexDirection: "row",
                              }}
                            >
                              {data?.withdrawal?.cryptoStatus && (
                                <LoopIcon
                                  onClick={(ev) => refreshStatus(data)}
                                  sx={{ px: 1 }}
                                />
                              )}

                              <Button
                                disabled
                                sx={{
                                  borderRadius: 9999,
                                  textTransform: "capitalize",
                                  "&:disabled": {
                                    color: "#2d2d2d",
                                  },
                                  marginLeft: data?.withdrawal?.cryptoStatus
                                    ? 0
                                    : "45px",
                                }}
                                variant="contained"
                                color="success"
                              >
                                {data?.withdrawal?.cryptoStatus
                                  ? data?.withdrawal?.cryptoStatus
                                  : "Payment not initiated"}
                              </Button>
                              {data?.status == "completed" && (
                                <Button
                                  disabled
                                  sx={{
                                    borderRadius: 9999,
                                    textTransform: "capitalize",
                                    "&:disabled": {
                                      color: "#2d2d2d",
                                    },
                                    marginLeft: "5px",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Paid
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  sx={{
                    py: 3,
                    display: "flex",
                    ml: "auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomPagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(ev, value) => {
                      getPaymentData(value);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <NodataFound />
            )}
          </Box>
        )}
      </Box>
    </section>
  );
}
