import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import deleteVec from "../../Assets/Images/resolvedVec.png";
import close from "../../Assets/Images/cancel-circle-half-dot.png";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { updateAPI } from "../../Services/Api";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import DisputeBalanceAdd from "./DisputeBalanceAdd";

const STATUS = {
  "In Progress":
    "This action shows that you have aknowledged the dispute case, this action cannot be undone.",
  Resolved: "",
  Closed: "",
  "Manually Resolved":
    "This action shows that you have mutually resolved this issue and you are agreeing to pay given amount by you to the other party, this action cannot be undone.",
  Withdraw:
    "Are you sure you want to withdraw your filed dispute?? This action cannot be undone.",
  Pending: "",
  Accept:
    "This action shows that you have mutually resolved this issue and you are agreeing to pay whole amount by the other party to the other party, this action cannot be undone.",
  "Resolved by complainant":
    "Are you sure you want to resolve your filed dispute?? This action cannot be undone.",
  Rejected:
    "Are you sure you want to reject filed dispute?? This action cannot be undone.",
};

export default function UpdateDisputeModal({
  open,
  onClose,
  dispute,
  newStatus,
  onSave,
  userBalance,
  getBalance,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { t } = useTranslation();

  const [showAmtField, setShowAmtField] = useState(false);
  const [amount, setAmount] = useState("");

  const [addBalanceModalOpen, setAddBalanceModalOpen] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    if (open) {
      if (newStatus == "Manually Resolved") {
        setAmount(dispute.amount || 1);
        setShowAmtField(true);
      }
    } else {
      setAmount("");
      setShowAmtField(false);
    }
  }, [open, dispute, newStatus]);

  const onNext = (ev) => {
    ev.preventDefault();
    if (isNaN(amount) || amount <= 0) {
      setError("Please enter amount greater than 0.");
      return;
    }
    setError("");
    setShowAmtField(false);
  };

  const extraAmt = useMemo(() => {
    if (dispute?.milestoneId?.status != "paid") {
      return newStatus == "Manually Resolved"
        ? Number(amount - dispute?.milestoneId?.amount) - userBalance
        : dispute?.amount - dispute?.milestoneId?.amount - userBalance;
    }
    return newStatus == "Manually Resolved"
      ? Number(amount) - userBalance
      : dispute?.amount - userBalance;
  }, [amount, userBalance, dispute]);

  const changeStatus = async () => {
    setAddBalanceModalOpen(false);
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const data = {
          id: dispute._id,
          status: newStatus,
        };
        if (newStatus == "Manually Resolved") {
          data["agreedAmount"] = Number(amount);
        }
        const response = await updateAPI("dispute/update", data, token);
        if (response.statusCode == 200) {
          toast.success("Status changed successfully");
          window.location.reload();
        } else {
          console.log(response);
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err.message || "Something went wrong!");
      toast.error(err.message || "Something went wrong!");
    }
    LoaderHide();
  };

  const onProceed = async () => {
    if (extraAmt > 0) {
      setAddBalanceModalOpen(true);
      return;
    }
    changeStatus();
  };

  return (
    <>
      <DisputeBalanceAdd
        open={addBalanceModalOpen}
        onClose={() => setAddBalanceModalOpen(false)}
        balance={extraAmt}
        afterPaid={async () => {
          await getBalance();
          setAddBalanceModalOpen(false);
        }}
      />
      <Modal open={open && !addBalanceModalOpen} onClose={onClose}>
        <div>
          {showAmtField && (
            <Box
              className="employeeProjdetails"
              sx={{
                position: "absolute",
                width: isMobile ? "80%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                boxShadow: 24,
                p: 4,
                borderRadius: 5,
              }}
            >
              <Box className="SubHeader">
                <Typography
                  variant="h5"
                  id="subscribe-modal-title"
                  sx={{
                    borderBottom: 1,
                    py: 2,
                    pt: 0,
                    borderColor: "#E6E6E6",
                  }}
                  gutterBottom
                >
                  Dispute
                  <img
                    onClick={onClose}
                    src={close}
                    alt="Close"
                    style={{ cursor: "pointer", float: "right" }}
                  />
                </Typography>
              </Box>
              <form onSubmit={(ev) => onNext(ev)}>
                <FormControl
                  sx={{
                    mt: 3,
                  }}
                  fullWidth
                >
                  <FormLabel
                    sx={{
                      display: "block",
                      textAlign: "start",
                      // py: 1.4,
                      mt: -1,
                      fontSize: 20,
                    }}
                  >
                    {t("enteramtUSD")}
                  </FormLabel>
                  <TextField
                    required
                    type="number"
                    variant="outlined"
                    min={0.01}
                    sx={{
                      background: "transparent",
                      borderColor: "#E0E0E0",
                      boxShadow: "none",
                    }}
                    fullWidth={true}
                    placeholder={t("enteramtUSD")}
                    id="perHourAmount"
                    name="perHourAmount"
                    onChange={(ev) => setAmount(ev.target.value)}
                    value={amount}
                  />
                </FormControl>
                <Button
                  type="submit"
                  className="SignUpBtn"
                  sx={{
                    mt: 2,
                    textTransform: "capitalize",
                    py: "8px !important",
                    px: "24px !important",
                    width: "auto !important",
                    borderRadius: 12,
                    maxWidth: "auto !important",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Proceed
                </Button>
              </form>
            </Box>
          )}
          {!showAmtField && (
            <Box
              sx={{
                position: "absolute",
                width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
                borderRadius: 4,
                maxHeight: "90dvh",
              }}
            >
              <IconButton
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <img src={close} alt="Close" />
              </IconButton>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    margin: "auto",
                    justifyContent: "center",
                    py: 1,
                  }}
                >
                  <img
                    className="deleteVec"
                    src={deleteVec}
                    alt="Delete Vector"
                  />
                </Box>
                <Typography
                  id="logout-modal-description"
                  variant="h5"
                  sx={{
                    fontWeight: 700,
                    fontSize: 32,
                    textAlign: "center",
                    py: 2,
                    pb: 1,
                  }}
                  gutterBottom
                >
                  Are you sure you want to continue?
                </Typography>
                <Typography
                  id="logout-modal-description"
                  variant="body1"
                  sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                  gutterBottom
                >
                  {STATUS[newStatus]}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
              >
                <Button
                  variant="contained"
                  onClick={onClose}
                  sx={{
                    backgroundColor: "#0B3013!important",
                    fontSize: 16,
                    borderRadius: 20,
                    textTransform: "capitalize",
                    px: 4,
                    py: 1,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0B3013!important",
                    fontSize: 16,
                    borderRadius: 20,
                    textTransform: "capitalize",
                    px: 6,
                    py: 1,
                  }}
                  variant="contained"
                  onClick={onProceed}
                >
                  Proceed
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </Modal>
    </>
  );
}
