// SupportManagementTable.jsx
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Modal,
  useMediaQuery,
  Typography,
  Button,
  InputBase,
  InputAdornment,
} from "@mui/material";

import eye from "../../../Assets/Images/Eye.png";
import editICO from "../../../Assets/Images/verified.png";
import deleteICO from "../../../Assets/Images/DeleteICon.png";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { toast } from "react-toastify";
import { deleteAPI, getAPI, updateAPI } from "../../../Services/Api";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../../Components/NodataFound/NodataFound";
import { useTheme } from "@emotion/react";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import deleteVec from "../../../Assets/Images/deleteVector.png";
import resolveVec from "../../../Assets/Images/resolve-vector.png";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

// Sample data for different tabs
const tabData = {
  open: [
    {
      name: "John Doe",
      email: "s@hnkd.com",
      queries: "Lorem Ispum ...",
      status: "Pending",
    },
    {
      name: "Jane Smith",
      email: "jsmith@domain.com",
      queries: "Another query ...",
      status: "Resolved",
    },
  ],
  inProgress: [
    {
      name: "Alice Johnson",
      email: "alice@domain.com",
      queries: "In progress ...",
      status: "In Progress",
    },
  ],
  closed: [
    {
      name: "Bob Brown",
      email: "bob@domain.com",
      queries: "Resolved ...",
      status: "Resolved",
    },
  ],
};

const getChipColor = (status) => {
  switch (status) {
    case "pending":
      return { color: "#267cff", label: "Pending" };
    case "resolved":
      return { color: "#2ab95f", label: "Resolved" };
    default:
      return { color: "#e0e0e0", label: "Unknown" };
  }
};

export default function SupportManagementTable({
  onEditClick,
  onDeleteClick,
  onViewClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();

  const [tickets, setTickets] = useState([]);
  const [tab, setTab] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const searchRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const [showPagination, setShowPagination] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [timeOutId, setTimeOutId] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setResolveOpen(false);
    setSelectedTicket(null);
  };

  const handleOpen = (ticket) => {
    setOpen(true);
    setSelectedTicket(ticket);
  };

  const handleResolveOpen = (ticket) => {
    setResolveOpen(true);
    setSelectedTicket(ticket);
  };

  useEffect(() => {
    setTickets([]);
    setTab("");
    setSearchText("");
    if (searchRef?.current?.querySelector("input"))
      searchRef.current.querySelector("input").value = "";
    getTickets({}, 1, "", "");
  }, [location]);

  const getTickets = async (
    ev = {},
    pageNumber = 1,
    status = tab,
    search = searchText
  ) => {
    LoaderShow();
    await getSearchedTickets(ev, pageNumber, status, search);
    LoaderHide();
  };

  const getSearchedTickets = async (
    ev = {},
    pageNumber = 1,
    status = tab,
    search = searchText
  ) => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const response = await getAPI(
          `contactus/contact-us-list?page=${pageNumber}&type=${
            window.location.pathname.includes("support-management")
              ? "help"
              : "contact"
          }&status=${status}&search=${search}`
        );
        if (response?.statusCode == 200) {
          setTickets(response?.data?.contactUsList || []);
          setTotalPages(response?.data?.totalPages);
        } else toast.error(response?.message || "Something went wrong!");
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const response = await deleteAPI(
          "contactus/delete",
          { id: selectedTicket._id },
          token
        );
        if (response.statusCode == 200) {
          setTickets((tickets) =>
            tickets.filter((ele) => ele._id != selectedTicket._id)
          );
          handleClose();
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleConfirmResolve = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const response = await updateAPI("contactus/status-update", {
          status: "resolved",
          id: selectedTicket._id,
        });
        if (response.statusCode == 200) {
          toast.success("Changed status successfully");
          setTickets((tickets) => {
            if (!tab) {
              return tickets.map((ele) => {
                if (ele._id == selectedTicket._id) {
                  ele.status = "resolved";
                }
                return ele;
              });
            } else {
              return tickets.filter((ele) => ele._id != selectedTicket._id);
            }
          });
          handleClose();
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
    LoaderHide();
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    setShowPagination(false);
    getTickets({}, 1, newTab);
    setShowPagination(true);
  };

  const handleSearchChange = (event) => {
    if (timeOutId) clearTimeout(timeOutId);
    setTimeOutId(
      setTimeout(async () => {
        setShowPagination(false);
        setSearchText(event.target.value);
        await getSearchedTickets({}, 1, tab, event.target.value);
        setShowPagination(true);
      }, 500)
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 3,
          justifyContent: "end",
        }}
      >
        <InputBase
          ref={searchRef}
          placeholder="Search…"
          onChange={handleSearchChange}
          sx={{
            maxWidth: "400px",
            ml: 1,
            flex: 1,
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "24px",
            py: "8px",
            px: "20px",
            justifyContent: "flex-end",
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#000" }} />
            </InputAdornment>
          }
        />
      </Box>
      <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "14px" }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={deleteVec}
                  alt="Delete Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                Are you sure you want to delete?
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                This action cannot be undone.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={handleConfirmDelete}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={resolveOpen}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center",
                  py: 1,
                }}
              >
                <img
                  className="deleteVec"
                  src={resolveVec}
                  style={{ height: "240px" }}
                  alt="Resolve Vector"
                />
              </Box>
              <Typography
                id="logout-modal-description"
                variant="h5"
                sx={{
                  fontWeight: 700,
                  fontSize: 32,
                  textAlign: "center",
                  py: 2,
                  pb: 1,
                }}
                gutterBottom
              >
                Are you sure it is Resolved ?
              </Typography>
              <Typography
                id="logout-modal-description"
                variant="body1"
                sx={{ fontSize: 18, textAlign: "center", display: "block" }}
                gutterBottom
              >
                This action cannot be undone.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 4,
                  py: 1,
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#0B3013!important",
                  fontSize: 16,
                  borderRadius: 20,
                  textTransform: "capitalize",
                  px: 6,
                  py: 1,
                }}
                variant="contained"
                onClick={handleConfirmResolve}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="support tickets tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0B3013", // Active tab indicator color
            },
          }}
        >
          <Tab
            sx={{
              fontFamily: "inherit",
              fontSize: 16,
              textTransform: "capitalize",

              "&.Mui-selected": {
                color: "#0B3013", // Active tab label color
              },
            }}
            label="All"
            value=""
          />
          <Tab
            sx={{
              fontFamily: "inherit",
              fontSize: 16,
              textTransform: "capitalize",

              "&.Mui-selected": {
                color: "#0B3013", // Active tab label color
              },
            }}
            label="Open"
            value="pending"
          />
          <Tab
            sx={{
              fontFamily: "inherit",
              fontSize: 16,
              textTransform: "capitalize",

              "&.Mui-selected": {
                color: "#0B3013", // Active tab label color
              },
            }}
            label="Resolved"
            value="resolved"
          />
        </Tabs>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            mt: 2,
            overflow: "initial",
          }}
        >
          {tickets.length > 0 ? (
            <>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <Table
                  aria-label="user management table"
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px 24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {window.location.pathname.includes("support-management")
                          ? "Title"
                          : "Name"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px 24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px 24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Message
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px 24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "24px 24px",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                          fontSize: "16px",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tickets.map((ticket, index) => {
                      const chip = getChipColor(ticket.status);
                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px 24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            {window.location.pathname.includes(
                              "support-management"
                            )
                              ? ticket.title || "-"
                              : ticket.firstName || "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px 24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            {ticket.email || "-"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px 24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom: "1px solid #e0e0e0",
                              maxWidth: "300px",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {ticket.message}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px 24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <Chip
                              label={chip.label}
                              sx={{
                                py: 2.2,
                                px: 2,
                                backgroundColor: chip.color,
                                color: "#fff",
                                borderRadius: "30px",
                                fontSize: "16px",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              padding: "24px 24px",
                              fontFamily: "inherit",
                              fontSize: "16px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <IconButton
                              sx={{
                                backgroundColor: "#f7fdf9",
                                borderRadius: "4px",
                                p: 0.5,
                              }}
                              color="primary"
                              aria-label="view"
                              onClick={() => onViewClick(ticket)}
                            >
                              <img src={eye} alt="View" />
                            </IconButton>
                            {ticket.status == "pending" && (
                              <IconButton
                                sx={{
                                  backgroundColor: "#f7fdf9",
                                  borderRadius: "4px",
                                  mx: 1,
                                  p: 0.5,
                                }}
                                color="primary"
                                aria-label="edit"
                                onClick={() => handleResolveOpen(ticket)}
                              >
                                <img src={editICO} alt="Edit" />
                              </IconButton>
                            )}
                            <IconButton
                              sx={{
                                backgroundColor: "#f7fdf9",
                                borderRadius: "4px",
                                mx: 1,
                                p: 0.5,
                              }}
                              aria-label="delete"
                              onClick={() => handleOpen(ticket)}
                            >
                              <img src={deleteICO} alt="Delete" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Box
                sx={{ display: "flex", justifyContent: "end", py: 3, px: 2 }}
              >
                {showPagination && (
                  <CustomPagination count={totalPages} onChange={getTickets} />
                )}
              </Box>
            </>
          ) : (
            <NodataFound />
          )}
        </TableContainer>
      </Box>
    </>
  );
}
