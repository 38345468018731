import React, { useEffect, useState } from "react";
// import "./ProfileEmployee.css";
import {
  Box,
  Typography,
  Button,
  FormLabel,
  Avatar,
  TextField,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import "../../../../../Localization/i18n";
import LockIcon from "@material-ui/icons/Lock"; // Add this line

// rest of your code

export default function ProfileEmployer(props) {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const [isFocused, setIsFocused] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (props?.employerUserData?.profileEmployer) {
      setUserName(props?.employerUserData?.profileEmployer?.userName);
    }
  }, []);

  // Function to handle avatar upload
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatarUrl(reader.result);
    };
    reader.readAsDataURL(file);
    setAvatar(file);
  };

  const handleNextStep = () => {
    let isValid = true;

    if (userName.trim() === "") {
      setUserNameError("Please enter your user name");
      isValid = false;
    } else {
      setUserName("");
    }

    if (isValid) {
      const dataToSend = {
        userName: userName,
      };
      if (avatar) dataToSend.profile = avatar;
      props.SetEmployerUserDataObject("profileEmployer", dataToSend);
      props.handleNextStepEmployer();
    }
  };

  return (
    <Box
      sx={{
        p: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="sideBg"
    >
      <Box>
        <Typography variant="h5" sx={{ textAlign: "start", fontWeight: 700, pb: 1 }}>
          {t("Complete")}
        </Typography>
        <p className="beginText">{t("FoPurpose")}</p>
      </Box>

      {/* Avatar */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
        }}
      >
        <Avatar
          src={avatarUrl}
          sx={{ width: 120, height: 120, border: 1, borderColor: "#ccc" }}
          onClick={() => document.getElementById("avatar-upload").click()}
        />
        {avatar && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              fontSize: 20,
              borderRadius: 2,
              padding: 0.4,
              marginLeft: 10,
            }}
          >
            <EditIcon
              color="secondary"
              sx={{
                backgroundColor: "#0B3013",
                fontSize: 20,
                padding: 0.4,
                borderRadius: 2,
                color: "#fff",
              }}
              onClick={() => document.getElementById("avatar-upload").click()}
            />
          </Box>
        )}
        {!avatar && (
          <label
            htmlFor="avatar-upload"
            style={{
              position: "absolute",
              bottom: 0,
              left: 76,
              right: 0,
              cursor: "pointer",
            }}
          >
            <EditIcon
              color="secondary"
              sx={{
                backgroundColor: "#0B3013",
                fontSize: 20,
                padding: 0.4,
                borderRadius: 2,
                color: "#fff",
              }}
            />
          </label>
        )}

        <input
          accept="image/*"
          id="avatar-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleAvatarChange}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}
        >
          {t("User")}
        </FormLabel>
        <TextField
          variant="outlined"
          sx={{
            background: isFocused ? "#FFFFFF" : "transparent",
            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
            boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          }}
          fullWidth={true}
          placeholder={t("EnterUserName")}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          error={!!userNameError}
          helperText={userNameError}
        />
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          onClick={handleNextStep}
          // onClick={props.handleNextStepEmployer}
          className="SignUpBtn"
          sx={{ textTransform: "capitalize", width: 100, color: "white" }}
        >
          {t("Next")}
        </Button>
      </Box>
      <Box sx={{ my: 1 }}>
        <FormLabel
          sx={{
            display: "flex",
            textAlign: "center",
            py: 1,
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
          {t("YourInfo")}
        </FormLabel>
      </Box>
    </Box>
  );
}
